import { observer } from "mobx-react";
import React from "react";
import rootStores from "../../../../BL/stores";
import { IJobOccupation } from "../../../../common/interfaces/Job-Occupation";
import "./autoCorrectCard.scss";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/reuseables/close_light.svg'

const languageStore = rootStores.LangsStore;

interface Props {
  data: any[];
  setSelectedJobOccupation: (value: Array<IJobOccupation>) => void;
}

const AutoCorrectCard: React.FC<Props> = ({ data, setSelectedJobOccupation }) => {
  return (
    <div className="recent-occupations-container">
      {data[0] !== undefined
        ? data.map((jobOccupation: IJobOccupation, index: number) => {
          return (
            <div className="recent-occupations-bar" key={index}>
              <div
                className="recent-occupations-bar__delete"
                tabIndex={1}
                onClick={(e) => {
                  const filtered: Array<IJobOccupation> = data.filter(
                    (joboccupation) =>
                      jobOccupation.male_name !== joboccupation.male_name
                  );
                  setSelectedJobOccupation(filtered);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const filtered: Array<IJobOccupation> = data.filter(
                      (joboccupation) =>
                        jobOccupation.male_name !== joboccupation.male_name
                    );
                    setSelectedJobOccupation(filtered);
                  }
                }}
              >
                <CloseIcon />
              </div>
              <p className="recent-occupations-bar__text">
                {languageStore.currentLanguageIndex === 0
                  ? jobOccupation.male_name
                  : jobOccupation.female_name}
              </p>
            </div>
          );
        })
        : ""}
    </div>
  );
};

const AutoCorrectCardObserver = observer(AutoCorrectCard);
export default AutoCorrectCardObserver;
