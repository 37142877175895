import React, { useEffect, useState } from "react";
import CSS from "csstype";

import "./RegistrationForm.scss";
import leftArrow from "../../../assets/images/Main/Arrow_main.png";
import UserFetcher from "../../../Infrastructure/fetchers/UserFetcher";
import rootStores from "../../../BL/stores";
import {
  LANGS_STORE,
  QUESTIONNAIRE_STORE,
  USER_INFO_STORE,
  CITIES_STORE,
  APP_STORE,
} from "../../../BL/consts/stores";
import { IInterest } from "../../interfaces/Interest";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import White_radio_full from "../../../assets/images/Main/White_radio_full.svg";
import White_radio from "../../../assets/images/Main/White_radio.svg";
import { ModalStatus, SideBarModals } from "../../../BL/stores/App.store";
import TermsOfUse from "../../../UI/sideBar/modals/termsOfUse/TermsOfUse";

interface Props {}

interface Props {
  width?: string | number;
  height?: string | number;
  style?: CSS.Properties;
  className?: string;
  title?: string;
  textRegisterButton:string
  onSubmit:(event:any)=>void;
  onCancel:()=>void;
}
export interface ISubmitResult {
  privateName?: string;
  lastName?: string;
  email?: any;
  password?: any;
}

const userInfoStore = rootStores[USER_INFO_STORE];
const questionsStore = rootStores[QUESTIONNAIRE_STORE];
const languageStore = rootStores[LANGS_STORE];
const cityStore = rootStores[CITIES_STORE];
const appStore = rootStores[APP_STORE];

const RegistrationForm: React.FC<Props> = ({ style, title ,textRegisterButton,onCancel,onSubmit}) => {
  
  const {setChosenModal} = appStore;
  const {
    selectedPreviousJobs,
    selectedInterestsS,
    selectedAge,
    selectedIsEmployee,
    selectedEducationalDegree,
    isAgreeToTerms,
    setIsAgreeToTerms,
    setEmail
  } = userInfoStore;
  const { currentLanguageIndex ,languages} = languageStore;
  const { selectedCity } = cityStore;
  const { IQuestionnaire_info, answers, questions } = questionsStore;
  const [inputs, setInputs] = useState<ISubmitResult>({});
  const [toAgreeTerms, setToAgreeTerms] = useState<boolean>(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);
  const history = useHistory();

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };
  const { register, handleSubmit, formState: { errors }, setError,clearErrors } = useForm<any>({
    criteriaMode: "all"
  });

  const login = () => {
    onCancel();
    setChosenModal(SideBarModals.account);
  };

  useEffect(()=>{
    setError('terms',{type:"required"})
  },[])

  const onClickTerms = () => {
    if (toAgreeTerms) {
      setError("terms", { type: "required" });
      setToAgreeTerms(!toAgreeTerms);
    } else {
      clearErrors("terms");
      setToAgreeTerms(!toAgreeTerms);
    }
  };

  
  return (
    <form
      className="form-registration"
      onSubmit={handleSubmit(onSubmit)}
      style={{ ...style }}
    >
      {isTermsModalOpen && (
        <TermsOfUse onClose={() => setIsTermsModalOpen(false)} />
      )}
      {/* {title && (
        <h1 className="h1Registration" style={{ color: "white" }}>
          {title}
        </h1>
      )} */}
      <div className="input-contact-container-registration">
        <input
          className="inputForm"
          type="text"
          placeholder="שם פרטי"
          {...register("privateName", {
            required: true,
            pattern: /^[a-z\u0590-\u05fe]+$/i,
            minLength: 2,
          })}
        />
      </div>
      {errors.privateName && errors.privateName.type === "pattern" ? (
        <p className="position-registration-form"> שם מלא באותיות בלבד</p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      {errors.privateName && errors.privateName.type === "required" ? (
        <p className="position-registration-form">שם מלא באותיות בלבד</p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      {errors.privateName && errors.privateName.type === "minLength" ? (
        <p className="position-registration-form">יש להכניס לפחות אות אחת</p>
      ) : (
        <p className="position-registration-form"></p>
      )}

      <div className="input-contact-container-registration">
        <input
          className="inputForm"
          type="text"
          placeholder="שם משפחה"
          {...register("lastName", {
            required: true,
            pattern: /^[a-z\u0590-\u05fe]+$/i,
          })}
        />
      </div>
      {errors.lastName && errors.lastName.type === "pattern" ? (
        <p className="position-registration-form"> שם מלא באותיות בלבד</p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      {errors.lastName && errors.lastName.type === "required" ? (
        <p className="position-registration-form">שם מלא באותיות בלבד</p>
      ) : (
        <p className="position-registration-form"></p>
      )}

      <div className="input-contact-container-registration">
        <input
          className="inputForm"
          type="email"
          placeholder={'דוא"ל'}
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
      </div>
      {errors.email && errors.email.type === "required" ? (
        <p className="position-registration-form">חובה להזין כתובת מייל</p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      {errors.email && errors.email.type === "pattern" ? (
        <p className="position-registration-form">יש להזין כתובת דוא"ל תקינה</p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      <div className="input-contact-container-registration">
        <input
          className="inputForm"
          type="password"
          placeholder={"יצירת סיסמה"}
          {...register("password", {
            required: true,
            maxLength: 12,
            minLength: 8,
            pattern:
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,12}$/,
          })}
        />
      </div>
      {errors.password && errors.password?.type === "maxLength" ? (
        <p className="position-registration-form">ניתן להכניס עד 12 תווים</p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      {errors.password && errors.password?.type === "minLength" ? (
        <p className="position-registration-form">
          ניתן להכניס מינימום 8 תווים
        </p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      {errors.password && errors.password?.type === "required" ? (
        <p className="position-registration-form">הכנס ערכים היא חובה </p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      {errors.password && errors.password?.type === "pattern" ? (
        <p className="position-registration-form">
          חובה להכניס סיסמה בין 8-12 תווים, אות גדולה אחת סיפרה אחת, וסימן אחד{" "}
        </p>
      ) : (
        <p className="position-registration-form"></p>
      )}

      <div>
        <button
          type="button"
          className="radio-button-to-agree-terms"
          onClick={onClickTerms}
        >
          {toAgreeTerms ? (
            <img src={White_radio} alt="radio button" />
          ) : (
            <img src={White_radio_full} alt="radio button" />
          )}
        </button>
        <p className="agree-to-terms">אני מסכימ\ה ל</p>
        <p
          className="agree-to-terms underline pointer"
          onClick={() => setIsTermsModalOpen(true)}
          onKeyUp={(e)=>e.key=="Enter" && setIsTermsModalOpen(true)}
          tabIndex={0}
          role={"button"}
        >
          תנאי השימוש
        </p>
      </div>
      {errors.terms ? (
        <p className="position-registration-form">חובה לאשר את תנאי השימוש </p>
      ) : (
        <p className="position-registration-form"></p>
      )}
      <div className="footer-container">
        <button
          type="button"
          className="continue-as-guest-button"
          id="existing_account"
          onClick={login}
        >
          <p className="blue2 bolded underline pointer font-size-registration">
            {"יש לי כבר חשבון"}
          </p>
        </button>
        <button
          type="submit"
          className="submit-container-registration-form"
          id="submit-registration"
        >
          <p className="create-user-text-registration-form">
            {textRegisterButton}
          </p>
          <img
            className="arrow-registration-form"
            src={leftArrow}
            alt="press to-continue"
          />
        </button>
      </div>
    </form>
  );
};

const RegistrationFormObserver = observer(RegistrationForm);

export default RegistrationFormObserver;
