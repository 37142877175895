import React from "react";
import CSS from "csstype";

interface Props {
  src: string;
  width?: string | number;
  height?: string | number;
  alt?: string;
  style?: CSS.Properties;
  className?: string;
  onClick?(): void;
  onKeyPress?: any;
  role?: any;
  tabIndex?: any;
  ariaPressed?: any;
  id?: string;
}

const Image: React.FC<Props> = ({
  src,
  width,
  height,
  alt,
  style,
  className,
  onClick,
  onKeyPress,
  role,
  tabIndex,
  ariaPressed,
  id,
}) => {
  // functionality
  return (
    <img
      id={id}
      src={src}
      height={height}
      width={width}
      style={{ ...style }}
      alt={alt ? alt : ""}
      className={className ? className : undefined}
      onClick={onClick}
      onKeyPress={onKeyPress}
      role={role}
      tabIndex={tabIndex}
      aria-pressed={ariaPressed}
    />
  );
};

export default Image;
