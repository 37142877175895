import { IObservableArray, toJS } from "mobx"
import { IJobOccupation } from "../interfaces/Job-Occupation";

export const setDataLayer = (
    event: string, trainingName: string | undefined,
    trainingInstitution: string | undefined,
    trainingLocation: string | undefined,
    trainingFormat: string | undefined,
    isDiploma: boolean | undefined,
    isEmploymentGuaranteed: boolean | undefined,
    isBudgeting: boolean | undefined,
    occupationName: string | null | undefined,
) => {
    (window as any).dataLayer.push({
        'event': `${event}`,
        name: trainingName,
        training_institution: trainingInstitution,
        location: trainingLocation,
        training_format: trainingFormat,
        is_diploma: isDiploma ? "yes" : "no",
        is_employment_guaranteed: isEmploymentGuaranteed ? "yes" : "no",
        is_budgeting: isBudgeting ? "yes" : "no",
        occupation_name: occupationName,
    })
}

export const setLastOccupationsToDataLayer = (event: string, lastOccupations: IObservableArray<IJobOccupation>) => {
    const previousOccupations = toJS(lastOccupations);

    previousOccupations.forEach((occupation: any) => {
        (window as any).dataLayer.push({
            'event': `${event}`,
            previous_occupation_selected: occupation.male_name
        })
    })
}