import { observer } from "mobx-react";
import rootStores from "../../BL/stores";
import "./HollandQuestionnaire.scss"

const questionnaireStore = rootStores.questionnaireStore;
const languageStore = rootStores.LangsStore;
interface Props {
    moveNextPage: any;
    setLevel: any;
}

const SkipQuestionnaire: React.FC<Props> = ({ moveNextPage, setLevel }) => {
    const { skipAllQuestion } = questionnaireStore
    return (
        <button
            className="skip-questionnaire-btn-introduction p-md-JO"
            id="skip-questionnaire-btn-introduction"
            onClick={() => {
                moveNextPage();
                setLevel(3);
                skipAllQuestion()
            }}
        >
            {languageStore.translate("questionnairePage", "skipOnQuestionnaire")}
        </button>
    );
};

const SkipQuestionnaireObserver = observer(SkipQuestionnaire);
export default SkipQuestionnaireObserver;