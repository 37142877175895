import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ICourse } from "../../../../common/interfaces/Course";
import "./CardCourseComponent.scss";
import { ReactComponent as Location } from "../../../../assets/Location_P.svg";
import { ReactComponent as Time_p } from "../../../../assets/Time_p.svg";
import { ReactComponent as Card_Arrow } from "../../../../assets/Card_Arrow.svg";

interface IProps {
  course: ICourse;
  moveToCoursePage: (name: string, course: ICourse, courses: ICourse[]) => void;
  filterMemo: any;
  index: number;
}

const CardCourseComponent: React.FC<IProps> = ({
  course,
  moveToCoursePage,
  filterMemo,
  index,
}) => {

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <div
      role="button"
      tabIndex={0}
      aria-pressed="false"
      onClick={() => moveToCoursePage(course.name, course, filterMemo)}
      className={index % 2 === 0 ? "course-card-container" : "course-card-container button-without-border"}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          moveToCoursePage(course.name, course, filterMemo);
        }
      }}
    >
      <div className="course-card__main-container">
        <div className="course-card-container__institution-details">
          <p className="institution-name" tabIndex={0}>
            {course.training_institution.name}
          </p>
          {dimensions.width < 670 && <Card_Arrow />}
        </div>
        <div className="course-card-container__course-details">
          <p className="course-name" tabIndex={0}>
            {course.name}
          </p>
        </div>
        <div className="course-card-container__time-and-location">
          <div className="course-details-row">
            {course.course_format || course.duration.months ? (
              <>
                <div className="details-icon time-icon">
                  <Time_p />
                </div>
                <p className="course-info time-and-duration" tabIndex={0}>
                  {course.duration.months !== null
                    ? `${course.duration.months} חודשי הכשרה, `
                    : null}
                  {course.course_format === "morning"
                    ? `קורס בוקר`
                    : course.course_format === "evening"
                      ? "קורס ערב"
                      : course.course_format === "online"
                        ? "קורס אונליין"
                        : null}{" "}
                </p>
              </>) : null}
          </div>
          <div className="course-details-row">
            {course.location && (
              <>
                <div className="details-icon location-icon"><Location /></div>
                <p className="course-info location" style={{ display: "block" }} tabIndex={0}>
                  {course.location === "online" ? "למידה מרחוק" : course.location}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="course-card-container__tags" >
          {course.diploma && (
            <p className="tags__text" tabIndex={0}>{"תעודה ממשלתית"}</p>
          )}
          {course.employment_guaranteed && (
            <p className="tags__text" tabIndex={0}>{"השמה"}</p>
          )}
          {course.budgeting && (
            <p className="tags__text" tabIndex={0}>{"סבסוד"}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const CardCourseComponentObserver = observer(CardCourseComponent);
export default CardCourseComponentObserver;
