import { IJobOccupation } from "../../common/interfaces/Job-Occupation";
import { ICategories } from "../../Infrastructure/interfaces/CategoriesFetcher.interface";
import { BaseHttpFetcher } from "./BaseHttpFetcher";
import axios from "axios";
import ClientConfig from "../../common/config";
import SortingUtils from "../../common/utils/sortingUtils";
class JobOccupationFetcher extends BaseHttpFetcher {
  getJobOccupations = async (): Promise<IJobOccupation[]> => {
    try {
      const res = await axios.get(ClientConfig.apiBaseHost + "/joboccupation", {
        headers: { "Content-Type": "application/json" },
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  getJobOccupationsTrueCourse = async (): Promise<IJobOccupation[]> => {
    try {
      const res = await axios.get(ClientConfig.apiBaseHost + "/joboccupation?/with_no_courses=False", {
        headers: { "Content-Type": "application/json" },
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  getInterests = async (): Promise<ICategories[]> => {
    try {
      const res = await axios.get(
        ClientConfig.apiBaseHost + "/joboccupation/categories",
        { headers: { "Content-Type": "application/json" } }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  getRecommendedJobOccupationsByUserInfo = async (
    data: any
  ): Promise<IJobOccupation[]> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + "/joboccupation/recommended",
        data,
        { headers: { "Content-Type": "application/json" } }
      );
      // const sortedRes = SortingUtils.jobOccupationBubbleSort(res.data);
      const sortedRes: IJobOccupation[] = SortingUtils.jobOccupationNameBubbleSort(res.data)
      return sortedRes;
    } catch (err) {
      throw err;
    }
  };
  findOrCreateSearch = async (
    value: string
  ): Promise<any> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + "/search",
        { value },
        { headers: { "Content-Type": "application/json" } }
      );
      // const sortedRes = SortingUtils.jobOccupationBubbleSort(res.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export default new JobOccupationFetcher(`joboccupation`);
