import React, { FC } from "react";
import Image from "../../../../common/components/image.component";
import { IInterest } from "../../../../common/interfaces/Interest";
import "./InterestCardComponent.scss";
import { ComputerIcon } from "../../../../assets/svg_components/ComputerIcon";
import { BuildingIcon } from "../../../../assets/svg_components/BuildingIcon";
import { DesingIcon } from "../../../../assets/svg_components/DesingIcon";
import { LogisticIcon } from "../../../../assets/svg_components/LogisticIcon";
import { ManufacturingIcon } from "../../../../assets/svg_components/ManufacturingIcon";
import { HealthIcon } from "../../../../assets/svg_components/HealthIcon";
import { FinanceIcon } from "../../../../assets/svg_components/FinanceIcon";
import { OfficeIcon } from "../../../../assets/svg_components/OfficeIcon";
import { EducationIcon } from "../../../../assets/svg_components/EducationIcon";
import { TourismIcon } from "../../../../assets/svg_components/TourismIcon";
import { QaIcon } from "../../../../assets/svg_components/QaIcon";
import { SalesIcon } from "../../../../assets/svg_components/SalesIcon";
import { DriveIcon } from "../../../../assets/svg_components/DriveIcon";
import { MangeIcon } from "../../../../assets/svg_components/MangeIcon";
import { names } from "../../InterestNamesConst";
interface IProps {
  interest: IInterest;
  handleSelectedInterests: (
    item: { id: number; name: string },
    e: HTMLDivElement
  ) => void;
  isSelected: boolean;
}

const InterestCardComponent: React.FC<IProps> = ({
  interest,
  handleSelectedInterests,
  isSelected,
}) => {
  const handleClick = (e: HTMLDivElement): void => {
    handleSelectedInterests({ id: interest.id, name: interest.name }, e);
  };

  const selectedColor = "#0C7AF6";
  const notSelectedColor = "#1B1F45";

  const getSvg: any = (name: string) => {
    switch (name) {
      case names.computers:
        return (
          <ComputerIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.manufacturing:
        return (
          <ManufacturingIcon
            fill={isSelected ? selectedColor : notSelectedColor}
          />
        );
      case names.logistic:
        return (
          <LogisticIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.design:
        return (
          <DesingIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.building:
        return (
          <BuildingIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.health:
        return (
          <HealthIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.finance:
        return (
          <FinanceIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.office:
        return (
          <OfficeIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.education:
        return (
          <EducationIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.tourism:
        return (
          <TourismIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.qa:
        return <QaIcon fill={isSelected ? selectedColor : notSelectedColor} />;
      case names.sales:
        return (
          <SalesIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.drive:
        return (
          <DriveIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );
      case names.mange:
        return (
          <MangeIcon fill={isSelected ? selectedColor : notSelectedColor} />
        );

      default:
        return <ComputerIcon fill={"#ffff"} />;
        break;
    }
  };

  return (
    <div
      className={`interest-card ${isSelected ? "selected" : ""}  `}
      onClick={(e) => handleClick(e.currentTarget)}
      role={"button"}
      tabIndex={0}
      aria-pressed={isSelected ? true : false}
      aria-describedby={"interest-title-img"}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleClick(e.currentTarget);
        }
      }}
    >
      <div className="interest-svg__container">
        {getSvg(interest.name)}
      </div>
      <p className="card-text">
        {interest.name}
      </p>
    </div>
  );
};

export default InterestCardComponent;
