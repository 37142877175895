import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import UserFetcher from "../../../Infrastructure/fetchers/UserFetcher";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react";
import Logo from "../../../assets/logo.svg";
import "./ForgetPassword.scss";
const ForgotPasswordPage: React.FC = () => {
  const { token }: { token: string } = useParams();
  const [error, setError] = useState("");
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  useEffect(() => {
    (async function anyNameFunction() {
      await UserFetcher.checkIfUserTokenValid(token).catch((err) => {
        alert("משהו השתבש אנא נסה שנית");
        history.push("/");
      });
    })();
  }, []);

  const checkPassword = async (data: any) => {
    if (data.password != data.passwordRef) {
      setError("סיסמאות אינן תואמות")
      return;
    }
    setError("")
    await UserFetcher.forgottenPasswordUpdate(data.password, token).then(
      (res) => {
        alert("סיסמה השתנתה בהצלחה");
        history.push("/");
      }
    );
  };
  return (
    <>
      <div className="forget-password-page">
        <img className="logo-forget-password-page" src={Logo} alt="" />
        <h2 style={{ marginTop: "1rem", marginBottom: 0 }}>
          אנא בחר סיסמה חדשה
        </h2>
        <p className="forget-pasword-rules p-lg-JO" style={{ marginTop: 0 }}>
          {" "}
          חובה להכניס סיסמה בין 8-12 תווים אות גדולה אחת סיפרה אחת וסימן אחד{" "}
        </p>

        <form onSubmit={handleSubmit(checkPassword)} className="form-container">
          <input
            placeholder="סיסמה חדשה"
            className="forget-password-input"
            type="password"
            {...register("password", {
              required: true,
              maxLength: 12,
              minLength: 8,
              pattern:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,12}$/,
            })}
          />
          <p
            className="p-md-JO"
            style={{
              color: "red",
              visibility: errors.password ? "visible" : "hidden",
            }}
          >
            {errors?.password?.type === "required"
              ? "הכנסת תווים היא חובה"
              : "חובה להכניס סיסמה בין 8-12 תווים אות גדולה אחת סיפרה אחת וסימן אחד"}
          </p>

          <input
            className="forget-password-input"
            type="password"
            {...register("passwordRef", {
              required: true,
            })}
            placeholder="אימות סיסמה חדשה"
          />

          <p
            className="p-md-JO"
            style={{
              color: "red",
              visibility: errors.passwordRef || error ? "visible" : "hidden",
            }}
          >
            {error ? error : "הכנסת תווים היא חובה"}
          </p>

          <button className="forget-button" type="submit">
            אישור סיסמה חדשה
          </button>
        </form>
      </div>
    </>
  );
};

const ForgotPasswordPageObser = observer(ForgotPasswordPage);
export default ForgotPasswordPageObser;
