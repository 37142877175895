
import { BaseHttpFetcher } from "./BaseHttpFetcher";
import axios from "axios";
import ClientConfig from "../../common/config";
class TranslateFetcher extends BaseHttpFetcher {

  getTranslateFetcher = async (): Promise<any> => {
    try {
      const res = await axios.get(ClientConfig.apiBaseHost + "/translate", {
        headers: { "Content-Type": "application/json" },
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };


  // updateUserQuestionnaire = async (body: {question:string,answer:number}[]): Promise<any> => {
  //   try {
  //     const res = await axios.put(
  //       ClientConfig.apiBaseHost + "/question",
  //       body,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         }
  //       }


  //     );
  //     return res.data;

  //   } catch (error) {
  //     Promise.reject(error);
  //   }
  // }


}


export default new TranslateFetcher(`translate`);
