import React, { useMemo, useRef, useState } from "react";
import "./textInputAutoCorrect.scss";
import AutoCorrectCard from "../autoCorrectCard/autoCorrectCard.component";
import { IJobOccupation } from "../../../../common/interfaces/Job-Occupation";
import rootStores from "../../../../BL/stores";
import { observer } from "mobx-react";
import { toJS } from "mobx";

const languageStore = rootStores.LangsStore;
interface Props {
  data: Array<any>;
  type: string;
  setSelectedPreviousJobs: (value: Array<IJobOccupation>) => void;
  selectedPreviousJobsS: Array<IJobOccupation>;
  setSelectedPreviousJobsS: (selectedPreviousJobsS: any) => void;
  // setSelectedPreviousJobsLocal: (previousJobs: any) => void;
}

const JobOccupationTextInputAutoComplete: React.FC<Props> = ({
  data,
  type,
  setSelectedPreviousJobs,
  setSelectedPreviousJobsS,
  selectedPreviousJobsS,
}) => {
  const { currentLanguageIndex } = languageStore;
  let [search, setSearch] = useState<string>("");

  const _filteredData = useMemo(
    () => filterDataByName(search, data),
    [search, data]
  );

  function onChangeText(e: any) {
    setSearch(e.target.value);
  }

  function filterDataByName(value: string | undefined, data: any) {
    const filteredData: Array<any> = toJS(data).filter(
      (obj: any) => obj.male_name.includes(value) || obj.female_name.includes(value)
    );

    filteredData.sort((a, b) =>
      currentLanguageIndex == 0
        ? a.male_name.indexOf(value) == 0 && b.male_name.indexOf(value) != 0
          ? -1
          : a.male_name.indexOf(value) != 0 && b.male_name.indexOf(value) == 0
            ? 1
            : 0
        : a.female_name.indexOf(value) == 0 && b.female_name.indexOf(value) != 0
          ? -1
          : a.female_name.indexOf(value) != 0 && b.female_name.indexOf(value) == 0
            ? 1
            : 0
    );
    return filteredData
  }

  function onClickText(obj: IJobOccupation, e: any): any {
    if (selectedPreviousJobsS.length < 3 && !isJobAlreadyExist(obj)) {
      setSelectedPreviousJobsS([...selectedPreviousJobsS, obj]);
    }
    setSearch("");
  }

  function isJobAlreadyExist(obj: IJobOccupation) {
    return selectedPreviousJobsS.some(item => obj.description === item.description)
  }

  return (
    <div className="textInput-container">
      {type === "city" && (
        <div className="input-container">
          <input
            // ref={(inputRef) => { inputRef?.focus() }}
            type="text"
            className="choose-past-jobs"
            placeholder="תוך כדי הקלדה כאן נציע לך שמות של עיסוקים ותפקידים שניתן לסמן ולבחור"
            onChange={onChangeText}
            value={search}
          />
        </div>
      )}
      {search !== "" && (
        <div dir="ltr" id="last-things-scroll-bar" className="values-div">
          {_filteredData.map((obj: any, index: number) => {
            return (
              <li
                className="past-jobs-item"
                key={index}
                value={obj}
                onClick={(e) => onClickText(obj, e)}
                dir="rtl"
                style={{ lineHeight: '200%' }}
              >
                {languageStore.currentLanguageIndex !== 1 ? obj.male_name : obj.female_name}
              </li>
            );
          })}
        </div>
      )}


      <div className="card-container">
        {selectedPreviousJobsS.length > 0 && (
          <AutoCorrectCard
            setSelectedJobOccupation={setSelectedPreviousJobs}
            data={selectedPreviousJobsS}
          // setLocal ={setSelectedPreviousJobsLocal}
          />
        )}
      </div>
    </div>
  );
};

const JobOccupationTextInputAutoCompleteObserver = observer(
  JobOccupationTextInputAutoComplete
);
export default JobOccupationTextInputAutoCompleteObserver;
