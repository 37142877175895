import { useMemo, useState } from "react";
import "./input.scss";
import DropdownArrow from "../../../assets/icons/reuseables/dropdown_arrow.png";
import { ReactComponent as MagnifyingGlass } from "../../../assets/icons/reuseables/MagnifyingGlass.svg";
import { toJS } from "mobx";
import { observer } from "mobx-react";

interface Props {
  placeholder: string;
  icon: "dropdown" | "search" | "none";
  data: any;
  keyName: string;
  defaultValue?: any;
  setSelection: (value: any) => void;
}

// CREATED BY DORIN HAVARDI
const Input: React.FC<Props> = ({
  placeholder,
  icon,
  data,
  keyName,
  defaultValue,
  setSelection,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [select, setSelect] = useState<string>("");

  // HANDLE SHOW LIST
  const onChangeText = (value: string) => {
    if (value.length > 1) {
      setIsOpen(true);
      setSearch(value);
    }
    if (value.length < 2) {
      setIsOpen(false);
      setSearch(value);
    }
  };

  // HANDLE OPTION SELECT
  const handleSelect = (value: any) => {
    setSearch(value[keyName]);
    setSelect(value);
    setIsOpen(false);
    setSelection(value);
  };

  const handleSearch = (value: string, data: any) => {
    // GET ALL DATA
    const filterStart = toJS(data);

    // GET RESULTS BY SEARCH VALUE
    const filteredData = filterStart.filter((object: any) =>
      object[keyName].includes(value)
    );

    // RETURN RESULT OBJECT
    return filteredData.map((results: any) => {
      return toJS(results);
    });
  };

  const searchResults = useMemo(
    () => handleSearch(search, data),
    [search, data]
  );

  return (
    <>
      <div className="main-input__container">
        <div className="main-input" onClick={() => setIsOpen(!isOpen)}>
          <input
            autoComplete="off"
            type="text"
            className="main-input__input"
            placeholder={placeholder}
            onChange={(e) => onChangeText(e.target.value)}
            defaultValue={defaultValue ? defaultValue : null}
            key={defaultValue}
          />
          {icon === "dropdown" ? (
            <img className="main-input__icon" src={DropdownArrow} />
          ) : icon === "search" ? (
            <MagnifyingGlass className="main-input__svg" />
          ) : null}
        </div>
        <div
          className="main-input__list"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {searchResults !== undefined &&
            searchResults.map((object: any, i: number) => {
              let isKeyExists = `${keyName}` in object;
              if (isKeyExists) {
                return (
                  <p
                    className="list__option"
                    role="button"
                    tabIndex={0}
                    key={i}
                    onClick={() => handleSelect(object)}
                    onKeyPress={() => handleSelect(object)}
                  >
                    {object[keyName]}
                  </p>
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

export default observer(Input);
