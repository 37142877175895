import React, { FC } from 'react';

interface Iicon {
  fill?: string;
}
export const FinanceIcon: FC<Iicon> = ({ fill }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 54 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.5148 0C28.6035 0.262024 29.4073 0.940362 30.2046 1.69584C33.8565 5.16068 38.2552 7.25422 43.1196 8.32626C45.5658 8.86494 48.0799 9.12297 50.5767 9.39697C52.412 9.59781 53.9372 11.0236 53.9518 12.8538C53.9798 16.0846 53.9665 19.314 53.9625 22.5447C53.9625 23.2523 53.6018 23.6421 53.0029 23.6367C52.408 23.6314 52.0659 23.2457 52.0646 22.5235C52.058 19.5587 52.062 16.594 52.0606 13.6293C52.0606 11.9347 51.4537 11.3894 49.7449 11.279C42.6591 10.8215 36.0698 8.91814 30.3949 4.45708C29.6669 3.88381 29.0001 3.23208 28.3227 2.5963C27.4283 1.75703 26.5153 1.7424 25.6409 2.60827C21.3421 6.86717 16.085 9.27859 10.2357 10.4663C8.12356 10.8946 5.95552 11.0542 3.80878 11.2937C2.57104 11.432 1.90958 12.0598 1.90958 13.3087C1.90426 22.5793 1.76851 31.8526 1.9535 41.1192C2.10789 48.8124 5.7133 54.69 12.3319 58.6443C15.9147 60.7844 19.56 62.8207 23.1747 64.9089C25.7088 66.3733 28.2375 66.3866 30.7742 64.9195C34.4435 62.7981 38.1341 60.7125 41.7821 58.5525C47.3399 55.2619 50.6445 50.3792 51.7771 44.0414C51.9861 42.8736 52.0407 41.6659 52.0486 40.4768C52.0806 36.295 52.0606 32.1133 52.062 27.9315C52.062 27.7107 52.0566 27.4886 52.0713 27.2678C52.1099 26.6799 52.4479 26.382 53.0202 26.3847C53.5938 26.3873 53.9399 26.6959 53.9558 27.2798C53.9785 28.0978 53.9665 28.9171 53.9665 29.7351C53.9665 33.5631 54.057 37.3937 53.9425 41.2176C53.7016 49.2739 50.1282 55.5465 43.3459 59.8599C39.473 62.3219 35.4323 64.5272 31.425 66.7723C28.4597 68.4336 25.412 68.395 22.4547 66.7271C18.5898 64.5471 14.7248 62.3631 10.9331 60.0595C4.964 56.4337 1.40384 51.12 0.275236 44.2196C0.0902405 43.0917 0.0170409 41.9346 0.0143791 40.7907C-0.00691529 31.6318 -0.000260784 22.4716 0.00373192 13.3114C0.00373192 10.9704 1.31733 9.56722 3.66371 9.40362C8.27262 9.08174 12.7524 8.2265 16.9714 6.25001C19.4708 5.07955 21.7853 3.62977 23.7643 1.69717C24.5496 0.931051 25.3614 0.258034 26.4527 0L27.5148 0Z" fill={fill || "#1B1F45"}/>
    <path d="M46.6504 29.4838C46.6504 25.3685 46.6424 21.2519 46.6597 17.1367C46.6624 16.5953 46.5306 16.3852 45.9424 16.3041C40.8996 15.6044 36.0671 14.2132 31.6179 11.686C30.2191 10.892 28.8988 9.95695 27.5719 9.04451C27.1407 8.74791 26.8519 8.70668 26.4114 9.04186C21.3846 12.8711 15.667 15.0485 9.473 16.0793C8.95129 16.1657 8.42958 16.2854 7.90387 16.3213C7.43938 16.3533 7.30629 16.5594 7.31029 16.9944C7.32493 18.6982 7.31694 20.402 7.31561 22.1058C7.31561 22.2827 7.32626 22.461 7.30763 22.6365C7.24241 23.271 6.8498 23.6527 6.29082 23.6381C5.74249 23.6234 5.3512 23.2138 5.33523 22.5807C5.31527 21.8292 5.32991 21.0764 5.32991 20.3235C5.32991 19.2834 5.32991 18.2433 5.32991 17.2032C5.32991 15.3969 6.10981 14.5789 7.91984 14.3355C14.3082 13.479 20.1934 11.3442 25.3613 7.40719C26.5085 6.53201 27.4561 6.53068 28.6154 7.4165C33.2562 10.9625 38.5292 13.0214 44.2348 14.0642C45.0161 14.2065 45.8026 14.3143 46.5852 14.4513C47.8522 14.6734 48.6148 15.5539 48.6281 16.8294C48.6414 18.0903 48.6308 19.3513 48.6308 20.6135C48.6308 27.2732 48.6547 33.9342 48.6228 40.5939C48.5988 45.6388 46.7795 49.9522 43.0556 53.3812C42.0561 54.3003 40.9049 55.085 39.7337 55.782C36.2215 57.8688 32.6573 59.8706 29.1278 61.9296C27.6704 62.7795 26.2743 62.7702 24.817 61.9149C21.0079 59.6804 17.1283 57.5643 13.3712 55.2459C8.99521 52.5459 6.42524 48.553 5.58943 43.4761C5.42573 42.4786 5.34721 41.4557 5.34188 40.4449C5.31527 36.1966 5.32192 31.9484 5.34588 27.7001C5.34854 27.3344 5.47231 26.8941 5.69857 26.6214C6.09384 26.1466 6.94296 26.3421 7.17853 26.9287C7.28899 27.204 7.30896 27.5312 7.30896 27.8345C7.31827 32.1931 7.26237 36.5531 7.33158 40.9117C7.42208 46.6031 9.99472 50.9471 14.8365 53.8826C18.5005 56.1038 22.2537 58.1761 25.9589 60.3268C26.6363 60.7205 27.2778 60.7471 27.9566 60.3534C31.7949 58.1269 35.6997 56.0054 39.4635 53.6592C44.0871 50.7769 46.512 46.506 46.6238 41.0354C46.7023 37.1875 46.6384 33.3356 46.6384 29.4851H46.6451L46.6504 29.4838Z" fill={fill || "#1B1F45"}/>
    <path d="M22.9379 35.6912C24.3606 34.268 25.7035 32.9246 27.0464 31.5826C27.3432 31.286 27.6333 30.9787 27.9421 30.6941C28.3773 30.2937 28.9296 30.2751 29.3103 30.6289C29.7415 31.0293 29.7495 31.5347 29.3289 32.0401C29.273 32.108 29.2078 32.1691 29.1452 32.2317C27.3765 33.9993 25.613 35.771 23.8389 37.532C23.1881 38.1784 22.7276 38.1731 22.0821 37.5293C20.4198 35.8747 18.7709 34.2068 17.1006 32.5602C16.125 31.5985 14.8593 31.3645 13.6841 31.8819C12.5489 32.382 11.7983 33.5564 11.8542 34.7881C11.8888 35.5662 12.1762 36.2498 12.7312 36.8058C15.4369 39.5138 18.1334 42.2298 20.8564 44.9206C22.0116 46.0604 23.7018 46.0777 24.9462 45.031C25.0474 44.9458 25.1459 44.8554 25.239 44.7623C30.528 39.4752 35.817 34.1882 41.106 28.8998C41.8886 28.1177 42.2839 27.1907 42.0563 26.0774C41.8154 24.9016 41.118 24.0783 39.9601 23.7352C38.8182 23.396 37.7695 23.6287 36.9044 24.484C35.5988 25.7755 34.3038 27.0776 33.0035 28.3744C32.8478 28.5301 32.6947 28.6977 32.5137 28.8187C32.0719 29.114 31.618 29.09 31.252 28.707C30.8874 28.3252 30.8528 27.8717 31.2041 27.4514C31.4437 27.1641 31.7232 26.9087 31.9894 26.6427C33.1938 25.4376 34.3783 24.2113 35.6108 23.0355C38.3923 20.3807 42.9001 21.6536 43.873 25.3805C44.3494 27.2053 43.8996 28.8386 42.5607 30.1887C40.394 32.3713 38.214 34.5407 36.038 36.7153C32.9556 39.7971 29.8732 42.8789 26.7895 45.9594C24.4099 48.3362 21.5138 48.3442 19.1342 45.9727C16.5482 43.3963 13.9583 40.8213 11.391 38.2263C9.99622 36.8164 9.5863 35.094 10.1426 33.2186C10.6883 31.3831 11.9793 30.2046 13.8572 29.8096C15.6685 29.4292 17.2629 29.9453 18.5792 31.2727C19.8103 32.5136 21.0507 33.744 22.2844 34.9823C22.4867 35.1858 22.6744 35.4039 22.9366 35.6899L22.9379 35.6912Z" fill={fill || "#1B1F45"}/>
    </svg>
    

  )
}

