
interface IConfigEnvironment {
	apiBaseHost: string;
}


export interface IClientConfig extends IConfigEnvironment { }

/**
 * Definitions of all environments config
 */
const LOCAL_SERVER = 'localhost:8082';
const DEV_SERVER = 'dev-api.skillil.co.il'
const PROD_SERVER = 'api.skillil.co.il';


const local: IConfigEnvironment = {
	apiBaseHost: `http://${LOCAL_SERVER}/api`,
};
const dev: IConfigEnvironment = {
	apiBaseHost: `https://${DEV_SERVER}/api`,
};
const prod: IConfigEnvironment = {
	apiBaseHost: `https://${PROD_SERVER}/api`,
};
const serverMode = "dev"; // REPLACE BACK TO PRODUCTION

// console.log("server is ", serverMode)



const envConfigs = {
	local,
	dev,
	prod,
};

// if (env != "prod") {
// }

/**
 * Generate config according to env
 */
const ClientConfig: IClientConfig = {
	// Get all environment configurations
	...envConfigs[serverMode]

	// ss
};

export default ClientConfig;
