import { observer } from "mobx-react";
import { ReactComponent as ClockImage } from "../../assets/images/Smylis/time.svg";

import Image from "../../common/components/image.component";
import NextArrowImage from "../../assets/images/Next_arrow.png";
import "./QuestionnaireIntroductionComponent.scss";
import SmileyPageComponent from "./SmileyPageComponent/SmileyPageComponent";
import SkipQuestionnaire from "./SkipQuestionnaire";
import rootStores from "../../BL/stores";
import { DisplayState } from "../../BL/stores/questionnaire.store";

const languageStore = rootStores.LangsStore;

interface Props {
    setDisplayState: any;
    setDoneOrSkipOrSaveQuestionnaire: any;
    moveNextPage: any;
    setLevel: any;
}

const QuestionnaireIntroductionComponent: React.FC<Props> = ({ setDisplayState, setDoneOrSkipOrSaveQuestionnaire, moveNextPage, setLevel }) => {
    const { translate } = languageStore;

    return (
        <div className="body-introduction">
            <div className="p-md-JO">
                <div className={"bolded-family clock-image-text-container"}>
                    <p className="clock-text-introduction" tabIndex={0}>
                        {translate("questionnairePage", "descriptionClockLine")}
                    </p>
                    <ClockImage className="clock-image-introduction" />
                </div>

                <div className={"description-first-line-question"} tabIndex={0}>
                    {translate("questionnairePage", "description")}
                </div>
            </div>
            <div className="smiley-container-questionnaire-intro">
                <SmileyPageComponent displayState={DisplayState.introduction} />
            </div>
            <div style={{ paddingBottom: "3rem" }}>
                <span style={{ fontFamily: "Almoni-BOLD-AAA" }} tabIndex={0}>
                    {translate("questionnairePage", "descriptionLine")}
                </span>
                <br />
                <p className="introduction-text-description2" tabIndex={0}>{translate("questionnairePage", "description2")}</p>
                <br />
                <br />
                <p className="p-md-JO" style={{ fontWeight: "1000", fontFamily: "Almoni-BOLD-AAA" }} tabIndex={0}>
                    {translate("questionnairePage", "descriptionEighthLine")}
                </p>
            </div>
            <div
                className={"container-next1"} //  next-in-mobile
                role={"button"}
                id={"start-fiiing-questionnaire"}
                tabIndex={0}
                aria-pressed={"false"}
                onClick={() => {
                    setDisplayState(DisplayState.Questionnaire);
                    setDoneOrSkipOrSaveQuestionnaire(false)
                }}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        setDisplayState(DisplayState.Questionnaire);
                        setDoneOrSkipOrSaveQuestionnaire(false)
                    }
                }}
            >
                <div className="arrow-in-question">
                    <div className="arrow-text-lets-start-desktop">
                        {translate("questionnairePage", "letsStartText")}&nbsp;
                    </div>
                    <div className="arrow-text-lets-start-mobile">
                        {"הבא"}&nbsp;
                    </div>
                    <Image className="arrow-img-next1 " src={NextArrowImage} />
                </div>

                <div className="skip-questionnaire-container-mobile">
                    <SkipQuestionnaire
                        moveNextPage={moveNextPage}
                        setLevel={setLevel}
                    />
                </div>
            </div>
        </div>
    );
};

const QuestionnaireIntroductionComponentObserver = observer(QuestionnaireIntroductionComponent);
export default QuestionnaireIntroductionComponentObserver;