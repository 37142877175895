import React, { useEffect, useState } from "react";
import "./SmileyPageComponent.scss";
import { observer } from "mobx-react";
import rootStores from "../../../BL/stores";
import { iconsOfSmiles, IImge } from "./Smiley";
import SessionUtils from "../../../common/utils/SessionUtils";
import { switchText } from "../../../common/utils/SmileyText.util";

interface IProps {
    moveBetweenQuestionsSmiley: any;
}

const questionsStore = rootStores.questionnaireStore;

const SmileysIconsComponent: React.FC<IProps> = ({ moveBetweenQuestionsSmiley }) => {
    const {
        selectedQuestion,
        updateQuestionsAnswer,
    } = questionsStore;

    const [isSelected, setIsSelected] = useState<boolean>(false);

    useEffect(() => {
        setIsSelected(false);
    }, [selectedQuestion]);

    const handleSelectedSmiley = (answer: number) => {
        setIsSelected(true);
        if (selectedQuestion) {
            SessionUtils.setItem(selectedQuestion._id, answer)
            if (selectedQuestion?.answer !== answer) {
                selectedQuestion && updateQuestionsAnswer(selectedQuestion._id, answer);
            }
        }
        setTimeout(() => {
            moveBetweenQuestionsSmiley("forward", selectedQuestion!._id);
            const currentQuestion = document.getElementsByClassName(`h1-JO-smileys`)[0] as HTMLElement;
            currentQuestion?.focus();
        }, 500);
    }

    const checkForSmiley: any = (smiley: IImge) => {
        return selectedQuestion?.answer === smiley.value ? smiley.blueIcon : smiley.grayIcon;
    };

    function over(e: any, smiley: IImge) {
        e.currentTarget.src = smiley.blueIcon;
    }
    function out(e: any, smiley: IImge) {
        selectedQuestion?.answer !== smiley.value ? e.currentTarget.src = smiley.grayIcon : e.currentTarget.src = smiley.blueIcon;
    }



    return (
        <div className="smileys-container">
            {
                iconsOfSmiles.map((smiley: IImge, index: number) => (
                    <div className="smiley-container" key={index}>
                        <button
                            tabIndex={0}
                            className={"smiley-btn"}
                            onClick={() => {
                                handleSelectedSmiley(smiley.value)
                            }}
                            disabled={isSelected}
                        >
                            <img
                                className={"smiley-image-icon-questions pointer"}
                                src={checkForSmiley(smiley)}
                                alt={switchText(index)}
                                onMouseOver={(e) => { !isSelected && over(e, smiley) }}
                                onMouseOut={(e) => { !isSelected && out(e, smiley) }}
                            />
                        </button>
                    </div>
                ))
            }
        </div>
    );
};
const SmileysIconsComponentObservable = observer(SmileysIconsComponent);
export default SmileysIconsComponentObservable;