import React from "react";
import "./FeedbackPopup.scss";
import { ReactComponent as CloseIcon } from '../../../assets/Close_card.svg'
import rootStores from '../../../BL/stores'
import { observer } from "mobx-react";

const userInfoStore = rootStores.UserInfoStore;
interface IProps {
    closePopupText?: string;
}

const FeedbackPopup: React.FC<IProps> = ({ closePopupText }) => {

    const { isFeedbackPopupOpen, setIsFeedbackPopupOpen } = userInfoStore;

    const backUpText = "לא כרגע, תודה"

    const closePopup = () => {
        setIsFeedbackPopupOpen(false);
    }

    return (
        <div className="feedback-popup__dark-layer" style={{ display:"flex" }}>
            <div className="feedback-popup__container" tabIndex={0}>
                <div className="feedback-popup__close-btn">
                    <span className="close-btn" tabIndex={0} onClick={closePopup}><CloseIcon /></span>
                </div>
                <div className="feedback-popup__content">
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSd6Oh4X9RuhtdMwqDQx7TdotUWsYirJKOqdJm7QWQHE2Mu0BQ/viewform?embedded=true"
                        title="feedback-form" tabIndex={0}
                        allowFullScreen
                        className="feedback-popup__iframe">
                    </iframe>
                </div>
                <div className="feedback-popup__close-btn">
                    <span className="close-btn" tabIndex={0} onClick={closePopup}>{closePopupText || backUpText}</span>
                </div>
            </div>
        </div>
    )
}

export default observer(FeedbackPopup);