import React, { useEffect, useState } from "react";
import rootStores from "../../BL/stores/index";
import {
  AUTH_STORE,
  CITIES_STORE,
  JOB_OCCUPATION_STORE,
  USER_INFO_STORE,
} from "../../BL/consts/stores";
import "./userDetailsPageComponent.scss";
import Image from "../../common/components/image.component";
import { IJobOccupation } from "../../common/interfaces/Job-Occupation";
import NextArrowImage from "../../assets/images/Next_arrow.png";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import Helmet from "react-helmet";
import UserFetcher from "../../Infrastructure/fetchers/UserFetcher";
import { Degrees } from "../../BL/stores/UserInfo.store";
import SessionUtils from "../../common/utils/SessionUtils";
import Input from "../../common/components/Input/input.cmp";
import years from "./years";

interface Props {
  setLevel: (number: number) => void;
  onClickMoveShortFlow: (value: string) => void;
  goBack: (pathName: string, search: string) => void;
}

const citiesStore = rootStores[CITIES_STORE];
const userInfoStore = rootStores[USER_INFO_STORE];
const JobOccupationStore = rootStores[JOB_OCCUPATION_STORE];
const authStore = rootStores[AUTH_STORE];
const languageStore = rootStores.LangsStore;

const UserDetailsComponent: React.FC<Props> = ({
  setLevel,
  onClickMoveShortFlow,
  goBack
}) => {
  const {
    selectedAge,
    setSelectedAge,
    selectedEducationalDegree,
    setSelectedEducationalDegree,
    checked,
    setChecked,
    userPath,
    selectedInterestsS,
    previusURLs,
    setPreviusURLs,
    addPreviusLevel,
    previusLevels,
    removePreviusLevel
  } = userInfoStore;
  const { recommendedJobOccupation } = JobOccupationStore;
  const { cities, selectedCity, setSelectedCity } = citiesStore;
  const { isUserLogged } = authStore;

  const [text, setText] = useState("");
  const [city, setCity] = useState("")
  const [selectedYear, setSelectedYear] = useState("שנת לידה");
  const history = useHistory();


  useEffect(() => {
    
    window.onpopstate = function(event) {
      //the costomer want to go back to the previous level and not to the previous page(like the browser back button should go)
    
      //goBack(pathname, search); //DID NOT WORK
      //get the previous level
      let newLevel = previusLevels[previusLevels.length - 1];
      setLevel(newLevel);
      //remove the previous level from the array
      removePreviusLevel();
      history.push(previusURLs[newLevel]);
    }

    !userPath &&
      history.push({
        search: `studyField=${selectedInterestsS
          .map(({ id }) => id)
          .join(",")}`,
      });
  }, []);

  useEffect(() => {
    selectedCity &&
      selectedEducationalDegree != Degrees[0] &&
      selectedAge &&
      isUserLogged &&
      updateDetails();
  }, [
    selectedCity,
    selectedEducationalDegree,
    selectedAge,
  ]);

  const handleRadioChange = (num: number) => {
    setChecked(num);
    setSelectedEducationalDegree(num);
  }

  const updateDetails = async () => {
    const data = {
      city: {
        name: selectedCity?.name,
        residence_area: selectedCity!.residence_area,
      },
      education_degree: selectedEducationalDegree,
      age: selectedAge,
      page: 2,
    };
    await UserFetcher.update(data);
  };

  const moveNextPage = async (
    value?: string,
    jobOccupation?: IJobOccupation
  ) => {
    if (text === null) {
      alert("אנא בחר עיר מגורים");
    } else {
      // Local storage 
      SessionUtils.setItem("age", selectedAge)
      SessionUtils.setItem("city", selectedCity?.name)
      SessionUtils.setItem("education_degree", checked)
      // Local storage 

      setLevel(2);//need for slide bar
      addPreviusLevel(1);
      setPreviusURLs(`${history.location.pathname}${history.location.search}`,1);

      if (!userPath) {
        history.push("/long/questionnaire");//for url
      } else {
        onClickMoveShortFlow(value!);
      }
    }
  };

  const handleCitySelect = (chosenCity: any) => {
    setSelectedCity(chosenCity)
    return selectedCity;
  }

  const handleYearSelect = (e: any) => {
    setSelectedYear(e.value);
    const thisYear = new Date().getFullYear();
    const calculatedAge: any = thisYear - e.value;

    try {
      if (calculatedAge >= 16) {
        setSelectedAge(calculatedAge);
        return;
      }
      setSelectedAge(0);
      alert("גיל מינימלי למילוי שאלון הוא 16");
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Helmet>
        <title> Skill-il - הפרטים שלך </title>
      </Helmet>
      <div className="center-of-screen">
        <div className="container-user-page">
          <div className="container-screen-and-arrow">
            <div className="user-details__texts-container">
              <h1 className="h1-layout" tabIndex={0}>
                {languageStore.translate("userDetailsPage", "header")}
                <span className="h1-layout bolded" style={{ display: "block" }}>
                  {languageStore.translate("userDetailsPage", "headerBr")}
                </span>
              </h1>
              <br />
              <h5 className="bolded" tabIndex={0}>
                {languageStore.translate(
                  "userDetailsPage",
                  "descriptionFirstLine"
                )}
              </h5>
            </div>
            <div className="user-details__details-inputs">
              <div className="details-inputs__container">
                <div className="details-inputs__input cities">
                  <Input
                    defaultValue={!selectedCity ? null : selectedCity?.name}
                    placeholder={"יישוב"}
                    icon={"dropdown"}
                    data={cities}
                    keyName={'name'}
                    setSelection={handleCitySelect}

                  />
                </div>
                <div className="details-inputs__input years">
                  <Input
                    defaultValue={!selectedAge ? null : new Date().getFullYear() - selectedAge}
                    placeholder={"שנת לידה"}
                    icon={"dropdown"}
                    data={years.years}
                    keyName={'label'}
                    setSelection={handleYearSelect}
                  />
                </div>
              </div>
              <div className="details-radios__container">
                <span className="details-radios__title bolded" tabIndex={0}>{"השכלה:"}</span>
                <label
                  className="details-radio__label"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleRadioChange(1);
                    }
                  }}
                >
                  <input
                    name="radio"
                    aria-checked={checked === 1}
                    value={1}
                    type="radio"
                    checked={checked === 1}
                    onChange={(e) =>
                      checked === 1
                        ? handleRadioChange(0)
                        : handleRadioChange(Number(e.target.value))
                    }
                    tabIndex={0}
                  />
                  {languageStore.translate(
                    "userDetailsPage",
                    "checkboxOptionOne"
                  )}
                  <span className="details-radio__checkmark"></span>
                </label>
                <label
                  className="details-radio__label"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleRadioChange(2);
                    }
                  }}
                >
                  <input
                    aria-checked={checked === 2}
                    value={2}
                    type="radio"
                    checked={checked === 2}
                    onChange={(e) =>
                      checked === 2
                        ? handleRadioChange(0)
                        : handleRadioChange(Number(e.target.value))
                    }
                    name="radio"
                  />
                  {languageStore.translate(
                    "userDetailsPage",
                    "checkboxOptionTwo"
                  )}
                  <span className="details-radio__checkmark"></span>
                </label>
                <label
                  className="details-radio__label"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleRadioChange(3);
                    }
                  }}
                >
                  <input
                    value={3}
                    type="radio"
                    aria-checked={checked === 3}
                    checked={checked === 3}
                    onChange={(e) =>
                      checked === 3
                        ? handleRadioChange(0)
                        : handleRadioChange(Number(e.target.value))
                    }
                    name="radio"
                  />
                  {languageStore.translate(
                    "userDetailsPage",
                    "checkboxOptionThree"
                  )}
                  <span className="details-radio__checkmark"></span>
                </label>
                <label
                  className="details-radio__label"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleRadioChange(4);
                    }
                  }}
                >
                  <input
                    name="radio"
                    value={4}
                    type="radio"
                    aria-checked={checked === 4}
                    checked={checked === 4}
                    onChange={(e) =>
                      checked === 4
                        ? handleRadioChange(0)
                        : handleRadioChange(Number(e.target.value))
                    }
                  />
                  {languageStore.translate(
                    "userDetailsPage",
                    "checkboxOptionFour"
                  )}
                  <span className="details-radio__checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="user-details__next-container">
            <button
              className="user-details__next-btn"
              tabIndex={0}
              onClick={() =>
                moveNextPage!(
                  recommendedJobOccupation?.male_name,
                  recommendedJobOccupation!
                )
              }
              style={{
                cursor:
                  selectedAge > 1 && selectedCity != null && checked !== 0
                    ? "pointer"
                    : "not-allowed",
                opacity:
                  selectedAge > 1 && selectedCity != null && checked !== 0
                    ? 1
                    : 0.4,
                pointerEvents:
                  selectedAge > 1 && selectedCity != null && checked !== 0
                    ? "fill"
                    : "none",
              }}
            >
              <span className="next-btn__text ultra-bolded">
                {languageStore.translate("userDetailsPage", "nextArrow")}
              </span>
              <Image className="next-btn__arrow-image" src={NextArrowImage} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const UserDetailsComponentObserver = observer(UserDetailsComponent);
export default UserDetailsComponentObserver;
