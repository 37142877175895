import { observer } from "mobx-react";
import rootStores from "../../../BL/stores";
import "./ChangeAnswerPopup.scss"
interface Props {
    setCurrentChapter: any;
    currentChapter: any;
}

const questionnaireStore = rootStores.questionnaireStore;

const PopupChaptersComponent: React.FC<Props> = ({ setCurrentChapter, currentChapter }) => {
    const { questions } = questionnaireStore;

    const numOfChapters = questions.length / 6;

    const presentChapters = () => {
        let array = [];
        for (let i = 0; i < numOfChapters; ++i) {
            array.push(
                <div className="chapter-answer-popup-container">
                    <button
                      tabIndex={3}
                        style={{ border: "none", backgroundColor: "transparent", display: "flex", flexDirection: "column" }}
                        onClick={() => { setCurrentChapter(i + 1); }}>
                        <p className="chapter-text-answer-popup" style={{ color: currentChapter === i + 1 ? "white" : "#201C49" }}>
                            {`פרק`}
                        </p>
                        <p className="chapter-text-answer-popup" style={{ color: currentChapter === i + 1 ? "white" : "#201C49" }}>
                            {`0${i + 1}.`}
                        </p>
                    </button>
                </div>
            )
        }
        return array
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {presentChapters()}
        </div>
    );
};

const PopupChaptersComponentObserver = observer(PopupChaptersComponent);
export default PopupChaptersComponentObserver;