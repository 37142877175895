import jwtDecode, * as jwt_decode from "jwt-decode";
import { JwtPayload } from "jwt-decode";
import authFetcher from "../../Infrastructure/fetchers/AuthFetcher";
import Cookies from 'js-cookie'
import { getCookie } from "../utils/CookieUtils";

export const isJwtValidDecode = async () => {
  try {
    const auth = getCookie("auth_token") != null && getCookie("auth_token") != undefined ? getCookie("auth_token")?.split(`%22`)[1] : null
    const refresh_token = getCookie("refresh_token")


    if (!auth && !refresh_token) {
      return false;
    }

    if (refresh_token && !auth) {
      return await isRefreshTokenValidDecode();
    }
    const decodedToken: JwtPayload = jwtDecode(auth!, { header: true });
    const decodedTokenValue: JwtPayload = jwtDecode(auth!);

    if (Date.now() < decodedTokenValue?.exp! * 1000) {
      return true;
    }
    else if (!(Date.now() < decodedTokenValue?.exp! * 1000)) {
      return await isRefreshTokenValidDecode();
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const isRefreshTokenValidDecode = async () => {
  try {
    // if (!auth) return false;
    // const decodeToken: any = jwt_decode.default(auth);
    // if (decodeToken && Date.now() >= decodeToken.exp * 1000) return false;
    // const token = await authFetcher.refreshToken();
    // return true;
    const auth = getCookie("refresh_token") != null ? getCookie("refresh_token")?.split(`%22`)[1] : null

    if (!auth) {
      return false;
    }
    const decodedToken: JwtPayload = jwtDecode(auth!, { header: true });
    const decodedTokenValue: JwtPayload = jwtDecode(auth!);
    if (Date.now() < decodedTokenValue?.exp! * 1000) {
      const token = await authFetcher.refreshToken();
      return true;
    }
    else if (!(Date.now() < decodedTokenValue?.exp! * 1000)) {
      return false

    }
  } catch (err) {
    return false;
  }
};
