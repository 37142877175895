import Lottie from "lottie-react"
import React, { useEffect } from "react"
import NotFound from "../../assets/404.json"
import { useHistory } from "react-router-dom"



const NotFoundComponent: React.FC = () => {

    const history = useHistory()

    useEffect(() => {
        setInterval(() => {
            history.push("/")
        }, 3000)
    }, [])

    return (<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
        <h1  >משהו השתבש</h1>
        <Lottie style={{ maxWidth: "50%" }} animationData={NotFound} />
    </div>)
}

export default NotFoundComponent







