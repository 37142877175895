import React, { useState } from "react";
import "./contactUs.scss";
import ContactUsForm from "./contactUsForm/ContactUsForm";
import { ReactComponent as Close_White } from "../../../../assets/Results images/Close-white.svg";
import { observer } from "mobx-react";
import { SideBarModals } from "../../../../BL/stores/App.store";
import rootStores from "../../../../BL/stores";
import { APP_STORE } from "../../../../BL/consts/stores";

const appStore = rootStores[APP_STORE];

const ContactUs: React.FC = () => {
  const { setChosenModal } = appStore;
  const [header, setHeader] = useState("יצירת קשר");
  const [modalDisplay, setModalDisplay] = useState("open");

  const handleClose = () => {
    setModalDisplay("close");
    setChosenModal(SideBarModals.none);
  };
  
  return (
    <div
      className="contact-us-trasparent-container"
      onClick={()=>handleClose()}
    >
      <div
        className={`contact-us-container ${modalDisplay}`}
        style={{display:'flex',flexDirection:'column'}}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* <button
          className="close-button-side-bar-aboutUs"
          style={{ right: "11%" }}
          onClick={() => setChosenModal(SideBarModals.none)}
          autoFocus
        > */}
          <Close_White tabIndex={0} role={"img"} name={""}  onClick={() => handleClose()}
 className={"close-button-side-bar-contactUs"}  />
        {/* </button> */}
        <ContactUsForm title={header} setChosenModal={setChosenModal} />
      </div>
    </div>
  );
};
const ContactUsObserver = observer(ContactUs);
export default ContactUsObserver;
