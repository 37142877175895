import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import rootStores from "../../BL/stores";
import Image from "../../common/components/image.component";
import NextArrowImage from "../../assets/images/Next_arrow.png";
import ChangeAnswerPopup from "./AnswerPopup/ChangeAnswerPopup";
import "./HollandQuestionnaire.scss"
import { ReactComponent as AnswersIcon } from '../../assets/images/Answer/Answers_icon.svg';

const questionnaireStore = rootStores.questionnaireStore;
const languageStore = rootStores.LangsStore;

interface Props {
    moveNextPage: any;
    setLevel: any;
    setQuestionnaireStateToDisplay: any;
    setDoneOrSkipOrSaveQuestionnaire: any;
}
const QuestionAnalysisComponent: React.FC<Props> = ({ moveNextPage, setLevel, setQuestionnaireStateToDisplay, setDoneOrSkipOrSaveQuestionnaire }) => {
    const { IQuestionnaire_info, topTwo, setCloneQuestions, questions } = questionnaireStore;
    const { translate } = languageStore;

    const [changeAnswerPopup, setChangeAnswerPopup] = useState<boolean>(false);

    useEffect(() => {
        setCloneQuestions();
    }, [questions]);

    const checkForQuestionnarieResults = () => {
        let newFirstAnalyseConclusion;
        if (topTwo.length === 2) {
            newFirstAnalyseConclusion = ` ` + translate("questionnairePage", `${topTwo[0][0]}`) + ` ` + "ו" + translate("questionnairePage", `${topTwo[1][0]}`)
        }
        else if (topTwo.length === 1) {
            newFirstAnalyseConclusion = ` ` + translate("questionnairePage", `${topTwo[0][0]}`)
        }
        else {
            newFirstAnalyseConclusion = ""
        }
        return newFirstAnalyseConclusion;
    }

    return (
        <div className="question-analysis-page">
            <div className="conclusion-container">
                {
                    changeAnswerPopup &&
                    <ChangeAnswerPopup
                        setChangeAnswerPopup={setChangeAnswerPopup}
                        setQuestionnaireStateToDisplay={setQuestionnaireStateToDisplay}
                        setDoneOrSkipOrSaveQuestionnaire={setDoneOrSkipOrSaveQuestionnaire}
                    />
                }
                <h2 className="p-md-JO" tabIndex={0}>
                    {translate("questionnairePage", "conclusionExplnation1")}
                    <div className="bolded-family">
                        {translate("questionnairePage", "conclusionExplnation2")}
                    </div>
                </h2>
                {Object.entries(IQuestionnaire_info!)
                    .sort(([, a], [, b]) => b - a)
                    .map(([key, value], index) => {
                        return (
                            <div className="diagram-div">
                                <p className="personality-traits-text bold" tabIndex={0}>
                                    {key === "enterprising" &&
                                        translate("questionnairePage", "enterprising")}
                                    {key === "social" &&
                                        translate("questionnairePage", "social")}
                                    {key === "artistic" &&
                                        translate("questionnairePage", "artistic")}
                                    {key === "investigative" &&
                                        translate("questionnairePage", "investigative")}
                                    {key === "realistic" &&
                                        translate("questionnairePage", "realistic")}
                                    {key === "conventional" &&
                                        translate("questionnairePage", "conventional")}
                                </p>
                                <div className="conclusion-graph-container">
                                    <div className="conclusion-graph" role="img" title={`${(value / 20) * 100}%`}
                                        style={{
                                            width: `${(value / 20) * 100}%`,
                                            // marginRight: "5%",
                                            background:
                                                index === 0 ? "#15AEED"
                                                    : index === 1 ? "#0B7BF6"
                                                        : index === 2 ? "#1B1F45"
                                                            : index === 3 ? "#1B1F45"
                                                                : index === 4 ? "#1B1F45"
                                                                    : "#1B1F45",
                                        }}
                                    ></div>
                                </div>
                            </div>
                        );
                    })}
                <div style={{ paddingTop: "1.5rem" }}>
                    <button
                        className="change-answers-button"
                        id="change-answers-button"
                        onClick={() => { setChangeAnswerPopup(!changeAnswerPopup) }}>
                        <AnswersIcon style={{ cursor: "pointer" }} />
                        <div style={{ marginRight: "1vh" }} tabIndex={0}>
                            <p className="change-answer-popup-btn-text-h1">{"לא מאפיין אותך?"}</p>
                            <p className="change-answer-popup-btn-text-h2">{"לשינוי התשובות בשאלון"}</p>
                        </div>
                    </button>
                </div>
                <div style={{ marginTop: "4rem" }}>
                    <Link
                        tabIndex={0}
                        style={{ display: "flex", alignItems: "center", }}
                        to={"/long/last-thing"}
                        onClick={() => {
                            moveNextPage();
                            setLevel(3);
                        }}
                    >
                        <p className="almost-done-text" >
                            {translate("questionnairePage", "almostDoneArrow")}&nbsp;
                        </p>
                        <Image className="almost-done-arrow" src={NextArrowImage} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

const QuestionAnalysisComponentObserver = observer(QuestionAnalysisComponent);
export default QuestionAnalysisComponentObserver;