import { action, IObservableArray, makeAutoObservable, observable, runInAction } from "mobx";
import { RootStore } from ".";
import TranslateFetcher from "../../Infrastructure/fetchers/TranslateFetcher";
import UserFetcher from "../../Infrastructure/fetchers/UserFetcher";
export interface ILanguage {
  language: string;
  gender: string;
  id: number;
}
class languageStore {
  public currentLanguageIndex: number;
  public languages: IObservableArray<ILanguage>;
  private maleData: any = {};
  private femaleData: any = {};

  private _rootStore: RootStore
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
    this.languages = observable([
      { language: "he", gender: "זכר", id: 0 },
      { language: "he", gender: "נקבה", id: 1 },
    ]);
    this.currentLanguageIndex = 1;
    runInAction(() => {
      this.fetchData();
    });
  }

  @action
  setLanguage = async (language: number, isUserLogged: boolean) => {
    this.currentLanguageIndex = language;
    isUserLogged && await UserFetcher.update({ gender: this.languages[language].gender, page: 4 });
  };
  @action
  setLanguageByGender = async (gender: string) => {
    const currentLanguageIndex = this.languages.findIndex((lan) => lan.gender == gender);
    this.currentLanguageIndex = currentLanguageIndex == -1 ? 1 : currentLanguageIndex;
  };

  @action
  fetchData = async () => {
    const data = await TranslateFetcher.getTranslateFetcher();
    this.maleData = data[0];
    this.femaleData = data[1];
  };

  @action
  translate = (page: string, key: string): string => {
    let langData: { [key: string]: { [key: string]: string } } = {};

    switch (this.languages[this.currentLanguageIndex].gender) {
      case "זכר":
        langData = this.maleData;
        break;
      case "נקבה":
      default:
        langData = this.femaleData;
        break;
    }
    return langData[page][key];
  };

  @action
  chooseLanguage = (text: {
    male_name: string;
    female_name: string;
  }): string => {
    switch (this.languages[this.currentLanguageIndex].gender) {
      case "זכר":
        return text.male_name;
      case "נקבה":
      default:
        return text.female_name;
    }
  };
}

export default languageStore;
