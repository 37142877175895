import {
  action,
  IObservableArray,
  makeAutoObservable,
  observable,
} from "mobx";

import { IQuestion } from "../../common/interfaces/Question";
import { IAnswers } from "../../common/interfaces/Answers";
import { IQuestionnaireInfo } from "../../common/interfaces/Questionnaire";
import QuestionsFetcher from "../../Infrastructure/fetchers/QuestionsFetcher";
import { RootStore } from ".";



export enum DisplayState {
  introduction = "introduction",
  Questionnaire = "questionnaire",
  QuestionAnalysis = "questionAnalysis",
}
class QuestionnaireStore {
  public questions: IObservableArray<IQuestion>;
  public questionsClone: IObservableArray<IQuestion>;
  public selectedQuestion: IQuestion | null;
  // need to check if we are using this answers array and interface
  public answers: IObservableArray<IAnswers>;
  public IQuestionnaire_info: IQuestionnaireInfo;
  public topTwo: IObservableArray<any>;
  public lastEpisodeEachChapter: IObservableArray<any>;
  //public lastQuestionAnswered: IObservableArray<number>;;
  public doneOrSkipOrSaveQuestionnaire: boolean;
  public displayState: DisplayState;
  private _rootStore: RootStore
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore; makeAutoObservable(this);
    this.questions = observable([]);
    this.questionsClone = observable([]);
    this.selectedQuestion = null;
    this.answers = observable([]);
    this.IQuestionnaire_info = {
      realistic: 0,
      investigative: 0,
      artistic: 0,
      social: 0,
      enterprising: 0,
      conventional: 0,
    };
    this.topTwo = observable([]);
    this.lastEpisodeEachChapter = observable([]);
    //this.lastQuestionAnswered = observable([]);
    this.doneOrSkipOrSaveQuestionnaire = false;
    this.displayState = DisplayState.introduction;
  }
  @action
  setDisplayState = (displayState: DisplayState) => {
    this.displayState = displayState;
  }
  @action
  getAllQuestions = async () => {
    const questionsRes: IQuestion[] = await QuestionsFetcher.getQuestions();
    questionsRes.map((ques: IQuestion) => (ques.answer = 0));
    this.setQuestions(questionsRes);
    this.setSelectedQuestion(this.questions[0])
  };

  @action
  updateQuestionsAnswer = (id: string, answer: number) => {
    const questionIndex: number = this.questions.findIndex(
      (ques: IQuestion) => ques._id === id
    );
    if (this.questions[questionIndex].answer !== answer) {
      this.questions[questionIndex].answer = answer;
    } else {
      this.questions[questionIndex].answer = 0;
    }
    this.isAllQuestionsAnswer() && this.calculateQuestionaire();
  };
  @action
  updateQuestionsCloneAnswer = (id: string, answer: number) => {
    const questionIndex: number = this.questionsClone.findIndex(
      (ques: IQuestion) => ques._id === id
    );
    if (this.questionsClone[questionIndex].answer !== answer) {
      this.questionsClone[questionIndex].answer = answer;
    } else {
      this.questionsClone[questionIndex].answer = 0;
    }
    //this.isAllQuestionsAnswer()&&this.calculateQuestionaire();
  };

  @action
  isAllQuestionsAnswer = () => {
    let result = true;
    for (let i = 0; i < this.questions.length; ++i) {
      if (this.questions[i].answer === 0) {
        result = false;
        break;
      }
    }
    return result;
  }

  @action
  numOfFilledAnswers = () => {
    if (this.isAllQuestionsAnswer()) {
      return 30;
    }
    else {
      return this.questions?.filter(function (item) {
        return item.answer !== 0;
      }).length;
    }
  }

  @action
  setQuestions = (value: IQuestion[]) => {
    this.questions.replace(value);
  }

  @action
  setDoneOrSkipOrSaveQuestionnaire = (value: boolean) => {
    this.doneOrSkipOrSaveQuestionnaire = value;
  }

  @action
  setAnswers = (value: IAnswers[]) => {
    this.answers.replace([
      ...this.answers,
      { _id: value[0]._id, answer: value[0].answer },
    ]);
  };

  @action
  setTopTwo = (value: IQuestionnaireInfo) => {
    const sortedQuestionnaireInfo = Object.entries(value)
      .sort(([, a], [, b]) => b - a);
    this.topTwo.clear();
    sortedQuestionnaireInfo[0][1] !== 0 && this.topTwo.push((sortedQuestionnaireInfo[0]))
    sortedQuestionnaireInfo[1][1] !== 0 && this.topTwo.push((sortedQuestionnaireInfo[1]))
  };



  @action
  resetAnswers = () => {
    this.questions.map((q) => {
      q.answer = 0;
    });
  }

  @action
  getAnswersFromLogIn = (logIndata: []) => {

    this.questions.map((q, index) => {
      q.answer = logIndata ? logIndata[index]["answer"] : 0;
    });

    const checkForUnAnswerdQuesion = this.questions.find(
      (qusetion) => qusetion.answer === 0
    );

    if (checkForUnAnswerdQuesion !== undefined) {
      this.setSelectedQuestion(checkForUnAnswerdQuesion!);
    } else {
      this.setSelectedQuestion(this.questions[0]);
    }
  };

  // @action
  // setSelectedQuestionLocal = (selectedQuestion: string): void => {
  // };

  @action
  setSelectedQuestion = (value: IQuestion | null) => {
    this.selectedQuestion = value;
  };

  @action
  setCloneQuestions = () => {
    this.questionsClone = JSON.parse(JSON.stringify(this.questions));
  };


  @action
  getSelectedQuestionFromLogIn = (checkAuthData: []) => {

    const checkForUnAnswerdQuesion = checkAuthData.find(
      (qusetion) => qusetion["answer"] === 0
    );

    if (checkForUnAnswerdQuesion !== undefined) {
      const questionToStartWith: IQuestion = { _id: checkForUnAnswerdQuesion["question"]["_id"], value: checkForUnAnswerdQuesion["question"]["value"], chapter: checkForUnAnswerdQuesion["question"]["chapter"], type: checkForUnAnswerdQuesion["question"]["type"], answer: checkForUnAnswerdQuesion["answer"] }
      this.setSelectedQuestion(questionToStartWith!);
    } else {
      this.setSelectedQuestion(this.questions[0]);
    }
  };

  @action
  calculateQuestionaire = async () => {
    this.IQuestionnaire_info = {
      realistic: 0,
      investigative: 0,
      artistic: 0,
      social: 0,
      enterprising: 0,
      conventional: 0,
    };
    this.questions.map((question: IQuestion) => {
      switch (question.type) {
        case "R":
          if (question.answer !== 0) {
            this.IQuestionnaire_info!.realistic += question.answer! - 1;
          }
          break;

        case "I":
          if (question.answer !== 0) {
            this.IQuestionnaire_info!.investigative += question.answer! - 1;
          }
          break;

        case "A":
          if (question.answer !== 0) {
            this.IQuestionnaire_info!.artistic += question.answer! - 1;
          }
          break;

        case "S":
          if (question.answer !== 0) {
            this.IQuestionnaire_info!.social += question.answer! - 1;
          }
          break;

        case "E":
          if (question.answer !== 0) {
            this.IQuestionnaire_info!.enterprising += question.answer! - 1;
          }
          break;

        case "C":
          if (question.answer !== 0) {
            this.IQuestionnaire_info!.conventional += question.answer! - 1;
          }
      }
    });
    this.setTopTwo(this.IQuestionnaire_info)
  };

  @action
  skipAllQuestion = () => {

    this.IQuestionnaire_info = {
      realistic: 0,
      investigative: 0,
      artistic: 0,
      social: 0,
      enterprising: 0,
      conventional: 0,
    }
  }

  @action
  startOrContinueQuestion = () => {
    if (this.selectedQuestion === undefined || this.selectedQuestion === null) {
      this.setSelectedQuestion(this.questions[1]);
    }
    else {
      //this.selectedQuestion = this.lastQuestionAnswered;
    }
  };

}

export default QuestionnaireStore;
