import { observer } from "mobx-react";
import "./ChangeAnswerPopup.scss"
import rootStores from "../../../BL/stores/index";
import {
    LANGS_STORE,
  } from "../../../BL/consts/stores";
interface Props {
    resetAnswers: any;
    setIsReset: any;
}
const languageStore = rootStores[LANGS_STORE];
const ResetPopupConfirmation: React.FC<Props> = ({ resetAnswers, setIsReset }) => {

    return (
        <div style={{display:"flex", flexDirection:"column"}}>

            <div className="reset-description-container">
                {languageStore.currentLanguageIndex === 0 ?
                <p className="reset-description-text">{"שים לב, בחרת לאפס את כל התשובות."}</p>:
                <p className="reset-description-text">{"שימי לב, בחרת לאפס את כל התשובות."}</p>}
                <p className="reset-description-text">{"איפוס השאלון יחזיר אותך למסך השאלון הראשי לתחילת התהליך"}</p>
            </div>
            
            <div style={{display:"flex"}}>
                <button className="reset-button-approve" onClick={()=>{resetAnswers()}}>
                    {"כן, אני רוצה להתחיל מחדש"}
                </button>
                {/* <div style={{width:"2rem"}}></div> */}
                <button className="reset-button-cancel" onClick={()=>{setIsReset(false)}}>
                    {"ביטול"}
                </button>
            </div>
        </div>
    );
};

const ResetPopupConfirmationObserver = observer(ResetPopupConfirmation);
export default ResetPopupConfirmationObserver;
