import { observer } from "mobx-react";
import rootStores from "../../BL/stores";
import "./HollandQuestionnaire.scss";
interface Props {
    moveBetweenQuestionsSmiley: any;
}

const questionnaireStore = rootStores.questionnaireStore;

const ChaptersHeader: React.FC<Props> = ({ moveBetweenQuestionsSmiley }) => {
    const { questions } = questionnaireStore

    const numOfChapters = questions.length / 6;
    const UnansweredQuestion = questions.find((question) => question.answer === 0)

    const presentChapters = () => {
        let array = [];
        let isChapterAnswered = false;
        let currentChapter = false;
        for (let i = 0; i < numOfChapters; ++i) {
            isChapterAnswered = UnansweredQuestion ? parseInt(UnansweredQuestion?.chapter) > i + 1 : false;
            currentChapter = UnansweredQuestion ? parseInt(UnansweredQuestion?.chapter) === i + 1 : false;
            array.push(
                <>
                    <div>
                        <button tabIndex={0}
                            className="chapter-button"
                            onClick={() => { moveBetweenQuestionsSmiley("chapter", null, i + 1); }}
                        >
                            <p className="h1-JO-numbers pointer"
                                style={{ color: isChapterAnswered ? "#0c7af6" : undefined, fontFamily: currentChapter ? "almoni-bold-aaa" : "almoni-dl-aaa" }}
                            >
                                {"0" + (i + 1)}
                            </p>
                        </button>
                    </div>
                    <div className="h1-JO-chapter-dots-container">
                        {i + 1 < numOfChapters &&
                            <p className="h1-JO-chapter-dots"
                                style={{ color: isChapterAnswered ? "#0c7af6" : undefined }}
                            >
                                {" .. "}
                            </p>
                        }
                    </div>
                </>
            )
        }
        return array
    }

    return (
        <div>
            <p className="chapter-header-title-text" tabIndex={0}>
                {"פרק"}
            </p>
            <div className="chapter-header-number-dots-container">
                {presentChapters()}
            </div>
        </div>
    );
};

const ChaptersHeaderObserver = observer(ChaptersHeader);
export default ChaptersHeaderObserver;