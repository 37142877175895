import React, { useEffect } from 'react';
import rootStores from "../../BL/stores/index";
import { AUTH_STORE, USER_INFO_STORE } from "../../BL/consts/stores";
import "./interesetsPageComponent.scss";
import NextArrowImage from "../../assets/images/Next_arrow.png";
import { observer } from "mobx-react";
import InterestCardComponent from "../interesetsPage/components/InteresetCard/InterestCardComponen";
import { useHistory } from "react-router-dom";
import interests from "./interestsIcon";
import Helmet from "react-helmet";
import SerializationUtils from "../../common/utils/serializationUtils";
import SessionUtils from "../../common/utils/SessionUtils";
interface Props {
  goBack: (pathName: string, search: string) => void;
  setLevel: (number: number) => void;
}

const userInfoStore = rootStores[USER_INFO_STORE];
const authStore = rootStores[AUTH_STORE];
const languageStore = rootStores.LangsStore;

const InterestsPageComponentNew: React.FC<Props> = ({ goBack, setLevel }) => {
  const { setSelectedInterestsS, selectedInterestsS,setPreviusURLs,addPreviusLevel } = userInfoStore;
  const { isUserLogged } = authStore;

  const history = useHistory();

  const moveNextPage = () => {
    const interestsIdsArr: number[] = []
    selectedInterestsS.forEach((item: any) => {
      if (item.id) {
        interestsIdsArr.push(item.id)
      }
    })
    const interestsIdsStr = SerializationUtils.spreadWithNumberOrStringLocalStorage(interestsIdsArr)
    SessionUtils.setItem("interests", interestsIdsStr)
    setLevel(1);
    addPreviusLevel(0);
    setPreviusURLs(`${history.location.pathname}?${history.location.search}`, 0);
    history.push("/long/user-details");
  };

  const handleSelectedInterests = (
    item: { id: number; name: string },
    e: HTMLDivElement
  ): void => {
    const index = selectedInterestsS.findIndex((s) => s.name === item.name);
    let tempInterests = [...selectedInterestsS];
    if (tempInterests.length < 4 && index === -1) {
      tempInterests = [...tempInterests, item];
    } else if (index !== -1) {
      tempInterests.splice(index, 1);
    }
    setSelectedInterestsS(tempInterests, isUserLogged);
  };


  useEffect(() => {
    window.onpopstate = function(event) {
      let pathname = "/";
      let search = "";      
      goBack(pathname, search); 
}
    const mainComponent = document.getElementsByClassName("flow2-container")[0] as HTMLElement;
    mainComponent?.focus();
  }, [])


  return (
    <>
      <Helmet>
        <title> Skill-il - מה מעניין אותך? </title>
      </Helmet>
      <div className="center-of-screen" >
        <div className="container-interest-page">
          <div className="container-screen-and-arrow container-screen-and-arrow-interest">
            <div className="header-interest" style={{ height: "auto" }}>
              <div className="header-container-right-side-interest">
                <h1 className="h1-layout" style={{ color: '#0B2559' }} tabIndex={0}>
                  {languageStore.translate(`interestsPage`, "header")}
                  <span className="h1-layout ultra-bolded no-margin" style={{ color: '#0B2559' }}>
                    <br />
                    {languageStore.translate(`interestsPage`, "headerBr")}
                  </span>
                </h1>
                <h5 className="description-p-inter" tabIndex={0} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  {languageStore.translate(`interestsPage`, "descriptionFirstLine")}
                  <br />
                  {languageStore.translate(`interestsPage`, "descriptionSecondLine")}
                </h5>
              </div>
            </div>
            <div className="content-row-internets">
              <div className="card-container">
                {interests.map((interest, index) => (
                  <InterestCardComponent
                    handleSelectedInterests={handleSelectedInterests}
                    interest={interest}
                    key={index}
                    isSelected={selectedInterestsS.some(
                      (interst) => interst.id === interest.id
                    )}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="container_arrow1-inter arrow-interest">

            {/* <div onClick={moveBack} className={"container-previous1"}>
              <Image
                style={{ cursor: "pointer" }}
                className="arrow-img-last1 rotate180 "
                src={NextArrowImage}
              />
              <div className={"small-arrow"}>&nbsp; </div>
            </div> */}

            <button
              disabled={selectedInterestsS.length === 0}
              onClick={moveNextPage}
              className="container-next1"
              tabIndex={0}
            >
              <p className="arrow-text">
                {languageStore.translate(`interestsPage`, "nextArrow")}
              </p>
              <img className="arrow-img-last1" src={NextArrowImage} alt="הבא" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const InterestsPageComponentNewObserver = observer(InterestsPageComponentNew);
export default InterestsPageComponentNewObserver;
