import { observer } from "mobx-react";
import rootStores from "../../BL/stores";
import { DisplayState } from "../../BL/stores/questionnaire.store";

const questionnaireStore = rootStores.questionnaireStore;
const languageStore = rootStores.LangsStore;
interface IProps {
    displayState: DisplayState;
}

const QuestionnaireHeaderTitleText: React.FC<IProps> = ({ displayState }) => {
    const { topTwo } = questionnaireStore;
    const { translate } = languageStore;

    const checkForQuestionnarieResults = () => {
        let newFirstAnalyseConclusion;
        if (topTwo.length === 2) {
            newFirstAnalyseConclusion = ` ` + translate("questionnairePage", `${topTwo[0][0]}`) + ` ` + "ו" + languageStore.translate("questionnairePage", `${topTwo[1][0]}`)
        }
        else if (topTwo.length === 1) {
            newFirstAnalyseConclusion = ` ` + translate("questionnairePage", `${topTwo[0][0]}`)
        }
        else {
            newFirstAnalyseConclusion = ""
        }
        return newFirstAnalyseConclusion;
    }
    const firstAnalyseConclusion = checkForQuestionnarieResults();

    return (
        <>
            {
                displayState === DisplayState.introduction &&
                <div className="title-questionnaire-intro">
                    <h1 className="h1-layout" tabIndex={0}>
                        {"פעילויות"}
                        <br />
                        <span className="header-title-two">{translate("questionnairePage", "questionsTitle")}</span>
                    </h1>
                </div>
            }
            {
                // displayState === DisplayState.Questionnaire && <p>{translate("questionnairePage", "headerWhileQuestions")}</p>
                displayState === DisplayState.Questionnaire &&
                <div style={{ display: "flex", alignItems: "end", }}>
                    {languageStore.currentLanguageIndex === 1 ?
                        <p className="title-questionnaire" tabIndex={0}>{"פעילויות שתעדיפי לעשות"}</p>
                        :
                        <p className="title-questionnaire" tabIndex={0}>{"פעילויות שתעדיף לעשות"}</p>
                    }
                </div>
            }
            {
                displayState === DisplayState.QuestionAnalysis &&
                <h1 className="question-analysis-text" tabIndex={0}>
                    {languageStore.translate("questionnairePage", "conclusionHeader")}
                    <div className="bolded-family">
                        טיפוס
                        {firstAnalyseConclusion}
                    </div>
                </h1>
            }

        </>
    );
};

const QuestionnaireHeaderTitleTextObserver = observer(QuestionnaireHeaderTitleText);
export default QuestionnaireHeaderTitleTextObserver;
