import React, { useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import Select from "react-select";
import rootStores from "../../../../BL/stores/index";
import { LANGS_STORE } from "../../../../BL/consts/stores";
import { toJS } from "mobx";
import { IJobOccupation } from "../../../../common/interfaces/Job-Occupation";
import AutoCorrectCardObserver from "../autoCorrectCard/autoCorrectCard.component";
import Image from "../../../../common/components/image.component";
import MagnifingImage from "../../../../assets/images/Main/Magnifing.png"
import { ReactComponent as MagnifyingGlass } from "../../../../assets/Svg/MagnifyingGlass.svg"
import { sendSearch } from "../../../../common/utils/SendSearch.util";
interface Props {
  data: any;
  setSelectedPreviousJobs: (value: Array<IJobOccupation>) => void;
  selectedPreviousJobsS: Array<IJobOccupation>;
  setSelectedPreviousJobsS: (selectedPreviousJobsS: any) => void;
}

const languageStore = rootStores[LANGS_STORE];

const ReactSelectJob: React.FC<Props> = ({
  data,
  setSelectedPreviousJobs,
  setSelectedPreviousJobsS,
  selectedPreviousJobsS,
}) => {
  const { currentLanguageIndex } = languageStore;

  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      direction: "rtl",
      alignContent: "center",
      background: "transparent",
      boxShadow: null,
      "&:hover": {
        // borderColor: "white",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // borderRadius: "0",
      boxSizing: "border-box",
      height: "7vh",
      // marginTop: "2.8vh",
      padding: "0 2vh",
      fontSize: " 18px !important",
      // border: "none",
      backgroundColor: "inherit",
      borderColor: "inherit",
      width: "100%",
      border: "2px solid black",
      borderRadius: "50px",
    }),
    container: (base: any) => ({
      ...base,
      flex: 1,
      direction: "ltr",
      fontFamily: "Almoni-DL-AAA",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    }),
    valueContainer: (base: any) => ({
      ...base,
      fontFamily: "Almoni-DL-AAA",
      padding: "0",
      cursor: "text"
    }),
    placeholder: (base: any) => ({
      ...base,
      fontFamily: "Almoni-DL-AAA",
      padding: "0",
      color: "black",
      fontSize: "2vh !important",
    }),
    input: (provided: any) => ({
      ...provided,
      direction: "rtl",
      // color: "black",
      WebkitTextFillColor: "black",
      fontSize: "2vh !important",
    }),
    singleValue: (provided: any, state: { isDisabled: any }) => ({
      ...provided,
      direction: "rtl",
      color: "black",
      fontSize: "2vh !important",
    }),
    menu: (base: any) => ({
      ...base,
      width: "100%",
      boxShadow: "none",
      borderRadius: 0,
      background: "transparent",
      // color: "white",
      margin: "0",
      position: "static",
      top: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      fontFamily: "Almoni-BOLD-AAA",
      overflowX: "hidden",
      overflowY: "scroll",
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      height: "13vh",
      // overflow-y: scroll;
      display: "inline-block",
      paddingRight: "20px",
      marginTop: "2.4vh",
      lineHeight: "3.7vh",
      "::-webkit-scrollbar": {
        background: "transparent",
        width: "8px",
        cursor: "pointer",
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
        borderRadius: "10px",
        cursor: "pointer",
      },
      "::-webkit-scrollbar-thumb": {
        background: "black",
        // height: "1rem",
        borderRadius: "10px",
        cursor: "pointer",
      },
    }),
    option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
      ...provided,
      direction: "rtl",
      padding: "0",
      color: "black",
      backgroundColor: state.isFocused ? "#80808066 !important" : undefined,
      // : null,
      textOverflow: "ellipsis !important",
      overflow: "hidden !important",
      fontSize: "2vh !important",
      lineHeight: "3vh",
      fontFamily: "Almoni-DL-AAA",
      cursor: "pointer",
    }),

    indicatorsContainer: (base: any) => ({
      ...base,
      paddingRight: "0",
    }),
    indicatorContainer: (base: any) => ({

      ...base,

      padding: "0",
    }),
  };

  const selectRef = useRef<any>();
  let [search, setSearch] = useState<string>("");
  const [displayText, setDisplayText] = useState<boolean>(false);
  function onChangeText(e: any) {
    e.length > 1 && setDisplayText(true);
    setSearch(e);
  }

  function filterDataByName(value: string, data: any) {
    const filteredData: Array<any> = toJS(data).filter((obj: any) =>
      obj.male_name.toLowerCase().includes(value.toLowerCase()) || obj.female_name.toLowerCase().includes(value.toLowerCase())
    );

    filteredData.sort((a, b) =>
      currentLanguageIndex === 0
        ? a.male_name.indexOf(value) === 0 && b.male_name.indexOf(value) !== 0
          ? -1
          : a.male_name.indexOf(value) !== 0 && b.male_name.indexOf(value) === 0
            ? 1
            : 0
        : a.female_name.indexOf(value) === 0 && b.female_name.indexOf(value) !== 0
          ? -1
          : a.female_name.indexOf(value) !== 0 && b.female_name.indexOf(value) === 0
            ? 1
            : 0
    );
    return filteredData.map((job: any) => {
      if (currentLanguageIndex === 0)
        return { value: toJS(job), label: job.male_name };
      else return { value: toJS(job), label: job.female_name };
    });
  }

  const _filteredData = useMemo(
    () => filterDataByName(search, data),
    [search, data]
  );

  function onSelectedOption(e: any) {
    // const selectedObject: IJobOccupation | undefined = jobOccupations.find(
    //   (job) => job.male_name == e.value || job.female_name == e.value
    // );
    if (e !== null) {
      setSearch("");
      setDisplayText(false);
      // setRecommendedJobOccupation(selectedObject!);
      if (
        selectedPreviousJobsS.length < 3 &&
        !isJobAlreadyExist(e.value)
      ) {
        // setSelectedPreviousJobsLocal([...selectedPreviousJobsS, obj]);
        setSelectedPreviousJobsS([...selectedPreviousJobsS, e.value]);
      }
      selectRef?.current?.clearValue();
    }
  }

  function isJobAlreadyExist(obj: IJobOccupation) {
    return selectedPreviousJobsS.some(
      (item) => obj.description === item.description
    );
  }

  const DropdownIndicator = (props: any) => {

    // if (chosenJobOccupation)
    return (
      // <components.DropdownIndicator
      //   role={"button"}
      //   tabIndex={0}
      //   aria-pressed="false"
      //   {...props}

      // >
      <MagnifyingGlass />
      // <Image
      //   role={"button"}
      //   tabIndex={0}
      //   ariaPressed="false"
      //   src={}
      //   // onClick={chosenJobOccupation ? moveNextPage : () => {}}
      //   // onKeyPress={(e: any) => {
      //   //   if (e.key === "Enter") {
      //   //     moveNextPage();
      //   //   }
      //   // }}
      //   className={"searching-image"}
      //   id="search-icon-wp"
      // style={
      //   !chosenJobOccupation ? { cursor: "default", opacity: 0.75 } : {}
      // }
      // style={{ cursor: !chosenJobOccupation ? "default" : undefined,opacity: recommendedJobOccupation ? 1.0 : 0.75 }}
      // />
      // </components.DropdownIndicator>
    );
    // else return null;
  };

  return (
    <div style={{ width: "100%" }}>
      <Select
        controlShouldRenderValue={false}
        tabIndex={0}
        className="input-react-select-black"
        styles={customStyles}
        maxMenuHeight={150}
        menuIsOpen={displayText}
        options={_filteredData}
        onChange={(e, triggeredAction) =>
          onSelectedOption(e)
        }
        onInputChange={onChangeText}
        // defaultValue={defaultValue()}
        ref={selectRef}
        placeholder={"תוך כדי הקלדה כאן נציע לך שמות של עיסוקים ותפקידים שניתן לסמן ולבחור"}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        isRtl={true}
        noOptionsMessage={(e) => {
          sendSearch(e.inputValue)
          return "לא נמצאו תוצאות"
        }}
      // filterOption={(job: any, input) =>
      //   job.value.male_name.includes(input) ||
      //   job.value.female_name.includes(input)
      // }
      />
      <div className="card-container">
        {selectedPreviousJobsS.length > 0 && (
          <AutoCorrectCardObserver
            setSelectedJobOccupation={setSelectedPreviousJobs}
            data={selectedPreviousJobsS}
          />
        )}
      </div>
    </div>
  );
};
const ReactSelectJobObserver = observer(ReactSelectJob);

export default ReactSelectJobObserver;
