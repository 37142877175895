import { ICity } from "../../BL/stores/City.store";
import { IJobOccupation } from "../interfaces/Job-Occupation";

export default class SortingUtils {
  static jobOccupationBubbleSort(array: IJobOccupation[]): IJobOccupation[] {
    array = array.slice();
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array.length - 1; j++) {
        if (
          array[j] &&
          array[j + 1] &&
          (array[j].match_percentage as number) <
            (array[j + 1].match_percentage as number)
        ) {
          [array[j], array[j + 1]] = [array[j + 1], array[j]];
        }
      }
    }
    return array;
  }

  static jobOccupationNameBubbleSort(array: IJobOccupation[]): IJobOccupation[] {
    array = array.slice();
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array.length - 1; j++) {
        if (
          array[j] &&
          array[j + 1] &&
          (array[j].male_name as string) >
            (array[j + 1].male_name as string)
        ) {
          [array[j], array[j + 1]] = [array[j + 1], array[j]];
        }
      }
    }
    return array;
  }

  static cityBubbleSort(array: ICity[]): ICity[] {
    array = array.slice();
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array.length - 1; j++) {
        if (
          array[j] &&
          array[j + 1] &&
          (array[j].name as string) > (array[j + 1].name as string)
        ) {
          [array[j], array[j + 1]] = [array[j + 1], array[j]];
        }
      }
    }
    return array;
  }
}
