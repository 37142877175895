import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { JOB_OCCUPATION_STORE, USER_INFO_STORE } from "../../BL/consts/stores";
import { CommonHeader } from "../../common/components/Header/header.component";
import "./GetCourse.component.scss";
import rootStores from "../../BL/stores/index";
import { useHistory, useParams } from "react-router-dom";
import { ICourse } from "../../common/interfaces/Course";
import FirstRowMobileCoursesObsever from "../../common/components/FirstRowMobileCourses/FirstRowMobileCourses";
import GetCoursePageComponent from "./GetCoursePageComponent/GetCoursePageComponent";
import CourseDetails from "./CourseDetails/CourseDetails";
import GetCourseBar from "./GetCourseBar/GetCourseBar";
import { CourseLabel } from "./CourseLabel/CourseLabel.component";
import BackArrow from "../../assets/Results images/Back.svg";
import BackBlue from "../../assets/Results images/Back-blue.svg";
import Arrow from "../../assets/Results images/Next.svg";
import NextBlue from "../../assets/Results images/Next-blue.svg";
import { setDataLayer } from "../../common/utils/dataLayer.util";

const userInfoStore = rootStores[USER_INFO_STORE];
const jobOccupationStore = rootStores[JOB_OCCUPATION_STORE];
const courseStore = rootStores.CourseStore;
const languageStore = rootStores.LangsStore;

export const GetCourse: FC = () => {
  const { recommendedJobOccupation } = jobOccupationStore;
  const { setpopUpTriggerForBack, userPath } = userInfoStore;
  const { currentLanguageIndex } = languageStore;
  const {
    selectedCourse,
    coursesByJobOccupation,
    setSelectedCourse,
    setIsPopupShown
  } = courseStore;

  const { id }: { id: string } = useParams();
  const history = useHistory();


  const [nextCourse, setNextCourse] = useState<ICourse>(selectedCourse!);
  const [lastCourse, setLastCourse] = useState<ICourse>(selectedCourse!);

  useEffect(() => {
    if (coursesByJobOccupation.length === 0) {
      history.push("/");
      return;
    }
    setLastCourse(selectedCourse!);
    setNextCourse(selectedCourse!);
  }, [id]);

  useEffect(() => {
    if (!selectedCourse) {
      history.push("/");
      return;
    }
    history.push({
      search: `flow=${userPath ? "short" : "long"}&name=${selectedCourse?.name
        }`,
    });
    history.push({
      search: `flow=${userPath ? "short" : "long"}&name=${selectedCourse?.name
        }&training_institution=${selectedCourse?.training_institution.name
        }&location=${selectedCourse?.location}&training_format=${selectedCourse?.course_format
        }`,
    });
  }, [selectedCourse]);

  useEffect(() => {
    return () => setSelectedCourse(null);
  }, []);

  const findNextObject = (out_course: ICourse) => {
    const length = coursesByJobOccupation.length;
    const id = coursesByJobOccupation.findIndex((s) => s.id === out_course!.id);
    if (id >= length - 1) {
      setNextCourse(coursesByJobOccupation[0]);
      setSelectedCourse(coursesByJobOccupation[0]);
      history.push(`/course/0`);
    } else {
      const temp = coursesByJobOccupation!.find((course: ICourse) => {
        return (course.id as number) === id + 1;
      });
      history.push(`/course/${temp!.id}`);
      setNextCourse(temp!);
      setSelectedCourse(temp!);
    }
  };

  const findLastObject = (course: ICourse) => {
    const length = coursesByJobOccupation.length;
    const id = coursesByJobOccupation.findIndex((s) => s.id === course!.id);
    if (id === 0) {
      setLastCourse(coursesByJobOccupation[length - 1]);
      setSelectedCourse(coursesByJobOccupation[length - 1]);
      history.push(`/course/${length - 1}`);
    } else {
      const temp = coursesByJobOccupation!.find((course: ICourse) => {
        return (course.id as number) === id + -1;
      });
      history.push(`/course/${temp!.id}`);
      setLastCourse(temp!);
      setSelectedCourse(temp!);
    }
  };

  const moveOnePage = () => {
    setpopUpTriggerForBack(false);
    history.push(`/courses-list-of/${recommendedJobOccupation?.male_name}`);
  };

  const moveBack = () => {
    history.push("/recommendation-flow");
  };

  const currentOccupation = recommendedJobOccupation ? currentLanguageIndex === 1 ?
    recommendedJobOccupation!.female_name
    : recommendedJobOccupation!.male_name : null;


  const addParamsToURL = () => {
    setDataLayer('linkto__college',
      selectedCourse?.name,
      selectedCourse?.training_institution.name,
      selectedCourse?.location,
      selectedCourse?.course_format,
      selectedCourse?.diploma,
      selectedCourse?.employment_guaranteed,
      selectedCourse?.budgeting,
      currentOccupation
    )
    history.push({ search: `flow=${userPath ? "short" : "long"}&name=${selectedCourse?.name}&training_institution=${selectedCourse?.training_institution.name}&location=${selectedCourse?.location}&training_format=${selectedCourse?.course_format}` })
    setIsPopupShown();
  }

  return (
    <>
      <Helmet>
        <title>Skill-il - ההכשרות</title>
      </Helmet>
      <div className="course-container">
        <div className="course-container--content-bg"></div>
        {/* Priority #1 */}
        <div tabIndex={1} className="course-container--heading">
          <div className="header-text-lanuage-container-single-page" style={{ flexWrap: "wrap" }}>
            <h1 className="h1-layout" tabIndex={0}>
              {selectedCourse?.training_institution.name}
            </h1>
            <h2 tabIndex={0} className="h1-layout bolded">
              {selectedCourse?.name}
            </h2>
            <div className="tags-container">
              {selectedCourse?.employment_guaranteed ? (
                <CourseLabel
                  label="השמה"
                  content="בקורס זה יש אפשרות לשלב השמה אצל מעסיק. לפרטים נוספים יש לבדוק מול המכללה."
                />
              ) : null}
              {selectedCourse?.budgeting ? (
                <CourseLabel
                  label="סבסוד"
                  content="בקורס זה יש אפשרות לקבל מימון לשכר הלימוד באמצעות שובר."
                  isSubsidizedLink={true}
                />
              ) : null}
              {selectedCourse?.diploma ? (
                <CourseLabel
                  label="תעודה ממשלתית"
                  content="קורס זה מקנה תעודה ממשלתית."
                />
              ) : null}
            </div>
          </div>
        </div>
        {/* Priority #2 */}
        <div className="course-container--main">
          {selectedCourse !== null ? (
            <GetCoursePageComponent
              lastCourse={lastCourse}
              nextCourse={nextCourse}
              findLastObject={findLastObject}
              findNextObject={findNextObject}
              moveBack={moveBack}
              moveOnePage={moveOnePage}
            />
          ) : null}
        </div>
        <div className="course-container--information-card">
          <CourseDetails />
        </div>
        <div className="course-container--navigation-buttons">
          <button className="get-course-blank-url-mobile" onClick={() => { window.open(selectedCourse?.course_url); addParamsToURL() }} >אני רוצה לשמוע עוד!</button>
          <div className="footer-single-page" style={{ alignItems: "center", height: "100%" }} >
            <button
              className="container-back-screen-arrow-13"
              style={{ flex: "0.3" }}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  findLastObject(lastCourse!);
                }
              }}
            >
              <img
                className="arrow-image-screen-13-back pointer"
                src={BackArrow}
                alt=""
                onClick={() => findLastObject(lastCourse!)}
              />
              <img
                onClick={() => findNextObject(nextCourse!)}
                className="arrow-image-screen-13-next-mobile pointer"
                src={BackBlue}
                alt=""
              />
              <p
                onClick={() => findLastObject(lastCourse!)}
                className="container-back-screen-arrow-13--forth"
              >
                ההכשרה הקודמת
              </p>
            </button>
            {/* <span id="read-this" hidden>{"מעבר לאתר המכללה"}</span> */}
            <span id="read-this" hidden>a link to external website</span>
            <button
              // aria-hidden="true"
              aria-describedby="read-this"
              className="get-course-blank-url"
              onClick={() => {
                setIsPopupShown();
                addParamsToURL();
                window.open(selectedCourse?.course_url);
              }}
            >
              אני רוצה לשמוע עוד!
            </button>
            <button
              className="container-move-screen-arrow-13"
              style={{ flex: "0.3" }}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  findNextObject(nextCourse!);
                }
              }}
            >
              <p className="container-move-screen-arrow-13--back" onClick={() => findNextObject(nextCourse!)}>
                ההכשרה הבאה
              </p>
              <img
                onClick={() => findNextObject(nextCourse!)}
                className="arrow-image-screen-13-next-mobile pointer"
                src={NextBlue}
                alt=""
              />
              <img
                onClick={() => findNextObject(nextCourse!)}
                className="arrow-image-screen-13-next pointer"
                src={Arrow}
                alt=""
              />
            </button>
          </div>
        </div>
        <div tabIndex={0} className="course-container--sidebar">
          <FirstRowMobileCoursesObsever moveBack={moveOnePage} title={"חזרה לכל ההכשרות"} />
          <div className="course-container--sidebar--mobile">
            <h1 className="h1-layout-keren" style={{ marginRight: "0px", marginTop: "0px", color: 'white' }}>
              {selectedCourse?.training_institution.name}
              <br />
              <span className="bolded ">{selectedCourse?.name}</span>
            </h1>
            <div style={{ paddingTop: "1rem" }}>
              {selectedCourse?.employment_guaranteed ? (
                <CourseLabel
                  label="השמה"
                  content="בקורס זה יש אפשרות לשלב השמה אצל מעסיק. לפרטים נוספים יש לבדוק מול המכללה."
                />
              ) : null}
              {selectedCourse?.budgeting ? (
                <CourseLabel
                  label="סבסוד"
                  content="בקורס זה יש אפשרות לקבל מימון לשכר הלימוד באמצעות שובר."
                  isSubsidizedLink={true}
                />
              ) : null}
              {selectedCourse?.diploma ? (
                <CourseLabel
                  label="תעודה ממשלתית"
                  content="קורס זה מקנה תעודה ממשלתית."
                />
              ) : null}
            </div>{" "}
          </div>
          <div className="navigator-row desktop" style={{ height: "52%", marginTop: "17%" }}>
            <GetCourseBar
              setLastCourse={setLastCourse}
              setNextCourse={setNextCourse}
            />
          </div>
        </div>
        <CommonHeader />
      </div>
    </>
  );
};
