import { FC } from "react";
import { Link } from "react-router-dom";
import {
    APP_STORE
  } from "../../../BL/consts/stores";
  import rootStores from "../../../BL/stores";
import { SideBarModals } from "../../../BL/stores/App.store";
import { ReactComponent as Close_White } from "../../../assets/Results images/Close-white.svg";
import { observer } from "mobx-react";
import { LanguageDropdown } from "../../../UI/interesetsPage/components/LanguageComponent/Language.component";

const appStore = rootStores[APP_STORE];

export const CommonHeader:FC = observer(() =>{
    const { chosenModal, setChosenModal } = appStore;

    return (
        <header className="flow-header">
          <div className="flow-header--button-container">
            <a
              role={"button"}
              tabIndex={0}
              style={{ cursor: "pointer" }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setChosenModal(SideBarModals.contactUs);
                }
              }}
              onClick={() => {
                if (chosenModal === SideBarModals.contactUs) {
                  setChosenModal(SideBarModals.none);

                  return;
                }
                setChosenModal(SideBarModals.contactUs);
              }}
            >
              יצירת קשר
            </a>
            {chosenModal !== SideBarModals.contactUs ? (
              <img
                // onClick={() => {
                //   setChosenModal(SideBarModals.contactUs);
                // }}
                // role={"button"}
                // tabIndex={0}
                // onKeyPress={(e) => {
                //   if (e.key === "Enter") {
                //     setChosenModal(SideBarModals.contactUs);
                //   }
                // }}
                style={{ width: "18px", marginBlockEnd: "-5px" }}
                src={
                  "data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAABI0lEQVQ4jaXTu0oDQRSH8d+uG4wX7MVCC0GsrbQRRESfIGLpO1iKVlGw8B0sxSqV4K3yFWKnYGFhJwbES4zF7soYllzMB9v8mflmzp4zUanSVMA0NrGKeUzgBXVc4BSP4YaoQFTFDpKiEzI+cID9ItEwbrDYQdDONdbQjIPwtk8JrOAKclEVC31KcpaxF5UqzUk8/VOS8x1je0AJfMVYbwvvMIqowzeGh2DPeyKdk5ARLOGt4OQWmhiXdvmXJLOHzOCyh3L+kOAV5SC7x5Z06FpBHmW3OMNUkagubWHOZyZrYCjI85KKSpag1iaaw3MP1YSUY5xIyxiEKJb+7Ljbyi7sxtL30umld+Mch0UD2Q81bMhuMisdgZy85VEHQQPHOMqDH8DRNMvRJOKxAAAAAElFTkSuQmCC"
                }
              />
            ) : (
              <div
                role="button"
                tabIndex={0}
                aria-pressed={false}
                style={{
                  aspectRatio: "1/1",
                  maxWidth: "16px",
                  maxHeight: "16px",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setChosenModal(SideBarModals.none)
                  }
                }}
                onClick={() => {
                  setChosenModal(SideBarModals.none);
                }}
              >
                <div
                  className="close-button-side-bar"
                  // role="button"
                  // tabIndex={0}
                  // aria-pressed={false}
                  // aria-describedby={"informatio-button"}
                  style={{
                    cursor: "pointer",
                    maxWidth: "18px",
                    maxHeight: "18px",
                  }}
                >
                  <Close_White />
                </div>
              </div>
            )}
          </div>
          <div
            className="flow-header--button-container"
            style={{ verticalAlign: "middle" }}
          >
            <Link to={"/"}>לעמוד הבית</Link>
            <img
              src={
                "data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAASCAYAAAC0EpUuAAABKUlEQVQ4ja3UsSvEcRjH8ZdzVyZRdKUMMkrKRBkUozqTUyYZ/AWMyip/gMlkoAz+AQxKUYaLQZIuxSmjhaQMdz/9+rnv7+6cz/Q8z/fzvL/D8/0+HbnilwYawzZGcYt1XKY1ZBsACziK5XlcYBEHoaZMCnAtAYxrHxutQnexlXIhbGKvGWg3TrHcABhpCefoC0FHcIPpJoGRJmt940loASUMtgiMlMcVFqhOfxU7f4QldYD+jlzx6x7D/wSFckZ1KMd4aRP2ihOsZHCGWYHn0YIOMYOT+PRzAXMF8xjCHB4DvuyvAJ11jJ+YwkMtL2OilnclvD/9ad8U7mLASBXVdxlUI+h7oP7RDjS0xVK3WxzaU+e8Xi1U76134zXeEsanAPQZA4laKQq+AXIgMITFQvEtAAAAAElFTkSuQmCC"
              }
            />
          </div>
          <div
            className="flow-header--button-container"
            style={{ position: "relative" }}
          >
            <LanguageDropdown IsWhite={false} />
            {/* <div style={{aspectRatio:'16/5',border:'1px solid black'}}>

              </div> */}
            {/* Language */}
          </div>
        </header>
    )
})