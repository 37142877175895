import { observer } from "mobx-react";
import React ,{useEffect} from "react";
import Helmet from "react-helmet";
import rootStores from "../../BL/stores/index";
import {
  JOB_OCCUPATION_STORE,
  USER_INFO_STORE,
  AUTH_STORE,
} from "../../BL/consts/stores";
import "./lastThingsPageComponent.scss";
import Image from "../../common/components/image.component";
import NextArrowImage from "../../assets/images/Next_arrow.png";
import JobOccupationTextInputAutoComplete from "./components/textInputAutoCorrect/textInputAutoCorrect.component";
import ReactSelectJobObserver from "./components/textInputAutoCorrect/reactSelectJob";
import { IJobOccupation } from "../../common/interfaces/Job-Occupation";
import SerializationUtils from "../../common/utils/serializationUtils";
import SessionUtils from "../../common/utils/SessionUtils";
import { setLastOccupationsToDataLayer } from "../../common/utils/dataLayer.util";
import { useHistory } from "react-router";
interface Props {
  movePage?: (isUserLoggedIn: boolean) => void;
  setLevel: (number: number) => void;
  //goBack: (pathName: string, search: string) => void;
}

const userInfoStore = rootStores[USER_INFO_STORE];
const jobOccupationStore = rootStores[JOB_OCCUPATION_STORE];
const authStore = rootStores[AUTH_STORE];
const languageStore = rootStores.LangsStore;

const LastThingsPage: React.FC<Props> = ({ movePage, setLevel }) => {

  const { selectedPreviousJobs, setSelectedPreviousJobs,previusURLs,previusLevels, 
    removePreviusLevel} = userInfoStore;
  const { jobOccupations } = jobOccupationStore;
  const { isUserLogged } = authStore;
  const { translate } = languageStore;
  const history = useHistory();

useEffect(() => {
    window.onpopstate = function(event) {
      //removePreviusLevel();
      let previusLevel = previusLevels[previusLevels.length-1];
      setLevel(previusLevel);
      history.push(previusURLs[previusLevel]);
      //setPreviusLevel(3);
    }
  }, [])

  const setPreviousJobs = (previousJobs: Array<IJobOccupation>) => {
    setSelectedPreviousJobs(previousJobs, isUserLogged);
  };

  const setPrevJobsToLocalStorage = () => {
    const previousJobsNamesArr: number[] = []
    selectedPreviousJobs.forEach((item: any) => {
      if (item.male_name) {
        previousJobsNamesArr.push(item.male_name)
      }
    })
    const previousJobsNamesStr = SerializationUtils.spreadWithNumberOrStringLocalStorage(previousJobsNamesArr)
    SessionUtils.setItem("previous_jobs", previousJobsNamesStr)
  }

  return (
    <>
      <Helmet>
        <title> Skill-il - כמה דברים אחרונים </title>
      </Helmet>
      <div className="center-of-screen">
        <div className="container-last-things">
          <div className="container-screen-and-arrow-last-thing">
            <div className="top-row">
              <div className="top_text">
                <h1 className="h1-layout" tabIndex={0}>
                  {translate("lastThingsPage", "header")}
                  <span className="bolded" style={{ display: 'block' }}>
                    {translate("lastThingsPage", "headerBr")}
                  </span>
                </h1>
                <h5 className="last-discription" tabIndex={0}>
                  {translate(
                    "lastThingsPage",
                    "descriptionFirstLine"
                  )}
                  <br />
                  {translate(
                    "lastThingsPage",
                    "descriptionFirstLineBr"
                  )}
                </h5>
              </div>
            </div>
            <div className="last-things-inner-container">
              <div className="past-jobs-container">
                <div className="inner-past-jobs-container">
                  <div className="inner-past-jobs-container2">
                    {window.innerWidth < 767 ? (
                      <JobOccupationTextInputAutoComplete
                        data={jobOccupations}
                        setSelectedPreviousJobs={setPreviousJobs}
                        selectedPreviousJobsS={selectedPreviousJobs}
                        setSelectedPreviousJobsS={setPreviousJobs}
                        type="city"
                      />
                    ) : (
                      <ReactSelectJobObserver
                        data={jobOccupations}
                        setSelectedPreviousJobs={setPreviousJobs}
                        selectedPreviousJobsS={selectedPreviousJobs}
                        setSelectedPreviousJobsS={setPreviousJobs}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container_arrow1 arrow-interest">
            <button
              className="container-next1"
              onClick={() => {
                setPrevJobsToLocalStorage();
                setLastOccupationsToDataLayer('occupation_selected', selectedPreviousJobs)
                movePage!(isUserLogged);
              }}
            >
              <div className="arrow-text" tabIndex={0}>
                {translate("lastThingsPage", "nextArrow")}
              </div>
              <Image className="arrow-img-last1" src={NextArrowImage} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const LastThingsObserver = observer(LastThingsPage);
export default LastThingsObserver;
