import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import rootStores from "../../../../BL/stores";
import "./CaruselComponent.scss";
import { IJobOccupation } from "../../../../common/interfaces/Job-Occupation";
import OneItemCarusel from "./OneItemCarusel";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Pagination } from "swiper";

const jobStore = rootStores.JobOccupationStore;

interface IProps {
  recommendedJobOccupation: IJobOccupation | null;
  clickOnScroll: (name: string, job: IJobOccupation) => void;
  moveToCourse: (name: string) => void;
}
const CaruselComponent: React.FC<IProps> = ({ recommendedJobOccupation, clickOnScroll, moveToCourse }) => {
  const { currentRecommendedJobOccupations } = jobStore;

  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const slideTo = (index: number) => swiper?.slideTo(index);
  const slide = useCallback(() => {
    if (!swiper)
      return;

    const index = currentRecommendedJobOccupations.findIndex((jo) => jo.male_name === recommendedJobOccupation?.male_name);
    slideTo(index);
  }, [swiper, swiper?.isEnd, recommendedJobOccupation]);

  useEffect(() => {
    slide();
  });

  return (
    <div className="wrapper">
      {/* <div className="carousel"> */}
      <Swiper
        onSwiper={(e) => setSwiper(e)}
        dir="rtl"
        slidesPerView={4}
        spaceBetween={7}
        // setWrapperSize={true}
        // centeredSlides={true}
        pagination={false}
        modules={[Pagination]}
        className="mySwiper"
      >
        {currentRecommendedJobOccupations.length > 0
          ? currentRecommendedJobOccupations.map(
            (recJob: IJobOccupation, index: number) => (
              <SwiperSlide onClick={(id) => swiper?.slideTo(index)}>
                <OneItemCarusel
                  moveToCourse={moveToCourse}
                  clickOnScroll={clickOnScroll}
                  key={recJob.male_name}
                  recJob={recJob}
                />
              </SwiperSlide>
            )
          )
          : 
          recommendedJobOccupation?
          [recommendedJobOccupation!].map(
            (recJob: IJobOccupation, index: number) => (
              <SwiperSlide onClick={(id) => swiper?.slideTo(index)}>
                <OneItemCarusel
                  moveToCourse={moveToCourse}
                  clickOnScroll={clickOnScroll}
                  key={recJob.male_name}
                  recJob={recJob}
                />
              </SwiperSlide>
            )
          )
          :null
          }
      </Swiper>
    </div>
  );
};

const CaruselJOCoursesComponent = observer(CaruselComponent);
export default CaruselJOCoursesComponent;
