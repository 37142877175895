import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
} from "axios";
// import { isNullOrUndefined } from 'util';
import ClientConfig from "../../common/config";
import { SessionConsts } from "../../common/consts/SessionConsts";
import SessionUtils from "../../common/utils/SessionUtils";
import { ApiResponseBody } from "../interfaces/ApiResponse.interface";
// import { isJwtValidDecode } from "../../common/jwt";
import jwtDecode, * as jwt_decode from "jwt-decode";
import { JwtPayload } from "jwt-decode";
import Cookies from "js-cookie";
import { getCookie } from "../../common/utils/CookieUtils";

const TOKEN_KEY_ON_HEADERS = "Authorization";
const isNullOrUndefined = (value: any) => value === null || value === undefined;
export class BaseHttpFetcher {
  public axios: AxiosInstance;

  constructor(baseRoute: string, fetcherBaseURL?: string) {
    let baseApiUrl = fetcherBaseURL ? fetcherBaseURL : ClientConfig.apiBaseHost;
    baseApiUrl += `/${baseRoute}`;
    this.axios = axios.create({
      baseURL: baseApiUrl,
    });

    // Set interceptor on the request
    this.axios.interceptors.request.use(async (config: AxiosRequestConfig) => {

      //   if (!config.url?.includes("refresh")) {
      //     await this.isJwtValidDecode();
      //   } 
      // Check if there is NOT token on the headers
      // Also checks if this request should have token
      if (
        isNullOrUndefined(config.headers[TOKEN_KEY_ON_HEADERS]) &&
        this.shouldAddTokenToRequest(config)
      ) {
        // Set token from storage
        const tokenFromStorage = SessionUtils.getItem(SessionConsts.Token);
        if (tokenFromStorage) {
          config.headers[TOKEN_KEY_ON_HEADERS] = `Bearer ${tokenFromStorage}`;
        }
      }

      // Set withCredentials
      config.withCredentials = true;

      return config;
    });

    // Set interceptor on the response - Always return the "data" from the response
    // this.axios.interceptors.response.use((response: AxiosResponse<ApiResponseBody>) => {
    // 	const apiResponse: ApiResponseBody = response.data.results;
    // 	return apiResponse as any;
    // });
  }
  //   private isJwtValidDecode = async () => {
  //     try {
  //       const auth =
  //         getCookie("auth_token") != null && getCookie("auth_token") != undefined
  //           ? getCookie("auth_token")?.split(`%22`)[1]
  //           : null;
  //       const refresh_token = getCookie("refresh_token");

  //       if (!auth && !refresh_token) {
  //         return false;
  //       }

  //       if (refresh_token && !auth) {
  //         return await this.isRefreshTokenValidDecode();
  //       }
  //       const decodedTokenValue: JwtPayload = jwtDecode(auth!);

  //       if (Date.now() < decodedTokenValue?.exp! * 1000) {
  //         return true;
  //       } else if (Date.now() >= decodedTokenValue?.exp! * 1000) {
  //         return await this.isRefreshTokenValidDecode();
  //       }
  //       return false;
  //     } catch (err) {
  //       return false;
  //     }
  //   };

  //   private isRefreshTokenValidDecode = async () => {
  //     try {
  //       const refresh_token =
  //         getCookie("refresh_token") != null
  //           ? getCookie("refresh_token")?.split(`%22`)[1]
  //           : null;

  //       if (!refresh_token) {
  //         return false;
  //       }
  //       const decodedTokenValue: JwtPayload = jwtDecode(refresh_token!);

  //       if (Date.now() < decodedTokenValue?.exp! * 1000) {
  //           const res = await this.post(
  //             ClientConfig.apiBaseHost + "/auth/refresh/"
  //             // +
  //           );
  // 		  return true;
  //           // if (res.data.token && res.data.refresh_token) {
  //           // }
  //           // return res.data;

  //       } else return false;

  //     } catch (err) {
  //       return false;
  //     }
  //   };

  public get<V>(url: string, headers?: any): Promise<any> {
    return this.axios.get(url, this.getBasicAxiosConfig({ headers }));
  }

  public post<V>(url: string, body?: any, headers?: any): Promise<any> {
    return this.axios.post(url, body, this.getBasicAxiosConfig({ headers }));
  }

  public async put(url: string, body?: any, headers?: any): Promise<any> {
    const res = await this.axios.put(
      url,
      body,
      this.getBasicAxiosConfig(headers)
    );
    return res;
  }

  public del(url: string, headers?: any): Promise<any> {
    return this.axios.delete(url, this.getBasicAxiosConfig({ headers }));
  }

  public postFormData(url: string, file: any) {
    const formData = new FormData();
    formData.append("file", file);
    const axiosConfig = this.getBasicAxiosConfig({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return this.post(url, formData, axiosConfig);
  }
  public postMultipleFormData(url: string, file: any, obj: any) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      if (!key)
        // if its an undefined id (on create)
        continue;
      if (
        !(value instanceof Date) &&
        typeof value !== "string" &&
        typeof value !== "number" &&
        typeof value !== "boolean"
      ) {
        // if its a file
        if (value) {
          formData.append("file", file);
          formData.append(key.toString(), "image");
        }
        continue;
      }
      formData.append(key.toString(), value.toString());
    }
    const axiosConfig = this.getBasicAxiosConfig({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return this.post(url, formData, axiosConfig);
  }
  public putMultipleFormData(url: string, file: any, obj: any) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      if (key === "_id") {
        // if its an undefined id (on create)
        continue;
      }
      if (
        typeof value !== "string" &&
        typeof value !== "boolean" &&
        typeof value !== "number"
      ) {
        // if its a file

        formData.append("file", file);
        continue;
      }
      formData.append(key.toString(), value.toString());
    }
    const axiosConfig = this.getBasicAxiosConfig({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return this.put(url, formData, axiosConfig);
  }

  public uploadFile(
    url: string,
    file: any,
    cancelToken: CancelToken
  ): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);

    const axiosConfig = this.getBasicAxiosConfig({
      cancelToken,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return this.post(url, formData, axiosConfig);
  }

  /**
   * This method is for future "thinking".
   * When we'll need to change the configuration, we can do that in this method
   * @param config configuration object of Axios
   */
  private getBasicAxiosConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
    return config || {};
  }

  /**
   * This method decides wether to send the token  in the request
   * Currently, it's checking:
   * The url we send the request to
   * @param config The request object (By Axios)
   */
  private shouldAddTokenToRequest(config: AxiosRequestConfig) {
    // if (config && config.url) {
    //	Do some manipulations on the URL
    // }

    return true;
  }
}
