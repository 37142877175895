import "./common/styles/_typography.module.scss";
import "./common/styles/_fonts.module.scss";
import SideBar from "./UI/sideBar/sideBar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import StartFlowComponent from "./Flow2NewComponent";
import { Suspense, useEffect, useState } from "react";
import rootStores from "./BL/stores";
import CourseInformationAndRegistration from "./UI/Course information and registration/CourseInformationAndRegistration";
import "./App.scss";
import JobOccupationPageomponent from "./UI/jobOccupationPage/JobOccupationPageComponent";
import CoursesByJobOccupationPageComponent from "./UI/course-by-job-occupation/CoursesByJobOccupationPageComponent";
import { observer } from "mobx-react";
import VlaidFirstSignUpComponent from "./UI/forgotpassword/password/validSignUp/VlaidFirstSignUpComponent";
import NotFoundComponent from "./UI/notFoundPage/NotFoundComponent";
import ForgotPasswordPageObser from "./UI/forgotpassword/password/ForgotPasswordPage";
import React from "react";
import { RegistrationModal } from "./UI/RgistrationModal/RegistrationModal";
import { LoginConfirmModal } from "./UI/Modals/LoginConfirmModal";
import TermsOfUse from "./UI/sideBar/modals/termsOfUse/TermsOfUse"
import FeedbackPopup from "./common/components/FeedbackPopup/FeedbackPopup.cmp";
import { GetCourse } from "./UI/Course information and registration/GetCourse.component";
import AboutTheSystem from "./UI/AboutTheSystem/AboutTheSystem";
import { SideBarModals } from "./BL/stores/App.store";
import Footer from "./UI/Footer/Footer";
import HomePage from "./UI/homePage/HomePage";

const userInfoStore = rootStores.UserInfoStore;
const authStore = rootStores.AuthStore;
const appStore = rootStores.AppStore;

function App() {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);

  const { init, chosenModal } = appStore
  const {
    isLoading,
    userPath,
    setIsLoading,
    isFeedbackPopupOpen
  } = userInfoStore;

  const { registrationModalSettings, isOpenLoginConfirmModal } = authStore;

  useEffect(() => {
    setIsLoading(true);

    (async function anyNameFunction() {
      await init();

      // Authorisation

      // await getAllCategories();

      // const dataFromDb = await authClass.isUserLogged();

      // await getJobOccupations();
      // await getAllQuestions();
      // // if(!dataFromDb){
      // // }

      // if (authStore.isUserLogged) {
      //   setDataFromLogin(dataFromDb);
      //   setIsUserLogged(true);
      //   setUserDetails(dataFromDb);
      //   dataFromDb.city && citiesStore.setSelectedCity(dataFromDb.city);
      //   getAnswersFromLogIn(dataFromDb?.questionnaire?.testQuestions);
      //   languageStore.setLanguageByGender(dataFromDb?.gender)
      //   // getSelectedQuestionFromLogIn(dataFromDb?.questionnaire?.testQuestions);
      //   await calculateQuestionaire();
      // } else {

      //   // setIsUserLogged(false);
      //   // getSelectedQuestion();
      //   // await calculateQuestionaire();
      // }

      // Authorisation

      setIsLoading(false);
    })();
  }, [authStore.isUserLogged]);


  const WelcomePageComponent1 = React.lazy(
    () => import("./UI/welcomePage/welcomePageComponent")
  );
  // const LoginComponentn1 = React.lazy(
  //   () => import("./UI/AdminScreen/Login/LoginComponent")
  // );
  // const ChoosePasswordComponent = React.lazy(
  //   () => import("./UI/ChoosePassword/ChoosePasswordComponent")
  // );

  return (
    <Suspense fallback={() => console.log("err")}>
      <>
        {!isLoading && (
          <Router>
            <div className="main-container">
              <div className="router-container">
                {chosenModal === SideBarModals.aboutUs && <AboutTheSystem />}
                <Switch>
                  {/* <Route exact path="/" component={WelcomePageComponent1} /> */}
                  <Route exact path="/" component={HomePage} />
                  <Route
                    path={`/${userPath ? "short" : "long"}`}
                    component={StartFlowComponent}
                  />
                  <Route
                    exact
                    path="/recommendation-flow"
                    component={JobOccupationPageomponent}
                  />
                  <Route
                    exact
                    path={`/courses-list-of/:name`}
                    component={CoursesByJobOccupationPageComponent}
                  />
                  <Route
                    path={`/course/:id`}
                    component={GetCourse}
                  />
                  <Route
                    path={`/password-reset/:token`}
                    component={ForgotPasswordPageObser}
                  />
                  <Route
                    path={`/auth/:token`}
                    component={VlaidFirstSignUpComponent}
                  />
                  <Route component={NotFoundComponent} />
                </Switch>
                {isFeedbackPopupOpen && <FeedbackPopup />}
                <SideBar />

                <Footer setIsTermsModalOpen={setIsTermsModalOpen} />

              </div>
              {registrationModalSettings.isOpen && <RegistrationModal />}
              {isOpenLoginConfirmModal && <LoginConfirmModal />}
              {isTermsModalOpen && <TermsOfUse onClose={() => setIsTermsModalOpen(false)} />}
            </div>
          </Router>
        )}
      </>
    </Suspense>
  );
}

export default observer(App);
