import { observer } from "mobx-react";
import React from "react";
import rootStores from "../../../../BL/stores";
import { ICourse } from "../../../../common/interfaces/Course";
import { IJobOccupation } from "../../../../common/interfaces/Job-Occupation";
import Substruction from "../../../../assets/images/Main/Substruction.png";
import "./CourseBtnComponents13.scss";
interface IProps {
  course?: ICourse;
  recJob: IJobOccupation;
  clickOnScroll: (name: string, job: IJobOccupation) => void;
  moveToCourse: (name: string) => void;
}

const jobStore = rootStores.JobOccupationStore;
const languageStore = rootStores.LangsStore;

const CourseBtnComponents13: React.FC<IProps> = ({
  recJob,
  clickOnScroll,
  moveToCourse,
}) => {
  const { recommendedJobOccupation, setRecommendedJobOccupation } = jobStore;

  const handleChooseJob = (job: IJobOccupation) => {
    if (recommendedJobOccupation!.male_name !== recJob.male_name) {
      clickOnScroll(job.male_name, job);
      setRecommendedJobOccupation(job);
    }
  };

  return (

    <div className="occupation-container" tabIndex={0}>
      <div
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleChooseJob(recJob);
          }
        }}
        onClick={(e) => {
          handleChooseJob(recJob);
        }}
        className={
          recommendedJobOccupation!.male_name === recJob.male_name
            ? "occupation__title--chosen"
            : "occupation__title"
        }
      >
        <>
          <div className="occupation-container__slider" />
        </>
        {languageStore.currentLanguageIndex === 1
          ? recJob.female_name
          : recJob.male_name}
      </div>
      <div className="slider__section-btn">
        <>
          <img
            className={
              recommendedJobOccupation!.male_name === recJob.male_name
                ? "slider__img-indicator"
                : "slider__img-indicator--hidden"
            }
            src={Substruction}
            alt={"Substruction pointer"}
          />
        </>
        <p style={{ width: "9%", zIndex: 3 }}>
          {recommendedJobOccupation!.male_name === recJob.male_name ? (
            <>
              <div className="course-text-color">
              </div>
              <div className="custom-click-scroll"></div>
            </>
          ) : (
            <>
              <div className="custom-click-scroll" style={{ visibility: "hidden" }}></div>
            </>
          )}
        </p>
      </div>
    </div>

  );
};

const CourseBtnComponentsObserver13 = observer(CourseBtnComponents13);
export default CourseBtnComponentsObserver13;
