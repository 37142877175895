import { observer } from "mobx-react";
import rootStores from "../../../../BL/stores";
import { ICourse } from "../../../../common/interfaces/Course";
import Substruction from "../../../../assets/images/Main/Substruction.png";
import "./CourseBtnComponents.scss";
import { useHistory } from "react-router-dom";
interface IProps {
  course: ICourse;
  setLastCourse?: (value: ICourse) => void;
  setNextCourse?: (value: ICourse) => void;
}

const courseStore = rootStores.CourseStore;

const CourseBtnComponents: React.FC<IProps> = ({ course, setLastCourse, setNextCourse }) => {
  const { setSelectedCourse, selectedCourse } = courseStore;

  const history = useHistory();

  const handleChooseCourse = (oneCourse: ICourse) => {
    setNextCourse!(oneCourse);
    setSelectedCourse(oneCourse);
    setLastCourse!(oneCourse)
    history.push(`/course/${oneCourse?.id}`);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          marginTop: "5px",
          justifyContent: "space-between",
          zIndex: 4000,
          width: "100%",
          flexDirection: "row-reverse",
          alignItems: "center",
          minHeight: "40px",
          position: "relative"
        }}
      >
        <>
          <div
            className="course-text"
          > </div>
        </>
        <p
          tabIndex={0}
          onClick={(e) => {
            handleChooseCourse(course);
          }}
          className={
            selectedCourse?.id === course.id
              ? "btn-is-true "
              : ` btn`
          }
          style={{ width: "84%" }}
          // role={"button"}
          // tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleChooseCourse(course);
            }
          }}
        >
          {course.name ? course.name : ""}
        </p>

        <div style={{
          position: "relative",
          justifyContent: "center"
        }}>
          <div>
            <img
              className={selectedCourse?.id === course?.id ?
                "substruction-course-img-show"
                : "substruction-course-img-hidden"}
              src={Substruction}
              alt={"Substruction pointer"}
            ></img>
          </div>
          <div
            style={{
              position: "absolute",
              backgroundColor: "black",
              borderRadius: "60px",
              height: "40%",
              width: "2%",
              right: "0%",
              zIndex: 2,
              visibility: "hidden",
              display: "none"

            }}
          >
          </div>
          <p style={{ width: "9%", zIndex: 3 }}>
            {selectedCourse?.id === course.id ? (
              <>
                <div
                  //  style={{
                  //    position: "absolute",
                  //    backgroundColor: "#0B1324",
                  //    borderRadius: "60px",
                  //    height: "5.2rem",
                  //    width: "62%",
                  //  //  right: "0%",
                  //    left:"27%",
                  //    top:"-13.5%",
                  //    zIndex: 0,
                  // // visibility: "hidden"
                  //  }}
                  className="course-text-color"
                > </div>
                <div className="custom-click-scroll p-md-JO"></div>
                {/* <div
            className="course-text"
          > </div> */}
              </>
            ) : (
              <>
                {/* <div
        //   style={{
        //     position: "absolute",
        //    backgroundColor: "#0B1324",
        //    borderRadius: "60px",
        //    height: "4.2rem",
        //   width: "62%",
        //  // minWidth:"7px",
        //  //  right: "0%",
        //    left:"27%",
        //    top:"-13.5%",
        //    zIndex: 4,
           
        //   }}
        className="course-text"
        > </div> */}

                <div className="custom-click-scroll" style={{ visibility: "hidden" }}></div>
              </>
            )}
          </p>
        </div>
      </div>
    </>
    // <div className="custom-click-scroll-screen14 p-md-JO"></div>
  );
};

const CourseBtnComponentsObserver = observer(CourseBtnComponents);
export default CourseBtnComponentsObserver;