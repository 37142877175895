import { observer } from "mobx-react";
import "./ChangeAnswerPopup.scss"
interface Props {
    setIsReset: any;
}

const ResetButtonAnswerPopup: React.FC<Props> = ({ setIsReset }) => {

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <button
                tabIndex={4}
                onClick={() => { setIsReset(true) }}
                style={{ border: "none", backgroundColor: "transparent", color: "white" }}
            >
                <p className="reset-text-desktop">{"איפוס כל התשובות"}</p>
                <p className="reset-text-mobile">{"איפוס תשובות"}</p>
            </button>
        </div>
    );
};

const ResetButtonAnswerPopupObserver = observer(ResetButtonAnswerPopup);
export default ResetButtonAnswerPopupObserver;