import React, { useEffect, useRef } from "react";
import RequirementsCheckBox from "../components/RequirementsCheckBox/RequirementsCheckBox";
import "./PopUpFullPage.scss";
import Arrow from "../../../assets/images/Next_arrow.png";
import rootStores from "../../../BL/stores";
import { observer } from "mobx-react";
interface IProps {
  setPopUpTrigger?: (value: boolean) => void;
  filterRequirements?: string[];
  setFilterRequirements?: (value: string[]) => void;
  allFilterRequirements: Array<string>;
}

const languageStore = rootStores.LangsStore;

const PopUpFullPage: React.FC<IProps> = ({
  setPopUpTrigger,
  filterRequirements,
  setFilterRequirements,
  allFilterRequirements,
}) => {
  const { translate } = languageStore;

  const focusWhenPopupOpen = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    focusWhenPopupOpen.current?.focus()
  }, [])

  return (
    <div className="popup-position-and-layer">
      <div className="popup-container">
        <div className="popup-container__content">
          <p className="popup-content__text" tabIndex={1} ref={focusWhenPopupOpen}>
            {translate("CoursesByJobOccupationPage", "popUpFirstLine")},
            <br />
            <span className="bolded" tabIndex={1}>
              {translate("CoursesByJobOccupationPage", "popUpSecondLine")}
            </span>
            <br />
            <span className="bolded blue" tabIndex={1}>
              {translate("CoursesByJobOccupationPage", "popUpBlueLine")}
            </span>
          </p>
          <RequirementsCheckBox
            allFilterRequirements={allFilterRequirements}
            setFilterRequirements={setFilterRequirements}
            filterRequirements={filterRequirements}
          />
          <>
            <button
              onClick={() => setPopUpTrigger!(false)}
              className="popup-content__btn"
              role={"button"}
              tabIndex={1}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setPopUpTrigger!(false);
                }
              }}
            >
              <p className="popup-content__btn-text blue">{translate("CoursesByJobOccupationPage", "toCourses")}</p>
              <img className="popup-content__arrow-img" src={Arrow} alt="arrow" />
            </button>
          </>
        </div>
      </div>
    </div>
  );
};

const PopUpFullPageObservable = observer(PopUpFullPage);
export default PopUpFullPageObservable;
