export const years = {
  years: [
    // { value: "שנת לידה", label: "שנת לידה", color: "red" },
    // { value: "2010", label: "2010", color: "red" },
    // { value: "2009", label: "2009", color: "green" },
    // { value: "2008", label: "2008", color: "red" },
    // { value: "2007", label: "2007", color: "green" },
    { value: "2006", label: "2006", color: "red" },
    { value: "2005", label: "2005", color: "green" },
    { value: "2004", label: "2004", color: "red" },
    { value: "2003", label: "2003", color: "green" },
    { value: "2002", label: "2002", color: "red" },
    { value: "2001", label: "2001", color: "green" },
    { value: "2000", label: "2000", color: "red" },
    { value: "1999", label: "1999", color: "green" },
    { value: "1998", label: "1998", color: "red" },
    { value: "1997", label: "1997", color: "green" },
    { value: "1996", label: "1996", color: "red" },
    { value: "1995", label: "1995", color: "green" },
    { value: "1994", label: "1994", color: "red" },
    { value: "1993", label: "1993", color: "green" },
    { value: "1992", label: "1992", color: "red" },
    { value: "1991", label: "1991", color: "green" },
    { value: "1990", label: "1990", color: "red" },
    { value: "1989", label: "1989", color: "green" },
    { value: "1988", label: "1988", color: "red" },
    { value: "1987", label: "1987", color: "green" },
    { value: "1986", label: "1986", color: "red" },
    { value: "1985", label: "1985", color: "green" },
    { value: "1984", label: "1984", color: "green" },
    { value: "1983", label: "1983", color: "green" },
    { value: "1982", label: "1982", color: "green" },
    { value: "1981", label: "1981", color: "green" },
    { value: "1980", label: "1980", color: "green" },
    { value: "1979", label: "1979", color: "green" },
    { value: "1978", label: "1978", color: "green" },
    { value: "1977", label: "1977", color: "green" },
    { value: "1976", label: "1976", color: "green" },
    { value: "1975", label: "1975", color: "green" },
    { value: "1974", label: "1974", color: "green" },
    { value: "1973", label: "1973", color: "green" },
    { value: "1972", label: "1972", color: "green" },
    { value: "1971", label: "1971", color: "green" },
    { value: "1970", label: "1970", color: "green" },
    { value: "1969", label: "1969", color: "green" },
    { value: "1968", label: "1968", color: "green" },
    { value: "1967", label: "1967", color: "green" },
    { value: "1966", label: "1966", color: "green" },
    { value: "1965", label: "1965", color: "green" },
    { value: "1964", label: "1964", color: "green" },
    { value: "1963", label: "1963", color: "green" },
    { value: "1962", label: "1962", color: "green" },
    { value: "1961", label: "1961", color: "green" },
    { value: "1960", label: "1960", color: "green" },
    { value: "1959", label: "1959", color: "green" },
    { value: "1958", label: "1958", color: "green" },
    { value: "1957", label: "1957", color: "green" },
    { value: "1956", label: "1956", color: "green" },
    { value: "1955", label: "1955", color: "green" },
    { value: "1954", label: "1954", color: "green" },
    { value: "1953", label: "1953", color: "green" },
    { value: "1952", label: "1952", color: "green" },
    { value: "1951", label: "1951", color: "green" },
    { value: "1950", label: "1950", color: "green" },
    // { value: "1949", label: "1949", color: "green" },
    // { value: "1948", label: "1948", color: "green" },
    // { value: "1947", label: "1947", color: "green" },
    // { value: "1946", label: "1946", color: "green" },
    // { value: "1945", label: "1945", color: "green" },
    // { value: "1944", label: "1944", color: "green" },
    // { value: "1943", label: "1943", color: "green" },
    // { value: "1942", label: "1942", color: "green" },
    // { value: "1941", label: "1941", color: "green" },
    // { value: "1940", label: "1940", color: "green" },
    // { value: "1939", label: "1939", color: "green" },
    // { value: "1938", label: "1938", color: "green" },
    // { value: "1937", label: "1937", color: "green" },
    // { value: "1936", label: "1936", color: "green" },
    // { value: "1935", label: "1935", color: "green" },
    // { value: "1934", label: "1934", color: "green" },
    // { value: "1933", label: "1933", color: "green" },
    // { value: "1932", label: "1932", color: "green" },
    // { value: "1931", label: "1931", color: "green" },
    // { value: "1930", label: "1930", color: "green" },
    // { value: "1929", label: "1929", color: "green" },
    // { value: "1928", label: "1928", color: "green" },
    // { value: "1927", label: "1927", color: "green" },
    // { value: "1926", label: "1926", color: "green" },
    // { value: "1925", label: "1925", color: "green" },
    // { value: "1924", label: "1924", color: "green" },
    // { value: "1923", label: "1923", color: "green" },
    // { value: "1922", label: "1922", color: "green" },
    // { value: "1921", label: "1921", color: "green" },
    // { value: "1920", label: "1920", color: "green" },
    // { value: "1919", label: "1919", color: "green" },
    // { value: "1918", label: "1918", color: "green" },
    // { value: "1917", label: "1917", color: "green" },
    // { value: "1916", label: "1916", color: "green" },
    // { value: "1915", label: "1915", color: "green" },
    // { value: "1914", label: "1914", color: "green" },
    // { value: "1913", label: "1913", color: "green" },
    // { value: "1912", label: "1912", color: "green" },
    // { value: "1911", label: "1911", color: "green" },
    // { value: "1910", label: "1910", color: "green" },
    // { value: "1909", label: "1909", color: "green" },
    // { value: "1908", label: "1908", color: "green" },
    // { value: "1907", label: "1907", color: "green" },
    // { value: "1906", label: "1906", color: "green" },
    // { value: "1905", label: "1905", color: "green" },
    // { value: "1904", label: "1904", color: "green" },
    // { value: "1903", label: "1903", color: "green" },
    // { value: "1902", label: "1902", color: "green" },
    // { value: "1901", label: "1901", color: "green" },
    // { value: "1900", label: "1900", color: "green" }
  ],
};
export default years;
