import { ICourse } from '../../common/interfaces/Course';
import { BaseHttpFetcher } from './BaseHttpFetcher';
import axios from 'axios'
import ClientConfig from '../../common/config';
import { ICoursesByJobOccupation } from '../interfaces/CourseFetcher.interface';
class CourseFetcher extends BaseHttpFetcher {
	getCoursesByJobOccupation = async (_JobOccupation: ICoursesByJobOccupation): Promise<ICourse[]> => {
		try {
			const res = await axios.post(ClientConfig.apiBaseHost + "/course/byjoboccupation", { ..._JobOccupation }
				, { headers: { 'Content-Type': 'application/json' } })
			return res.data
		} catch (err) {
			throw err;
		}
	};
}

export default new CourseFetcher(`course`);
