import { observer } from "mobx-react";
import "./ChangeAnswerPopup.scss"
import { ReactComponent as AnswersSaved } from '../../../assets/images/Answer/Answers_Saved.svg';

interface Props {
    saveAnswersFunc: any;
    isSaved: boolean;
}
const SaveButtonAnswerPopup: React.FC<Props> = ({ saveAnswersFunc, isSaved }) => {

    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <button 
            tabIndex={4}
                style={{backgroundColor: isSaved ? "#201C49" : "white"}}
                className="save-answer-btn" 
                onClick={()=>{saveAnswersFunc()}}
            >
                { 
                    isSaved ?
                        <div className="answers-saved-container">
                            <div className="answers-saved-icon"><AnswersSaved /></div>
                            <p className="save-answer-btn-text-after">{"תשובותיך נשמרו"}</p>
                        </div>
                    :
                        <p className="save-answer-btn-text-before">{"שמירה"}</p>
                }
            </button>
        </div>
    );
};

const SaveButtonAnswerPopupObserver = observer(SaveButtonAnswerPopup);
export default SaveButtonAnswerPopupObserver;