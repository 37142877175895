import "./AboutTheSystem.scss";
import Logo from "../../assets/logo-new-2.svg";

import {
  APP_STORE,
  JOB_OCCUPATION_STORE,
  LANGS_STORE,
} from "../../BL/consts/stores";
import React, { useState, useContext } from "react";
import rootStores from "../../BL/stores/index";
import { ReactComponent as Close_White } from "../../assets/Results images/Close-white.svg";
import ContactUs from "../sideBar/modals/contactUsModal/ContactUs";
import { useHistory } from "react-router-dom";
import AccountManagment from "../sideBar/modals/account/account";
import LanguageComponent from "../interesetsPage/components/LanguageComponent/LanguageComponent";
import { SideBarModals } from "../../BL/stores/App.store";
import { observer } from "mobx-react";

// type Props = {
//   // setChosenModal?: React.Dispatch<React.SetStateAction<any>>;
//   // moveToRegister? :()=>void;
// };
const jobOccupationsStore = rootStores[JOB_OCCUPATION_STORE];
const languageStore = rootStores[LANGS_STORE];
const appStore = rootStores[APP_STORE];

const AboutTheSystem: React.FC = () => {
  const { jobOccupations } = jobOccupationsStore;
  const { setChosenModal, chosenModal } = appStore;
  // const [chosenModal, setChosenModal] = useState("");
  const history = useHistory();

  return (
    <div>
      <div
        className={
          history.location.pathname === "/"
            ? "side-bar-page-aboutUs-black-bg"
            : "side-bar-page-aboutUs"
        }
      >
        {chosenModal === SideBarModals.contactUs && <ContactUs />}

        {/* {chosenModal === SideBarModals.account && <AccountManagment />} */}

        <div
          className="close-button-side-bar-aboutUs"
          role="button"
          tabIndex={0}
          aria-pressed={false}
          // onClick={() => setIsOpen(!isOpen)}
          aria-describedby={"informatio-button"}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              // setIsOpen(!isOpen);
              setChosenModal(SideBarModals.none);
            }
          }}
        >
          <Close_White
            onClick={
              () => setChosenModal(SideBarModals.none)
              //  setIsOpen(!isOpen)
            }
          />
        </div>

        <p className="p-bigger-welcome blue-about-system">
          התאמה&nbsp;
          <span className="bolded">חכמה</span>
        </p>

        <img
          className="logo-img-about-system"
          src={Logo}
          alt="skil il logo"
          aria-describedby="logo-description"
        />

        <div className={"lan-menu-popup-mobile"}>
          <LanguageComponent IsWhite={true} />
        </div>
        {/* 
        <div
          className={"about-system-text-white-bold"}
          style={{ visibility: "hidden" }}
        >
          אודות המערכת{" "}
        </div> */}
        {/* <div
          onClick={() => {
            chosenModal === SideBarModals.account
              ? setChosenModal(SideBarModals.none)
              : setChosenModal(SideBarModals.account);
          }}
          style={
            chosenModal === SideBarModals.account
              ? { visibility: "hidden" }
              : { visibility: "unset" }
          }
          className={"about-system-text-white-bold"}
        >
          התחברות{" "}
        </div> */}
        <div
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              chosenModal === SideBarModals.contactUs
                ? setChosenModal(SideBarModals.none)
                : setChosenModal(SideBarModals.contactUs);
            }
          }}
          onClick={() => {
            chosenModal === SideBarModals.contactUs
              ? setChosenModal(SideBarModals.none)
              : setChosenModal(SideBarModals.contactUs);
          }}
          className={"about-system-text-white-bold"}
          style={
            chosenModal === SideBarModals.contactUs
              ? { visibility: "hidden" }
              : { visibility: "unset" }
          }
        >
          יצירת קשר
        </div>
      </div>
    </div>
  );
};;

const AboutTheSystemObserver = observer(AboutTheSystem);
export default AboutTheSystemObserver;
