import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./CoursesByJobOccupationPageComponent.scss";
import rootStores from "../../BL/stores";
import { observer } from "mobx-react";
import LeftSideCoursePageObserver from "./LeftSide-CoursePage/LeftSideCoursePage";
import PopUpFullPage from "./PopUpFullPage/PopUpFullPage";
import ScrollBarComponentObserver13 from "./ScrollBarScreen13/ScrollBarComponent13";
import { ICourse } from "../../common/interfaces/Course";
import { IJobOccupation } from "../../common/interfaces/Job-Occupation";
import CourseFetcher from "../../Infrastructure/fetchers/CourseFetcher";
import Helmet from "react-helmet";
import CaruselComponent from "./components/CaruselToMobile/CaruselComponent";
import FirstRowMobileCoursesObsever from "../../common/components/FirstRowMobileCourses/FirstRowMobileCourses";
import { CommonHeader } from "../../common/components/Header/header.component";
interface IProps {
  moveToCourse: (name: string) => void;
}

const courseStore = rootStores.CourseStore;
const cityStore = rootStores.CitiesStore;
const userInfoStore = rootStores.UserInfoStore;
const filterStore = rootStores.FilterStore;
const jobStore = rootStores.JobOccupationStore;

const CoursesByJobOccupationPageComponent: React.FC<IProps> = ({ moveToCourse }) => {
  const { selectedCity } = cityStore
  const { setCoursesByJobOccupation, setSelectedCourse } = courseStore;
  const {
    recommendedJobOccupation,
    currentRecommendedJobOccupations,
    setRecommendedJobOccupation,
  } = jobStore;
  const {
    selectedAge,
    userPath,
    popUpTriggerForBack,
    setpopUpTriggerForBack,
    selectedEducationalDegree,
  } = userInfoStore;
  const {
    filterRequirements,
    setFilterRequirements,
    allFilterRequirements,
    setAllFilterRequirements,
    setCheck,
    setCourseTime,
    setDurationOfCourse,
    setLevel,
    setLocationOfCourse,
  } = filterStore;

  const history = useHistory();
  const inputRef = useRef<any>();

  useEffect(() => {
    if (!recommendedJobOccupation?.male_name) {
      history.push("/");
      return;
    }
    const state: any = history.location.state;
    findLastObject(recommendedJobOccupation!);
    findNextObject(recommendedJobOccupation!);
    (async function anyNameFunction() {
      await coursesData();
      setPopUpTrigger(true);
    })();
    history.push({ search: `flow=${userPath ? `short&city=${selectedCity?.name}&age=${selectedAge}&educationalDegree=${selectedEducationalDegree}` : `long&indexJob=${state?.indexJob}`}` })
    // if (recommendedJobOccupation === null) {
    // } else {
    //   setPopUpTrigger(true);
    //   setIsLoading(false);
    // }
  }, [recommendedJobOccupation?.male_name]);

  useEffect(() => {
    inputRef.current.focus()
    if (!userPath) {
      if (currentRecommendedJobOccupations.length < 1) {
        history.push("/");
      }
    }
    //handle popstate - the client 
    window.onpopstate = function (event) {
      MoveBackClickOnTitle()
    };
  }, []);

  const [nextJobOccupation, setNextJobOccupation] = useState<IJobOccupation>();
  const [lastJobOccupation, setLastJobOccupation] = useState<IJobOccupation>();
  const [popUpTrigger, setPopUpTrigger] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const resetFilter = () => {
    setFilterRequirements([]);
    setCourseTime("");
    setCheck(0);
    setDurationOfCourse(36);
    setLevel(2);
    setLocationOfCourse(false);
  };

  const filterReqOfCourse = async (courses: ICourse[]) => {
    let tempFilter: Array<string> = [];
    courses.map((course: ICourse) => {
      return course.basic_requirements.map((c: string) => tempFilter.push(c));
    });
    setAllFilterRequirements(
      [...new Set(tempFilter)].filter((t: string) => t !== "")
    );
  };

  const clickOnScroll = (name: string) => {
    let temp = name.split("/").join().replace(",", "");
    history.push(`/courses-list-of/${name}`);
    history.push(`/courses-list-of/${temp}`);
  };

  const moveToCoursePage = (name: string, course: ICourse) => {
    setSelectedCourse(course);
    history.push(`/course/${course.id}`);
  };

  const coursesData = async () => {
    setIsLoading(true);
    const courses = await CourseFetcher.getCoursesByJobOccupation({
      occupation: {
        male_name: recommendedJobOccupation!.male_name,
        female_name: recommendedJobOccupation!.female_name,
        description: recommendedJobOccupation!.description,
        salary: recommendedJobOccupation!.salary,
        demand: recommendedJobOccupation!.demand,
        average_working_hours: recommendedJobOccupation!.average_working_hours,
        distance_from_home: recommendedJobOccupation?.distance_from_home
          ? recommendedJobOccupation!.distance_from_home
          : 0,
        match_percentage: recommendedJobOccupation!.match_percentage,
        course_count: recommendedJobOccupation!.course_count,
        top_courses: new Array<ICourse>(),
        id: recommendedJobOccupation!.id,
        parent_id: recommendedJobOccupation!.parent_id,
        parent_name: recommendedJobOccupation!.parent_name,
      },
      user_residence_area: selectedCity?.name
        ? selectedCity.name
        : "",
      education_degree: selectedEducationalDegree,
    });
    setpopUpTriggerForBack(true);
    resetFilter();
    filterReqOfCourse(courses);

    setCoursesByJobOccupation([]);
    courses.map((course: ICourse, index) => {
      if (typeof course.id !== "number") {
        return (course.id! = index);
      }
    });
    setCoursesByJobOccupation(courses);
    setIsLoading(false);
  };

  const moveBack = () => {
    setpopUpTriggerForBack(true);
    setAllFilterRequirements([]);
    setLocationOfCourse(false);
    resetFilter();

    !userPath
      ? history.push(`/recommendation-flow`)
      : history.push(`/short/user-details`);
  };

  const completeQuest = () => {
    history.push("/long/questionnaire");
  };

  const MoveBackClickOnTitle = () => {
    history.push("/recommendation-flow");
  };


  const findNextObject = (job_occupation_rec: IJobOccupation) => {
    const length = currentRecommendedJobOccupations.length;
    let id = currentRecommendedJobOccupations
      .findIndex(
        (job_occupation) =>
          job_occupation.male_name === job_occupation_rec.male_name
      );

    setNextJobOccupation(currentRecommendedJobOccupations[id >= length - 1 ? 0 : id + 1]);
    let temp = job_occupation_rec.male_name.split("/").join().replace(",", "");

    history.push(`/courses-list-of/${temp}`);
    setRecommendedJobOccupation(job_occupation_rec);
  };

  const findLastObject = (job_occupation_rec: IJobOccupation) => {
    const length = currentRecommendedJobOccupations.length;
    let id = currentRecommendedJobOccupations
      .findIndex(
        (job_occupation) =>
          job_occupation.male_name === job_occupation_rec.male_name
      );

    setLastJobOccupation(currentRecommendedJobOccupations[id === 0 ? length - 1 : id - 1]);
    let temp = job_occupation_rec.male_name.split("/").join().replace(",", "");

    history.push(`/courses-list-of/${temp}`);
    setRecommendedJobOccupation(job_occupation_rec);
  };

  return (
    <>
      <Helmet>
        <title>Skill-il - ההכשרות</title>
      </Helmet>
      <div ref={inputRef}>
        {popUpTriggerForBack &&
          popUpTrigger &&
          allFilterRequirements.length > 0 && (
            <PopUpFullPage
              allFilterRequirements={allFilterRequirements}
              filterRequirements={filterRequirements}
              setFilterRequirements={setFilterRequirements}
              setPopUpTrigger={setPopUpTrigger}
            />
          )}
      </div>
      <div className="courses-by-JO-container">
        <div className="courses-by-jo-gray-bg"></div>
        <div className="courses-by-jo-content" >
          <LeftSideCoursePageObserver
            MoveBackClickOnTitle={MoveBackClickOnTitle}
            nextJobOccupation={nextJobOccupation!}
            lastJobOccupation={lastJobOccupation!}
            findLastObject={findLastObject}
            findNextObject={findNextObject}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            filterRequirements={filterRequirements}
            allFilterRequirements={allFilterRequirements}
            setFilterRequirements={setFilterRequirements}
            moveToCoursePage={moveToCoursePage}
          />
        </div>
        <div className="slider-bar-blue-JO">
          <FirstRowMobileCoursesObsever moveBack={moveBack} title={!userPath ? "חזרה לכל העיסוקים" : null} />
          <CaruselComponent
            moveToCourse={moveToCourse}
            clickOnScroll={clickOnScroll}
            recommendedJobOccupation={recommendedJobOccupation}
          />
          {currentRecommendedJobOccupations !== undefined && (
            <>
              <ScrollBarComponentObserver13
                isLoading={isLoading}
                moveToCourse={moveToCourse}
                clickOnScroll={clickOnScroll}
                recommendedJobOccupation={recommendedJobOccupation}
                completeQuest={completeQuest}
              />
            </>
          )}
        </div>
        <CommonHeader />
      </div>
    </>
  );
};

const CoursesByJobOccupationPageComponentObserver = observer(CoursesByJobOccupationPageComponent);
export default CoursesByJobOccupationPageComponentObserver;
