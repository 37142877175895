import React, { useEffect, useState } from "react";
import {
  APP_STORE,
  AUTH_STORE,
  JOB_OCCUPATION_STORE,
  USER_INFO_STORE,
} from "./BL/consts/stores";
import rootStores from "./BL/stores";
import SliderProgressBar from "./UI/sliderProgressBar/SliderProgressBarComponent";
import logo from "./assets/logo-new-2.svg";
import Menu_blue from "./assets/Results images/blueMenu.svg";
import UserDetailsComponent from "./UI/userDetailsPage/userDetailsPageComponent";
import LastThingsPage from "./UI/lastThingsPage/lastThingsPageComponent";
import LanguageComponent from "./UI/interesetsPage/components/LanguageComponent/LanguageComponent";
import "./flow.style.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router-dom";
import InterestsPageComponentNew from "./UI/interesetsPage/InterestsPageComponentRemix";
import { observer } from "mobx-react";
import HollandQuestionnaire from "./UI/HollandQuestionnairePage/HollandQuestionnaire";
import { ReactComponent as BlueLogo } from "./assets/logo_blue_mobile.svg";
import { SideBarModals } from "./BL/stores/App.store";
import { CommonHeader } from "./common/components/Header/header.component";
import SkillIlOnTrialLogoMobile from './assets/logos/trial/Logo_Trial _Inner_Mobile.png'
import SkillIlOnTrialLogoDesktop from './assets/logos/trial/Logo_Trial _Inner.png'

const userInfoStore = rootStores[USER_INFO_STORE];
const appStore = rootStores[APP_STORE];
const authStore = rootStores[AUTH_STORE];
const jobOccupationStore = rootStores[JOB_OCCUPATION_STORE];
const JobOccupationFilterStore = rootStores.JobOccupationFiltersStore;

const StartFlowComponent: React.FC = () => {
  const { setChosenModal } = appStore;
  const { getQueriesRegistration } = authStore;
  const { userPath, addPreviusLevel,setPreviusURLs, resetPreviusLevels } = userInfoStore;

  let { path } = useRouteMatch();
  const history = useHistory();

  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const [Svg, setSvg] = useState(0);
  const [level, setLevel] = React.useState<number>(0);

  const newSetLevel = (value: number) => {
    // if (value === level) setIsMobileOpen(!isMobileOpen);
    if (value === level) setIsMobileOpen(false);
    else {
      setIsMobileOpen(false);
    }
    setLevel(value);
  };

  // const queriesRegistration = () => {
  //   return getQueriesRegistration()
  // };

  useEffect(() => {
    checkProgressBarStatusByUrl();
    //reset previus levels when user enter to the flow
    //resetPreviusLevels();
  }, []);

  // const onCloseRegistrationModal = () => {
  //  history.push({
  //    search: `registration=${isUserRegistered}&isDetailsComplete=${registrationModalSettings.isDetailsComplete}`,
  //  });
  // };

  const checkProgressBarStatusByUrl = () => {
    if (!userPath)
      switch (history.location.pathname) {
        case path + "/":
          setLevel(0);
          {
            process.env.NODE_ENV === "development" ? setSvg(0) : setLevel(5);
          }
          break;
        case path + "/user-details":
          setLevel(1);
          break;
        case path + "/questionnaire":
          setLevel(2);
          break;
        case path + "/last-thing":
          setLevel(3);
          break;
      }
    else setLevel(3);
  };

  const logoOnClick = () => {
    history.push("/");
  };

  const onClick = (isUeseLoggedIn?: boolean) => {
    //this function is called when the user click on the next button in the last thing page
    JobOccupationFilterStore.resetAllFilters();
    jobOccupationStore.recommendedJobOccupations.replace([]);

    // COMMIT THIS BACK TO DISPLAY REGISTER/LOGIN POPUP

    // setRegistrationModalSettings(!isUserRegistered, true);

    // COMMIT THIS BACK TO DISPLAY REGISTER/LOGIN POPUP

    // history.push({pathname:"/recommendation-flow",search:`previousJobs=${selectedPreviousJobs.map(job=>job.male_name).join(',')}`});
    //for some reason the history.location.pathname is just "long" insted of "/long/last-thing" so i added it menully
    setPreviusURLs("/long/last-thing",3);    
    history.push("/recommendation-flow" );
  };

  const onClickMoveShortFlow = (value: string) => {
    jobOccupationStore.setCurrentRecommendedJobOccupations([]);
    let temp = value.split("/").join().replace(",", "");
    if (value.includes("/")) {
      history.push(`/courses-list-of/${temp}`);
    } else {
      history.push(`/courses-list-of/${temp}`);
    }
  };
  const moveBack = () => {
    history.push("/");
  };
//unfortunately, this function 
  const goBack = (pathName: string,search:string ) => {
    history.push({
      pathname: pathName,
      search: search,
    })
  }

  return (
    <>
      <div className={"flow2-container"}>
        <img
          src={Menu_blue}
          alt="press to continue"
          className={"menu-blue-flow2"}
          onClick={() => setChosenModal(SideBarModals.aboutUs)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setChosenModal(SideBarModals.aboutUs);
            }
          }}
        />
        <div className="header-container">
          {/* NORMAL LOGO */}
          {/* <BlueLogo className="blue-logo-mobile" onClick={logoOnClick} /> */}
          {/* ON-TRIAL LOGO */}
          <img src={SkillIlOnTrialLogoMobile} className="blue-logo-mobile" onClick={logoOnClick} />
          {/* <div className={"language-header"}>
            <LanguageComponent IsWhite={false} />
          </div> */}
        </div>
        <div className="top-white-space"></div>
        {/* <CommonHeader/> */}
        <Router>

          {/* <div tabIndex={1}> */}
          <Switch>
            <Route
              path={`${path}/interest`}
              component={() => (
                <InterestsPageComponentNew
                  goBack={goBack}
                  setLevel={setLevel}
                />
              )}
            />
            <Route
              path={`${path}/user-details`}
              component={() => (
                <UserDetailsComponent
                  goBack={goBack}
                  setLevel={setLevel}
                  onClickMoveShortFlow={onClickMoveShortFlow}
                />
              )}
            />
            <Route
              path={`${path}/last-thing`}
              component={() => (
                <LastThingsPage movePage={onClick} setLevel={setLevel}/>
              )}
            />

            <Route
              path={`${path}/questionnaire`}
              component={() => (
                // <HollandQuestionnairePage
                //   goBack={() => history.push("/")}
                //   setLevel={setLevel}
                //   doneOrSkipOrSaveQuestionnaire={doneOrSkipOrSaveQuestionnaire}
                //   setDoneOrSkipOrSaveQuestionnaire={
                //     setDoneOrSkipOrSaveQuestionnaire
                //   }
                // />
                <HollandQuestionnaire
                  // goBack={goBack}
                  setLevel={setLevel}
                />
              )}
            />
            <Redirect
              exact
              to={{
                pathname: `long/interest`,
                search: getQueriesRegistration(),
              }}
              from={`/long`}
            />
            <Redirect
              exact
              to={{
                pathname: `short/user-details`,
                search: `short_path_query=${jobOccupationStore.recommendedJobOccupation?.male_name
                  }&${getQueriesRegistration()}`,
              }}
              from={`/short`}
            />
          </Switch>
          <div
            className={
              !isMobileOpen
                ? "right-side-in-mobile"
                : "right-side-in-mobile right-side-in-mobile-open"
            }
          >
            <div
              className={
                !isMobileOpen
                  ? "slider-bar-blue"
                  : "slider-bar-blue slider-bar-blue-open"
              }
            >
              <div className={"new-logo"} onClick={logoOnClick}>
                {/* NORMAL LOGO */}

                {/* <img
                  style={{ position: "relative" }}
                  src={logo}
                  className={"new-logo-flow2"}
                  alt="skill-logo מעבר לדף הבית"
                /> */}
                {/* ON-TRIAL LOGO */}
                <img src={SkillIlOnTrialLogoDesktop} className={"new-logo-flow2"}
                  alt="skill-logo מעבר לדף הבית" style={{ position: "relative" }} />

              </div>
              <div className={"navigator-row"} style={{ height: "100%" }}>
                <SliderProgressBar
                  moveBack={moveBack}
                  setLevel={newSetLevel}
                  isOpen={isMobileOpen}
                  level={level}
                />
              </div>
            </div>
          </div>
        </Router>
        <CommonHeader />
        {/* </div> */}
      </div>
    </>
  );
};

const StartFlowComponentObserver = observer(StartFlowComponent);
export default StartFlowComponentObserver;
