import React, { useEffect, useState } from "react";
import "../card/jobOpucationCard.scss";
import { ReactComponent as Close_white } from "../../../../assets/Results images/Close-white.svg";
import { observer } from "mobx-react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { IJobOccupation } from "../../../../common/interfaces/Job-Occupation";
Chart.register(ArcElement);

interface IProps {
  setPercentagePopUp?: (value: boolean) => void;
  job_occupation: IJobOccupation;
}
const PopUpCardJobComponent: React.FC<IProps> = ({
  setPercentagePopUp,
  job_occupation,
}) => {
  const [categories, setCategories] = useState(0);
  const [prev_occupations, setPrevOccupations] = useState(0);
  const [questionnaire, setQuestionnaire] = useState(0);
  const [totalPercentage, setTotalPercentage] = useState(-1);

  useEffect(() => {
    calcPercentage();
  }, [totalPercentage !== -1]);

  function calcPercentage() {
    let categoriesScore =
      job_occupation.match_score_parts?.categories === null
        ? 0
        : job_occupation.match_score_parts!.categories.score!.valueOf();
    let categoriesWeight =
      job_occupation.match_score_parts?.categories === null
        ? 0
        : job_occupation!.match_score_parts!.categories!.weight!.valueOf();

    let prev_occupationsScore =
      job_occupation.match_score_parts?.prev_occupations === null
        ? 0
        : job_occupation.match_score_parts!.prev_occupations.score!.valueOf();
    let prev_occupationsWeight =
      job_occupation.match_score_parts?.prev_occupations === null
        ? 0
        : job_occupation!.match_score_parts!.prev_occupations!.weight!.valueOf();

    let questionnaireScore =
      job_occupation.match_score_parts?.questionnaire === null
        ? 0
        : job_occupation.match_score_parts!.questionnaire.score!.valueOf();
    let questionnaireWeight =
      job_occupation.match_score_parts?.questionnaire === null
        ? 0
        : job_occupation!.match_score_parts!.questionnaire!.weight!.valueOf();

    setCategories(categoriesScore * categoriesWeight);

    setPrevOccupations(prev_occupationsScore * prev_occupationsWeight);

    setQuestionnaire(questionnaireScore * questionnaireWeight);

    setTotalPercentage(job_occupation.match_percentage! * 100);

    // if (isAllThreeParams()) {
    //     setCategories((job_occupation.match_score_parts!.categories.score!.valueOf() * (100 / 3)));
    //     setPrevOccupations((job_occupation.match_score_parts!.prev_occupations!.score!.valueOf() * (100 / 3)));
    //     setQuestionnaire((job_occupation.match_score_parts!.questionnaire!.score!.valueOf() * (100 / 3)));
    // }
    // else if (isCategoriesAndPrevOccupations()) {
    //     setCategories((job_occupation.match_score_parts!.categories!.score!.valueOf() * (100 / 3)));
    //     setPrevOccupations((job_occupation.match_score_parts!.prev_occupations!.score!.valueOf() * (100 / 3)));
    //     setQuestionnaire(0);
    // }
    // else if (isCategoriesAndQuestionnaire()) {
    //     setCategories((job_occupation.match_score_parts!.categories.score!.valueOf() * (100 / 3)));
    //     setPrevOccupations(0);
    //     setQuestionnaire((job_occupation.match_score_parts!.questionnaire.score!.valueOf() * (100 / 3)));
    // }
    // else {
    //     setCategories(job_occupation.match_percentage! * 100);
    //     setPrevOccupations(0);
    //     setQuestionnaire(0);
    // }
  }

  const isAllThreeParams = () => {
    return (
      job_occupation.match_score_parts?.categories?.score! > 0 &&
      job_occupation.match_score_parts?.prev_occupations?.score! > 0 &&
      job_occupation.match_score_parts?.questionnaire?.score! > 0
    );
  };

  const isCategoriesAndPrevOccupations = () => {
    return (
      job_occupation.match_score_parts?.categories?.score! > 0 &&
      job_occupation.match_score_parts?.prev_occupations?.score! > 0 &&
      job_occupation.match_score_parts?.questionnaire?.score === 0
    );
  };

  const isCategoriesAndQuestionnaire = () => {
    return (
      job_occupation.match_score_parts?.categories?.score! > 0 &&
      job_occupation.match_score_parts?.prev_occupations?.score === 0 &&
      job_occupation.match_score_parts?.questionnaire?.score! > 0
    );
  };

  const data = {
    datasets: [
      {
        data: [
          categories * 100,
          prev_occupations * 100,
          questionnaire * 100,
          100 - totalPercentage,
        ],
        backgroundColor: ["#f4b55a", "#ff617b", "#da86e8", "#252525"],
      },
    ],
  };

  const options = {
    borderAlign: "center", // center || inner
    cutout: "70%",
    borderColor: "#252525",
    innerHeight: "auto",
    legend: {
      display: false,
    },
  };

  return (
    <div className="percentage-details-card__container">
      <div className="percentage-details-card__header">
        <Close_white
          className="percentage-details-card__close-btn"
          onClick={() => setPercentagePopUp!(false)}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key == "Enter" && setPercentagePopUp!(false)}
        />
        <p className="percentage-details-card__title">
          {"ההמלצה שלנו מבוססת על השלבים הקודמים"}
        </p>
      </div>
      <div className="percentage-details-card__doughnut-chart-container">
        <div className="doughnut-chart__text-container">
          <p className="doughnut-chart__percentage-text">
            <span className="bold">{Math.trunc(totalPercentage)}%</span>
            <br />
            {"התאמה"}
          </p>
        </div>
        <div className="doughnut-chart-container__chart-container">
          <Doughnut data={data} options={options} />
        </div>
      </div>
      <div className="percentage-details-card__segmentation-container">
        {job_occupation.match_score_parts?.categories !== null && (
          <div className="segmentation-container__subject-and-percentage">
            <p className="orange segmentation-container__percentage-text">
              <span className="bold">{Math.trunc(categories * 100)}</span>
              %
            </p>
            <p className="segmentation-container__subject-text">
              {"תחומי"}
              <br />
              {"עניין"}
            </p>
          </div>
        )}
        {job_occupation.match_score_parts?.prev_occupations !== null && (
          <div className="segmentation-container__subject-and-percentage">
            <p className="pink segmentation-container__percentage-text">
              <span className="bold">{Math.trunc(prev_occupations * 100)}</span>
              %
            </p>
            <p className="segmentation-container__subject-text">
              {"ניסיון"}
              <br />
              {"תעסוקתי"}
            </p>
          </div>
        )}
        {job_occupation.match_score_parts?.questionnaire !== null &&
          (
            <div className="segmentation-container__subject-and-percentage">
              <p className="purple segmentation-container__percentage-text">
                <span className="bold">{Math.trunc(questionnaire * 100)}</span>
                %
              </p>
              <p className="segmentation-container__subject-text">
                {"נטיות"}
                <br />
                {"תעסוקתיות"}
              </p>
            </div>
          )}
      </div>
    </div>
  );
};

const PopUpCardJobComponentObserver = observer(PopUpCardJobComponent);
export default PopUpCardJobComponentObserver;
