import React from "react";
import { useHistory } from "react-router-dom";
import rootStores from "../../../../BL/stores";
import { ICourse } from "../../../../common/interfaces/Course";
import { IJobOccupation } from "../../../../common/interfaces/Job-Occupation";
import "./OneItemCarusel.scss";
import { observer } from "mobx-react";
interface IProps {
  course?: ICourse;
  recJob: IJobOccupation;
  clickOnScroll: (name: string, job: IJobOccupation) => void;
  moveToCourse: (name: string) => void;
}
const jobStore = rootStores.JobOccupationStore;
const languageStore = rootStores.LangsStore;
const OneItemCarusel: React.FC<IProps> = ({
  recJob,
  clickOnScroll,
  moveToCourse,
}) => {
  const history = useHistory();
  const {
    recommendedJobOccupation,
    setRecommendedJobOccupation,
    currentCourseCount,
    setCurrentCourseCount,
  } = jobStore;
  const handleChooseJob = (job: IJobOccupation) => {
    if (recommendedJobOccupation!.male_name !== recJob.male_name) {
      setCurrentCourseCount(Number(job.course_count));
      // setSelectedCourse(null!);
      clickOnScroll(job.male_name, job);
      setRecommendedJobOccupation(job);
    }
  };

  return (
    <div
      tabIndex={0}
      role={"button"}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleChooseJob(recJob);
        }
      }}
      onClick={(e) => {
        handleChooseJob(recJob);
      }}
      className={
        recommendedJobOccupation!.male_name === recJob.male_name
          ? "carousel-card--chosen"
          : "carousel-card"
      }
    >
      <div className="carousel-card__text-container">
        <span
          className={
            recommendedJobOccupation!.male_name === recJob.male_name
              ? "occupation-title--chosen"
              : "occupation-title"
          }
        >
          {languageStore.currentLanguageIndex === 1
            ? recJob.female_name
            : recJob.male_name}
        </span>
        <span
          className={
            recommendedJobOccupation!.male_name === recJob.male_name
              ? "occupation-counter--chosen"
              : "occupation-counter"
          }
        >
          {(recommendedJobOccupation!.male_name === recJob.male_name
            ? currentCourseCount
            : recJob.course_count) + " הכשרות"}
        </span>
      </div>
    </div>
  );
};
const OneItemCaruselObserver = observer(OneItemCarusel);
export default OneItemCaruselObserver;
