import "./Tooltip.scss";
import { ReactComponent as CloseIconDark } from "../../../assets/icons/job-occupation-card/close_dark.svg"
interface IProps {
    text?: string;
    visibility?: boolean;
    closeFunction?: (e: any) => void;
    isUpper?: boolean;
    isSubsidizedLink?: boolean;
}

const Tooltip: React.FC<IProps> = ({ text, visibility, closeFunction, isUpper, isSubsidizedLink }) => {
    let backupText = `מבוסס על שקלול של: מספר העוסקים בתחום, שינויים ברמות השכר וכמות המשרות הפתוחות`

    if (isUpper && visibility) {
        return (
            <div className="tooltip">
                <div className="tooltip--container">
                    <div className="tooltip--content">
                        <div className="tooltip__position--container--header mobile-only" role="button" onClick={closeFunction}>
                            <CloseIconDark className="tooltip__position--container--header--close_btn_course" />
                        </div>
                        <p className="tooltip__position--container--text">{text ? text : backupText}
                            {isSubsidizedLink && (
                                <>
                                    <br />
                                    <a href="https://www.labor-shovarim.co.il/" rel="noreferrer noopener" target={"_blank"} className="link">
                                        לחץ כאן {" "}
                                    </a>
                                    למעבר לאתר השוברים לבדיקת זכאות.
                                </>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        )
    } else if (visibility) {
        return (
            <div className="tooltip__position">
                <div className="tooltip__position--container">
                    <div className="tooltip__position--container--header mobile-only" role="button" onClick={closeFunction}>
                        <CloseIconDark className="tooltip__position--container--header--close_btn_course" />
                    </div>
                    <p className="tooltip__position--container--text">{text || backupText}</p>
                </div>
            </div>
        )
    }
    return (
        null
    )
}

export default Tooltip;