import "./CourseDetails.scss";
import rootStores from "../../../BL/stores";
import { ReactComponent as Degree } from "../../../assets/Results images/Degree.svg";
import { ReactComponent as Duration } from "../../../assets/Results images/Duration.svg";
import { ReactComponent as Handle } from "../../../assets/Results images/Time.svg";
import { ReactComponent as Location } from "../../../assets/Results images/Location.svg";
import dgreeInMobile from "../../../assets/icons class data/Diploma.png";
import College from "../../../assets/Results images/College.png";
import LengthInMobile from "../../../assets/icons class data/Length.png";
import LocationInMobile from "../../../assets/icons class data/Location.png";
import TimeInMobile from "../../../assets/icons class data/time.png";
import { useHistory } from "react-router-dom";
import { setDataLayer } from "../../../common/utils/dataLayer.util";

const courseStore = rootStores.CourseStore;
const UserInfoStore = rootStores.UserInfoStore;
const JobOccupationStore = rootStores.JobOccupationStore;
const languageStore = rootStores.LangsStore;

const CourseDetails = () => {
  const { selectedCourse, setIsPopupShown } = courseStore;
  const { recommendedJobOccupation } = JobOccupationStore;
  const { userPath } = UserInfoStore;
  const { currentLanguageIndex } = languageStore

  const history = useHistory();

  const currentOccupation = recommendedJobOccupation ? currentLanguageIndex === 1 ?
    recommendedJobOccupation!.female_name
    : recommendedJobOccupation!.male_name : null;


  const addParamsToURL = () => {
    setDataLayer('linkto__college',
      selectedCourse?.name,
      selectedCourse?.training_institution.name,
      selectedCourse?.location,
      selectedCourse?.course_format,
      selectedCourse?.diploma,
      selectedCourse?.employment_guaranteed,
      selectedCourse?.budgeting,
      currentOccupation)
    history.push({ search: `flow=${userPath ? "short" : "long"}&name=${selectedCourse?.name}&training_institution=${selectedCourse?.training_institution.name}&location=${selectedCourse?.location}&training_format=${selectedCourse?.course_format}` })
    setIsPopupShown();
  }

  return (
    <div className="main-container__blue">
      <div className="details-container">
        {selectedCourse?.diploma && (
          <div className="text-and-img-container">
            <Degree className="icon-of-courses" />
            <img src={dgreeInMobile} className={"icon-of-courses-in-mobile"} alt="" />
            <div className="details-text-container">
              <p className="details-texts details-texts-in-mobile" tabIndex={0}>
                לימודי
              </p>
              <p className="details-texts details-texts-in-desktop" tabIndex={0}>
                {selectedCourse?.diploma && "מקבלים תעודה "}
              </p>
              <p className="details-texts details-texts-in-mobile" tabIndex={0}>
                {selectedCourse?.diploma && "תעודה "}
              </p>
            </div>
          </div>
        )}
        {selectedCourse?.course_format && (
          <div className="text-and-img-container">
            {selectedCourse?.course_format !== null && <Handle className={"icon-of-courses"} />}
            {selectedCourse?.course_format !== null && <img src={TimeInMobile} className={"icon-of-courses-in-mobile"} alt="" />}
            <div className="details-text-container">
              <p className="details-texts details-texts-in-mobile" style={{ fontWeight: "400" }}>
                מועד ביום
              </p>
              <p className="details-texts" tabIndex={0}>
                {" "}
                {selectedCourse?.course_format === "morning"
                  ? `קורס בוקר`
                  : selectedCourse?.course_format === "evening"
                    ? " קורס ערב"
                    : selectedCourse?.course_format === "online"
                      ? "קורס אונליין"
                      : ""}{" "}
              </p>
            </div>
          </div>
        )}
        {selectedCourse?.duration.months && (
          <div className="text-and-img-container">
            {selectedCourse?.duration.months !== null && <Duration className={"icon-of-courses"} />}
            {selectedCourse?.duration.months !== null && <img src={LengthInMobile} className={"icon-of-courses-in-mobile"} alt="" />}
            <div className="details-text-container">
              <p className="details-texts details-texts-in-mobile" style={{ fontWeight: "400" }}>
                משך
              </p>
              <p className="details-texts details-texts-in-desktop" style={{ fontWeight: "500" }} tabIndex={0}>
                {" "}
                {selectedCourse?.duration.months !== null
                  ? `${selectedCourse?.duration.months} חודשי הכשרה`
                  : ``}
              </p>
              <p className="details-texts details-texts-in-mobile" style={{ fontWeight: "500" }}>
                {" "}
                {selectedCourse?.duration.months !== null
                  ? `${selectedCourse?.duration.months} חודשים`
                  : ``}
              </p>
            </div>
          </div>
        )}
        {selectedCourse?.location && (
          <div className="text-and-img-container">
            <Location className={"icon-of-courses"} />
            <img src={LocationInMobile} className={"icon-of-courses-in-mobile"} alt="" />
            <div className="details-text-container">
              <p className="details-texts details-texts-in-mobile" style={{ fontWeight: "400" }} >
                מיקום
              </p>
              <p className="details-texts" style={{ fontWeight: "500" }} tabIndex={0}>{selectedCourse?.location}</p>
            </div>

          </div>
        )}
        {selectedCourse?.training_institution.name && (
          <div className="college-redirect__container" title="מעבר לאתר המכללה">
            <img className="college-icon" src={College} />
            <a
              tabIndex={0}
              title="מעבר לאתר המכללה"
              onClick={addParamsToURL}
              id="details-website-id"
              className="details-website"
              href={selectedCourse?.course_url}
              target={"_blank"}
              rel="noreferrer"
              style={{ fontFamily: 'Almoni-ULTRABOLD-AAA', verticalAlign: 'middle', textDecoration: 'none' }}
            >
              לאתר המכללה
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseDetails;
