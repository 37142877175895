import { BaseHttpFetcher } from "./BaseHttpFetcher";
import axios from "axios";
import ClientConfig from "../../common/config";
import { getCookie } from "../../common/utils/CookieUtils";
import { isJwtValidDecode } from "../../common/jwt";

axios.defaults.withCredentials = true;
class AuthFetcher extends BaseHttpFetcher {
  getIP = async () => {
    try {
      const data = await axios.get("https://api.ipify.org/?format=json");
      return data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  login = async (email: string, password: string): Promise<any> => {
    try {
      const body = {
        email: email,
        password,
      };
      const res = await axios.post(
        ClientConfig.apiBaseHost + "/auth/login",
        body,
        {
          withCredentials: true,
          headers: {
            crossDomain: true,
            Accept: "/",
            "Content-Type": "application/json",
          },
        }
      );

      // const cookieAT = bake_cookie("auth_token", res.data.token,dayjs().add(15, "minutes").toDate());
      // const cookieRT = bake_cookie("refresh_token", res.data.token,dayjs().add(1, "days").toDate());

      return res.data;
      // return await this.axios.post(+'/login', body);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        alert(err.response?.data.message);

        throw err.response?.data;
        // Access to config, request, and response
      } else {
        throw err;
        // Just a stock error
      }
    }
  };

  getAuthUserDetails = async (): Promise<any> => {
    try {
      const isAccessTokenValid = await isJwtValidDecode();
      if (!isAccessTokenValid) throw new Error("משתמש לא נמצא");
      // cookie.useCookies()
      const res = await axios.get(ClientConfig.apiBaseHost + "/user/details", {
        httpAgent: true,
        withCredentials: true,
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  async updatePasswordWithResetToken(token: string, password: string) {
    try {
      const body = {
        token,
        password,
      };
      const response = await this.post(
        "/updatePasswordWithResetToken",
        body
      ).then((data) => data.results);
      return response;
    } catch (err) {
      // Logger.error(err);
      return false;
    }
  }

  async sendResetPasswordMail(email: string) {
    try {
      const body = { email };
      const response = await this.post("/resetPassword", body).then(
        (data) => data.results
      );
      if (response && response.result) {
        alert("מייל לשחזור סיסמה נשלח בהצלחה");
      } else {
        alert("קרתה תקלה בשליחת המייל, אנא פנה לשירות לקוחות");
      }
      return response;
    } catch (err) {
      // Logger.error(err);
      return false;
    }
  }



  refreshToken = async () => {
    try {
      // if (localStorage.getItem("refresh_token")) {
      if (getCookie("refresh_token")) {
        const res = await axios.post(
          ClientConfig.apiBaseHost + "/auth/refresh/"
          // +
        );
        if (res.data.token && res.data.refresh_token) {
        }
        return res.data;
      }
      throw new Error("בעיה בהחלפת אקסס טוקן");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          return "האקסס טוקן פג תוקף";
        }
        throw err.response?.data.message;
      } else {
        throw err;
      }
    }
  };
}

export default new AuthFetcher(`auth`);
