import { action, IObservableArray, makeAutoObservable, observable, runInAction, toJS } from "mobx";
import { RootStore } from ".";
import { IQuestion } from "../../common/interfaces/Question";
import SessionUtils from "../../common/utils/SessionUtils";
import interests from "../../UI/interesetsPage/interestsIcon";
import QuestionnaireStore, { DisplayState } from "./questionnaire.store";
import { Degrees } from "./UserInfo.store";

export enum ModalStatus {
  login,
  register,
  forgot_password,
  details,
  registered,
  none
}

export enum SideBarModals {
  account,
  contactUs,
  aboutUs,
  none,
}
class appStore {
  chosenModal: SideBarModals = SideBarModals.none;
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore
    makeAutoObservable(this);
  }

  @action
  setChosenModal = (value: SideBarModals) => {
    this.chosenModal = value;
  };

  @action
  init = async () => {
    this._rootStore.CitiesStore.getCities()
    await Promise.all([
      this._rootStore.questionnaireStore.getAllQuestions(),
      // this._rootStore.JobOccupationStore.getAllCategories(),
      this._rootStore.JobOccupationStore.getJobOccupations()
    ]);
    this._rootStore.AuthStore.setIsUserLogged(false)
    // setIsUserLogged(false);
    // getSelectedQuestion();
    // await calculateQuestionaire();
    // Load Local Storage
    // ///////////////////

    const previous_jobs_ids: string[] | undefined =
      SessionUtils.getItem("previous_jobs")?.split(",");
    this._rootStore.JobOccupationStore.jobOccupations.forEach(
      (job_occupation) => {
        if (previous_jobs_ids?.includes(job_occupation.male_name))
          this._rootStore.UserInfoStore.selectedPreviousJobs.push(
            job_occupation
          );
      }
    );

    const interests_ids: string[] | undefined =
      SessionUtils.getItem("interests")?.split(",");
    if (interests && interests instanceof Array && interests.length > 0) {
      interests.forEach((interest) => {
        if (interests_ids?.includes(interest.id.toString())) {
          this._rootStore.UserInfoStore.selectedInterestsS.push(interest);
        }
      });
    }

    const cityString = SessionUtils.getItem("city");
    if (cityString) {
      const City = this._rootStore.CitiesStore.cities.find(
        (city) => city.name === cityString
      );
      if (City) {
        this._rootStore.CitiesStore.setSelectedCity(City);
      }
    }

    const age = SessionUtils.getItem("age");
    if (age) {
      const Age = Number(age);
      this._rootStore.UserInfoStore.setSelectedAge(Age);
    }

    // date_of_birth
    const education_degree = SessionUtils.getItem("education_degree");
    if (education_degree) {
      this._rootStore.UserInfoStore.setSelectedEducationalDegree(
        Degrees[Number(education_degree)]
      );
      this._rootStore.UserInfoStore.setChecked(Degrees[Number(education_degree)])
    }

    // const arr:IQuestion[] = []
    let isFoundLocalStorage = false;
    let itemsCounter = 0;

    this._rootStore.questionnaireStore.setDoneOrSkipOrSaveQuestionnaire(false);

    this._rootStore.questionnaireStore.questions.forEach((question) => {
      const value = SessionUtils.getItem(question._id);
      // value as number
      if (value) {
        const find: IQuestion | undefined = this._rootStore.questionnaireStore.questions.find((data) => question._id === data._id);
        if (find) {
          isFoundLocalStorage = true;
          this._rootStore.questionnaireStore.updateQuestionsAnswer(find._id, Number(value))
          itemsCounter++;
        }
      }

    })
    if (isFoundLocalStorage) {
      const checkForUnAnswerdQuesion = this._rootStore.questionnaireStore.questions.find(
        (qusetion) => qusetion.answer === 0
      );
      if (checkForUnAnswerdQuesion) {
        this._rootStore.questionnaireStore.setSelectedQuestion(checkForUnAnswerdQuesion)
        this._rootStore.questionnaireStore.setDisplayState(DisplayState.Questionnaire);
      }
    }
    else if (itemsCounter === this._rootStore.questionnaireStore.questions.length - 1) {
      this._rootStore.questionnaireStore.setDisplayState(DisplayState.QuestionAnalysis);
      this._rootStore.questionnaireStore.calculateQuestionaire();
    }
  };

}




export default appStore;
