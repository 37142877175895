import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RegistrationForm from "../../common/components/forms/RegistrationForm";
import UserFetcher from "../../Infrastructure/fetchers/UserFetcher";
import rootStores from "../../BL/stores";
import {
  LANGS_STORE,
  QUESTIONNAIRE_STORE,
  USER_INFO_STORE,
  CITIES_STORE,
  AUTH_STORE,
} from "../../BL/consts/stores";
//import logo from "../../assets/logo-new-2.svg";
import "./LoginConfirmModal.scss";
import { IInterest } from "../../common/interfaces/Interest";
import { toJS } from "mobx";
import leftArrow from "../../assets/images/Main/Arrow_main.png";
import { ReactComponent as Close_Icon } from "../../assets/images/Answer/Answers_Close.svg";

interface Props {
  // onClose: () => void;
  // onCancel: () => void;
  // onCancel: ()=>void;
}

const userInfoStore = rootStores[USER_INFO_STORE];
const questionsStore = rootStores[QUESTIONNAIRE_STORE];
const languageStore = rootStores[LANGS_STORE];
const cityStore = rootStores[CITIES_STORE];
const authStore = rootStores[AUTH_STORE];

export const LoginConfirmModal: React.FC<Props> = observer(() => {
  const {
    selectedPreviousJobs,
    selectedInterestsS,
    selectedAge,
    selectedIsEmployee,
    selectedEducationalDegree,
    isAgreeToTerms,
    setIsAgreeToTerms,
    setEmail,
    email,
    reSendVerificationMail,
    setDataFromLogin,
  } = userInfoStore;
  const { currentLanguageIndex, languages } = languageStore;
  const { selectedCity, setSelectedCity } = cityStore;
  const { IQuestionnaire_info, answers, questions } = questionsStore;
  const { cancelLogin, login } = authStore;

  const history = useHistory();
  const onClickLogin = async () => {
    try {
      const userData = await login();
      setDataFromLogin(userData.user); // only in user store
      setSelectedCity(userData.user.city || null);

      history.push({pathname:"/",search:'login=true'});
    } catch (err) {
      console.log(err);
    }
  };

  const textMale={
    line1:"הנך מבצע התחברות.",
    line2:"שים לב שהנתונים שיוצגו לך הינם הנתונים שנשמרו עבורך מההתחברות הקודמת"
  };
  const textFemale={
    line1:"הינך מבצעת התחברות.",
    line2:"שימי לב שהנתונים שיוצגו לך הינם הנתונים שנשמרו עבורך מההתחברות הקודמת"
  }
  return (
    <div
      className="popup_bg"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="login-confirm-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Close_Icon
          className={`login-confirm_close_icon pointer`}
          onClick={cancelLogin}
        />

        {/* <div className="registration-while-loading-inside-container"> */}
        
        <p className="login-confirm_header white">{currentLanguageIndex==0? textMale.line1 : textFemale.line1}</p>

        <p className="login-confirm_header white bolded">
          {currentLanguageIndex==0?
            textMale.line2:textFemale.line2
          }
        </p>
        <div className="login-confirm-buttons-row row">
          <button
            className="login-confirm-button-approve"
            onClick={onClickLogin}
          >
            {"התחברות"}
          </button>
          <button className="login-confirm-button-cancel" onClick={cancelLogin}>
            {"ביטול"}
          </button>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
});
