import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RegistrationForm from "../../common/components/forms/RegistrationForm";
import UserFetcher from "../../Infrastructure/fetchers/UserFetcher";
import rootStores from "../../BL/stores";
import {
  LANGS_STORE,
  QUESTIONNAIRE_STORE,
  USER_INFO_STORE,
  CITIES_STORE,
  AUTH_STORE,
} from "../../BL/consts/stores";
//import logo from "../../assets/logo-new-2.svg";
import "./RegistrationModal.scss";
import { IInterest } from "../../common/interfaces/Interest";
import { toJS } from "mobx";
import leftArrow from "../../assets/images/Main/Arrow_main.png";
import { ReactComponent as Close_Icon } from "../../assets/images/Answer/Answers_Close.svg";

interface Props {
  // onClose: () => void;
  // onCancel: () => void;
  // onCancel: ()=>void;
}

const userInfoStore = rootStores[USER_INFO_STORE];
const questionsStore = rootStores[QUESTIONNAIRE_STORE];
const languageStore = rootStores[LANGS_STORE];
const cityStore = rootStores[CITIES_STORE];
const authStore = rootStores[AUTH_STORE];

export const RegistrationModal: React.FC<Props> = observer(
  () => {
    const {
      selectedPreviousJobs,
      selectedInterestsS,
      selectedAge,
      selectedIsEmployee,
      selectedEducationalDegree,
      isAgreeToTerms,
      setIsAgreeToTerms,
      setEmail,
      email,
      reSendVerificationMail,
    } = userInfoStore;
    const { currentLanguageIndex, languages } = languageStore;
    const { selectedCity } = cityStore;
    const { IQuestionnaire_info, answers, questions } = questionsStore;
    const { setIsUserRegistered, isUserRegistered, registrationModalSettings, onCancelRegisration, onCloseRegisration } = authStore;

    const textMale = {
      welcomePage: {
        header1: "יותר נעים",
        header2: "כשמכירים :)",
        description1: "כדי שההמלצות שלנו יהיו מושלמות בשבילך, ממש כדאי להירשם",
        description2: "או, ",
        blueDescription: "המשך כאורח",
        registerButton: "לאישור הרשמה במייל",
      },
      jobOccupationsPage: {
        header1: "אולי בכל זאת?",
        header2: "אם תרצה לחזור בעתיד",
        description1: "כדי שתוכל להמשיך לצפות בהכשרות שלך בעתיד אנא צור חשבון",
        description2: "או, ",
        blueDescription: "המשך כאורח",
        registerButton: "יצירת משתמש",
      },
      processNotCompleted: {
        header1: "לתשומת ליבך,",
        header2: "תהליך ההרשמה לא הושלם.",
        description1: `חזרו לבדוק את תיבת הדוא״ל שלכם.`,
        description2: `לא קיבלתם מייל? האם זוהי הכתובת הנכונה? `,
        blueDescription: `${email}`,
        registerButton: "שליחה חוזרת",
      },
    };
    const textFemale = {
      welcomePage: {
        header1: "יותר נעים",
        header2: "כשמכירים :)",
        description1: "כדי שההמלצות שלנו יהיו מושלמות בשבילך, ממש כדאי להירשם",
        description2: "או, ",
        blueDescription: "המשיכי כאורחת",
        registerButton: "לאישור הרשמה במייל",
      },
      jobOccupationsPage: {
        header1: "אולי בכל זאת?",
        header2: "אם תרצי לחזור בעתיד",
        description1: "כדי שתוכלי להמשיך לצפות בהכשרות שלך בעתיד אנא צרי חשבון",
        description2: "או, ",
        blueDescription: "המשיכי כאורחת",
        registerButton: "יצירת משתמש",
      },
      processNotCompleted: {
        header1: "לתשומת ליבך,",
        header2: "תהליך ההרשמה לא הושלם.",
        description1: `חזרו לבדוק את תיבת הדוא״ל שלכן.`,
        description2: `לא קיבלתם מייל? האם זוהי הכתובת הנכונה? `,
        blueDescription: `${email}`,
        registerButton: "שליחה חוזרת",
      },
    };
    const [currentText, setCurrentText] = useState(textFemale.welcomePage);
    const history = useHistory();
    // const moveNextPage = () => {
    //   setIsRegistrationModalOpen(false);
    //   history.push(nextPath);
    // };
    useEffect(() => {
      const text = currentLanguageIndex == 0 ? textMale : textFemale;
      if (isUserRegistered) setCurrentText(text.processNotCompleted);
      else if (registrationModalSettings.isDetailsComplete) setCurrentText(text.jobOccupationsPage);
      else setCurrentText(text.welcomePage);
    }, [registrationModalSettings.isDetailsComplete, isUserRegistered, currentLanguageIndex]);


    const reRegistration = async () => {
      setEmail("");
      setIsUserRegistered(false);
    };
    const onSubmit = async (event: any) => {
      try {
        let test: any = {
          email: event.email || "",
          password: event.password || "",
          city: selectedCity! || undefined,
          education_degree: selectedEducationalDegree || "",
          first_name: event.privateName || "",
          last_name: event.lastName || "",
          age: selectedAge || undefined,
          interests: selectedInterestsS
            ? selectedInterestsS.map((interest: IInterest) => interest.id)
            : [],
          is_employee: selectedIsEmployee ? true : false,
          gender: languages[currentLanguageIndex].gender,
          previous_jobs: toJS(selectedPreviousJobs!),
          // answers: answers ? answers!.map((answer) => answer.value!) : [],
          questionnaire_info: toJS(IQuestionnaire_info!),
          answers: questions!.map((q) => q!.answer!),
          isDetailsComplete: registrationModalSettings.isDetailsComplete,
        };
        await UserFetcher.register(test);
        setEmail(test.email);
        setIsUserRegistered(true);
      } catch (error) {

      }
    };
    return (
      <div
        className="registration_popup_bg"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="registration-while-loading-container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Close_Icon
            className={`registration_modal_close_icon ${isUserRegistered ? "pointer" : "transparent"
              }`}
            onClick={isUserRegistered ? onCloseRegisration : () => { }}
          />

          {/* <div className="registration-while-loading-inside-container"> */}
          <p className="registration_modal_header white">
            {currentText.header1}
          </p>

          <p className="registration_modal_header white bolded">
            {currentText.header2}
          </p>
          <div className="description-container">
            <span className="white">{currentText.description1}</span>
            {/* <br/> */}
            {window.innerWidth > 767 ? (
              <br />
            ) : (
              <span className="white"> </span>
            )}
            <span className="white">{currentText.description2}</span>
            {isUserRegistered ? (
              <span className="blue2 bolded underline">
                {currentText.blueDescription}
              </span>
            ) : (
              // <button className="continue-as-guest-button" onClick={onClose}>
              <span
                className="blue2 bolded underline pointer continue_as_guest_btn" id="continue_as_guest_btn"
                tabIndex={0} role="button" aria-pressed="false"
                onClick={onCloseRegisration}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onCloseRegisration()
                  }
                }}
              >
                {currentText.blueDescription}
              </span>
              // </button>
            )}
          </div>

          {isUserRegistered ? (
            <div className="after-register-actions-container">
              <div className="row" onClick={reSendVerificationMail}>
                <p className="white bolded pointer">
                  {currentText.registerButton}
                </p>
                <img
                  className="arrow-after-register second-registration-popup"
                  src={leftArrow}
                  alt="press to- continue"
                />
              </div>
              <p className="white pointer bolded second-registration-popup" onClick={reRegistration}>
                {"הרשמה מחדש"}
              </p>
            </div>
          ) : (
            <RegistrationForm
              textRegisterButton={currentText.registerButton}
              onSubmit={onSubmit}
              onCancel={onCancelRegisration}
            />
          )}
        </div>
        {/* </div> */}
      </div>
    );
  }
);
