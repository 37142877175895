import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import rootStores from "../../../BL/stores";
import { ICourse } from "../../../common/interfaces/Course";
import CourseBtnComponents from "./CourseBtnComponents/CourseBtnComponents";
import "./ScrollBar.scss";

import { useContext } from "react";
import { findDOMNode } from "react-dom";
import { LANGS_STORE } from "../../../BL/consts/stores";

const courseStore = rootStores.CourseStore;
const jobStore = rootStores.JobOccupationStore;
const languageStore=rootStores.LangsStore;

interface Props {
  setLastCourse: (value: ICourse) => void;
  setNextCourse: (value: ICourse) => void;
}

const ScrollBarComponent: React.FC<Props> = ({
  setLastCourse,
  setNextCourse,
}) => {
  const { coursesByJobOccupation, selectedCourse, setSelectedCourse } =
    courseStore;
  const clickedScreenRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    clickedScreenRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [selectedCourse]);
  return (
    <div className="scrollBar-container-screen-courses">
      <div className="scroll-bar-text-div">
        <p tabIndex={0} className="first-line-of-scroll  ">{selectedCourse?.name}</p>
        <p tabIndex={0} className="seconded-line">
          {" "}
          {coursesByJobOccupation.length}{" "}
          {languageStore.translate("CoursePage", "scrollbarLength")}
        </p>
      </div>
      <div className={"scroll-screen-13-progress-bar-container-courses scroll"} >
        <div className="scroll-screen-13-courses" dir="LTR">
          {coursesByJobOccupation.map((course: ICourse) => (
            <div
              key={course.id}
              style={{ zIndex: 4, paddingTop:"5%" }}
              className={`${
                selectedCourse?.id === course?.id ? "clicked-screen-13" : ""
              }`}
              ref={selectedCourse?.id === course?.id ? clickedScreenRef : null}
              dir="RTL"
            >
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "black",
                  borderRadius: "60px",
                  height: "40%",
                  width: "2.5%",
                  right: "2rem",
                  zIndex: 2,
                  // display: "none",
                }}
              ></div>

              <CourseBtnComponents
                setLastCourse={setLastCourse}
                setNextCourse={setNextCourse}
                key={course?.id}
                course={course}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ScrollBarComponentObserver = observer(ScrollBarComponent);
export default ScrollBarComponentObserver;
