import React from "react";
import "./GetCourseBar.scss";
import ScrollBarComponent from "../ScrollBar/ScrollBarComponent";
import { ICourse } from "../../../common/interfaces/Course";
interface Props {
  setLastCourse: (value: ICourse) => void;
  setNextCourse: (value: ICourse) => void;
}

const GetCourseBar: React.FC<Props> = ({ setNextCourse, setLastCourse }) => {

  return (
    <div className="container-course" style={{ width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <ScrollBarComponent setLastCourse={setLastCourse} setNextCourse={setNextCourse} />
      </div>
    </div>
  );
};

export default GetCourseBar;