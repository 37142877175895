import {
  action,
  makeAutoObservable,
  observable,
} from "mobx";
import { RootStore } from ".";

class FilterCourseStore {
  private _rootStore:RootStore
  constructor(rootStore:RootStore) {
    this._rootStore = rootStore;
        makeAutoObservable(this);
    this.check = 0;
    this.courseTime = ""
    this.durationOfCourse=36
    this.locationOfCourse= false
    this.level =2
    this.filterRequirements =observable([])
    this.allFilterRequirements =observable([])
  }

  check: number;

  @action 
  resetAllFilters = ()=>{
    this.check = 0;
    this.courseTime = ""
    this.durationOfCourse=36
    this.locationOfCourse= false
    this.level =2
    this.setFilterRequirements([]);
    this.setAllFilterRequirements([])
  }
  @action
  setCheck =(checked: number) => {
    this.check = checked;
  }

  courseTime: string;
  @action
  setCourseTime =(courseTime: string)=> {
    this.courseTime = courseTime;
  }

  durationOfCourse:number;
  @action
  setDurationOfCourse =(duration: number)=>{
    this.durationOfCourse = duration;
  }

  locationOfCourse:boolean
  @action
  setLocationOfCourse = (course: boolean)=>{
    this.locationOfCourse = course;
  }
  level: number
  @action
  setLevel =(value: number)=>{
    this.level =value
  }
  filterRequirements:Array<string>
  @action
  setFilterRequirements =(value: Array<string>)=>{
    this.filterRequirements =value
  }

  allFilterRequirements:string[];
  @action
  setAllFilterRequirements =(value: Array<string>)=>{
    this.allFilterRequirements =value
  }

}
export default FilterCourseStore;
