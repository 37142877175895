import React, { useState } from "react";
import RequirementsCheckBox from "../RequirementsCheckBox/RequirementsCheckBox";
import "./BtnFilterRequirement.scss";
import { ReactComponent as FilterImage } from "../../../../assets/Results images/Archive/Fillter.svg";
import rootStores from "../../../../BL/stores";
import { observer } from "mobx-react";

interface IProps {
  setFilterRequirements?: (value: Array<string>) => void;
  filterRequirements?: Array<string>;
  allFilterRequirements: string[];
}
const languageStore = rootStores.LangsStore;
const BtnFilterRequirement: React.FC<IProps> = ({
  setFilterRequirements,
  filterRequirements,
  allFilterRequirements,
}) => {
  const [display, setDisplay] = useState<boolean>(false);

  return (
    <div
      className="btn-and-img-container"
      role={"button"}
      tabIndex={0}
      onClick={() => setDisplay(!display)}
      onKeyUp={(e) => e.key == "Enter" && setDisplay(!display)}
    >
      <p className="text-of-requirements">
        {languageStore.translate(
          "CoursesByJobOccupationPage",
          "filterRequirements"
        )}
      </p>
      <div className="icon-style">
        <div className="btn-no-border mobile-filter-image">
          <FilterImage className={"mobile-filter-image"} />
        </div>
        {display && allFilterRequirements.length > 0 && (
          <div
            className="filter-inside-the-page"
            onClick={(e) => e.stopPropagation()}
            onKeyUp={(e) => e.stopPropagation()}
          >
            <div style={{ marginTop: "5%" }}>
              <p
                className="p-sm-JO"
                style={{ color: "white", marginRight: "10%" }}
              >
                {languageStore.translate(
                  "CoursesByJobOccupationPage",
                  "filterRequirementsInside"
                )}
              </p>
              <RequirementsCheckBox
                isBig={display}
                allFilterRequirements={allFilterRequirements}
                filterRequirements={filterRequirements}
                setFilterRequirements={setFilterRequirements}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const BtnFilterRequirementObserver = observer(BtnFilterRequirement);
export default BtnFilterRequirementObserver;
