import { ICourse } from "../../common/interfaces/Course";
import { BaseHttpFetcher } from "./BaseHttpFetcher";
import axios from "axios";
import ClientConfig from "../../common/config";
import {
  IUserLoginDTO,
  IUserRegisterDTO,
} from "../interfaces/UserFetcher.interface";
import { getCookie } from "../../common/utils/CookieUtils";
import { isJwtValidDecode } from "../../common/jwt";
// import Benefit from '../../common/models/Benefit';

class UserFetcher extends BaseHttpFetcher {
  register = async (user_info: IUserRegisterDTO): Promise<any[]> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + "/user",
        { ...user_info },
        { headers: { "Content-Type": "application/json" } }
      );
      return res.data;
      // return await axios.get(`/`,);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        alert(err.response?.data.message);
        // alert(err.response?.data.message);
        throw err;
        // Access to config, request, and response
      } else {
        throw err;
        // Just a stock error
      }
    }
  };

  login = async (user_info: IUserLoginDTO): Promise<ICourse[]> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + "/course/byjoboccupation",
        { ...user_info },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return res.data;
      // return await axios.get(`/`,);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        alert(err.response?.data.message);
        throw err.response?.data;
        // Access to config, request, and response
      } else {
        throw err;
        // Just a stock error
      }
    }
  };
  update = async (body: any): Promise<any> => {
    try {
      const isAccessTokenValid = await isJwtValidDecode();
      if (!isAccessTokenValid) throw new Error("משתמש לא נמצא");
      const res = await axios.put(
        ClientConfig.apiBaseHost + "/user/flow2/",
        { ...body },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getCookie("auth_token"),
          },
        }
      );
      return res.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  passwordValid = async (user_email: string): Promise<string> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + "/user/auth/email",
        { email: user_email },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      alert("נשלח אליך מייל לאיפוס סיסמה");

      return res.data;
      // return await axios.get(`/`,);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        alert("כתובת האימייל לא קיימת במערכת");
        // alert(err.response?.data.message);
        throw err.response?.data;
        // Access to config, request, and response
      } else {
        throw err;
        // Just a stock error
      }
    }
  };
  forgottenPasswordUpdate = async (
    password: string,
    token: string
  ): Promise<string> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + "/user/reset/password",
        {
          password: password,
          token: token,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return res.data;
      // return await axios.get(`/`,);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        alert(err.response?.data.message);
        throw err.response?.data;
        // Access to config, request, and response
      } else {
        throw err;
        // Just a stock error
      }
    }
  };
  checkIfUserTokenValid = async (token: string): Promise<string> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + `/user/reset/${token}`,
        {
          token: token,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return res.data;
      // return await axios.get(`/`,);
    } catch (err) {
      throw err;
    }
  };
  firtSignUpTokenCheck = async (token: string): Promise<any> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + `/user/auth/${token}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return res.data;
      // return await axios.get(`/`,);
    } catch (err) {
      throw err;
    }
  };

  async logout() {
    try {
      const isAccessTokenValid = await isJwtValidDecode();
      if (!isAccessTokenValid) throw new Error("משתמש לא נמצא");
      const res = await axios.post(
        ClientConfig.apiBaseHost + `/user/auth/signout`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  contactMail = async (mailFrom: any): Promise<any> => {
    try {
      const res = await axios.post(ClientConfig.apiBaseHost + `/contact/`, {
        ...mailFrom,
      });
      return res.data;
      // return await axios.get(`/`,);
    } catch (err) {
      throw err;
    }
  };
  reSendVerificationMail = async (user_email: string): Promise<string> => {
    try {
      const res = await axios.post(
        ClientConfig.apiBaseHost + "/user/auth/reSendVerificationMail",
        { email: user_email },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      return res.data;
    } catch (err) {
      throw err;
    }
  };
}

export default new UserFetcher(`course`);
