import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import rootStores from "../../../BL/stores";
import CourseBtnComponents13 from "./CourseBtnComponents13/CourseBtnComponents13";
import "./ScrollBar13.scss";
import { IJobOccupation } from "../../../common/interfaces/Job-Occupation";
interface IProps {
  recommendedJobOccupation: IJobOccupation | null;
  clickOnScroll: (name: string, job: IJobOccupation) => void;
  moveToCourse: (name: string) => void;
  isLoading: boolean;
  completeQuest: () => void;
}

const jobStore = rootStores.JobOccupationStore;

const ScrollBarComponent13: React.FC<IProps> = ({
  recommendedJobOccupation,
  clickOnScroll,
  moveToCourse,
}) => {
  const { currentRecommendedJobOccupations } = jobStore;

  const clickedScreenRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    clickedScreenRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [recommendedJobOccupation]);

  return (
    <div className={"navigator-row-screen-13"} >
      <div className="scrollBar-container-screen-13" >
        {currentRecommendedJobOccupations.length >= 1 && (
          <p className="seconded-line-screen-13" >
            העיסוקים שמתאימים לך:
          </p>
        )}
        <div className={"scroll-screen-13-progress-bar-container"}>
          <div className="scroll-screen-13" dir="LTR">
            {currentRecommendedJobOccupations
              .map((recJob: IJobOccupation, index: number) => (
                <div
                  // role={'link'}
                  key={index}
                  style={{ zIndex: 4 }}
                  className={`${recommendedJobOccupation!.male_name === recJob.male_name
                    ? "clicked-screen-13"
                    : ""
                    }`}
                  ref={
                    recommendedJobOccupation!.male_name === recJob.male_name
                      ? clickedScreenRef
                      : null
                  }
                  dir="RTL"
                >
                  <CourseBtnComponents13
                    moveToCourse={moveToCourse}
                    clickOnScroll={clickOnScroll}
                    key={recJob.male_name}
                    recJob={recJob}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* {!userPath && (
        <div
          onClick={() => completeQuest()}
          style={{ cursor: "pointer" }}
          className="div-of-questionnaire-scroll"
        >
          <Questionnaire className="questionnaire-scroll-13" />
          <p className="title-of-questionnaire  p-md-JO bolded">
            להשלמת השאלון
          </p>
        </div>
      )} */}
    </div>
  );
};

const ScrollBarComponentObserver13 = observer(ScrollBarComponent13);
export default ScrollBarComponentObserver13;