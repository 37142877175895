import React, { useEffect, useRef, useState } from "react";
import "./SliderProgressBar.scss";
// import Image from "../../common/components/image.component";
import { useContext } from "react";

import Substruction from "../../assets/images/Main/Substruction.png";
import { Link, useHistory } from "react-router-dom";
import rootStores from "../../BL/stores";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { ReactComponent as Side_Bar_1 } from "../../assets/SideBar01.svg";
import { ReactComponent as Side_Bar_2 } from "../../assets/SideBar02.svg";
import { ReactComponent as Side_Bar_3 } from "../../assets/SideBar03.svg";
import { ReactComponent as Side_Bar_4 } from "../../assets/SideBar04.svg";

// import languageStore from "../../BL/stores/language.store";

interface Props {
  setLevel: (number: number) => void;
  level: number;
  isOpen: boolean;
  moveBack: () => void;
}
const jobOccupationsStore = rootStores.JobOccupationStore;
const userInfoStore = rootStores.UserInfoStore;
const cityStore = rootStores.CitiesStore;
const languageStore = rootStores.LangsStore;
const questionStore = rootStores.questionnaireStore;
const SliderProgressBar: React.FC<Props> = ({
  level,
  setLevel,
  moveBack,
  isOpen,
}) => {
  const {
    selectedInterestsS,
    selectedAge,
    userPath,
    selectedEducationalDegree,
    setPreviusURLs,
    addPreviusLevel,
  } = userInfoStore;
  const { recommendedJobOccupation, setRecommendedJobOccupation } =
    jobOccupationsStore;
  const { selectedCity } = cityStore;
  const { currentLanguageIndex } = languageStore;
  const { topTwo, questions, isAllQuestionsAnswer, doneOrSkipOrSaveQuestionnaire, numOfFilledAnswers } = questionStore;
  const firstUpdate = useRef(true);
  const [screenSize, getDimension] = useState<number>(window.innerWidth);

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // if (userPath && level === 0) history.goBack();
    if (userPath && level === 0) moveBack();
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [level, selectedInterestsS.length, screenSize]);

  const educationDagreeHebrew = (selectedEducationalDegree: string) => {
    switch (selectedEducationalDegree) {
      case "collegeDegree":
        return "תואר אקדמי";
      case "highSchoolStudent":
        return "השכלה תיכונית";
      case "highSchoolDiploma":
        return "תעודת בגרות";
      case "professionalCertificate":
        return "הנדסאי / טכנאי / תעודה";
    }
  };
  const barStatusHeight = "4vw";

  var barStatusTop =
    level === 0
      ? `calc(${barStatusHeight}*2)`
      : level === 1
        ? `calc(42% + ${barStatusHeight})`
        : level === 2
          ? `calc(68% + ${barStatusHeight})`
          : `calc(94% + ${barStatusHeight})`;

  // function changeBarStatusHeight(){
  //   barStatusTop =
  //   level === 0
  //     ? `calc(4.5% + ${barStatusHeight}*2)`
  //     : level === 1
  //     ? `calc(36% + ${barStatusHeight})`
  //     : level === 2
  //     ? `calc(65% + ${barStatusHeight})`
  //     : `calc(94% + ${barStatusHeight})`;
  // }

  const interesets: string = " תחומי עניין";
  const intereset: string = "כבר בחרתי מסלול";
  const userDetails: string = "פרטים אישיים";
  const questionnaire: string = "שאלון הכוונה";
  const lastThings: string = "כמה דברים אחרונים";

  const titlesDepenedsOnFlow = () => {
    // long flow

    if (!userPath) {
      // return [interesets, userDetails, questionnaire, lastThings];
      return [interesets, userDetails, questionnaire, lastThings];
    }
    // short flow
    else return [intereset, userDetails];
  };

  // const titles: string[] = [interesets, userDetails, lastThings];
  const titles: string[] = titlesDepenedsOnFlow();

  const TextComponentProgressBar: React.FC<{
    index: number;
    title?: string;
    level?: number;
    setLevel?: React.Dispatch<React.SetStateAction<number>>;
  }> = ({ index, title }) => {
    const history = useHistory();

    const handelLinkTo = (index: number) => {
      return userPath
        ? "/short/user-details"
        : subTitles[0][0] === ""
          ? "/long/interest"
          : index === 1
            ? "/long/user-details"
            : index === 2
              ? subTitles[1].length !== 2 || subTitles[1][1] === undefined
                ? "/long/user-details"
                : "/long/questionnaire"
              : index === 3
                ? subTitles[1].length !== 2 || subTitles[1][1] === undefined
                  ? "/long/user-details"
                  : doneOrSkipOrSaveQuestionnaire === false && topTwo.length === 0
                    ? "#"
                    : "/long/last-thing"
                : "/long/interest";
    };

    const check = () => {
      alert("אנא בחר עיר מגורים , שנת לידה והשכלה");
      setLevel(1);
    };

    const handelProgressBar = (index: number) => {
      if (!userPath && subTitles[0][0] === "") {
        alert("אנא בחר תחומי עניין");
      } else {
        if (!userPath && subTitles[0][0] !== "") {
          if (
            !userPath &&
            (subTitles[1].length !== 2 || subTitles[1][1] === undefined) &&
            (index === 2 || index === 3)
          ) {
            check();
          } else {
            if (
              index === 3 &&
              userPath === false &&
              topTwo.length === 0 &&
              doneOrSkipOrSaveQuestionnaire === false
            ) {
              alert("עליך למלא את השאלון או לדלג עליו על מנת להמשיך");
            } else {
              setLevel(index);
            }
          }
        } else {
          if (userPath && index === 0) {
            setRecommendedJobOccupation(null);
            //  setLevel(index)
            moveBack();
          } else alert("in else ");
          setLevel(3);
        }
      }

      addPreviusLevel(level);//save previus level in the store
      setPreviusURLs(`${history.location.pathname}${history.location.search}`, level);//save previus url in the store
      history.push(handelLinkTo(index));
    };

    const getOpacity = (index: number) => {
      if (index === 0 && selectedInterestsS.length === 0) {
        return 0.5;
      } else if (index === 0 && selectedInterestsS.length > 0) return 1;
      if (
        (index === 1 && selectedCity === null) ||
        selectedAge === 0 ||
        selectedEducationalDegree === "none"
      )

        return 0.5;
      else if (
        index === 1 &&
        selectedCity != null &&
        selectedAge > 0 &&
        selectedEducationalDegree !== "none"
      )

        return 1;
      else if (
        index === 2 &&
        doneOrSkipOrSaveQuestionnaire === false &&
        topTwo.length === 0
      )
        return 0.5;
      else if (index === 2 && doneOrSkipOrSaveQuestionnaire === true) return 1;
      if (index === 3 && doneOrSkipOrSaveQuestionnaire === false) return 0.5;
      else {
        return 1;
      }
    };

    // const numOfFilledAnswers = () => {
    //   if(isAllQuestionsAnswer()){
    //     return 30;
    //   }
    //   else{
    //       return parseInt(item, 10);
    //     });

    //     return answersArr?.filter(function (item) {
    //       return item !== 0;
    //     }).length;
    //   }

    // }

    const checkForquestionnaireResults = () => {
      if (topTwo.length == 2 && numOfFilledAnswers() === questions.length)
        return Array(
          ` 
        ` +
          languageStore.translate("questionnairePage", topTwo[0][0]) +
          ` ` +
          "ו" +
          languageStore.translate("questionnairePage", topTwo[1][0])
        );
      else if (topTwo.length == 1 && numOfFilledAnswers() === questions.length)
        return Array(
          ` 
        ` + languageStore.translate("questionnairePage", topTwo[0][0])
        );
      else return [`5 דקות`];
    };

    const interesetsSubTitles: string[] = [
      `${selectedInterestsS?.map((selected) => " " + selected.name)}`,
      // selectedAge.toString()
    ];
    const userDetailsSubTitles: string[] =
      selectedCity && selectedAge && selectedEducationalDegree
        ? [
          `${selectedCity?.name ? selectedCity.name + " . " : " "} ${selectedAge !== 0 ? selectedAge : ""
          }`,
          educationDagreeHebrew(selectedEducationalDegree)!,
          // selectedEducationalDegree,
        ]
        : ["1 דקה"];
    const questionnaireSubTitles: string[] = checkForquestionnaireResults();

    const lastThingsSubTitles: string[] = [`1 דקה`];

    // useEffect(() => {

    //   checkForquestionnaireResults();
    // }, [topTwo]);
    const subTitlesDependesOnFlow = () => {
      // long flow
      if (!userPath) {
        return [
          interesetsSubTitles,
          userDetailsSubTitles,
          questionnaireSubTitles,
          lastThingsSubTitles,
        ];
      }
      // short Flow
      else if (currentLanguageIndex === 0) {
        return [
          [
            recommendedJobOccupation!.male_name &&
            recommendedJobOccupation!.male_name,
          ],
          userDetailsSubTitles,
        ];
      } else {
        return [
          [
            recommendedJobOccupation!.female_name! &&
            recommendedJobOccupation!.female_name!,
          ],
          userDetailsSubTitles,
        ];
      }
    };
    const subTitles: string[][] = subTitlesDependesOnFlow();
    const pageNumberTitles: string[] = ["01", "02", "03", "04"];

    return (
      <div className="sliderProgressBarContainer"
        style={{
          position: "absolute",
          top: !userPath
            ? index === 0
              ? window.innerWidth > 767 ? "7.5%" : "0%"
              : index === 1
                ? window.innerWidth > 767 ? "34.5%" : "25%"
                : index === 2
                  ? window.innerWidth > 767 ? "62%" : "50%"
                  : window.innerWidth > 767 ? "83%" : "75%"
            : index === 0
              ? "10%"
              : "84%",
        }}
        // to={handelLinkTo(index)}
        onClick={() => {
          handelProgressBar(index);
        }}
      >
        <div
          className="p-progress-bar  p-20-JO"
          style={{

            color: !userPath
              ? level === index
                ? "#4681FA"
                : "#D4D4D4"
              : level === index
                ? "#4681FA"
                : level === index + 1
                  ? "#4681FA"
                  : "#D4D4D4",
            fontWeight: !userPath
              ? level === index
                ? "bold"
                : "normal"
              : level === index
                ? "bold"
                : level === index + 1
                  ? "bold"
                  : "normal",
            cursor:
              !userPath && subTitles[0][0] !== ""
                ? !userPath &&
                  (index === 2 || index === 3) &&
                  (subTitles[1].length !== 2 || subTitles[1][1] === undefined)
                  ? "context-menu"
                  : "pointer"
                : userPath || index === 0
                  ? "pointer"
                  : "context-menu",

            opacity: getOpacity(index),
          }}
          // onClick={() => {
          //   handelProgressBar(index);
          // }}
          role={"button"}
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handelProgressBar(index);
            }
          }}
        >
          {/* <div className={isOpen ? "no-show-number" : "no-show-number-close"}>
            {pageNumberTitles[index]}
          </div> */}

          {(isOpen || window.innerWidth > 767) && <>{title}</>}
        </div>

        {subTitles[index].map((subtitle, key) => {
          return (
            <div
              // to={handelLinkTo(index)}
              key={key}
              className="p-progress-bar-little p-progress-text"
              style={{
                fontWeight: "bold",
                color: !userPath
                  ? level === index
                    ? "#4681FA"
                    : "#D4D4D4"
                  : level === index
                    ? "#4681FA"
                    : level === index + 1
                      ? "#4681FA"
                      : "#D4D4D4",

                cursor:
                  !userPath && subTitles[0][0] !== ""
                    ? !userPath &&
                      (index === 2 || index === 3) &&
                      (subTitles[1].length !== 2 || subTitles[1][1] === undefined)
                      ? "context-menu"
                      : "pointer"
                    : userPath || index === 0
                      ? "pointer"
                      : "context-menu" /*: "context-menu"*/,
                opacity: getOpacity(index),
              }}
            // onClick={() => handelProgressBar(index)}
            >
              {(isOpen || window.innerWidth > 767) && <>{subtitle}</>}
            </div>
          )
        })}
      </div>
    );
  };
  const TextComponentProgressBarObserver = observer(TextComponentProgressBar);

  return (
    <div className="progress-bar-container">


      <div className="shadow-container">
        <div
          className="fill-in-changing-size-div"
          style={{
            top: "1%",
            height:
              level === 0
                ? 0
                : level === 1
                  ? "37%"
                  : level === 2
                    ? "65%"
                    : "85%",
          }}
        ></div>
        <div
          className="bar-status"
          style={{
            top:
              level === 0
                ? "1%"
                : level === 1
                  ? window.innerWidth < 767
                    ? "28%"
                    : "32%"
                  : level === 2
                    ? window.innerWidth < 767
                      ? "56%"
                      : "58%"
                    : window.innerWidth < 767
                      ? "82%"
                      : "78%",
          }}
        ></div>
        <img
          src={Substruction}
          className="Substruction-img"
          alt={"Substruction pointer"}
          style={{
            top:
              level === 0
                ? "-7.5%"
                : level === 1
                  ? "22.5%"
                  : level === 2
                    ? "47.5%"
                    : "67.5%",
          }}
        />
      </div>
      <div className="side-bar-status-mobile-container">
        {
          level === 0 ? <Side_Bar_1 className="svg-side-bar-mobile" /> :
            level === 1 ? <Side_Bar_2 className="svg-side-bar-mobile" /> :
              level === 2 ? <Side_Bar_3 className="svg-side-bar-mobile" /> :
                <Side_Bar_4 className="svg-side-bar-mobile" />
        }
      </div>

      <div className="text-progressBar-container">
        {titles.map((title, index) => (
          <TextComponentProgressBarObserver
            key={index}
            index={index}
            title={title}
            level={level}
          />
        ))}
      </div>
    </div>
  );
};
const SliderProgressBarObserver = observer(SliderProgressBar);
export default SliderProgressBarObserver;
