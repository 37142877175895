import { observer } from "mobx-react";
import rootStores from "../../../BL/stores";
import { switchText } from "../../../common/utils/SmileyText.util";
import { iconsOfSmiles, IImge } from "../SmileyPageComponent/Smiley";
import "./PopupQuestionComponent.scss"
interface Props {
    question: any;
    index: number;
    setIsSaved: any;
}

const questionnaireStore = rootStores.questionnaireStore;

const PopupQuestionComponent: React.FC<Props> = ({ question, index, setIsSaved }) => {
    const { updateQuestionsCloneAnswer } = questionnaireStore;

    const answer = question ? question.answer : "0";

    const updateAnswer = (smileyValue: any) => {
        question.answer !== smileyValue && updateQuestionsCloneAnswer(question._id, smileyValue);
        setIsSaved(false);
    }

    const checkForSmiley: any = (smiley: IImge) => {
        return answer === smiley.value
            ? <smiley.whiteIcon className={"smiley-popup-answer-icon"} />
            : <smiley.lightBlueIcon className={"smiley-popup-answer-icon"} />;
    };

    return (
        <div>
            <p className="question-value-number">{`0${index}.`}</p>
            <div>
                {question && <p className="question-value-text" tabIndex={2}>{question.value}</p>}
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "78%" }}>
                {iconsOfSmiles.map((smiley: IImge, i) => (
                    <div>
                        <button className={"popup-question-smiley-button pointer"}
                            key={i}
                            tabIndex={2}
                            id={`smiley-${i}`}
                            onClick={() => { updateAnswer(smiley.value) }}
                            title={switchText(i)}
                        >
                            {checkForSmiley(smiley)}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

const PopupQuestionComponentObserver = observer(PopupQuestionComponent);
export default PopupQuestionComponentObserver;