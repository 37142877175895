import React from "react";
import rootStores from "../../../../BL/stores";
import "./CourseTimeFilter.scss";

import { useContext } from "react";
import { observer } from "mobx-react";
interface IProps {
  setDurationOfCourse: (range: number) => void;
}
const filterStore = rootStores.FilterStore;
const languageStore = rootStores.LangsStore;
const CourseTimeFilter: React.FC<IProps> = ({ setDurationOfCourse }) => {
  const { level, setLevel } = filterStore;
  // const [level, setLevel] = useState<number>(2);
  const handleClick = (value: number, time: number) => {
    setLevel(value);
    setDurationOfCourse(time);
  };


  return (
    <div className={"main-of-duration-time-course"}>
      <div className={"main-slide-bar-container-of-course-duration"}>
        <div
          style={{
            width: level === 2 ? "100%" : level === 1 ? "50%" : "0%",
          }}
          className={"slide-row-black-of-course-page"}
        ></div>
        <div
          className={"slide-bar-contiener-course-pages"}
          style={{
            alignItems:
              level === 2 ? "flex-end" : level === 1 ? "center" : "flex-start",
          }}
        >
          <div className={"image-slide-bar-of-course-page"}></div>
        </div>
        <div className={"text-slide-bar-of-course-page"}>
          <p
            role="button"
            tabIndex={0}
            id="right-text-slider"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleClick(0, 3);
              }
            }}
            className={"slide-text-in-right-course-page"}
            onClick={() => {
              handleClick(0, 3);
            }}
          >
            {"עד"}{" "}{languageStore.translate("CoursesByJobOccupationPage", "threeMonth")}
          </p>

          <p
            role="button"
            tabIndex={0}
            id="center-text-slider"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleClick(1, 12);
              }
            }}
            className={
              "slide-text-in-center-course-page button-without-border"
            }
            onClick={() => {
              handleClick(1, 12);
            }}
          >
            {"עד"}{" "}{languageStore.translate("CoursesByJobOccupationPage", "oneYear")}
          </p>
          <p
            role="button"
            tabIndex={0}
            id="left-text-slider"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleClick(2, 38);
              }
            }}
            className={
              "slide-text-in-left-course-page button-without-border"
            }
            onClick={() => {
              handleClick(2, 38);
            }}
          >
            {"עד"}{" "}{languageStore.translate("CoursesByJobOccupationPage", "threeYears")}
          </p>
        </div>
      </div>
    </div>
  );
};
const CourseTimeFilterObserver = observer(CourseTimeFilter)
export default CourseTimeFilterObserver;
