import { observer } from "mobx-react"
import './HomePage.scss'
import rootStores from "../../BL/stores/index";
import SearchEngineCmp from "./SearchEngine.cmp";
import { useState, useEffect } from "react";
import { IJobOccupation } from "../../common/interfaces/Job-Occupation";
import { useHistory } from "react-router-dom";
import { SideBarModals } from "../../BL/stores/App.store";
import ContactUsObserver from "../sideBar/modals/contactUsModal/ContactUs";
import AboutTheSystemObserver from "../AboutTheSystem/AboutTheSystem";
import { ReactComponent as BlueRouteRight } from '../../assets/images/homepage/route_blue_right.svg';
import { ReactComponent as PurpleRouteLeft } from '../../assets/images/homepage/route_purple_left.svg';
import BlueRouteUp from '../../assets/images/homepage/route_blue_up.png';
import PurpleRouteDown from '../../assets/images/homepage/route_purple_down.png';
import { ReactComponent as AzrieliLogo } from '../../assets/images/homepage/logos/azrieli-foundation_logo_white.svg';
import { ReactComponent as JointLogo } from '../../assets/images/homepage/logos/joint_logo_white.svg';
import LaborLogo from '../../assets/images/homepage/logos/labor_logo_white.png';
import TaasukaLogo from '../../assets/images/homepage/logos/taasuka_logo_white.png';
import SkillIlTrialLogoDesktop from '../../assets/logos/trial/logo_Trial_Main.png'
import SkillIlTrialLogoMobile from '../../assets/logos/trial/Logo_Trial_Main_Mobile.png'
import RetrainLogo from '../../assets/images/homepage/logos/retrain-developedby_logo_white.png';
import SocialSecurityLogo from '../../assets/images/homepage/logos/social_security_white.png';
import SideMenu from '../../assets/icons/reuseables/Menu.svg'

const languageStore = rootStores.LangsStore;
const jobOccupationsStore = rootStores.JobOccupationStore;
const userInfoStore = rootStores.UserInfoStore;
const filterCourseStore = rootStores.FilterStore
const citiesStore = rootStores.CitiesStore;
const appStore = rootStores.AppStore;


interface Props { }
const HomePage: React.FC<Props> = () => {
    // STORES START
    const { chosenModal, setChosenModal } = appStore;
    const { translate } = languageStore;
    const { setUserPath } = userInfoStore;
    const { resetAllFilters } = filterCourseStore;
    const { getCities, cities } = citiesStore;
    const {
        jobOccupations,
        setRecommendedJobOccupation,
        setRecommendedJobOccupations,
    } = jobOccupationsStore;
    // STORES END

    const history = useHistory();

    const [chosenJobOccupation, setChosenJobOccupation] = useState<IJobOccupation | null>(null);

    useEffect(() => {
        getCities();
    }, [jobOccupations, cities, getCities]);

    const moveNextPageLong = () => {
        setRecommendedJobOccupation(null)
        setUserPath(false);
        history.push("/long");
    }

    const moveNextPageShort = () => {
        setUserPath(true);
        setRecommendedJobOccupations([]);
        // setSelectedCourse(null);
        resetAllFilters();
        history.push("/short");
    }

    useEffect(() => {
        chosenJobOccupation && setRecommendedJobOccupation(chosenJobOccupation);
    }, [chosenJobOccupation]);


    return (
        <div className="grid-container">
            {chosenModal === SideBarModals.aboutUs && <AboutTheSystemObserver />}
            {chosenModal === SideBarModals.contactUs && <ContactUsObserver />}
            <div className="blue-cmp__container">
                <img
                    tabIndex={0}
                    src={SideMenu}
                    alt="תפריט צד"
                    className="blue-cmp__side-menu" // MOBILE's ELEMENT ONLY
                    onClick={() => {
                        setChosenModal(SideBarModals.aboutUs);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            setChosenModal(SideBarModals.aboutUs);
                        }
                    }}
                />
                <div className="blue-route">
                    <BlueRouteRight className="blue-route__img-right" />
                    <img src={BlueRouteUp} className="blue-route__img-up" alt="route" />
                </div>
                <div className="blue-cmp__logo-container">
                    <img className="logo-container__logo-desktop" src={SkillIlTrialLogoDesktop} alt="לוגו סקיל IL" />
                    <img className="logo-container__logo-mobile" src={SkillIlTrialLogoMobile} alt="לוגו סקיל IL" />
                </div>
                <div className="blue-cmp__text-container">
                    <h1 className="blue-cmp__title" tabIndex={0}>
                        {languageStore.translate("welcomePage", "header")}&nbsp;
                        <span className="ultra-bolded">
                            {languageStore.translate("welcomePage", "headerBr")}
                        </span>
                    </h1>
                    <span className="blue-cmp__subtitle" tabIndex={0}>
                        <span className="bold">
                            {translate("welcomePage", "description1")}
                        </span>
                        <br />
                        {translate("welcomePage", "description2")}
                    </span>
                    <div className="button-container">
                        <button
                            tabIndex={0}
                            className="cta-button"
                            onClick={moveNextPageLong}
                        >
                            {"בואו נתחיל!"}
                        </button>
                    </div>
                </div>
                <div className="logos-container-desktop">
                    <JointLogo className="single-logo svg" title="לוגו הג'וינט" />
                    <img src={LaborLogo} className="single-logo" alt="לוגו משרד העבודה" />
                    <img src={TaasukaLogo} className="single-logo" alt="לוגו שירות התעסוקה" />
                    <AzrieliLogo className="single-logo svg" title="לוגו קרן עזריאלי" />
                    <img src={SocialSecurityLogo} className="single-logo" alt="לוגו ביטוח לאומי" />
                </div>
            </div>
            <div className="purple-cmp__container">
                <div className="purple-route">
                    <PurpleRouteLeft className="purple-route__img-left" />
                    <img src={PurpleRouteDown} className="purple-route__img-down" alt="route" />
                </div>
                <div className="purple-cmp__text-container">
                    <h2 className="purple-cmp__title" tabIndex={0}>
                        {translate("welcomePage", "flow2Headr")}&nbsp;{translate("welcomePage", "flow2HeadrBr")}!
                    </h2>
                    <h1 className="purple-cmp__subtitle" tabIndex={0}>
                        {"אני רוצה לעבוד"}
                        <br />
                        <span className="ultra-bolded">
                            {"כ..."}
                        </span>
                    </h1>
                    <div className="search-engine__main-container">
                        <SearchEngineCmp
                            data={jobOccupations}
                            moveNextPage={moveNextPageShort}
                            chosenJobOccupation={chosenJobOccupation}
                            setChosenJobOccupation={setChosenJobOccupation}
                        />
                    </div>
                </div>
                <div className="retrain-logo__container-desktop">
                    <img src={RetrainLogo} className="retrain-logo__logo" alt="לוגו ריטריין" />
                </div>
                <div className="logos-container-mobile">
                    <div className="retrain-logo__container-mobile">
                        <img src={RetrainLogo} className="retrain-logo__logo" alt="לוגו ריטריין" />
                    </div>
                    <div className="logos-container-mobile__container">
                        <JointLogo className="single-logo__mobile svg" title="לוגו הג'וינט" />
                        <img src={LaborLogo} className="single-logo__mobile" alt="לוגו משרד העבודה" />
                        <img src={TaasukaLogo} className="single-logo__mobile" alt="לוגו שירות התעסוקה" />
                        <AzrieliLogo className="single-logo__mobile svg" title="לוגו קרן עזריאלי" />
                        <img src={SocialSecurityLogo} className="single-logo__mobile" alt="לוגו ביטוח לאומי" />
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(HomePage)