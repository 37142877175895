import React, { useEffect} from "react"
import { useParams, useHistory } from "react-router-dom"
import UserFetcher from "../../../../Infrastructure/fetchers/UserFetcher";
import rootStores from "../../../../BL/stores/index";

import {
  JOB_OCCUPATION_STORE,
  LANGS_STORE,
  USER_INFO_STORE,
  CITIES_STORE,
  QUESTIONNAIRE_STORE,
  AUTH_STORE,
} from "../../../../BL/consts/stores";

const userInfoStore = rootStores[USER_INFO_STORE];
const authStore = rootStores[AUTH_STORE];
const cityStore = rootStores[CITIES_STORE];

const VlaidFirstSignUpComponent: React.FC = () => {
    const { isUserLogged, setIsUserLogged, user, setUserDetails,logout } = authStore;
    const {
      setDataFromLogin,
      selectedPreviousJobs,
      setSelectedInterestsS,
      selectedInterestsS,
      selectedAge,
      selectedEducationalDegree,
    } = userInfoStore;
    const { setSelectedCity } = cityStore;
   
    const { token }: { token: string } = useParams()
    const history = useHistory()

    useEffect(() => {
      (async function anyNameFunction() {
        const res = await UserFetcher.firtSignUpTokenCheck(token).catch(
          (err) => {
            alert("משהו התשבש נא נסו שנית");
            history.push("/");
          }
        );
        setUserDetails(res.user);
        setDataFromLogin(res.user); // only in user store
        setSelectedCity(res.user.city || null);
        setIsUserLogged(true);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const isDetailsComplete = urlParams.get("isDetailsComplete") == "true";
        isDetailsComplete
          ? history.push("/recommendation-flow")
          : history.push("/");
        // history.push("/")
      })();
    }, []);
    return (
        <div>

        </div>
    )
}

export default VlaidFirstSignUpComponent




