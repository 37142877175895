
import { observer } from "mobx-react";
import { QUESTIONNAIRE_STORE, AUTH_STORE, CITIES_STORE, USER_INFO_STORE } from "../../BL/consts/stores";
import rootStores from "../../BL/stores";
import SaveForAnotherTimeComponent from "./SaveForAnotherTimeComponent";
import ChaptersHeader from "./ChaptersHeader";
import { useEffect } from "react";
import SmileyPageComponent from "./SmileyPageComponent/SmileyPageComponent";
import QuestionsFetcher from "../../Infrastructure/fetchers/QuestionsFetcher";
import SkipQuestionnaire from "./SkipQuestionnaire";
import { useHistory } from "react-router-dom";
import QuestionnaireIntroductionComponent from "./QuestionnaireIntroductionComponent";
import QuestionAnalysisComponent from "./QuestionAnalysisComponent";
import "./HollandQuestionnaire.scss"
import QuestionnaireHeaderTitleText from "./QuestionnaireHeaderTitleText";
import { Helmet } from "react-helmet";
import { DisplayState } from "../../BL/stores/questionnaire.store";


export enum smileyIconsComponentType {
  QuestionnaireProgressbar,
  selectedQuestions,
  introduction,
}
interface Props {
  setLevel: (number: number) => void;
  // goBack: () => void; //this function did not work
}

const HollandQuestionnaire: React.FC<Props> = ({ setLevel }) => {

  const questionsStore = rootStores[QUESTIONNAIRE_STORE];
  const authStore = rootStores[AUTH_STORE];
  const citiesStore = rootStores[CITIES_STORE];
  const userInfoStore = rootStores[USER_INFO_STORE];

  const {
    questions,
    setSelectedQuestion,
    isAllQuestionsAnswer,
    calculateQuestionaire,
    doneOrSkipOrSaveQuestionnaire,
    setDoneOrSkipOrSaveQuestionnaire,
    selectedQuestion,
    displayState,
    setDisplayState,
  } = questionsStore;
  const {previusURLs,setPreviusURLs,previusLevels,addPreviusLevel,removePreviusLevel} = userInfoStore;
  const { isUserLogged } = authStore;

  const history = useHistory();

  useEffect(() => {
    window.onpopstate = function(event) {
      //get the latest level from the array
      let newLevel = previusLevels[previusLevels.length - 1];
      removePreviusLevel();//remove the current level from the array
      
      setLevel(newLevel);  
      history.push(previusURLs[newLevel]);
    }
  }, [])


  useEffect(() => {
    const isStarted = questions.find((question) => { return question.answer !== 0; });

    if (isAllQuestionsAnswer()) {
      setDisplayState(DisplayState.QuestionAnalysis);
      setSelectedQuestion(questions[0])
    }
    else if (isStarted) {
      const selectedQuestion = questions.find((question) => { return question.answer === 0; });
      selectedQuestion && setSelectedQuestion(selectedQuestion);
      setDisplayState(DisplayState.Questionnaire);
      setDoneOrSkipOrSaveQuestionnaire(false);
    }
    else {
      setDoneOrSkipOrSaveQuestionnaire(false);
    }
  }, [questions]);

  useEffect(() => {
    const UnansweredQuestion = questions.find((question) => question.answer === 0)
    if (displayState == DisplayState.Questionnaire) {
      history.push({ search: `state=${displayState}&chapter=${selectedQuestion?.chapter}&type=${selectedQuestion?.type}&city=${citiesStore.selectedCity?.name}&age=${userInfoStore.selectedAge}&educationalDegree=${userInfoStore.selectedEducationalDegree}` })
    }
    // else if(displayState==DisplayState.introduction){
    //   history.push({search:`state=${displayState}&city=${citiesStore.selectedCity?.name}&age=${userInfoStore.selectedAge}&educationalDegree=${userInfoStore.selectedEducationalDegree}`})

    // }
    else {
      history.push({ search: `state=${displayState}&city=${citiesStore.selectedCity?.name}&age=${userInfoStore.selectedAge}&educationalDegree=${userInfoStore.selectedEducationalDegree}` })

    }
  }, [displayState, selectedQuestion])

  const saveForAnotherTimeFunc = () => {
    let previousAnswers: {
      answer: number;
      question: string;
    }[] = [];

    questions.map((q) =>
      previousAnswers.push({
        answer: q.answer!,
        question: q._id,
      })
    );
        
    setPreviusURLs(`${history.location.pathname}${history.location.search}`,2);
    setLevel(3);
    setDoneOrSkipOrSaveQuestionnaire(true);
    history.push("/long/last-thing");
  }

  const saveAnswers = () => {
    let previousAnswers: {
      answer: number;
      question: string;
    }[] = [];
    questions.map((q) =>
      previousAnswers.push({
        answer: q.answer!,
        question: q._id,
      })
    );
    isUserLogged && QuestionsFetcher.updateUserQuestionnaire(previousAnswers);
  }

  const moveBetweenQuestionsSmiley = (direction: string, id: string, chapterNumber?: number) => {
    const selectedQuestionIndex = questions.findIndex(
      (question) => question._id === id
    );

    if (direction === "forward") {
      saveAnswers();
      if (selectedQuestionIndex === questions.length - 1) {
        checkForUnAnsweredQuestion();
      } else {
        const nextQuestion = questions[selectedQuestionIndex + 1];
        setSelectedQuestion(nextQuestion);
      }
    }
    if (direction === "backward") {
      if (selectedQuestionIndex === 0) {
        //setDisplayState(DisplayState.introduction);
        // setSelectedQuestion(previousQuestion);
      } else {
        const previousQuestion = questions[selectedQuestionIndex - 1];
        setSelectedQuestion(previousQuestion);
      }
    }
    if (direction === "chapter" && chapterNumber !== undefined) {
      const UnansweredQuestion = questions.find((question) => question.answer === 0)

      if (UnansweredQuestion?.chapter !== undefined && parseInt(UnansweredQuestion?.chapter) > chapterNumber) {
        const goToQuestion = questions[chapterNumber * 6 - 1];
        setSelectedQuestion(goToQuestion);
      }
      else if (UnansweredQuestion?.chapter !== undefined && parseInt(UnansweredQuestion?.chapter) === chapterNumber) {
        const selectedQuestionIndex = questions.findIndex((question) => question._id === UnansweredQuestion._id);
        const goToQuestion = questions[selectedQuestionIndex];
        setSelectedQuestion(goToQuestion);
      }
    }
  };

  const checkForUnAnsweredQuestion = () => {
    const UnansweredQuestion = questions.find((question) => question.answer === 0)

    if (UnansweredQuestion === undefined) {
      setDisplayState(DisplayState.QuestionAnalysis);
      calculateQuestionaire();
      saveAnswers();
      setSelectedQuestion(questions[0]);
    }
    else {
      alert(window.innerWidth > 767 ? `ישנה שאלה שעדיין לא ענית עליה!\nאנחנו מעבירים אותך בחזרה על מנת לענות עליה, לפני שתוכל/י להמשיך הלאה.\nלחילופין, ניתן לבחור "דלג על השאלון"- בלחצן הממוקם למעלה, או לשמור לפעם אחרת.\nשים/י לב! מומלץ לענות על כל השאלות על מנת לקבל התאמות מדויקות יותר.
            `  : `
            ישנה שאלה שעדיין לא ענית עליה!\nאנחנו מעבירים אותך בחזרה על מנת לענות עליה, לפני שתוכל/י להמשיך הלאה.\nלחילופין, ניתן לבחור "דלג" - בלחצן הממוקם למטה , או לשמור לפעם אחרת , בכפתור "שמירה " שמלעלה.\nשים/י לב! מומלץ לענות על כל השאלות על מנת לקבל התאמות מדויקות יותר.
            `  )

      setSelectedQuestion(UnansweredQuestion)
    }
  }

  const moveNextPage = () => {
    addPreviusLevel(2);
    setDoneOrSkipOrSaveQuestionnaire(true);
    setLevel(3);
    setPreviusURLs(`${history.location.pathname}${history.location.search}`,2);
    history.push("/long/last-thing");
  };

  return (
    <div className="div5-question">
      <Helmet>
        <title>Skill-il - שאלון העדפות</title>
      </Helmet>
      <div style={{ height: "100%" }}>
        <div className="div5-question-header">
          <div className="questionnaire-header-title-container">
            <QuestionnaireHeaderTitleText displayState={displayState} />
          </div>
          {displayState === DisplayState.introduction && (
            <div className="skip-save-container-desktop">
              <SkipQuestionnaire
                moveNextPage={moveNextPage}
                setLevel={setLevel}
              />
            </div>
          )}
          {displayState === DisplayState.Questionnaire && (<div className="questionnaire-header-buttons-container">

            <div className="skip-save-container-desktop">
              <SaveForAnotherTimeComponent
                saveForAnotherTimeFunc={saveForAnotherTimeFunc}
              />
            </div>

            {/* <div className="desktop">
                    <LanguageComponent IsWhite={false} />
                    </div> */}
          </div>)}
        </div>

        {displayState === DisplayState.introduction && (
          <QuestionnaireIntroductionComponent
            setDisplayState={setDisplayState}
            setDoneOrSkipOrSaveQuestionnaire={
              setDoneOrSkipOrSaveQuestionnaire
            }
            moveNextPage={moveNextPage}
            setLevel={setLevel}
          />
        )}
        {displayState === DisplayState.Questionnaire && (
          <div className="questionnaire-smiley-container">
            <div className="questionnaire-container">
              <div>
                <ChaptersHeader
                  moveBetweenQuestionsSmiley={moveBetweenQuestionsSmiley}
                />
              </div>

              <div>
                <SmileyPageComponent
                  displayState={displayState}
                  setDisplayState={setDisplayState}
                  moveBetweenQuestionsSmiley={moveBetweenQuestionsSmiley}
                  doneOrSkipOrSaveQuestionnaire={
                    doneOrSkipOrSaveQuestionnaire
                  }
                  setDoneOrSkipOrSaveQuestionnaire={
                    setDoneOrSkipOrSaveQuestionnaire
                  }
                  saveForAnotherTimeFunc={saveForAnotherTimeFunc}
                />
              </div>
            </div>
          </div>
        )}
        {displayState === DisplayState.QuestionAnalysis && (
          <QuestionAnalysisComponent
            moveNextPage={moveNextPage}
            setLevel={setLevel}
            setQuestionnaireStateToDisplay={setDisplayState}
            setDoneOrSkipOrSaveQuestionnaire={
              setDoneOrSkipOrSaveQuestionnaire
            }
          />
        )}
      </div>
      {displayState === DisplayState.QuestionAnalysis && (
        <p className="information_text_question_analysis">
          This page includes information from the{" "}
          <a href="https://www.onetcenter.org/tools.html">
            O*NET Career Exploration Tools{" "}
          </a>
          by the U.S. Department of Labor, Employment and Training
          Administration (USDOL/ETA). Used under the{" "}
          <a href="/license_tools.html">O*NET Tools Developer License</a>.{" "}
          O*NET&reg; is a trademark of USDOL/ETA. Joint-israel Tevet has
          modified all or some of this information. USDOL/ETA has not
          approved, endorsed, or tested these modifications.
        </p>
      )}
    </div>
  );
};

const HollandQuestionnaireObserver = observer(HollandQuestionnaire);
export default HollandQuestionnaireObserver;