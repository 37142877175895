import React, {useEffect,useState} from 'react'
import laborLogo from "../../assets/logos/sidebar/Labor_Logo_Grey.png";
import taasukaLogo from "../../assets/logos/sidebar/Taasuka_Logo_Grey.png";
import SocialSecurityLogo from "../../assets/logos/sidebar/Social_Security_Logo.png";
// import SocialSecurityLogo from "../../assets/tvt_logo_grey.png";
import { ReactComponent as Logo_Azr_Gray } from "../../assets/Logo_Azr_Gray.svg";
import "./sideBar.scss";

function LogosComponent() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    simulateDelay(); // Call the function to simulate the delay
  }, []);

  const simulateDelay = () => {
    setTimeout(() => {
      // Simulate an asynchronous operation
      setIsLoading(false);
    }, 3000); // Delay time in milliseconds (e.g., 2000 = 2 seconds)
  };
  return (
    
    <div className={"ls-container"}>
    <a
      href={"https://www.thejoint.org.il/"}
      target={"_blank"}
      rel="noreferrer"
    >              
      <img
      
        src={require('../../assets/tvt_logo_grey.png')}
        className="sidebar-logo"
        alt="https://www.thejoint.org.il/"
      />
      
    </a>
    <a
      href={"https://www.gov.il/he/departments/labor"}
      target={"_blank"}
      rel="noreferrer"
    >
      <img
        className="sidebar-logo"
        src={laborLogo}
        alt="לוגו זרוע העבודה"
      />
    </a>
    <a
      href={"https://www.taasuka.gov.il/he"}
      target={"_blank"}
      rel="noreferrer"
    >
      <img
        className="sidebar-logo"
        src={taasukaLogo}
        alt="לוגו התעסוקה"
      />
    </a>

    <a
      href={"https://www.azrielifoundation.org.il/"}
      target={"_blank"}
      aria-label="redirect to azrieli web site"
      rel="noreferrer"
      style={{ height: "auto", marginBottom: "0.8vw" }}
    >
      <Logo_Azr_Gray className="sidebar-logo" />
    </a>
    <a
      href={"https://www.btl.gov.il/Pages/default.aspx"}
      target={"_blank"}
      aria-label="מעבר לאתר ביטוח לאומי"
      rel="noreferrer"
      style={{ height: "auto", marginBottom: "0.8vw" }}
    >
      {/* TODO: REPLACE TO SOCIAL SECURITY'S LOGO */}

      <img
        style={{ maxHeight: '15vh', width: 'auto', height: "100%" }}
        className="sidebar-logo"
        src={SocialSecurityLogo}
        alt="לוגו ביטוח לאומי"
      />
    </a>
  </div>
    
  )
    
}

export default LogosComponent