import React, { useEffect, useMemo, useRef, useState } from "react";
import "./JobOccupationPageComponent.scss";
import rootStores from "../../BL/stores";
import { observer } from "mobx-react-lite";
import { ReactComponent as LoadingCards } from "../../assets/Loading.svg";
import {
  CITIES_STORE,
  JOB_OCCUPATION_STORE,
  USER_INFO_STORE,
  LANGS_STORE,
  FILTER_COURSE_STORE,
  JOB_OCCUPATION_FILTER_STORE,
  QUESTIONNAIRE_STORE,
  AUTH_STORE,
} from "../../BL/consts/stores";
import JobOccupationCard from "./components/card/JobOpucationCard";
import { useHistory } from "react-router-dom";
import { ReactComponent as Refresh } from "../../assets/Results images/Refresh.svg";
import HeaderTextInMobile from "./components/JobOccupationSlideBar/headerTextInMobile/headerTextInMobile";
import { IJobOccupation } from "../../common/interfaces/Job-Occupation";
import { IUserInfoBody } from "../../common/interfaces/User_info.interface";
import Helmet from "react-helmet";
import { toJS } from "mobx";
import _, { create } from "lodash";
import NavigationJobOccupationCard from "./components/Navigation/NavigationJobOccupationCard";
import SideBarSortManager from "./SortManager/SideBarSortManager";
import FirstRowMobileCoursesObsever from "../../common/components/FirstRowMobileCourses/FirstRowMobileCourses";
import Lottie from "lottie-react";
import jumpingBallsLoader from "../../assets/animations/jumpingBallsLoader.json";
import { CommonHeader } from "../../common/components/Header/header.component";
import { ReccomenededJobOccupation } from "../../common/MOCK/reccomendedJobOccupation";

const JobOccupationStore = rootStores[JOB_OCCUPATION_STORE];
const UserInfoStore = rootStores[USER_INFO_STORE];
const CityStore = rootStores[CITIES_STORE];
const languageStore = rootStores[LANGS_STORE];
const filterCourseStore = rootStores[FILTER_COURSE_STORE];
const JobOccupationFilterStore = rootStores[JOB_OCCUPATION_FILTER_STORE];
const questionnaireStore = rootStores[QUESTIONNAIRE_STORE];
const authStore = rootStores[AUTH_STORE];

const JobOccupationPageComponent: React.FC = () => {
  const { IQuestionnaire_info, isAllQuestionsAnswer } = questionnaireStore;
  const { resetAllFilters } = filterCourseStore;
  const { currentLanguageIndex, languages, translate } = languageStore;
  const { selectedCity } = CityStore;
  const { nameToDrop, setNameToDrop } = JobOccupationFilterStore;
  const {
    recommendedJobOccupations,
    setRecommendedJobOccupations,
    setRecommendedJobOccupation,
    fetchRecommendedJobOccupation,
    setCurrentRecommendedJobOccupations,
    currentRecommendedJobOccupations,
  } = JobOccupationStore;
  const {
    isUserRegistered,
    isUserLogged,
    registrationModalSettings,
    getQueriesRegistration,
  } = authStore;
  const {
    selectedEducationalDegree,
    selectedPreviousJobs,
    userPath,
    selectedInterestsS,
    selectedIsEmployee,
    selectedAge,
  } = UserInfoStore;

  enum sortBy {
    salary = 1,
    match = 2,
  }

  const history = useHistory();

  const [navigationIndexCard, setNavigationIndexCard] = useState<number>(0);
  const [isLoadingJOC, setIsLoadingJOC] = useState<boolean>(true);
  const [sortCheckRadio, setSortCheckRadio] = useState<number>(0);
  const [isQuestionMarkClicked, setIsQuestionMarkClicked] = useState<boolean>(false);
  const [selectedQuestionMark, setSelectQuestionMark] = useState<{
    salary: number;
    monthlyHours: number;
    demand: string;
    name: string;
  }>();

  const moveBack = () => {
    history.push(`/long/last-thing`);
  };

  useEffect(() => {
    if (recommendedJobOccupations.length > 0) {
      setSortCheckRadio(sortBy.match);
    }
  }, [recommendedJobOccupations.length]);

  useEffect(() => {
    Validator();
    //handle popstate
    window.onpopstate = function (event) {    
      history.push("/long/last-thing");
    };

    if (recommendedJobOccupations.length == 0) {
      (async function anyNameFunction() {
        const value: IUserInfoBody = {
          categories: selectedInterestsS.map((interest) => interest.id),
          is_employee: selectedIsEmployee === 0 ? false : true,
          gender: languages[currentLanguageIndex].gender,
          age: selectedAge,
          residence_area: selectedCity?.residence_area
            ? selectedCity.residence_area
            : "",
          education_degree: selectedEducationalDegree,
          previous_jobs: selectedPreviousJobs.map((job) => {
            return job.id;
          }),
          questionnaire_info:
            (IQuestionnaire_info.artistic === 0 &&
              IQuestionnaire_info.realistic === 0 &&
              IQuestionnaire_info.investigative === 0 &&
              IQuestionnaire_info.social === 0 &&
              IQuestionnaire_info.enterprising === 0 &&
              IQuestionnaire_info.conventional === 0) ||
              isAllQuestionsAnswer() === false
              ? null
              : {
                realistic: IQuestionnaire_info?.realistic!,
                investigative: IQuestionnaire_info?.investigative!,
                artistic: IQuestionnaire_info?.artistic!,
                social: IQuestionnaire_info?.social!,
                enterprising: IQuestionnaire_info?.enterprising!,
                conventional: IQuestionnaire_info?.conventional!,
              },
        };
        await fetchRecommendedJobOccupation(value);
        resetAllFilters();
        setIsLoadingJOC(false);
      })();
    }
  }, []);

  const previousJobsToQuery = () =>
    selectedPreviousJobs.length > 0
      ? `&previousJobs=${selectedPreviousJobs
        .map((job) => job.male_name)
        .join(",")}`
      : "";

  useEffect(() => {
    setIsLoadingJOC(
      registrationModalSettings.isOpen || recommendedJobOccupations.length == 0
    );
  }, [registrationModalSettings.isOpen, recommendedJobOccupations.length]);

  useEffect(() => {
    if (!ReccomenededJobOccupation) {
      history.push("/");
      return;
    }
    if (!isLoadingJOC)
      history.push({
        search: `num-of-occupations=${recommendedJobOccupations.length
          }${previousJobsToQuery()}&${getQueriesRegistration()}`,
      });
  }, [isLoadingJOC]);

  const recomended_occupation_explnation: string = translate("JobOccupationPage", "recomended_occupation_explnation");

  const get_back_last_occupations: string = translate("JobOccupationPage", "get_back_last_occupations");

  const sortBySalary = () => {
    // BASED ON MATCH PERCENTAGE
    // let aboveMatch = [...toJS(recommendedJobOccupations)].filter(
    //   (obj: any) => obj.match_percentage >= 0.5
    // );
    // let belowMatch = [...toJS(recommendedJobOccupations)].filter(
    //   (obj: any) => obj.match_percentage < 0.5
    // );

    // aboveMatch.sort((a: any, b: any) => b.salary - a.salary);
    // belowMatch.sort((a: any, b: any) => b.salary - a.salary);

    // return [...aboveMatch, ...belowMatch];
    // BASED ON MATCH PERCENTAGE

    return [...toJS(recommendedJobOccupations)].sort((a: any, b: any) => b.salary - a.salary);
  };

  const moveToPage = (
    value: string,
    jobOccupation: IJobOccupation,
    index: number,
    path: boolean
  ) => {
    let temp = value.split("/").join().replace(",", "-");
    setRecommendedJobOccupations(filterdRecommendedJobOccupation);
    setRecommendedJobOccupation(jobOccupation);
    history.push({
      pathname: `/courses-list-of/${temp}`,
      state: { navigationIndexCard, indexJob: index },
    });
  };

  const Validator = () => {
    if (!userPath && !selectedInterestsS.length) {
      alert(`אנא בחר תחומי עניין,}`);
      history.push(`/long`);
      return;
    }

    if (!selectedCity?.name) {
      alert("אנא בחר עיר מגורים");
      history.push(`/long/user-details`);
      return;
    }

    if (selectedAge == null) {
      alert("אנא בחר שנת לידה");
      history.push(`/long/user-details`);
      return;
    }

    if (selectedEducationalDegree === "none") {
      alert("אנא בחר השכלה");
      history.push(`/long/user-details`);
      return;
    }
  };

  const selectedQuestionmare = (
    value: boolean,
    dataObj: {
      salary: number;
      monthlyHours: number;
      demand: string;
      name: string;
    }
  ) => {
    setSelectQuestionMark(dataObj);
    setIsQuestionMarkClicked(value);
  };

  const filterdRecommendedJobOccupation = useMemo(() => {
    let sortedRecommendedJobOccupationD;

    switch (sortCheckRadio) {
      case sortBy.salary:
        sortedRecommendedJobOccupationD = sortBySalary();
        break;
      case sortBy.match:
        sortedRecommendedJobOccupationD = [
          ...toJS(recommendedJobOccupations),
        ].sort((a: any, b: any) => b.match_percentage - a.match_percentage);
        break;
      default:
        sortedRecommendedJobOccupationD = recommendedJobOccupations;
    }

    if (nameToDrop.length > 0) {
      return sortedRecommendedJobOccupationD.filter(
        (jo: IJobOccupation) =>
          nameToDrop.indexOf(jo.male_name) === -1 &&
          jo.female_name &&
          nameToDrop.indexOf(jo.female_name) === -1
      );
    }

    sortedRecommendedJobOccupationD.forEach(
      (rec, index) => (rec.index = index)
    );

    return sortedRecommendedJobOccupationD;
  }, [nameToDrop.length, recommendedJobOccupations, sortCheckRadio]);

  useEffect(() => {
    setNavigationIndexCard(0);
  }, [sortCheckRadio]);
  useEffect(() => {
    setCurrentRecommendedJobOccupations(
      filterdRecommendedJobOccupation
      // .slice(
      //   navigationIndexCard,
      //   navigationIndexCard + 7
      // )
    );
  }, [navigationIndexCard, filterdRecommendedJobOccupation]);

  const refArr = useRef<any>([]);
  refArr.current = [];

  const changeFocus = (element: any) => {
    if (element) {
      refArr.current.push(element);
    }
  };

  const handleNavigationIndexCard = () => {
    refArr.current[0]?.focus();
  };

  return (
    <>
      <Helmet>
        <title>Skill-il - עיסוקים</title>
      </Helmet>
      <>
        <div className="flow2-container-JO">
          <div className="gray-bg-JO"></div>
          <div className="slider-bar-JO">
            <div
              className="navigator-row"
              style={{ flexDirection: "column", marginRight: 0 }}
            >
              <FirstRowMobileCoursesObsever moveBack={moveBack} />
              {isUserRegistered && !isUserLogged && (
                <p className="p-sm-JO sub-title-JO mobile" style={{ color: "#FA7997" }}>
                  {translate("JobOccupationPage", "registration_alert")
                    ? translate("JobOccupationPage", "registration_alert")
                    : "שמנו לב שלא סיימת את תהליך ההרשמה"}
                </p>
              )}
              {
                <HeaderTextInMobile
                  getBackLastOccupations={get_back_last_occupations}
                  filterLength={filterdRecommendedJobOccupation.length}
                  recomendedOccupationExplnation={recomended_occupation_explnation}
                  isLoadingJOC={isLoadingJOC}
                />
              }
            </div>
            {
              <SideBarSortManager
                sortCheckRadio={sortCheckRadio}
                setSortCheckRadio={setSortCheckRadio}
              />
            }
          </div>
          <CommonHeader />
          <div className="JO-cards">
            <>
              <div className="content-JO">
                <div>
                  {isLoadingJOC && (
                    <div className="loader-jobOccupations">
                      <Lottie
                        animationData={jumpingBallsLoader}
                        className="loader-jobOccupations-svg"
                        loop
                      />
                    </div>
                  )}
                  <div className="header-container-desktop-JO">
                    <div className="greetment-and-language-container-JO">
                      <h1
                        className="h1-layout-keren"
                        style={{ color: "#182344" }}
                        tabIndex={1}
                      ></h1>
                      {!isLoadingJOC ? (
                        <h1 className="h1-layout-keren">
                          {`${translate(
                            "JobOccupationPage",
                            "headerSecPart"
                          )} ${filterdRecommendedJobOccupation.length
                            } ${translate(
                              "JobOccupationPage",
                              "headerFirstPart"
                            )}`}
                        </h1>
                      ) : (
                        <span className="loading-text-in-JO bolded">
                          המערכת מעבדת את הנתונים שלך ומוצאת מסלולי הכשרה
                          ותעסוקה
                        </span>
                      )}
                      <h1 className="h1-layout-keren bolded">
                        {translate(
                          "JobOccupationPage",
                          "hedearBr"
                        )}
                      </h1>
                    </div>
                    <div className="last-line-header-container">
                      <h5
                        tabIndex={1}
                        style={
                          !isLoadingJOC
                            ? { visibility: "visible", flex: 2 }
                            : { visibility: "hidden" }
                        }
                      >
                        {recomended_occupation_explnation}
                      </h5>
                      <button
                        onClick={() => setNameToDrop([])}
                        tabIndex={1}
                        className="get_back_opuucations"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setNameToDrop([]);
                          }
                        }}
                      >
                        <p className="pointer">
                          {get_back_last_occupations}&nbsp;
                        </p>
                        <Refresh className="refresh-svg" />
                      </button>
                    </div>
                  </div>
                  <div className={"cards-container-JO"}>
                    {!isLoadingJOC && filterdRecommendedJobOccupation
                      ? currentRecommendedJobOccupations.map(
                        (job_occupation, index) => {
                          return (
                            <div ref={changeFocus} tabIndex={1} key={index}>
                              <JobOccupationCard
                                id={`JobOccupationCard${index}`}
                                key={index}
                                moveToPage={moveToPage}
                                match_percentage={
                                  job_occupation.match_percentage
                                }
                                description={job_occupation.description}
                                name={
                                  currentLanguageIndex === 1
                                    ? job_occupation.female_name
                                    : job_occupation.male_name
                                }
                                course_count={job_occupation.course_count}
                                demand={job_occupation.demand}
                                monthlyHours={
                                  job_occupation.average_working_hours!
                                }
                                salary={job_occupation.salary}
                                setIsQuestionMarkClicked={
                                  selectedQuestionmare
                                }
                                isQuestionMarkClicked={isQuestionMarkClicked}
                                selectedQuestionMark={selectedQuestionMark}
                                job_occupation={job_occupation}
                                navigationIndexCard={navigationIndexCard}
                              />
                            </div>
                          );
                        }
                      )
                      : null
                      // [...Array(7)].map((e, index) => {
                      //   return (
                      //     <div
                      //       className="JOC-opucation-card-container"
                      //       key={index}
                      //     >
                      //       <LoadingCards
                      //         style={{ width: "100%", height: "100%" }}
                      //       />
                      //     </div>
                      //   );
                      // })
                      }
                    {/* {!isLoadingJOC && (
                      <div className="navigation-job-occupation-card-component-container">
                        <NavigationJobOccupationCard
                          navigationIndexCard={navigationIndexCard}
                          setNavigationIndexCard={setNavigationIndexCard}
                          totalAmount={filterdRecommendedJobOccupation.length}
                          handleNavigationIndexCard={handleNavigationIndexCard}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              <p className="text__statistics-disclaimer">
                המידע הסטטיסטי בדף זה מבוסס על עיבודי זרוע העבודה לנתוני הלמ"ס
                משנת 2019
                <br />
                נתוני השכר בתחום אינם כוללים אקדמאים
              </p>
            </>
          </div>
        </div>
      </>
    </>
  );
};

const JobOccupationPageComponentObsever = observer(JobOccupationPageComponent);

export default JobOccupationPageComponentObsever;
