import {
  action,
  IObservableArray,
  makeAutoObservable,
  observable,
  toJS,
} from "mobx";
import interests from "./../../UI/interesetsPage/interestsIcon";

import { IInterest } from "../../common/interfaces/Interest";
import { IJobOccupation } from "../../common/interfaces/Job-Occupation";
import UserFetcher from "../../Infrastructure/fetchers/UserFetcher";
import { RootStore } from ".";

export enum Degrees {
  none,
  highSchoolStudent,
  highSchoolDiploma,
  collegeDegree,
  professionalCertificate,
}
class UserInfoStore {

  @observable
  isLoading: boolean;

  @observable
  userPath: boolean;

  @observable
  popUpTriggerForBack: boolean
  // const [popUpTrigger, setPopUpTrigger] = useState<boolean>(false);

  @observable
  selectedPreviousJobs: IObservableArray<IJobOccupation>;

  // @observable
  selectedAge: number;

  // @observable
  selectedEducationalDegree: string;

  // @observable
  // selectedInterestsS: IObservableArray<string>;
  selectedInterestsS: IObservableArray<{ id: number; name: string }>;

  // @observable
  selectedIsEmployee: number | null;

  // @observable
  isAgreeToTerms: boolean;

  email: string;

  @observable
  isFeedbackPopupOpen: boolean;
  getCoursePageClicks: number;
  previusURLs: Array<string>;//this is for the back button - will save the previus urls before url change
  previusLevels: Array<number>;//this is for the back button - will save the previus level before url change
  private _rootStore: RootStore
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
    this.isLoading = true;
    this.isFeedbackPopupOpen = false;
    this.getCoursePageClicks = 0;
    this.checked = 0;
    this.userPath = false;
    this.selectedPreviousJobs = observable([]);
    this.selectedAge = 0
    // age != null ? (this.selectedAge = parseInt(age)) : (this.selectedAge = 0);
    this.selectedEducationalDegree = Degrees[0];
    //   : Degrees[0];
    this.selectedInterestsS = observable([]);
    this.selectedIsEmployee = null;
    this.popUpTriggerForBack = true;
    this.isAgreeToTerms = false;
    this.email = '';
    this.previusURLs = ["","","",""];//this array will save the previus urls before url change
    this.previusLevels = [];//this array will save the previus level of slide progress bar before url change
  }

  @action
  setIsFeedbackPopupOpen = (value: boolean) => {
    this.isFeedbackPopupOpen = value;
  }

  @action
  addOneClickToCoursePage = () => {
    this.getCoursePageClicks++;
  }

  @action
  setEmail = (value: string) => {
    this.email = value;
  }

  @action
  setpopUpTriggerForBack = (value: boolean) => {
    this.popUpTriggerForBack = value;
  }


  @action
  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  @action
  setUserPath = (value: boolean) => {
    this.userPath = value;
  };

  @action
  setIsAgreeToTerms = (value: boolean) => {
    this.isAgreeToTerms = value;
  };

  @action
  setSelectedEmployee = (selectedEmployee: number | null) => {
    this.selectedIsEmployee = selectedEmployee;
  };

  @action
  setSelectedInterestsS = async (interests: { id: number; name: string }[], isUserLogged: boolean) => {

    this._setSelectedInterestsS(interests);
    const data = {
      interests: interests.map((interest: IInterest) => interest.id),
      page: 1,
    }
    if (isUserLogged) {
      await UserFetcher.update(data)
    }
  };
  @action
  _setSelectedInterestsS = (interests: { id: number; name: string }[]) => {

    this.selectedInterestsS.replace(interests);

  };

  // @action
  // clearLocalStorage=()=>{
  //   const auth_token=localStorage.getItem("auth_token");
  //   const refresh_token=localStorage.getItem("refresh_token");
  //   localStorage.clear()
  //   auth_token&&localStorage.setItem("auth_token", auth_token);
  //   refresh_token&&localStorage.setItem("refresh_token", refresh_token);
  // }

  @action
  _setSelectedPreviousJobs = (previousJobs: Array<IJobOccupation>) => {
    this.selectedPreviousJobs.replace(previousJobs);
  };
  @action
  setSelectedPreviousJobs = async (previousJobs: Array<IJobOccupation>, isUserLogged: boolean) => {
    const currentPreviousJobs = previousJobs[0] === undefined ? [] : previousJobs;
    const data = {
      previous_jobs: toJS(currentPreviousJobs),
      page: 3,
    };
    if (isUserLogged) {
      await UserFetcher.update(data)
    }

    this._setSelectedPreviousJobs(currentPreviousJobs);
  };

  @action
  reSendVerificationMail = async () => {
    try {
      await UserFetcher.reSendVerificationMail(this.email);
      alert("מייל נשלח בהצלחה")
    } catch (err) {
      alert("הייתה בעייה בשליחת המייל, נסו שוב")
    }

  }

  @action
  setSelectedAge = (selectedAge: number): void => {
    this.selectedAge = selectedAge;
  };

  checked: number
  @action
  setChecked = (value: number | string) => {
    if (typeof (value) === "number") {
      this.checked = value;
    }
    else {
      switch (value) {
        case "highSchoolStudent":
          this.checked = 1
          break;
        case "highSchoolDiploma":
          this.checked = 2
          break;
        case "collegeDegree":
          this.checked = 3
          break;
        case "professionalCertificate":
          this.checked = 4
          break;
        default:
          this.checked = 0
          break;
      }
    }
  }

  @action
  setSelectedEducationalDegree = (
    selectedEducationalDegree: number | string
  ): void => {
    if (typeof (selectedEducationalDegree) === "number")
      this.selectedEducationalDegree = Degrees[selectedEducationalDegree];
    else {
      this.selectedEducationalDegree = selectedEducationalDegree
    }
  };

  @action
  setDataFromLogin = (value: any): void => {
    if (value) {
      this.setSelectedAge(value.age || 0);
      value.previous_jobs && this._setSelectedPreviousJobs(value.previous_jobs);
      if (value.interests.length >= 1) {
        let arr: Array<any> = new Array<any>();

        for (let interst of interests) {
          for (let i: number = 0; i < value.interests.length; i++) {
            if (value.interests[i] === interst.id)
              arr.push(interst)
          }
        }
        this._setSelectedInterestsS(arr);
      }
      value.is_employee && this.setSelectedEmployee(value.is_employee === "true" ? 1 : 0)
      this.setChecked(value.education_degree) // for the educationalDegree checkbox
      this.setSelectedEducationalDegree(value.education_degree)
    }
  }

  @action
  setPreviusURLs = (value: string, index:number) => {
    this.previusURLs[index] = value;
  }

  @action
  addPreviusLevel = (value: number) => {
    //chack if the last level is the same as the current level
    if (this.previusLevels[this.previusLevels.length - 1] !== value) {
      this.previusLevels.push(value);
    }
  }
  @action
  removePreviusLevel = () => {
    //every time the user go back we remove the last level in the array
    this.previusLevels.pop();
  }

  @action
  resetPreviusLevels = () => {
    this.previusLevels = [];
  }

  @action
  clearData = (): void => {
    this.isLoading = true;
    this.checked = 0
    this.userPath = false;
    this.selectedPreviousJobs = observable([]);
    this.selectedAge = 0
    this.selectedEducationalDegree = Degrees[0];
    this.selectedInterestsS = observable([]);
    this.selectedIsEmployee = null;
    this.popUpTriggerForBack = true;
    this.isAgreeToTerms = false;
    this.email = '';
    this.previusURLs = [];
    this.previusLevels = [];
  }



}

export default UserInfoStore;
