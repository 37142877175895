import React, { useEffect, useMemo, useRef } from "react";
import "./LeftSideCoursePage.scss";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import jumpingBallsLoader from "../../../assets/animations/jumpingBallsLoader.json";
import rootStores from "../../../BL/stores";
import { ICourse } from "../../../common/interfaces/Course";
import { IJobOccupation } from "../../../common/interfaces/Job-Occupation";
import CardCourseComponentObserver from "./CardComponent/CardCourseComponent";
import FilterRow from "./FilterRow/FilterRow";
import { ReactComponent as BackArrow } from "../../../assets/images/BackArrow.svg";
import { ReactComponent as GoArrow } from "../../../assets/Arrow_L.svg";
import { toJS } from "mobx";
interface IProps {
  setFilterRequirements?: (value: Array<string>) => void;
  filterRequirements?: string[];
  moveToCoursePage: (name: string, course: ICourse, courses: ICourse[]) => void;
  setIsLoading: (bol: boolean) => void;
  isLoading: Boolean;
  allFilterRequirements: string[];
  nextJobOccupation: IJobOccupation;
  findNextObject: (job_occupation_rec: IJobOccupation) => void;
  findLastObject: (job_occupation_rec: IJobOccupation) => void;
  lastJobOccupation: IJobOccupation;
  MoveBackClickOnTitle: () => void;
}

const filterStore = rootStores.FilterStore;
const courseStore = rootStores.CourseStore;
const jobOccupation = rootStores.JobOccupationStore;
const languageStore = rootStores.LangsStore;

const LeftSideCoursePage: React.FC<IProps> = ({
  nextJobOccupation,
  lastJobOccupation,
  filterRequirements,
  setFilterRequirements,
  moveToCoursePage,
  isLoading,
  allFilterRequirements,
  findNextObject,
  findLastObject,
}) => {
  const { coursesByJobOccupation } = courseStore;
  const { translate, currentLanguageIndex } = languageStore;
  const { recommendedJobOccupation, currentRecommendedJobOccupations, setCurrentCourseCount } = jobOccupation;
  const {
    courseTime,
    setCourseTime,
    durationOfCourse,
    setDurationOfCourse,
    locationOfCourse,
    setLocationOfCourse,
  } = filterStore;

  const history = useHistory();
  const { name }: { name: string } = useParams();
  const focusOnHOne = useRef<null | HTMLDivElement>(null)
  const moveBack = () => {
    history.goBack();
  };

  function textWrap(text: any, length: number) {
    if (text.length >= length) return text.split(0, length - 1) + "...";
    return text;
  }

  const orderByEmploymentGuaranteed = (a: ICourse, b: ICourse) => {
    const isEqual = a.employment_guaranteed === b.employment_guaranteed;

    if (isEqual && a.employment_guaranteed === true) {
      return a.budgeting > b.budgeting ? 1 : -1;
    }
    else if (isEqual && a.employment_guaranteed === false) {
      return a.budgeting < b.budgeting ? 1 : -1;
    }
    else {
      return a.employment_guaranteed < b.employment_guaranteed ? 1 : -1;
    }
  }

  const sortByDistance = (a: ICourse, b: ICourse) => {
    if (a.distance === null) return 1;
    else if (b.distance === null) return -1;
    else if (a.distance < b.distance) return -1;
    else if (a.distance > b.distance) return 1;
    else return 0;
  }

  const sortByLabels = (a: ICourse, b: ICourse) => {
    // תעודה ממשלתית + סבסוד + השמה
    if (a.diploma && a.budgeting && a.employment_guaranteed && !(b.diploma && b.budgeting && b.employment_guaranteed)) return -1;
    else if (!(a.diploma && a.budgeting && a.employment_guaranteed) && !b.diploma && b.budgeting && b.employment_guaranteed) return 1;

    // תעודה ממשלתית + השמה
    if (a.diploma && a.employment_guaranteed && !(b.diploma && b.employment_guaranteed)) return -1;
    else if (!(a.diploma && a.employment_guaranteed) && b.diploma && b.employment_guaranteed) return 1;

    // תעודה ממשלתית + סבסוד
    if (a.diploma && a.budgeting && !(b.diploma && b.budgeting)) return -1;
    else if (!(a.diploma && a.budgeting) && b.diploma && b.budgeting) return 1;

    // תעודה ממשלתית
    if (a.diploma && !b.diploma) return -1;
    else if (!a.diploma && b.diploma) return 1;

    // סבסוד + השמה
    if (a.employment_guaranteed && a.budgeting && !(b.employment_guaranteed && b.budgeting)) return -1;
    else if (!(a.employment_guaranteed && a.budgeting) && b.employment_guaranteed && b.budgeting) return 1;

    // השמה
    if (a.employment_guaranteed && !b.employment_guaranteed) return -1;
    else if (!a.employment_guaranteed && b.employment_guaranteed) return 1;

    // סבסוד
    if (a.budgeting && !b.budgeting) return -1;
    else if (!a.budgeting && b.budgeting) return 1;

    else return 0;
  }

  const filterMemo = useMemo(() => {
    const filteredData = coursesByJobOccupation.filter((course: ICourse) => {
      return (
        (!courseTime || course.course_format === courseTime) &&
        (!durationOfCourse || course.duration.months! <= durationOfCourse) &&
        (!locationOfCourse || course.distance! < 25) &&
        (course.basic_requirements.length === 0 ||
          (filterRequirements &&
            course.basic_requirements.every((r) =>
              filterRequirements.includes(r)
            )))
      );
    })

    return filteredData;
    //all filters
    // if (
    //     courseTime !== "" &&
    //     durationOfCourse &&
    //     locationOfCourse &&
    //     //
    //     filterRequirements !== undefined &&
    //     filterRequirements.length > 0
    //   ) {
    //     return coursesByJobOccupation.filter(
    //       (course: ICourse) =>
    //         course.course_format === courseTime &&
    //         //  ||course.course_format === null
    //         course.duration.months! <= durationOfCourse &&
    //         course.distance! < 25 &&
    //         (course.basic_requirements.length === 0 ||
    //           course.basic_requirements.some((r) =>
    //             filterRequirements.includes(r)
    //           ))
    //     );
    //   }

    //
    // if (courseTime !== "" && durationOfCourse && locationOfCourse) {
    //   return coursesByJobOccupation.filter(
    //     (course: ICourse) =>
    //       course.course_format === courseTime &&
    //       //  ||
    //       // course.course_format === null
    //       course.duration.months! <= durationOfCourse &&
    //       course.distance! < 25&&course.basic_requirements.length === 0 
    //   );
    // }
    // if (
    //   courseTime !== "" &&
    //   durationOfCourse &&
    //   //
    //   filterRequirements !== undefined &&
    //   filterRequirements.length > 0
    // ) {

    //   return coursesByJobOccupation.filter(
    //     (course: ICourse) =>
    //       course.course_format === courseTime &&
    //       //  ||
    //       // course.course_format === null
    //       course.duration.months! <= durationOfCourse &&
    //       (course.basic_requirements.length === 0 ||
    //         course.basic_requirements.some((r) =>
    //           filterRequirements.includes(r)
    //         ))
    //   );
    // }
    // if (
    //   locationOfCourse &&
    //   durationOfCourse &&
    //   filterRequirements !== undefined &&
    //   filterRequirements.length > 0
    // ) {

    //   return coursesByJobOccupation.filter(
    //     (course: ICourse) =>
    //       course.duration.months! <= durationOfCourse &&
    //       course.distance! < 25 &&
    //       (course.basic_requirements.length === 0 ||
    //         course.basic_requirements.some((r) =>
    //           filterRequirements.includes(r)
    //         ))
    //   );
    // }

    // if (durationOfCourse && courseTime !== "") {
    //   return coursesByJobOccupation.filter(
    //     (course: ICourse) =>
    //       course.duration.months! <= durationOfCourse &&
    //       course.course_format === courseTime
    //     //  || course.course_format === null
    //   );
    // }
    // if (durationOfCourse && locationOfCourse) {
    //   return coursesByJobOccupation.filter(
    //     (course: ICourse) =>
    //       course.duration.months! <= durationOfCourse && course.distance! < 25
    //   );
    // }
    // if (
    //   durationOfCourse &&
    //   filterRequirements !== undefined &&
    //   filterRequirements.length > 0
    // ) {
    //   return coursesByJobOccupation.filter(
    //     (course: ICourse) =>
    //       course.duration.months! <= durationOfCourse &&
    //       (course.basic_requirements.length === 0 ||
    //         course.basic_requirements.some((r) =>
    //           filterRequirements.includes(r)
    //         ))
    //   );
    // }

    // const filteredCourses= coursesByJobOccupation.filter(
    //   (course: ICourse) =>
    //   course.duration.months! <= durationOfCourse &&
    //   course.basic_requirements.length === 0
    //   );
    // return filteredCourses;

  }, [
    coursesByJobOccupation.length > 0,
    courseTime,
    locationOfCourse,
    durationOfCourse,
    filterRequirements?.length,
  ]);

  useEffect(() => {
    setCurrentCourseCount(filterMemo.length)
  }, [filterMemo.length])

  return (
    <>
      <div className="container-courses-list-pages">
        <div className="header-courses-list">
          <div ref={focusOnHOne} tabIndex={-1} className="header-container-right-side-courses-page">
            <div className="description-row-courses-page">
              {isLoading ? (
                <div
                  style={{
                    width: "10vw",
                    maxHeight: "3rem",
                    marginTop: "-3.5vw",
                    marginBottom: "3.5vw",
                    marginRight: "-3vw",
                  }}
                >
                  <Lottie
                    animationData={jumpingBallsLoader}
                    style={{
                      top: 0,
                      zIndex: 1,
                      width: "100%",
                      background: "transparent",
                      height: "100%",
                    }}
                    loop
                  />
                </div>
              ) : (
                <h1 tabIndex={0} className="h1-layout">
                  {"נמצאו "}{filterMemo.length}{" "}
                  {translate("CoursesByJobOccupationPage", "headerBr")}
                </h1>
              )}
            </div>
            <h1 tabIndex={0} className="h1-layout ultra-bolded">
              {"ל"}
              {recommendedJobOccupation
                ? currentLanguageIndex === 1
                  ? recommendedJobOccupation!.female_name
                  : recommendedJobOccupation!.male_name
                : null}
              {
                recommendedJobOccupation?.parent_name ? ` - ${recommendedJobOccupation?.parent_name}` : null
              }
            </h1>
          </div>
        </div>
        <div className="filtered-row-courses">
          {isLoading && (
            <div style={{ width: "100%", height: "1rem" }}>
              <div
                className="lottie-in-mobile"
                style={{
                  width: "100%",
                  maxHeight: "5rem",
                  height: "20%",
                  right: "0%",
                  left: "0%",
                  position: "absolute",
                  marginTop: "-5vw",
                  marginBottom: "3.5vw",
                }}
              >
                <Lottie
                  animationData={jumpingBallsLoader}
                  style={{
                    top: 0,
                    zIndex: 1,
                    width: "100%",
                    alignItems: "center",
                    background: "transparent",
                    height: "100%",
                  }}
                  loop
                />
              </div>
            </div>
          )}
          <FilterRow
            allFilterRequirements={allFilterRequirements}
            setDurationOfCourse={setDurationOfCourse}
            locationOfCourse={locationOfCourse}
            setLocationOfCourse={setLocationOfCourse}
            setCourseType={setCourseTime}
            setFilterRequirements={setFilterRequirements}
            filterRequirements={filterRequirements}
          />
        </div>
        <div className="content-row-courses-page">
          {isLoading ? (
            <p>טוען....</p>
          ) : (
            filterMemo
              .sort(sortByDistance)
              .sort(sortByLabels)
              .map((course, index) => (
                <CardCourseComponentObserver
                  key={index}
                  filterMemo={filterMemo}
                  moveToCoursePage={moveToCoursePage}
                  index={index}
                  course={course}
                />
              ))
          )}
        </div>
        {isLoading && currentRecommendedJobOccupations !== undefined ? (
          ""
        ) : (
          <div className="footer-courses-page-arrows">
            {lastJobOccupation !== undefined ? (
              <button
                className={"container-previous14"}
                role={"button"}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    findLastObject(lastJobOccupation);
                  }
                }}
              >
                <button style={{ border: "0px", margin: "0px", padding: "0px", background: "transparent" }} onClick={() => findLastObject(lastJobOccupation)}>
                  <BackArrow className="back-arrow-crouse-page" />
                </button>
                <div className="text-of-next-privous-arrow">
                  <p className="backText-arrow" onClick={() => findLastObject(lastJobOccupation)}>
                    {translate("CoursesByJobOccupationPage", "backArrow")}
                  </p>
                  <p className="arrow-text14  pointer" onClick={() => findLastObject(lastJobOccupation)}>
                    {textWrap(
                      currentLanguageIndex === 1
                        ? lastJobOccupation.female_name
                        : lastJobOccupation.male_name,
                      10
                    )}
                  </p>
                </div>
              </button>
            ) : (
              ""
            )}
            {nextJobOccupation !== undefined ? (
              <button
                className="container-next14"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    findNextObject(nextJobOccupation);
                  }
                }}
              >
                <div className="text-of-next-go-arrow">
                  <p className="goText-arrow pointer" onClick={() => findNextObject(nextJobOccupation)}>
                    {translate("CoursesByJobOccupationPage", "nextArrow")}
                  </p>
                  <p
                    className="goText-name pointer"
                    onClick={() => findNextObject(nextJobOccupation)}
                  >
                    {textWrap(
                      currentLanguageIndex === 1
                        ? nextJobOccupation.female_name
                        : nextJobOccupation.male_name,
                      10
                    )}
                  </p>
                </div>
                <button style={{ border: "0px", margin: "0px", padding: "0px", background: "transparent" }} onClick={() => findNextObject(nextJobOccupation)}>
                  <GoArrow className="back-arrow-crouse-page" />
                </button>
              </button>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
};

const LeftSideCoursePageObserver = observer(LeftSideCoursePage);
export default LeftSideCoursePageObserver;