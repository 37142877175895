import React, { useState } from "react";
import "./FilterRow.scss";
import Dropdown_arrow from "../../../../assets/images/Dropdown_arrow.png";
import CheckBoxComponent from "../../components/CheckBoxComponent/CheckBoxComponent";
import ClickForLocation from "../../components/ClickForLocation/ClickForLocation";
import CourseTimeFilter from "../../components/CourseTimeFilter/CourseTimeFilter";
import BtnFilterRequirement from "../../components/BtnFilterRequirement/BtnFilterRequirement";
import Image from "../../../../common/components/image.component";
interface IProps {
  setCourseType: (value: string) => void;
  setLocationOfCourse: (boolean: boolean) => void;
  locationOfCourse: boolean;
  setDurationOfCourse: (range: number) => void;
  setFilterRequirements?: (value: string[]) => void;
  filterRequirements?: Array<string>;
  allFilterRequirements: string[];
}

const FilterRow: React.FC<IProps> = ({
  setCourseType,
  setLocationOfCourse,
  locationOfCourse,
  setDurationOfCourse,
  setFilterRequirements,
  filterRequirements,
  allFilterRequirements,
}) => {

  const [openTimeFilter, setOpenTimeFilter] = useState<boolean>(false);

  return (
    <div className="filters-in-mobile">
      <div className="filter-inside-container">
        <div className="checkbox-time-for-day">
          <CheckBoxComponent setCourseType={setCourseType} />
        </div>
        <div className="filter-by-location">
          <ClickForLocation
            locationOfCourse={locationOfCourse}
            setLocationOfCourse={setLocationOfCourse}
          />
        </div>
        <div className="time-of-course-container">
          <CourseTimeFilter setDurationOfCourse={setDurationOfCourse} />
        </div>

        <div className="time-of-course-container-JO">
          <p
            className="text-of-filter p-md-JO"
            onClick={() => setOpenTimeFilter(!openTimeFilter)}
          >
            משך ומועד
          </p>
          <span
            className="dropDown-time-of-courses"
            onClick={() => setOpenTimeFilter(!openTimeFilter)}
          >
            <Image src={Dropdown_arrow} />
          </span>
        </div>

        <div
          className="btn-for-filter-requirement"
          style={{
            // visibility: allFilterRequirements.length > 0 ? undefined : "hidden",
            opacity: allFilterRequirements.length > 0 ? undefined : 0.5,
            cursor: allFilterRequirements.length > 0 ? "pointer" : "default",
          }}
        >
          <BtnFilterRequirement
            allFilterRequirements={allFilterRequirements}
            filterRequirements={filterRequirements}
            setFilterRequirements={setFilterRequirements}
          />
        </div>
      </div>
      <div
        className="open-time-fiter-in-mobile"
        style={!openTimeFilter ? { display: "none" } : { display: "flex" }}
      >
        <p
          style={{ margin: "auto", paddingBottom: "6%" }}
          className="filters-title-in-mobile"
        >
          משך{" "}
        </p>
        <CourseTimeFilter setDurationOfCourse={setDurationOfCourse} />
      </div>

      <div
        className="checkbox-time-for-day-in-mobile"
        style={!openTimeFilter ? { display: "none" } : { display: "flex" }}
      >
        <p className="filters-title-in-mobile">מועד </p>
        <CheckBoxComponent setCourseType={setCourseType} />
      </div>
    </div>
  );
};

export default FilterRow;
