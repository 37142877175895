import {
  action,
  IObservableArray,
  makeAutoObservable,
  observable,
} from "mobx";
import { RootStore } from ".";
import SortingUtils from "../../common/utils/sortingUtils";
import ReturnCity from "../classes/city.json";
export interface ICity {
  _id: string;
  name: string;
  residence_area: string;
}
class CityStore {
  private _rootStore:RootStore
  constructor(rootStore:RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
    this.getCities();
    this.selectedCity = null;
  }
  cities: IObservableArray<{
    _id: string;
    name: string;
    residence_area: string;
  }> = observable([]);

  selectedCity: ICity | null

  @action
  setSelectedCity = (selectedCity: ICity | null): void => {
    this.selectedCity = selectedCity;
  };
  // @action
  // setSelectedCityLocal = (selectedCity: string|null): void => {
  //   if(selectedCity!=null)
  // };
  // @action
  // getSelectedCity = () => {
  //   foundCity !== undefined
  //     ? this.setSelectedCity(foundCity)
  //     : this.setSelectedCity(null);
  //   foundCity !== undefined
  //     ? this.setSelectedCityLocal(foundCity.name)


  // };

  // @action

  //Should run on statrup
  @action
  getCities = async (): Promise<void> => {
    try {
      this.cities.replace([...ReturnCity()]);
      this.sortCities()

    } catch (err) {
      throw err;
    }
  };

  @action
  sortCities = (): void => {
    const sortingCities = SortingUtils.cityBubbleSort(this.cities)
    this.cities.replace(sortingCities)
  }
}

export default CityStore;
