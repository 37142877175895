import { action, IObservableArray, makeAutoObservable, observable} from "mobx";
import { RootStore } from ".";

// import  from '../stores'
class JobOccupationFiltersStore {
  demendFilterBy: string;
  salaryFilterBy: number;
  minSalaryFilterBy: number;
  maxSalaryFilterBy: number;
  silderBarValueOne: number;
  silderBarValueTwo: number;
  silderBarValueThree: number;
  activeFilter: string;
  nameToDrop: IObservableArray<string>;

  private _rootStore:RootStore
  constructor(rootStore:RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
    this.demendFilterBy = "";
    this.salaryFilterBy = 0;
    this.minSalaryFilterBy = 0;
    this.maxSalaryFilterBy = 0;
    this.silderBarValueOne = 0;
    this.silderBarValueTwo = 0;
    this.silderBarValueThree = 0;
    this.activeFilter = "";
    this.nameToDrop = observable<string>([]);
  }

  @action
  resetAllFilters = () => {
    this.demendFilterBy = "";
    this.salaryFilterBy = 0;
    this.minSalaryFilterBy = 0;
    this.maxSalaryFilterBy = 0;
    this.silderBarValueOne = 0;
    this.silderBarValueTwo = 0;
    this.silderBarValueThree = 0;
    this.activeFilter = "";
  };

  @action
  setNameToDrop = (value: string[]) => {
    this.nameToDrop.replace(value);
  };

  @action
  setDemendFilterBy = (demand: number) => {    
    switch (demand) {
      case 1:
        this.demendFilterBy = "low";
        break;
      case 2:
        this.demendFilterBy = "medium";
        break;
      case 3:
        this.demendFilterBy = "high";
        break;
      case 4:
        this.demendFilterBy = "";
        break;
      default:
        this.demendFilterBy = "";
    }
  };

  @action
  setSalaryFilterBy = (salary: number) => {
    this.salaryFilterBy = salary;
  };

  @action
  setMinSalaryFilterBy = (salary: number) => {
    this.minSalaryFilterBy = salary;
  };

  @action
  setMaxSalaryFilterBy = (salary: number) => {
    this.maxSalaryFilterBy = salary;
  };

  @action
  setSilderBarValueOne = (silderBarValueOne: number) => {
    this.silderBarValueOne = silderBarValueOne;
  };
  @action
  setSilderBarValueTwo = (silderBarValueTwo: number) => {
    this.silderBarValueTwo = silderBarValueTwo;
  };
  @action
  setSilderBarValueThree = (silderBarValueThree: number) => {
    this.silderBarValueThree = silderBarValueThree;
  };

  @action
  setActiveFilter = (active_filter: string) => {
    this.activeFilter = active_filter;
  };
}
export default JobOccupationFiltersStore;
