export const names = {
    computers:"תכנות וסייבר",
    manufacturing:"ייצור מתקדם ותעשיה",
    logistic:"רכש ולוגיסטיקה",
    design:"עיצוב ואומנות",
    building:"בניה ואחזקה",
    health:"בריאות וטיפוח אישי",
    finance:"ביטוח ופיננסים",
    office:"משרד ותמיכה",
    education:"הדרכה חינוך וייעוץ",
    tourism:"תיירות,מלונאות וטבחות",
    qa:"מערכות מידע וQA",
    sales:"שיווק פרסום ודיגיטל",
    drive:"רכב/נהיגה",
    mange:"ניהול"

}