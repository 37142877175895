import React, { useState } from "react";
import { observer } from "mobx-react";
import "./SideBarSortManager.scss";

interface IProps {
  sortCheckRadio: number;
  setSortCheckRadio: any;
}

const SideBarSortManager: React.FC<IProps> = ({
  sortCheckRadio,
  setSortCheckRadio,
}) => {
  const [check, setCheck] = useState<number>(1);

  const handleCheckBox = (value: number) => {
    setCheck(value);
    setSortCheckRadio(value);
  };

  return (
    <div className="sort-manager-container">
      <p className="sort-title">{"מיון ההתאמות לפי: "}</p>
      <button
        className="sort-radio-container"
        onClick={() => {
          handleCheckBox(1);
        }}>
        <input
          id="sort-radio"
          tabIndex={-1}
          className="sort-radio-btn"
          value={1}
          type="radio"
          checked={sortCheckRadio === 1}
          onClick={(e) => {
            handleCheckBox(Number(e.currentTarget.value));
          }}
        />
        <p className="sort-radio-text">{"שכר (מהגבוה לנמוך)"}</p>
      </button>
      <button
        className="sort-radio-container"
        onClick={() => {
          handleCheckBox(2);
        }}>
        <input
          id="sort-radio"
          className="sort-radio-btn"
          value={2}
          type="radio"
          tabIndex={-1}
          checked={sortCheckRadio === 2}
          onClick={(e) => {
            handleCheckBox(Number(e.currentTarget.value));
          }}
        />
        <p className="sort-radio-text">{"ציון ההתאמה (מהגבוה לנמוך)"}</p>
      </button>
    </div>
  );
};

const SideBarSortManagerObserver = observer(SideBarSortManager);
export default SideBarSortManagerObserver;
