import { observer } from "mobx-react-lite";
import React from "react";
import "./RequirementsCheckBox.scss";
interface IProps {
  setFilterRequirements?: (value: Array<string>) => void;
  filterRequirements?: Array<string>;
  setPopUpTrigger?: (boolean: boolean) => void;
  allFilterRequirements?: Array<string>;
  isBig?: boolean;
}

const RequirementsCheckBox: React.FC<IProps> = ({
  setFilterRequirements,
  filterRequirements,
  allFilterRequirements,
  isBig,
}) => {

  const handleCheck = (value: string) => {
    const index = filterRequirements?.indexOf(value);
    let tempFilterData = [...filterRequirements!];
    if (index === -1) {
      tempFilterData = [...tempFilterData, value];
      // setFilterRequirements!([...filterRequirements!, value]);
    } else if (index !== -1) {
      tempFilterData.splice(index!, 1);
    }
    setFilterRequirements!(tempFilterData);
  };

  return (
    <div
      className={
        isBig
          ? "check-box-popup-container-clicked "
          : "check-box-popup-container"
      }
    >
      <div className="container-pop-up-check-box">
        {allFilterRequirements!.map((requirement: string, index: number) => (
          <div className="requirements-container">
            <label
              htmlFor={`requirements-checkbox-${index}`}
              key={index}
              className="check-box-popup"
              tabIndex={1}
              role="checkbox"
              // onClick={(e) => handleCheck(requirement)}
              onKeyUp={(e) => e.key == "Enter" && handleCheck(requirement)}
            >
              <input
                id={`requirements-checkbox-${index}`}
                onClick={(e) => handleCheck(e.currentTarget.value)}
                className="input-popup-style"
                value={requirement}
                checked={filterRequirements?.indexOf(requirement) !== -1}
                type="checkbox"
                tabIndex={-1}
              />
              {requirement}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
const RequirementsCheckBoxObserver = observer(RequirementsCheckBox);
export default RequirementsCheckBoxObserver;
