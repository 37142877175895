import React from "react";
import rootStores from "../../../../BL/stores";
import "./CheckBoxComponent.scss";

import { useContext } from "react";
import { observer } from "mobx-react";
const filterStore = rootStores.FilterStore;
interface IProps {
  setCourseType: (value: string) => void;
}
const languageStore = rootStores.LangsStore;
const CheckBoxComponent: React.FC<IProps> = ({ setCourseType }) => {
  const { check, setCheck } = filterStore;

  // const [check, setCheck] = useState<number>(0);
  const handleCheckBox = (value: number) => {
    if (check === value) {
      setCourseType("");
      setCheck(0);
      return;
    }
    switch (value) {
      case 1:
        setCourseType("morning");
        setCheck(value);
        break;
      case 2:
        setCourseType("evening");
        setCheck(value);
        break;
      case 3:
        setCourseType("online");
        setCheck(value);
        break;
    }
  };

  return (
    <div className="course-format-main">
      <div className="course-format__container">

        <label
          className="course-format__label"
          htmlFor="morning-radio"
          tabIndex={0}
          key={1}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleCheckBox(Number(1));
          }}
        >
          <input
            tabIndex={-1}
            id="morning-radio"
            className="course-format__radio"
            aria-checked={check === 1}
            value={1}
            type="radio"
            checked={check === 1}
            onClick={(e) => {
              handleCheckBox(Number(e.currentTarget.value));
            }}
          />
          <span className="checkmark"></span>
          <span className="label-text">
            {languageStore.translate("CoursesByJobOccupationPage", "morning")}
          </span>
        </label>
      </div>
      <div className="course-format__container">
        <label
          htmlFor="evening-radio"
          className="course-format__label"
          tabIndex={0}
          key={2}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleCheckBox(Number(2));
          }}
        >

          <input
            tabIndex={-1}
            id="evening-radio"
            className="course-format__radio"
            value={2}
            type="radio"
            aria-checked={check === 2}
            checked={check === 2}
            onClick={(e) => {
              handleCheckBox(Number(e.currentTarget.value));
            }}
          />
          <span className="checkmark"></span>
          <span className="label-text">
            {languageStore.translate("CoursesByJobOccupationPage", "evening")}
          </span>
        </label>
      </div>
      <div className="course-format__container">
        <label
          htmlFor="online-radio"
          className="course-format__label"
          tabIndex={0}
          key={3}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleCheckBox(Number(3));
          }}
        >
          <input
            tabIndex={-1}
            id="online-radio"
            className="course-format__radio"
            value={3}
            aria-checked={check === 3}
            type="radio"
            checked={check === 3}
            onClick={(e) => {
              handleCheckBox(Number(e.currentTarget.value));
            }}
          />
          <span className="checkmark"></span>
          <span className="label-text">
            {languageStore.translate("CoursesByJobOccupationPage", "online")}
          </span>
        </label>
      </div>
    </div>
  );
};
const CheckBoxComponentObserver = observer(CheckBoxComponent);
export default CheckBoxComponentObserver;
