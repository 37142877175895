import { FC, useState } from "react";
import Tooltip from "../../../common/components/Tooltip/tooltip.cmp";
import './CourseLabel.scss'

interface ICourseLabel {
    label: string;
    content: string;
    isSubsidizedLink?: boolean;
}

export const CourseLabel: FC<ICourseLabel> = ({ label, content, isSubsidizedLink }) => {
    const [visible, setVisible] = useState<boolean>(false)
    return (
        <div
            className="label-container"
            onMouseLeave={(e) => { e.preventDefault(); setVisible(false) }}
            onMouseOver={(e) => { e.preventDefault(); setVisible(true) }}
        >
            <img className="label__icon" src={"data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAA4klEQVQokW3RvUpDURAE4O9egoiljT/IJeALCIogWoghWutTBCt/nsEyEK18CnsFOxXEv8IqYKGClQiCIGhlkXNhOWabPczsMjtninazL6sJLGMab7jAe002suEutjEasG8cYx/KQNxiLw3/4i71sYRfxYUu5tP7EVNYwAyeE76EXtFu9sfxEZSK1E+whQov0cN65mMRq9jECF4j2cBstnCdFGsv55Es8eR/3eAnnbWWK5wOWWjhAXM5UeITvSFntXCf4Yf1t+7iMhAVOgZp13WGnRjcCo4Mkq1wgEl8JXwD/gC82CrWmBiBFwAAAABJRU5ErkJggg=="} />
            <span className="label__title">{label}</span>
            <Tooltip closeFunction={() => setVisible(false)} visibility={visible} isUpper text={content} isSubsidizedLink={isSubsidizedLink} />
        </div>
    )
}