import React from "react";
import rootStores from "../../../../../BL/stores";
import "./headerTextInMobile.scss";

import { observer } from "mobx-react";
interface IProps {
  getBackLastOccupations: String;
  filterLength: number;
  recomendedOccupationExplnation: String;
  isLoadingJOC: boolean;
}
const languageStore = rootStores.LangsStore;

const HeaderTextInMobile: React.FC<IProps> = ({
  filterLength,
  isLoadingJOC,
}) => {
  return (
    <div className={"header-text-in-mobile"}>
      {isLoadingJOC && (
        <p className="sub-title-JO">המערכת מעבדת את הנתונים שלך</p>
      )}
      <div className="greetment-and-language-container-JO">
        <h1 className="h1-layout-keren bolded white-in-mobile blue-JO">
          {isLoadingJOC ? (
            "ומוצאת מסלולי הכשרה ותעסוקה שמתאימים לך!"
          ) : (
            <>
              {` ${languageStore.translate(
                "JobOccupationPage",
                "headerSecPart"
              )} ${filterLength} ${languageStore.translate(
                "JobOccupationPage",
                "headerFirstPart"
              )}`}
              &nbsp;
              <span >
                {languageStore.translate("JobOccupationPage", "hedearBr")}
              </span>
            </>
          )}
        </h1>
        {!isLoadingJOC && (
          <h4 className="mobile__subtitle">
            {/* אם משהו כאן נראה לך לא מתאים, מומלץ לבטל את ההתאמה באמצעות לחצן
          'הסרה'. */}
            {languageStore.translate(
              "JobOccupationPage",
              "recomended_occupation_explnation"
            )}
          </h4>
        )}
      </div>

      {/* <div className="last-line-header-container"> */}
      {/* </div> */}
    </div>
  );
};

const HeaderTextInMobileObserver = observer(HeaderTextInMobile);
export default HeaderTextInMobileObserver;
