import { useMemo } from "react";
import "../LanguageComponent/LanguageComponent.scss";
import { ReactComponent as DropDownArrow } from "../../../../assets/Drop_blue.svg";
import { ReactComponent as Female } from "../../../../assets/images/Gender/Female.svg";
import { ReactComponent as FemaleWhite } from "../../../../assets/images/Gender/FemaleWhite.svg";
import { ReactComponent as Male } from "../../../../assets/images/Gender/Male.svg";
import { ReactComponent as MaleWhite } from "../../../../assets/images/Gender/MaleWhite.svg";
import { ReactComponent as DropDownArrowWhite } from "../../../../assets/images/Gender/drop-lan-white.svg";
import rootStores from "../../../../BL/stores";
import { observer } from "mobx-react";
import { ILanguage } from "../../../../BL/stores/language.store";
interface Props {
  IsWhite: Boolean;
}

const languageStore = rootStores.LangsStore;
const authStore = rootStores.AuthStore;

const LanguageComponent: React.FC<Props> = ({ IsWhite }) => {

  const { languages, currentLanguageIndex, setLanguage } = languageStore;
  const { isUserLogged } = authStore;

  const renderLanguage = useMemo(() => {
    return languages.filter((l, i) => {
      if (i !== currentLanguageIndex) {
        return l;
      }
    });
  }, [currentLanguageIndex])

  const handleClickToChangeLan = (languageIndex: number, e: any): void => {
    setLanguage(languageIndex, isUserLogged);
    const temp = document.getElementsByClassName("option");
    for (let index = 0; index < temp.length; index++) {
      temp[index].classList.remove("show");
    }
  };

  const handleClickToPickLan = () => {
    const temp = document.getElementsByClassName("option");
    for (let index = 0; index < temp.length; index++) {
      temp[index].classList.toggle("show");
    }
  };

  return (
    <div className="lan-continuer">
      <div className="change-lan-div"
        tabIndex={0} role="button" aria-pressed="false"
        id="pick-language-button"

        onClick={handleClickToPickLan}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleClickToPickLan()
          }
        }}
      >
        {/* <div className={IsWhite? "select-white-in-mobile" : "select"} onClick={handleClickToPickLan}> */}
        <div className={IsWhite ? "in-selected-white" : "in-selected"} >
          <div
            style={{ display: "inline-block", verticalAlign: "text-top", minWidth: "9px" }}>
            {currentLanguageIndex === 0 ? (
              IsWhite ? (
                <MaleWhite
                  // style={{ width: "15%" ,  verticalAlign:"top"}}
                  // className={"display-in-mobile"}
                  // style={{ verticalAlign: "text-top" }}
                />
              ) : (
                /*&& <Male className={"display-none-in-mobile"}/>*/
                <Male
                //  style={{ verticalAlign: "top" }}
                 />
              )
            ) : IsWhite ? (
              <FemaleWhite
                style={{ verticalAlign: "top", paddingTop: "22%" }}
              /> /* && <Female className={"display-none-in-mobile"}/>*/
            ) : (
              <Female style={{ verticalAlign: "top", paddingTop: "22%" }} />
            )}
          </div>
          <p
            style={{
              display: "inline-block",
              paddingRight: currentLanguageIndex === 0 ? "1rem" : "0.7rem",
              paddingLeft: currentLanguageIndex === 0 ? "1rem" : "0.7rem",
              verticalAlign: "middle",
            }}
            className={IsWhite ? "default-lan-white" : "default-lan"}
          >
            {languages[currentLanguageIndex].gender}
          </p>
          <div
            style={{ display: "inline-block", verticalAlign: "middle" }}
            className={"drop-dawn-lan-img"}
          >
            {IsWhite ? <DropDownArrowWhite /> : <DropDownArrow />}
          </div>
        </div>
      </div>
      <div className="all-option">
        {renderLanguage.map((languagePick: ILanguage, index: number) => {
          return (
            <div className="option lang-options-text"
              id="change-language-button" tabIndex={0} role="button" aria-pressed="false"
              key={languagePick.id}
              onClick={(e) =>
                handleClickToChangeLan(languagePick.id, e.currentTarget)
              }
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleClickToChangeLan(languagePick.id, e.currentTarget)
                }
              }}
              style={{
                color: IsWhite ? "white" : "#0557fd", position: !IsWhite ? "absolute" : undefined
              }}
            >
              <div style={{ display: "inline-block", verticalAlign: "top", paddingLeft: "1rem", minWidth: "10%", cursor: "pointer" }}>
                {languagePick.id !== 1 ? (
                  IsWhite ? (
                    <MaleWhite className={"display-in-mobile"}
                      style={{ verticalAlign: "top" }} />
                  ) : (
                    <Male />
                  )
                ) : IsWhite ? (
                  <FemaleWhite className={"display-in-mobile"}
                    style={{ verticalAlign: "top" }} />
                ) : (
                  <Female />
                )}
              </div>
              <p className={IsWhite ? "default-white " : "lang-options-text"}
                style={{ display: "inline-block", verticalAlign: "top", cursor: "pointer", fontSize: "14px" }}>
                {languagePick.gender}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const LanguageComponentObserver = observer(LanguageComponent);
export default LanguageComponentObserver;
