import React, { useMemo } from "react";
import "./FirstRowMobileCourses.scss";
import rootStores from "../../../BL/stores";
import { observer } from "mobx-react-lite";
import BackWArrow from "../../../assets/Results images/Back to Res.svg";
import { ReactComponent as AlertIcon } from "../../../assets/Results images/menu_alert.svg";
import { ReactComponent as MenuIcon } from "../../../assets/Results images/Menu_white_mobile.svg";
import { useHistory } from "react-router-dom";
import { SideBarModals } from "../../../BL/stores/App.store";
import SkillIlOnTrialLogoDesktop from '../../../assets/logos/trial/Logo_Trial _Inner.png'
import SkillIlOnTrialLogoMobile from '../../../assets/logos/trial/Logo_Trial _Inner_Mobile.png'

const authStore = rootStores.AuthStore;
const appStore = rootStores.AppStore;

type Props = {
  moveBack: () => void,
  title?: string | null,
};
const FirstRowMobileCourses: React.FC<Props> = ({ moveBack, title }) => {
  const { setChosenModal } = appStore;
  const { isUserRegistered, isUserLogged } = authStore;

  const history = useHistory();

  const windowWidth = useMemo(() => {
    return window.innerWidth
  }, [window.innerWidth])


  const logoOnClick = () => {
    history.push("/");
  };
  return (
    <div tabIndex={0} className="first-row-in-mobile-JO" style={{ justifyContent: 'space-between' }}>
      <div className="header__menu-logo-container">
        {isUserRegistered && !isUserLogged ? (
          <AlertIcon className="menu-alert" onClick={() => setChosenModal(SideBarModals.aboutUs)} />
        ) : (
          <MenuIcon className="menu-white-mobile" onClick={() => setChosenModal(SideBarModals.aboutUs)} />
        )}
        {windowWidth > 767 ? (
          <div className="logo-row-JO">
            <div className="new-logo-JO" onClick={logoOnClick}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  logoOnClick();
                }
              }}
            >
              <img src={SkillIlOnTrialLogoDesktop} className="new-logo-flow2" alt="skill-logo מעבר לדף הבית" style={{ position: "relative" }} />
            </div>
          </div>
        ) : (
          <img src={SkillIlOnTrialLogoMobile} className="logo-mobile" onClick={logoOnClick} />
        )}
      </div>
      <div>
        <button className="back-btn__mobile" onClick={moveBack}>
          <img className="back-btn__icon" src={BackWArrow} />
          <p className="back-btn__text">{title}</p>
        </button>
      </div>
      <button className="back-btn__desktop" onClick={moveBack}>
        <img className="back-btn__icon" src={BackWArrow} />
        <p className="back-btn__text">{title}</p>
      </button>
    </div>
  );
};

const FirstRowMobileCoursesObsever = observer(FirstRowMobileCourses);
export default FirstRowMobileCoursesObsever;
