import rootStores from "../../BL/stores";
const languageStore = rootStores.LangsStore;

export const switchText = (index: number) => {
    switch (index) {
        case 0:
            return languageStore.translate("questionnairePage", "smileyText0");
        case 1:
            return languageStore.translate("questionnairePage", "smileyText1");;
        case 2:
            return languageStore.translate("questionnairePage", "smileyText2");;
        case 3:
            return languageStore.translate("questionnairePage", "smileyText3");;
        case 4:
            return languageStore.translate("questionnairePage", "smileyText4");;
    }
};