import './Footer.scss'
import { ReactComponent as EyeIcon } from '../../assets/footer/Eye.svg'
import { useLocation } from 'react-router-dom';

interface Props {
    setIsTermsModalOpen: (arg: boolean) => void;
}

const Footer: React.FC<Props> = ({ setIsTermsModalOpen }) => {
    const { pathname } = useLocation()

    return (
        <div className='main-footer'>

            <a
                className="main-footer__btn"
                onClick={() => setIsTermsModalOpen(true)}
                onKeyUp={(e) => e.key == "Enter" && setIsTermsModalOpen(true)}
                tabIndex={0}
                role={"button"}
            >
                תנאי השימוש
            </a>
            <a
                className="main-footer__btn"
                href="https://skill-il-cp.s3.eu-central-1.amazonaws.com/static/Skill-IL+%D7%94%D7%A6%D7%94%D7%A8%D7%AA+%D7%A0%D7%92%D7%99%D7%A9%D7%95%D7%AA+25.1.2023.pdf"
                target={"_blank"}
                rel="noreferrer"
            >
                הצהרת נגישות
                <EyeIcon className='main-footer__icon' />
            </a>
        </div>
    )

}

export default Footer