import { action, IObservableArray, makeAutoObservable, observable } from "mobx";
import { IJobOccupation } from "../../common/interfaces/Job-Occupation";
import { IUserInfoBody } from "../../common/interfaces/User_info.interface";
import { ReccomenededJobOccupation } from "../../common/MOCK/reccomendedJobOccupation";
import JobOccupationFetcher from "../../Infrastructure/fetchers/JobOccupationFetcher";
import { ICategories } from "../../Infrastructure/interfaces/CategoriesFetcher.interface";
import SortingUtils from "../../common/utils/sortingUtils";
import SerializationUtils from "../../common/utils/serializationUtils";
import { RootStore } from ".";

class JobOccupationStore {
  public jobOccupations: IObservableArray<IJobOccupation>;
  public categoriesList: IObservableArray<ICategories>;
  public selectedJobOccupation: IJobOccupation | null;
  public recommendedJobOccupations: IObservableArray<IJobOccupation>;
  public currentRecommendedJobOccupations: IObservableArray<IJobOccupation>;
  public recommendedJobOccupationsTrueCourse: IObservableArray<IJobOccupation>;
  public recommendedJobOccupation: IJobOccupation | null;
  public selectedRecommendJobOccupation: IJobOccupation | null;
  public currentCourseCount: number;
  private _rootStore: RootStore
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
    this.jobOccupations = observable([]);
    this.categoriesList = observable([]);
    this.recommendedJobOccupationsTrueCourse = observable([]);
    this.selectedJobOccupation = null;
    this.recommendedJobOccupations = observable([]);
    this.currentRecommendedJobOccupations = observable([]);
    this.selectedRecommendJobOccupation = null;
    this.recommendedJobOccupation = null;
    this.currentCourseCount = 0;
  }

  @action
  setCurrentCourseCount = (value: number) => {
    this.currentCourseCount = value;
  }

  @action
  setCategoriesList(value: ICategories[]) {
    this.categoriesList.replace(value);
  }

  @action
  setRecommendedJobOccupation = (recJob: IJobOccupation | null) => {
    this.recommendedJobOccupation = recJob;
  };

  @action
  getRecommendedJobOccupation = () => {
    this.recommendedJobOccupations.replace(ReccomenededJobOccupation);
  };

  @action
  fetchRecommendedJobOccupation = async (value: IUserInfoBody) => {
    try {
      const res: IJobOccupation[] =
        await JobOccupationFetcher.getRecommendedJobOccupationsByUserInfo(
          value
        );
      this.recommendedJobOccupations.replace(
        res.filter((rec) => Number(rec.match_percentage) > 0.1)
      );
      this.currentRecommendedJobOccupations.replace(res.slice(0, 7));
    } catch (err) {
      throw err;
    }
  };

  @action
  setRecommendedJobOccupationsTrueCourse = (
    recJobOccupation: Array<IJobOccupation>
  ): void => {
    this.recommendedJobOccupationsTrueCourse.replace(recJobOccupation);
  };
  @action
  setRecommendedJobOccupations = (
    recJobOccupation: Array<IJobOccupation>
  ): void => {
    this.recommendedJobOccupations.replace(recJobOccupation);
  };
  @action
  setCurrentRecommendedJobOccupations = (
    recJobOccupation: Array<IJobOccupation>
  ): void => {
    this.currentRecommendedJobOccupations.replace(recJobOccupation);
  };

  @action
  setSelectedJobOccupation = (
    selectedJobOccupation: IJobOccupation | null
  ): void => {
    this.selectedJobOccupation = selectedJobOccupation
      ? { ...selectedJobOccupation }
      : null;
  };

  //Should run on statrup
  @action
  getJobOccupations = async (): Promise<void> => {
    try {
      const JobOccupationsRes: Array<IJobOccupation> =
        await JobOccupationFetcher.getJobOccupations();
      if (JobOccupationsRes.length) {
        const spreadArrayOfObjectsToLocalStorage = SerializationUtils.spreadTypeLocalStorage([JobOccupationsRes[0], JobOccupationsRes[1]], 'male_name') // array of objects and value from the object type
        const spreadStringArrayToLocalStorage = SerializationUtils.spreadWithNumberOrStringLocalStorage([JobOccupationsRes[0].male_name, JobOccupationsRes[1].male_name]) // array of string or number
      }

      const sortedRes: IJobOccupation[] =
        SortingUtils.jobOccupationNameBubbleSort(JobOccupationsRes);
      this.jobOccupations.replace(sortedRes);
    } catch (err) {
      throw err;
    }
  };

  getAllCategories = async (): Promise<void> => {
    try {
      const interest: ICategories[] = await JobOccupationFetcher.getInterests();
      this.setCategoriesList(interest);
    } catch (err) {
      throw err;
    }
  };

  @action
  getJobOccupationsCourse = async (): Promise<void> => {
    try {
      const JobOccupationsRes: Array<IJobOccupation> =
        await JobOccupationFetcher.getJobOccupationsTrueCourse();
      const sortedRes: IJobOccupation[] =
        SortingUtils.jobOccupationNameBubbleSort(JobOccupationsRes);

      this.recommendedJobOccupationsTrueCourse.replace(sortedRes);
    } catch (err) {
      throw err;
    }
  };
}

export default JobOccupationStore;
