export const ReccomenededJobOccupation: Array<any> = [
  {
    name: "מנהל אזור",
    description:
      "אחריות על ניהול כל הנושאים הקשורים בְּחברה בסניף עסקי או באזור גאוגרפי מוגדר. קבלת הנחיות מהמשרד הראשי כדי ליישם את אסטרטגיית החברה, תוך התאמתה לשוק שבו פועל הסניף ובכפוף למבנה החברה. גיבוש חזון לגבי ניהול העובדים, התקשורת ומאמצי השיווק ומעקב עד להשגת התוצאות והיעדים.",
    salary: 7000,
    demand_percentage: 1,
    average_working_hours: null,
    distance_from_home: 5,
    match_percentage: 80,
    course_count: 1,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements:[ "תעודת בגרות"],
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל בכיר",
    description:
      "מילוי התפקיד הבכיר ביותר בפירמידת המבנה הארגוני. גיבוש הבנה כוללת של תפקוד הגוף העסקי, על מחלקותיו, הסיכונים שמולו ובעלי המניות שלו. ניתוח פריטי מידע מסוגים שונים ויצירת קישורים ביניהם כדי לקבל החלטות. שימוש כחוליה מקשרת מול מועצת המנהלים לצורכי דיווח ויישום האסטרטגיה הכוללת.",
    salary: 6000,
    demand_percentage: 1,
    average_working_hours: null,
    distance_from_home: 20,
    match_percentage: 70,
    course_count: 2,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל סוכנות ביטוח",
    description:
      "תיאום וניהול של הפעילות בגוף או בסניף של גוף שמציע שירותי ביטוח. מתן ייעוץ ללקוחות על מוצרים ביטוחיים.",
    salary: 5398,
    demand_percentage: 1,
    average_working_hours: null,
    distance_from_home: 80,
    match_percentage: 70,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל ספרייה",
    description:
      "פיקוח על שימוש נכון בציוד ובחפצים בספרייה. ניהול של השירותים המוענקים בספרייה ושל פעילות המחלקות השונות בספרייה. העברת הדרכות וחפיפה לצוות בתחילת העבודה וניהול תקציב הספרייה.",
    salary: 5888,
    demand_percentage: 3,
    average_working_hours: null,
    distance_from_home: 33,
    match_percentage: 44,
    course_count: 3,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל גלריית אמנות",
    description: "ניהול ההצלחה המסחרית והאומנותית של הגלריה.",
    salary: 9000,
    demand_percentage: 1,
    average_working_hours: null,
    distance_from_home: 15,
    match_percentage: 77,
    course_count: 52,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל מוזאון",
    description:
      "ניהול אוספי האומנות, הפריטים המוצגים ומתקני התצוגה. השגה ומכירה של יצירות אומנות מצד אחד, וחתירה לשימור של אוספי האומנות של המוזאון מצד שני. ניהול ההיבטים הכספיים, הצוות ומאמצי השיווק של המוזאון.",
    salary: 9999,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 55,
    match_percentage: 87,
    course_count: 4,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל בתחום השירותים המשפטיים",
    description:
      "אחריות על הניהול הכללי של משרד לעריכת דין. חתירה למתן ייעוץ ושירותים משפטיים ביעילות ואפקטיביות מרביות תוך ניהול של צוות מקצועי בתחום המשפטים. ניהול של לקוחות מסוגים שונים והתאמת השירותים המשפטיים לצורכי הלקוחות.",
    salary: 9000,
    demand_percentage: 3,
    average_working_hours: null,
    distance_from_home: 89,
    match_percentage: 67,
    course_count: 6,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל חנות",
    description: "אחריות על הצוות ועל הפעילויות בחנויות מתמחות.",
    salary: 12111,
    demand_percentage: 1,
    average_working_hours: null,
    distance_from_home: 9,
    match_percentage: 99,
    course_count: 7,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל בית עסק למסחר קמעוני",
    description: "אחריות על הצוות ועל הפעילויות בחלק מסוים בחנות.",
    salary: 13333,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 9,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל חנות מכולת",
    description: "אחריות על הצוות ועל הפעילויות בסופרמרקט.",
    salary: 19666,
    demand_percentage: 3,
    average_working_hours: null,
    distance_from_home: 82,
    match_percentage: 80,
    course_count: 8,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל סוכנות נסיעות",
    description:
      "אחריות על ניהול הצוות ועל ניהול הפעילויות בסוכנות נסיעות. ארגון, פרסום ומכירה של מוצרי תיירות ושל חבילות נופש לאזורים ספציפיים.",
    salary: 5111,
    demand_percentage: 1,
    average_working_hours: null,
    distance_from_home: 78,
    match_percentage: 80,
    course_count: 15,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל מוקד שירות טלפוני",
    description:
      "תיאום ותכנון של הפעילות היומיומית במוקדים טלפוניים. וידוא שפניות הלקוחות זוכות למענה יעיל בהתאם למדיניות. ניהול הצוות, המשאבים והנהלים כדי לשפר את שיטות העבודה ולהשיג רמות גבוהות יותר של שביעות רצון לקוחות.",
    salary: 11121,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 104,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל פרסום",
    description:
      "יישום של יוזמות הפרסום שתוכננו במסגרת התוכנית השיווקית האסטרטגית. ארגון והכנה של המשאבים הנדרשים להשקה של קמפייני פרסום ושל פעילויות במשרדי פרסום. הכנה של ערוצי התקשורת ויצירת תיאום ביניהם, ניהול משא ומתן על חוזים ווידוא שהפעילויות מתבצעות במסגרת התקציב.",
    salary: 23333,
    demand_percentage: 3,
    average_working_hours: null,
    distance_from_home: 310,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל שיווק",
    description:
      "יישום של יוזמות ומאמצים הקשורים לפעילויות השיווק בחברה. פיתוח תוכניות ואסטרטגיות שיווקיות באמצעות פירוט של העלויות והמשאבים הנדרשים. ניתוח הרווחיות של תוכניות אלו, פיתוח אסטרטגיות תמחור וחתירה להעלאת המודעות למוצרים ולחברות בקרב לקוחות בשוק המטרה.",
    salary: 5111,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 41,
    match_percentage: 80,
    course_count: 22,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל מכירות",
    description:
      "פיתוח אסטרטגיות מכירות ואיתור לקוחות בחברה. ניהול צוותי מכירות, הקצאת משאבי מכירות בהתבסס על התכנון, תעדוף של לידים קריטיים ומעקב אחר הטיפול בהם, פיתוח נאומי מכירה ועדכון שלהם לאורך הזמן וניהול של פלטפורמת מכירות למעקב אחר הלידים והמכירות.",
    salary: 22222,
    demand_percentage: 1,
    average_working_hours: null,
    distance_from_home: null,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל יחסי ציבור",
    description:
      "חתירה לבנייה של תדמית רצויה או מוניטין רצויים לחברה, ליחידים, למוסד ממשלתי או לארגון באופן כללי, עבור הציבור ועבור בעלי העניין, ולשמירה עליהם. שימוש בכלי תקשורת ובאירועים מכל הסוגים לקידום התדמית החיובית של המוצרים, המטרות ההומניטריות או הארגונים. חתירה לוודא שהלקוחות מצטיירים כפי שרוצים שייראו בכל המסרים הציבוריים בתקשורת.",
    salary: 3222,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 2,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל מתקנים",
    description:
      "תכנון אסטרטגי ותכנון תפעולי שוטף לצורך ניהול ותחזוקה של מבנים. בקרה על נוהלי בטיחות וגהות וניהולם, פיקוח על עבודת קבלנים, תכנון וביצוע של פעולות לתחזוקת מבנים, טיפול בנושאי אבטחה ובטיחות אש, פיקוח על פעולות הניקיון בבניין ועל שירותי התשתית ואחריות על ניהול החללים.",
    salary: 2222,
    demand_percentage: 3,
    average_working_hours: null,
    distance_from_home: 4,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל פיתוח בענפי טכנולוגיות המידע (ICT)",
    description:
      "סיוע בגיבוש החזון הטכני של החברה והובלת כל היבטי הפיתוח הטכנולוגי בהתאם לתוכנית האסטרטגית וליעדי הצמיחה. התאמה בין הטכנולוגיה והצרכים העסקיים.",
    salary: 13122,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 32,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל עיבוד נתונים",
    description:
      "ניהול של תפקידי כריית המידע ושל כלל הנתונים ברמה הארגונית. וידוא שמתבצע שימוש בנתונים כנכס עסקי ברמת ההנהלה הבכירה, יישום של תשתית רחבה ואחידה יותר לניהול מידע לטובת הארגון כולו ותמיכה בהטמעתה.",
    salary: 11111,
    demand_percentage: 1,
    average_working_hours: null,
    distance_from_home: 5,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מנהל פיתוח תוכנה",
    description:
      "ניהול הרכישה והפיתוח של מערכות תוכנה כדי לספק תמיכה לכל היחידות הארגוניות. ניטור התוצאות והאיכות של מיזמי התוכנה ופתרונות התוכנה השונים המיושמים בארגון.",
    salary: 10888,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 9,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "מורה לנהיגה",
    description:
      "ניהול הרכישה והפיתוח של מערכות תוכנה כדי לספק תמיכה לכל היחידות הארגוניות. ניטור התוצאות והאיכות של מיזמי התוכנה ופתרונות התוכנה השונים המיושמים בארגון.",
    salary: 8888,
    demand_percentage: 3,
    average_working_hours: null,
    distance_from_home: 9,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "אומן",
    description:
      "ניהול הרכישה והפיתוח של מערכות תוכנה כדי לספק תמיכה לכל היחידות הארגוניות. ניטור התוצאות והאיכות של מיזמי התוכנה ופתרונות התוכנה השונים המיושמים בארגון.",
    salary: 10888,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 9,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "אגרונום",
    description:
      "ניהול הרכישה והפיתוח של מערכות תוכנה כדי לספק תמיכה לכל היחידות הארגוניות. ניטור התוצאות והאיכות של מיזמי התוכנה ופתרונות התוכנה השונים המיושמים בארגון.",
    salary: 15888,
    demand_percentage: 3,
    average_working_hours: null,
    distance_from_home: 9,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
  {
    name: "משק בית",
    description:
      "ניהול הרכישה והפיתוח של מערכות תוכנה כדי לספק תמיכה לכל היחידות הארגוניות. ניטור התוצאות והאיכות של מיזמי התוכנה ופתרונות התוכנה השונים המיושמים בארגון.",
    salary: 10888,
    demand_percentage: 2,
    average_working_hours: null,
    distance_from_home: 9,
    match_percentage: 80,
    course_count: 5,
    top_courses: [
      {
        id: null,
        name: "חשמלאי מוסמך",
        location: "באר שבע",
        duration: {
          hours: 1080,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס התלמיד לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/%d7%97%d7%95%d7%91%d7%a8%d7%aa-%d7%a7%d7%95%d7%a8%d7%a1%d7%99%d7%9d-%d7%9e%d7%a8%d7%9b%d7%96-%d7%9c%d7%94%d7%9b%d7%a9%d7%a8%d7%94-%d7%95%d7%94%d7%a9%d7%aa%d7%9c%d7%9e%d7%95%d7%99%d7%95%d7%aa/#fb0=64",
      },
      {
        id: null,
        name: "חשמלאי מוסמך לבוגרי מכטרוניקה",
        location: "באר שבע",
        duration: {
          hours: 470,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "במסגרת הקורס הסטודנט לומד עריכת תוכניות טכניות לביצוע מתקני חשמל כאשר המתקן בעל עוצמת זרם שאינה עולה על 80×3 אמפר, עבודות הקמה והתקנה של מתקני חשמל, ביצוע בדיקות תקינות, איתור ותיקון תקלות במתקנים חשמליים, ביצוע שינויים ותוספות במתקנים חשמליים, פירוק מתקנים חשמליים, אחזקה שוטפת של מתקנים חשמליים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "ממונה בטיחות על העבודה",
        location: "באר שבע",
        duration: {
          hours: 330,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "המכללה הטכנולוגית באר שבע",
          location: "באר שבע",
          information: "",
          url: "https://tcb.ac.il/",
        },
        syllabus:
          "מטרת הקורס היא הקניית ידע והכשרה בסיסיים בטכנולוגיות התעשייתיות ובהתפתחותן, בחקיקה העוסקת בבטיחות ובגיהות תעסוקתיות, בעדכונים ובשינוים החלים בהן. הקורס עוסק בהכשרת ממונה על בטיחות וגיהות לעבודה מעשית בשטח ובהכנתו להתמודדות בבעיות הבטיחות והגיהות הקיימות או המתעוררות במפעל בחיי היום יום. תוכנית הלימודים נערכה כדי להכשיר את המשתתף לקראת ביצוע סדרת מטלות כממונה על בטיחות וגיהות בכוחות עצמו ובשיתוף פעולה עם גורמים נוספים. הכנת תוכנית הבטיחות היא נדבך חשוב בקורס ומהווה אתגר ותמורה גם יחד לבוגר הקורס ולמפעל.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://tcb.ac.il/wp-content/uploads/2019/05/Hoveret2019Web.pdf",
      },
      {
        id: null,
        name: "הנדסאי בקרה ואוטומציה",
        location: "תל אביב",
        duration: {
          hours: 1744,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          "הנדסאי בקרה ואוטומציה הינו אחד התחומים המרתקים והעשירים ביותר בלימודי הטכנולוגיה. זהו תחום מולטי-דיסציפלינרי אשר משלב הכשרה בתחומים רבים: תורת החשמל, מבוא לאלקטרוניקה, תכנות מחשבים, מערכות ספרתיות, רכיבים ומתמרים, מערכות פיקוד ובקרה, בקרים מתוכנתים. בוגר הנדסאי בקרה ואוטומציה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה תעשייתית, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות, תחזוקה, אבחון תקלות, פיקוח, חניכה ופיתוח ברצפת הייצור. בוגר הנדסאי מכשור ובקרה של רשת עתיד יהיה בעל ידע מדעי הנדסי נרחב בעולם מכשור ובקרה, ובעל יכולת לחבר בין הידע ההנדסי התיאורטי לבין תהליכי היישום לשירות ותחזוקה ברצפת הייצור. דיפלומת הנדסאי מכשור ובקרה מטעם רשת עתיד פורסת בפני הסטודנטים את כל תחומי העולם הטכני ומאפשרת להם למצוא עבודה במגוון רחב מאוד של עיסוקים וחברות. זאת, מאחר והמאפיין הבולט והייחודי של המקצוע הינו היכולת לבצע אינטגרציה בתכנון וביישום של תחומים שונים.",
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%91%D7%A7%D7%A8%D7%94-%D7%95%D7%90%D7%95%D7%98%D7%95%D7%9E%D7%A6%D7%99%D7%94",
      },
      {
        id: null,
        name: "הנדסאי מכשור רפואי",
        location: "תל אביב",
        duration: {
          hours: 2740,
          months: null,
        },
        cost: null,
        description: null,
        training_institution: {
          id: null,
          name: "מכללת עתיד - תל אביב",
          location: "תל אביב",
          information: "",
          url: "https://www.atid.org.il/tel-aviv",
        },
        syllabus:
          'לימודי הנדסאות מכשור רפואי מכשירים את הסטודנטים לטפל, לתחזק, להתאים ולתפעל את הציוד הרפואי בבתי חולים, במכונים ובחברות העוסקות בתחום המכשור הרפואי. המקצוע חשוב מכיוון שכדי לבצע בדיקות רפואיות שונות, ניתוחים ופרוצדורות רפואיות יש צורך בתפעול נכון ובפעולות תחזוקה של הציוד הרפואי, ובהתאמה מדויקת של הציוד לבעיה או לבדיקה של המטופל. לימודי הנדסאות כוללים סטאזw של 2 סמסטרים בבית חולים או מכון המאושר על ידי מה"ט. לאחר סמסטר אחד של התנסות מעשית המסיימים יקבלו תעודת טכנולוג רפואי מטעם רשת עתיד ויוכלו להשתלב בבתי חולים ובמכונים רפואיים כטכנולוג רפואי, מקצוע הנדרש על מנת להפעיל מכשירים מסויימים. לאחר סמסטר שני של התנסות מעשית למסיימים תוענק גם תעודת הנדסאי מכשור רפואי מטעם מהט. תעודה זו תאפשר לבוגרים להשתלב גם בעבודות הנוגעות לתחזוקה של המכשור הרפואי ולא רק הפעלתו.',
        language: "עברית",
        course_type: "לימודי ערב",
        basic_requirements: "תעודת בגרות",
        is_employment_guaranteed: true,
        course_registration_url:
          "https://www.atid.org.il/courses/%D7%94%D7%A0%D7%93%D7%A1%D7%90%D7%99-%D7%9E%D7%9B%D7%A9%D7%95%D7%A8-%D7%A8%D7%A4%D7%95%D7%90%D7%99",
      },
    ],
  },
];
