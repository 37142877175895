import React, { FC, useEffect, useState } from "react";
import "./account.scss";
import Close_White from "../../../../assets/Results images/Close-white.svg";

import leftArrow from "../../../../assets/images/Main/Arrow_main.png";
import AuthFetcher from "../../../../Infrastructure/fetchers/AuthFetcher";
import rootStores from "../../../../BL/stores/index";

import {
  JOB_OCCUPATION_STORE,
  LANGS_STORE,
  USER_INFO_STORE,
  CITIES_STORE,
  QUESTIONNAIRE_STORE,
  AUTH_STORE,
  APP_STORE
} from "../../../../BL/consts/stores";
import { observer } from "mobx-react";
import UserFetcher from "../../../../Infrastructure/fetchers/UserFetcher";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import authClass from "../../../../Infrastructure/auth/auth.class";
import { ModalStatus, SideBarModals } from "../../../../BL/stores/App.store";

const userInfoStore = rootStores[USER_INFO_STORE];
const jobOccupationStore = rootStores[JOB_OCCUPATION_STORE];
const languageStore = rootStores[LANGS_STORE];
const cityStore = rootStores[CITIES_STORE];
const questionsStore = rootStores[QUESTIONNAIRE_STORE];
const authStore = rootStores[AUTH_STORE];
const appStore = rootStores[APP_STORE];

export interface ISubmitResult {
  privatename?: string;
  lastname?: string;
  email?: any;
  password?: any;
}
export interface IForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
// type Props = {
//   setChosenModal: React.Dispatch<React.SetStateAction<any>>;
// };

const AccountManagment: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IForm>({
    criteriaMode: "all",
  });
  const {
    isUserLogged,
    setIsUserLogged,
    user,
    setUserDetails,
    logout,
    isUserRegistered,
    confirmLogin,
  } = authStore;
  const {
    setDataFromLogin,
    email,
    reSendVerificationMail,
    selectedPreviousJobs,
    setSelectedInterestsS,
    selectedInterestsS,
    selectedAge,
    selectedEducationalDegree,
    clearData,
  } = userInfoStore;
  const history = useHistory();

  ///
  const { selectedJobOccupation } = jobOccupationStore;
  const { setChosenModal } = appStore;
  const { IQuestionnaire_info, answers, questions } = questionsStore;
  const { currentLanguageIndex, languages } = languageStore;
  const { selectedCity, setSelectedCity } = cityStore;
  const [modalStatus, setModalStatus] = useState<ModalStatus>(
    ModalStatus.login
  );

  useEffect(() => {
    setModalStatus(
      isUserLogged
        ? ModalStatus.details
        : isUserRegistered
          ? ModalStatus.registered
          : ModalStatus.login
    );
  }, [isUserLogged, isUserRegistered]);

  const RegisteredComponent: FC = () => {
    return (
      <div className="account-content registered-content">
        <p className="registration_modal_header white">{"לתשומת ליבך,"}</p>

        <p className="registration_modal_header white bolded">
          {"תהליך ההרשמה לא הושלם."}
        </p>
        <div className="regisered-modal-container">
          <span className="white">{"חזרו לבדוק את תיבת הדוא״ל שלכם."}</span>
          <br />
          <span className="white">{"לא קיבלתם מייל? "}</span>
          <span
            onClick={reSendVerificationMail}
            className="white underline pointer"
          >
            {"שליחה חוזרת"}
          </span>
        </div>
      </div>
    );
  };
  const LoggedInUserComponent: FC = () => {
    return (
      <div className="logged-in-content">
        {/* <div className="header-row-registration-modal">
          <h1 className={"registration-modal-h1"}>פרטים אישיים</h1>
        </div> */}
        <div className="header-row-registration-modal">
          <div className="registration-modal-col" style={{ color: "#fff" }}>
            <span>היי {user?.first_name + " " + user?.last_name + ","}</span>
            <span>האם ברצונך להתנתק?</span>
            <div className="row">
              <button
                className="logout-button blue-button white"
                onClick={(e) => {
                  logout();
                  setUserDetails(null);
                  setSelectedCity(null);
                  setChosenModal(SideBarModals.none);
                  // setSelectedInterestsS([])
                  clearData();
                  history.push("/");
                }}
              >
                כן
              </button>
              <button
                className="logout-button"
                onClick={(e) => setChosenModal(SideBarModals.none)}
              >
                לא
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const LoginComponent: FC = () => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
    } = useForm<any>({
      criteriaMode: "all",
    });

    const onClickLoginFetch = async (data: any) => {
      setChosenModal(SideBarModals.none);
      confirmLogin(data.email, data.password);
      // try {
      //   const userData = await AuthFetcher.login(data.email, data.password)
      //   .then(res => {
      //     setUserDetails(res.user)
      //     setDataFromLogin(res.user); // only in user store
      //     setSelectedCity(res.user.city||null);
      //     setIsUserLogged(true)
      //   })

      // } catch (err) {

      // }
      // history.push("/")
    };

    return (
      <>
        <form className="account-content" onSubmit={handleSubmit(onClickLoginFetch)}>
          <div className="header-row-registration-modal">
            <h1 className={"registration-modal-h1"}>התחברות</h1>
          </div>
          <div className="header-row-registration-modal">
            <div
              className="registration-modal-login"
            >
              <input
                type={"text"}
                placeholder="מייל"
                className={"input-registration-modal"}
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <p className="p-md-JO" style={{ color: "whitesmoke" }}>
                  חובה להזין כתובת מייל
                </p>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <p className="p-md-JO" style={{ color: "whitesmoke" }}>
                  יש להזין כתובת דוא"ל תקינה
                </p>
              )}
            </div>
            <div className="registration-modal-login">
              <input
                type={"password"}
                placeholder="סיסמה"
                className={"input-registration-modal"}
                {...register("password", {
                  required: true,
                  maxLength: 12,
                  minLength: 8,
                  pattern:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,12}$/,
                })}
              />
              {errors.password && errors.password?.type === "maxLength" && (
                <p className="p-md-JO" style={{ color: "whitesmoke" }}>
                  ניתן להכניס עד 12 תווים
                </p>
              )}
              {errors.password && errors.password?.type === "minLength" && (
                <p className="p-md-JO" style={{ color: "whitesmoke" }}>
                  ניתן להכניס מינימום 8 תווים
                </p>
              )}
              {errors.password && errors.password?.type === "required" && (
                <p className="p-md-JO" style={{ color: "whitesmoke" }}>
                  הכנסת ערכים היא חובה{" "}
                </p>
              )}
              {errors.password && errors.password?.type === "pattern" && (
                <p className="p-md-JO" style={{ color: "whitesmoke" }}>
                  חובה להכניס סיסמה בין 8-12 תווים, אות גדולה אחת, סיפרה אחת
                  וסימן אחד{" "}
                </p>
              )}
            </div>
          </div>
          <div className="sign-in-row-registration-modal">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p
                  onClick={() => setModalStatus(ModalStatus["forgot_password"])}
                  className={"forgot-password-p"}
                  role={"button"}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setModalStatus(ModalStatus["forgot_password"])
                    }
                  }}
                >
                  שכחתי סיסמה
                </p>
              </div>
              <button
                type="submit"
                style={{
                  border: 0,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <p className={"registration-next-step-p"}>התחברות</p>
                <img
                  src={leftArrow}
                  className={"registration-modal-left-arrow"}
                  alt="press to- continue"
                />
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const ForgotPasswordComponent: FC = () => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
    } = useForm<any>({
      criteriaMode: "all",
    });

    const submit = async (data: any) => {
      setChosenModal(SideBarModals.none)
      await UserFetcher.passwordValid(data.email);
    };

    return (
      <>
        <form className="account-content" onSubmit={handleSubmit(submit)}>
          <div className="header-row-registration-modal">
            <h1 className={"registration-modal-h1"}>סיסמה חדשה</h1>
          </div>
          <div className="header-row-registration-modal">
            <span className="white text-registration-modal registration-modal-login">הזינו את כתובת הדוא"ל שלכם ומייל לאיפוס סיסמה ישלח לתיבה שלכם בדקות הקרובות</span>
            {/* <div className="registration-modal-col-for-password"> */}
            <div className="registration-modal-login">

              <input
                type={"text"}
                placeholder="דוא״ל"
                className={"input-registration-modal"}
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <p className="p-md-JO" style={{ color: "whitesmoke" }}>
                  חובה להזין כתובת מייל
                </p>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <p className="p-md-JO" style={{ color: "whitesmoke" }}>
                  יש להזין כתובת דוא"ל תקינה
                </p>
              )}
            </div>
          </div>
          <div className="sign-in-row-registration-modal">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p
                  className={"registration-modal-footer-label"}
                  onClick={() => setModalStatus(ModalStatus["login"])}
                >
                  יש לך חשבון?
                  <span
                    style={{ display: "block" }}
                    className={"registration-underline"}
                    role={"button"}
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setModalStatus(ModalStatus["login"])
                      }
                    }}
                  >
                    להתחברות
                  </span>
                </p>
              </div>
              <button
                type="submit"
                style={{
                  border: "0",
                  backgroundColor: "transparent",
                  marginBottom: "1rem",
                  cursor: "pointer",
                }}
              >
                <p className={"registration-next-step-p"}>שליחת מייל</p>
                <img
                  src={leftArrow}
                  className="registration-modal-left-arrow"
                  alt="press to- continue"
                />
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <div
      className="side-bar-trasparent-container"
      onClick={() => setChosenModal(SideBarModals.none)}
    >
      <div
        className="side-bar-main-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="side-bar-inside-container">
          <img
            src={Close_White}
            className={"close-white-registration-modal"}
            alt="close-white-registration"
            onClick={() => setChosenModal(SideBarModals.none)}
          />
          <div
            style={{
              height: "max-content",
              display: "block",
              padding: "1rem",
              // paddingRight: "2rem",
              justifyContent: "center",
              flexWrap: "wrap",
              boxSizing: "border-box"
            }}
          >
            {modalStatus === ModalStatus.details && (
              // isUserLogged
              <LoggedInUserComponent />
            )}
            {/* Modal Of Login */}
            {modalStatus === ModalStatus.registered && <RegisteredComponent />}

            {modalStatus === ModalStatus.login && <LoginComponent />}

            {modalStatus === ModalStatus.forgot_password && (
              <ForgotPasswordComponent />
            )}
          </div>
        </div>
        {/* <div className="arrow-left-container"> */}
        <div className="arrow-left"></div>
        {/* </div> */}
      </div>
    </div>
  );
};

const AccountManagmentObserver = observer(AccountManagment);
export default AccountManagmentObserver;
