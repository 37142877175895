import React from "react";
import "./ClickForLocation.scss";

import { useContext } from "react";
import rootStores from "../../../../BL/stores";
import { observer } from "mobx-react";
interface IProps {
  setLocationOfCourse: (boolean: boolean) => void;
  locationOfCourse: boolean;
}
const languageStore = rootStores.LangsStore;
const ClickForLocation: React.FC<IProps> = ({
  setLocationOfCourse,
  locationOfCourse,
}) => {


  return (
    <div className="click-on-loc-btn"
      id="turn-on-off"
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          setLocationOfCourse(!locationOfCourse);
        }
      }}
      onClick={(e) => {
        e.preventDefault();
        setLocationOfCourse(!locationOfCourse);
      }}
    >
      <label className="switch">
        <input type="checkbox" checked={locationOfCourse} />
        <span className="slider round"></span>
      </label>
      <p className="text-of-filter-location"> {languageStore.translate("CoursesByJobOccupationPage", "OnlyCoursesClose")} </p>
      <p className="text-of-filter-mobile"> רק הכשרות קרובות אלי </p>
    </div>

  );
};
const ClickForLocationObserver = observer(ClickForLocation);
export default ClickForLocationObserver;
