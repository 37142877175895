import { action, makeAutoObservable } from "mobx";
import { RootStore } from ".";
import AuthFetcher from "../../Infrastructure/fetchers/AuthFetcher";
import UserFetcher from "../../Infrastructure/fetchers/UserFetcher";
class AuthStore {
  // coursesByJobOccupation: IObservableArray<ICourse> = observable([]);

  isUserLogged: boolean;
  user: any;
  isUserRegistered: boolean;
  isShownRegistration: boolean;
  registrationModalSettings: {
    isOpen: boolean;
    isDetailsComplete: boolean;
    onCancel?: () => void;
    onClose?: () => void;
  };
  isOpenLoginConfirmModal: boolean = false;
  email: string = "";
  password: string = "";
  // אם יוזר לוג true לעביר לעיסוקים
  // אם לא מחובר להעביר להרשמה
  private _rootStore: RootStore
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
    this.isUserLogged = false;
    this.user = {};
    this.isUserRegistered = false;
    this.isShownRegistration = false;
    this.registrationModalSettings = {
      isOpen: false,
      isDetailsComplete: false,
    };
  }

  @action
  setRegistrationModalSettings = (
    isOpen: boolean,
    isDetailsComplete: boolean,
    onCancel?: () => void,
    onClose?: () => void
  ) => {
    const newSettings = {
      isOpen,
      isDetailsComplete,
      onCancel,
      onClose,
    };
    this.registrationModalSettings = newSettings;
  };

  @action
  onCancelRegisration = () => {
    this.registrationModalSettings.isOpen = false;
    this.registrationModalSettings.onCancel?.();
  };
  @action
  onCloseRegisration = () => {
    this.registrationModalSettings.isOpen = false;
    this.registrationModalSettings.onClose?.();
  };

  @action
  setIsUserLogged = (value: boolean) => {
    this.isUserLogged = value;
    this.isUserRegistered = value;
  };
  @action
  setIsUserRegistered = (value: boolean) => {
    this.isUserRegistered = value;
  };
  @action
  setIsShownRegistration = (value: boolean) => {
    this.isShownRegistration = value;
  };

  @action
  setUserDetails = (user: any) => {
    this.user = user;
  };

  @action
  logout = async () => {
    await UserFetcher.logout();
    this.setIsUserLogged(false);
  };

  @action
  confirmLogin = (email: string, password: string) => {
    this.email = email;
    this.password = password;
    this.setIsOpenLoginConfirmModal(true);
  };
  @action
  cancelLogin = () => {
    this.email = "";
    this.password = "";
    this.setIsOpenLoginConfirmModal(false);
  };

  @action
  login = async () => {
    try {
      this.setIsOpenLoginConfirmModal(false);
      const userData = await AuthFetcher.login(this.email, this.password);
      this.setUserDetails(userData.user);
      this.setIsUserLogged(true);
      return userData;
    } catch (error) {
      throw error;
    }
  };

  @action
  setIsOpenLoginConfirmModal = (value: boolean) => {
    this.isOpenLoginConfirmModal = value;
  };

  @action
  getQueriesRegistration = () => {
    if (!this.isUserLogged && !this.isShownRegistration) {
      setTimeout(() => {
        // this.isShownRegistration = this.isUserRegistered;
        this.setIsShownRegistration(this.isUserRegistered)
      }, 3000);
      return `registration=${this.isUserRegistered}&formNumber=${this.registrationModalSettings.isDetailsComplete ? "2" : "1"
        }`;
    }
    return "";
  };

  //   @action
  //   checkIsUserLogged = (value: boolean) => {
  //     this.isUserLogged = value;
  //   };
}

export default AuthStore;
