import { IQuestion } from "../../common/interfaces/Question";
import { BaseHttpFetcher } from "./BaseHttpFetcher";
import axios from "axios";
import ClientConfig from "../../common/config";
import { isJwtValidDecode } from "../../common/jwt";
import { getCookie } from "../../common/utils/CookieUtils";

class QuestionsFetcher extends BaseHttpFetcher {
  getQuestions = async (): Promise<IQuestion[]> => {
    try {
      const res = await axios.get(ClientConfig.apiBaseHost + "/question", {
        headers: { "Content-Type": "application/json" },
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  updateUserQuestionnaire = async (
    body: { question: string; answer: number }[]
  ): Promise<any> => {
    try {

      const isAccessTokenValid = await isJwtValidDecode();
      if (!isAccessTokenValid) throw new Error("משתמש לא נמצא");

      const res = await axios.put(
        ClientConfig.apiBaseHost + "/question",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getCookie("auth_token"),

          },
        }
      );
      return res.data;
    } catch (error) {
      Promise.reject(error);
    }
  };
}

export default new QuestionsFetcher(`question`);
