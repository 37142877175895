import React, { useEffect } from "react";
import { observer } from "mobx-react";
import "./GetCoursePageComponent.scss";
import { ICourse } from "../../../common/interfaces/Course";
import rootStores from "../../../BL/stores";
interface Props {
  moveBack: () => void;
  lastCourse: ICourse | undefined;
  nextCourse: ICourse | undefined;
  findLastObject: (course: ICourse) => void;
  findNextObject: (course: ICourse) => void;
  moveOnePage: () => void;
}

const courseStore = rootStores.CourseStore;
const languageStore = rootStores.LangsStore;

const GetCoursePageComponent: React.FC<Props> = ({ moveOnePage }) => {
  const { selectedCourse } = courseStore;
  const { translate } = languageStore;

  useEffect(() => {
    window.onpopstate = function () {
      moveOnePage()
    };
  }, [])

  return (
    <>
      <div className="main-details-container">
        <div className="details-content__container">
          <div className="details-about-course-container">
            <div className="course-content__container">
              {selectedCourse?.syllabus ||
                selectedCourse?.description ||
                selectedCourse?.basic_requirements ? (
                <div className="course-content__texts-container" tabIndex={0}>
                  <h4 className="course-content__title ultra-bolded" tabIndex={0}>
                    {translate("CoursePage", "aboutCourse")}
                  </h4>
                  {selectedCourse.description ? (
                    <p className="details__text" tabIndex={0} >{selectedCourse?.description}</p>
                  ) : (
                    <p className="details__text" tabIndex={0}>
                      {translate("CoursePage", "noData")}
                    </p>
                  )}
                  <div className="syllabus-container">
                    <h4 className="course-content__title ultra-bolded" tabIndex={0}>
                      {translate("CoursePage", "aboutSyllabus")}
                    </h4>
                    {selectedCourse.syllabus ? (
                      <p className="details__text" tabIndex={0}>{selectedCourse?.syllabus}</p>
                    ) : (
                      <p className="details__text" tabIndex={0}>
                        {translate("CoursePage", "noData")}
                      </p>
                    )}
                  </div>
                  <div className="requirement-conditions-container">
                    <h4 className="course-content__title ultra-bolded" tabIndex={0}>
                      {translate("CoursePage", "requirementConditions")}
                    </h4>
                    {selectedCourse.basic_requirements_details ? (
                      <p className="details__text" tabIndex={0}>
                        {selectedCourse.basic_requirements_details}
                      </p>
                    ) : (
                      <p className="details__text" tabIndex={0}>
                        {translate("CoursePage", "noData")}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="details-container-single-page">
                  <p className="details__text" tabIndex={0}>
                    {translate("CoursePage", "noData")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const GetCoursePageComponentObserver = observer(GetCoursePageComponent);
export default GetCoursePageComponentObserver;