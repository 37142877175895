import { IInterest } from "../../common/interfaces/Interest";
import {names} from './InterestNamesConst'

const interests: IInterest[] = [
 
  {
    //"תכנות וסייבר"
    name:names.computers,
    id: 15,
  },
  
  {
    //"ייצור מתקדם ותעשיה"
    name: names.manufacturing,
    id: 51,    
  },
  {
    //"רכש ולוגיסטיקה"
    name: names.logistic,
    id: 49,
  },
  {
    //"עיצוב ואומנות"
    name:names.design ,
    id: 271,
  },
  
  {
    //"בנייה ואחזקה"
    name: names.building,
    id: 47,
  },
  {
    // `בריאות וטיפוח אישי`
    name:names.health,
    id: 2931,
  },
  {
    //"ביטוח ופיננסים"
    name: names.finance,
    id: 13,
  },
  {
    //"משרד ותמיכה"
    name: names.office,
    id: 43,
  },
 
  {
//"הדרכה חינוך וייעוץ"
    name: names.education,
    id: 25,
  },
 
  {
    //"תיירות, מלונאות וטבחות"
    name: names.tourism,
    id: 39,
  },
  {
    //"מערכות מידע וQA"
    name: names.qa,
    id: 17,
  },
 
  {
    //"שיווק פרסום ודיגיטל"
    name: names.sales,
    id: 41,
  },
  {
    //"רכב / נהיגה"
    name: names.drive,
    id: 53,
  },
  
  {
    //"ניהול"
    name: names.mange,
    id: 11,
  },

];

export default interests;
