import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import rootStores from "../../../BL/stores";
import PopupQuestionComponent from "./PopupQuestionComponent";

interface Props {
    currentChapter: any;
    setIsSaved: any;
}

const questionnaireStore = rootStores.questionnaireStore;

const PopupQuestionsComponent: React.FC<Props> = ({ currentChapter, setIsSaved }) => {
    const { questionsClone } = questionnaireStore;
    const [questionsByChapter, setQuestionsByChapter] = useState<any>([]);

    useEffect(() => {
        let chapterQuestions: any = [];

        for (let i = 0; i < questionsClone.length; ++i) {
            currentChapter === parseInt(questionsClone[i].chapter) && chapterQuestions.push(questionsClone[i]);
        }
        setQuestionsByChapter(chapterQuestions);
    }, [currentChapter, questionsClone]);

    return (
        <div className="popup-questions-container">
            <div style={{ display: "flex", flexDirection: "column", }}>
                <div className="popup-question-component-div"><PopupQuestionComponent index={1} question={questionsByChapter[0]} setIsSaved={setIsSaved} /></div>
                <div className="popup-question-component-div"><PopupQuestionComponent index={2} question={questionsByChapter[1]} setIsSaved={setIsSaved} /></div>
                <div className="popup-question-component-div"><PopupQuestionComponent index={3} question={questionsByChapter[2]} setIsSaved={setIsSaved} /></div>
            </div>
            <div className="second-column-questions">
                <div className="popup-question-component-div"><PopupQuestionComponent index={4} question={questionsByChapter[3]} setIsSaved={setIsSaved} /></div>
                <div className="popup-question-component-div"><PopupQuestionComponent index={5} question={questionsByChapter[4]} setIsSaved={setIsSaved} /></div>
                <div className="popup-question-component-div"><PopupQuestionComponent index={6} question={questionsByChapter[5]} setIsSaved={setIsSaved} /></div>
            </div>
        </div>
    );
};

const PopupQuestionsComponentObserver = observer(PopupQuestionsComponent);
export default PopupQuestionsComponentObserver;