import React,{Suspense, useEffect, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./sideBar.scss";
import { ReactComponent as Alert } from "../../assets/images/SideBar/Alert01.svg";

import Retrain from "../../assets/retrain_icon_black.png";
import AccountManagement from "./modals/account/account";
import ContactUs from "./modals/contactUsModal/ContactUs";
import { observer } from "mobx-react";
import rootStores from "../../BL/stores";
import { AUTH_STORE, APP_STORE } from "../../BL/consts/stores";
import { SideBarModals } from "../../BL/stores/App.store";
import LogosComponent from './LogosComponent'

const authStore = rootStores[AUTH_STORE];
const appStore = rootStores[APP_STORE];

const SideBar = () => {
  const { isUserLogged, isUserRegistered, user } = authStore;
  const { chosenModal, setChosenModal } = appStore;
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    simulateDelay(); // Call the function to simulate the delay
  }, []);

  const simulateDelay = () => {

    setTimeout(() => {
      //TODO: when we get SVG logos - we can remove the delay
      setIsLoading(false);
    }, 4500);
  };

  const AccountManagementIcon = () =>
    isUserLogged ? (
      <div
        className="key-top-image-side-bar center column"
        onClick={() => {
          setChosenModal(
            chosenModal == SideBarModals.account
              ? SideBarModals.none
              : SideBarModals.account
          );
        }}
        role={"button"}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setChosenModal(
              chosenModal == SideBarModals.account
                ? SideBarModals.none
                : SideBarModals.account
            );
          }
        }}
      >
        <div className="userIcon white bolded">
          {user.first_name[0] + user.last_name[0]}
        </div>
        <div className="signout_side_bar p-13px ">התנתקות</div>
      </div>
    ) : (
      <>
        {isUserRegistered ? (
          <Alert
            className="key-top-image-side-bar alert-registration-side-bar"
            onClick={() => {
              setChosenModal(
                chosenModal == SideBarModals.account
                  ? SideBarModals.none
                  : SideBarModals.account
              );
            }}
            role={"button"}
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setChosenModal(
                  chosenModal == SideBarModals.account
                    ? SideBarModals.none
                    : SideBarModals.account
                );
              }
            }}
          />
        ) : // COMMENT THIS BACK TO DISPLAY MY-PROFILE ICON

          // <Login
          //   className="key-top-image-side-bar"
          //   onClick={() => {
          //     setChosenModal(
          //       chosenModal == SideBarModals.account
          //         ? SideBarModals.none
          //         : SideBarModals.account
          //     );
          //   }}
          //   role={"button"}
          //   tabIndex={0}
          //   onKeyPress={(e) => {
          //     if (e.key === "Enter") {
          //       setChosenModal(
          //         chosenModal == SideBarModals.account
          //           ? SideBarModals.none
          //           : SideBarModals.account
          //       );
          //     }
          //   }}
          // />

          // COMMENT THIS BACK TO DISPLAY MY-PROFILE ICON

          null}
      </>
    );

  const contactUsAlt = "צרו קשר";
  return (
    <>
      {chosenModal != SideBarModals.none && (
        <div
          className="bg-side-bar"
          onClick={() => setChosenModal(SideBarModals.none)}
        />
      )}
      <div className={"slider-bar-container"}>
        <div
          className="side-bar-page"
          style={{
            display: history.location.pathname === "/" ? "none" : "block",
          }}
        >
          {chosenModal == SideBarModals.account && <AccountManagement />}
          {chosenModal === SideBarModals.contactUs && <ContactUs />}
          {/* {window.innerWidth > 699 &&  */}
          {/* <div className="language-component-side-bar-bg desktop" style={{ display: location.pathname === "/" ? "none" : "block" }}> */}
          {/* <div className="language-component-side-bar"> */}
          {/* <LanguageComponentObserver IsWhite={false} /> */}
          {/* </div> */}
          {/* </div>} */}
          <div className="side-bar-content">
            {
              isLoading? //TODO: add loader here
              <div></div>
              :
              <LogosComponent/>
            }              
          
            <div className={"ls-container"}>
              <a
                href={"https://retrain.ai/"}
                target={"_blank"}
                rel="noreferrer"
                style={{
                  height: "auto",
                  textAlign: "center",
                }}
              >
                <img
                  src={Retrain}
                  style={{ filter: "opacity(0.7)" }}
                  className="sidebar-logo"
                  alt="קישור לאתר ריטריין"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const SideBarObserver = observer(SideBar);
export default SideBarObserver;
