import { observer } from "mobx-react";
import "./HollandQuestionnaire.scss"

interface Props {
  saveForAnotherTimeFunc: any;
}
const SaveForAnotherTimeComponent: React.FC<Props> = ({ saveForAnotherTimeFunc }) => {

  return (
    <>
      <button
        className="save-questionnaire-btn"
        id="save-questionnaire-btn"
        onClick={saveForAnotherTimeFunc}
      >
        שמירה לפעם אחרת
        {/* {languageStore.translate("questionnairePage", "skip")} */}
      </button>
    </>
  );
};

const SaveForAnotherTimeComponentObserver = observer(SaveForAnotherTimeComponent);
export default SaveForAnotherTimeComponentObserver;
