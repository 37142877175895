export const USERS_STORE = 'UsersStore';
export const CITIES_STORE = 'CitiesStore';
export const JOB_OCCUPATION_STORE = "JobOccupationStore";
export const USER_INFO_STORE = "UserInfoStore";
export const COURSE_STORE ="CourseStore"
export const LANGS_STORE = "LangsStore"
export const FILTER_COURSE_STORE = "FilterStore"
export const JOB_OCCUPATION_FILTER_STORE = "JobOccupationFiltersStore"
export const QUESTIONNAIRE_STORE = "questionnaireStore"
export const AUTH_STORE = "AuthStore"
export const APP_STORE = "AppStore"