import { toJS } from 'mobx'
import { useMemo, useState, useEffect } from 'react'
import './SearchEngine.scss'
import ButtonImg from '../../assets/images/homepage/search_button.png'
import { IJobOccupation } from '../../common/interfaces/Job-Occupation';
import rootStores from '../../BL/stores/index';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import JobOccupationFetcher from '../../Infrastructure/fetchers/JobOccupationFetcher';
import { sendSearch } from '../../common/utils/SendSearch.util';

interface Props {
    data: any,
    moveNextPage: any;
    chosenJobOccupation: IJobOccupation | null;
    setChosenJobOccupation: (job: IJobOccupation | null) => void;
}

const languageStore = rootStores.LangsStore;
const userInfoStore = rootStores.UserInfoStore;
const jobOccupationsStore = rootStores.JobOccupationStore;
const filterCourseStore = rootStores.FilterStore;


const SearchEngine: React.FC<Props> = ({ moveNextPage, data, chosenJobOccupation, setChosenJobOccupation }) => {

    // CREATE BY DORIN HAVARDI

    const { currentLanguageIndex } = languageStore;
    const { setUserPath } = userInfoStore;
    const { setRecommendedJobOccupations } = jobOccupationsStore;
    const { resetAllFilters } = filterCourseStore;

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isFocus, setIsFocus] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("");
    const [select, setSelect] = useState<string>("");
    const [chosenFlow, setChosenFlow] = useState<string>("");

    const history = useHistory();

    useEffect(() => {

    }, [chosenJobOccupation])

    const onChangeText = (value: any) => {
        if (value.length > 1) {
            setIsOpen(true);
            setSearch(value);
        }
        if (value.length < 2) {
            setIsOpen(false)
            setSearch(value);
        }
    }

    // FOR ACCESSIBILITY 
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSelect(event.target.value);
        }
    };
    // FOR ACCESSIBILITY 

    const handleSelect = (value: any) => {
        setSearch(value.label)
        setSelect(value)
        setIsOpen(false)
        setChosenJobOccupation(value.value);
    }

    const handleSearch = (value: string, data: any) => {
        // GET ALL JOB OCCUPATIONS
        const filterStart = toJS(data)
            .filter(
                (job: IJobOccupation) => job.course_count !== null && job.course_count !== undefined && job.course_count > 0
            );

        // GET ONLY JOB OCCUPATIONS WITH COURSES
        const coursesCountFilter = toJS(filterStart)
        // .filter(
        //     (job: IJobOccupation) =>
        //         job.course_count !== null || job.course_count !== undefined ||
        //         job.course_count > 0
        // );

        // GET ALL JOB OCCUPATIONS BY SEARCH VALUE
        const filteredData = coursesCountFilter.filter(
            (job: IJobOccupation) => job.male_name.toLowerCase().includes(value.toLowerCase()) || job.female_name?.toLowerCase().includes(value.toLowerCase())
        );

        // SORT RESULTS BY ABC ACCORDING TO SELECTED GENDER
        filteredData.sort((a: any, b: any) =>
            currentLanguageIndex === 0
                ? a.male_name.indexOf(value) === 0 && b.male_name.indexOf(value) !== 0
                    ? -1
                    : a.male_name.indexOf(value) !== 0 && b.male_name.indexOf(value) === 0
                        ? 1
                        : 0
                : a.female_name.indexOf(value) === 0 && b.female_name.indexOf(value) !== 0
                    ? -1
                    : a.female_name.indexOf(value) !== 0 && b.female_name.indexOf(value) === 0
                        ? 1
                        : 0
        );

        // SEND SEARCH VALUE TO DB FUNCTION 
        value && filteredData.length === 0 &&
            sendSearch(value);

        return filteredData.map((jobResult: any) => {
            // RETURN FILTERED SORTED SEARCH RESULTS BY GNEDER
            if (currentLanguageIndex === 0) return { value: toJS(jobResult), label: jobResult.male_name };
            else return { value: toJS(jobResult), label: jobResult.female_name };
        });
    }

    const handleSubmit = () => {
        setChosenFlow("short");
        setUserPath(true);
        setRecommendedJobOccupations([]);
        resetAllFilters();
    }

    const searchResults = useMemo(() => handleSearch(search, data), [search, data]);

    return (
        <>
            <div className='search-field__container'>
                <div className='field-and-btn__container'>
                    <div className="search-field__wrapper">
                        <input className="search-field__input"
                            placeholder={'למשל: יועצת ארגונית'}
                            value={search.length > 0 ? search : ""}
                            onChange={(e) => onChangeText(e.target.value)}
                            onFocus={() => setIsFocus(true)}
                            onBlur={() => setIsFocus(false)}
                        />
                        <div className="search-field__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20.47 20.438" className="icon__magnifying-glass" fill={isFocus ? "#22184E" : "white"}>
                                <path id="Forma_1_copy_7" data-name="Forma 1 copy 7" d="M6440.01,710.312l-4.22-4.209a8.564,8.564,0,0,0,.84-8.253,8.619,8.619,0,0,0-1.84-2.762,8.747,8.747,0,0,0-2.77-1.841,8.629,8.629,0,0,0-6.72,0,8.622,8.622,0,0,0,0,15.917,8.661,8.661,0,0,0,8.27-.841l4.21,4.2a1.575,1.575,0,0,0,2.68-1.1A1.507,1.507,0,0,0,6440.01,710.312Zm-7.46-5.222a5.467,5.467,0,1,1,1.62-3.885A5.291,5.291,0,0,1,6432.55,705.09Z" transform="translate(-6420 -692.562)" />
                            </svg>
                        </div>
                    </div>
                    <div className='search-btn__container'>
                        <button className='search-btn__button' onClick={chosenJobOccupation ? moveNextPage : () => { }}>
                            {/* <button className='search-btn__button' onClick={handleSubmit}> */}
                            <img src={ButtonImg} alt="חיפוש" className='search-btn__img' />
                        </button>
                    </div>
                </div>
                <>
                    <div className="search-field__list"
                        style={{ display: isOpen ? 'flex' : 'none' }}
                    >
                        {searchResults !== undefined ? searchResults
                            .sort((a: any, b: any) => a.label > b.label ? 1 : -1)
                            .map((jobOccupation: any, i: number) => {
                                return (
                                    <p key={i} className="job-occupations__option" onClick={() => handleSelect(jobOccupation)} role="button" tabIndex={0} onKeyDown={handleKeyDown}>
                                        {jobOccupation.label}
                                    </p>
                                )
                            }) : null}

                    </div>
                    {isOpen ?
                        <p className='search-field__cant-find'>{"לא מוצאים? נסו איות אחר או "}
                            <span className='cant-find__btn' onClick={() => history.push(`/long`)}>{"לחצו כאן"}</span>
                        </p>
                        : null
                    }
                </>
            </div>
        </>
    )
}

export default observer(SearchEngine)