import CityStore from "./City.store";
import CourseStore from "./Course.store";
import JobOccupationStore from "./JobOccupation.store";
import languageStore from "./language.store";
import UserInfoStore from "./UserInfo.store";
import JobOccupationFiltersStore from "./JobOccupationFilter.store";
import FilterCourseStore from "./CourseFilters.store";
import QuestionnaireStore from "./questionnaire.store"
import AuthStore from "./Auth.store"
import AppStore from "./App.store";

export class RootStore {
  // UsersStore:User;
  LangsStore:languageStore;
  CitiesStore:CityStore;
  JobOccupationStore:JobOccupationStore;
  UserInfoStore:UserInfoStore;
  FilterStore:FilterCourseStore;
  JobOccupationFiltersStore:JobOccupationFiltersStore;
  questionnaireStore:QuestionnaireStore;
  AuthStore:AuthStore;
  AppStore:AppStore;
  CourseStore:CourseStore;

  constructor(){
    this.LangsStore = new languageStore(this);
    this.CitiesStore = new CityStore(this);
    this.JobOccupationStore = new JobOccupationStore(this)
    this.UserInfoStore = new UserInfoStore(this)
    this.FilterStore = new FilterCourseStore(this)
    this.JobOccupationFiltersStore = new JobOccupationFiltersStore(this)
    this.questionnaireStore = new QuestionnaireStore(this)
    this.AuthStore = new AuthStore(this)
    this.AppStore = new AppStore(this)
    this.CourseStore = new CourseStore(this)

  }
  // export const CITIES_STORE = 'CitiesStore';
  // export const JOB_OCCUPATION_STORE = "JobOccupationStore";
  // export const USER_INFO_STORE = "UserInfoStore";
  // export const COURSE_STORE ="CourseStore"
  // export const LANGS_STORE = "LangsStore"
  // export const FILTER_COURSE_STORE = "FilterStore"
  // export const JOB_OCCUPATION_FILTER_STORE = "JobOccupationFiltersStore"
  // export const QUESTIONNAIRE_STORE = "questionnaireStore"
  // export const AUTH_STORE = "AuthStore"
  // export const APP_STORE = "AppStore"
}

export default new RootStore()

/**
 * Initiate all stores
 */

// const citiesStore = new CityStore();
// const jobOccupationsStore = new JobOccupationStore();
// const userInfoStore = new UserInfoStore();
// const courseStore = new CourseStore();
// const languagesStore = new languageStore();
// const filterCoursesStore = new FilterCourseStore();
// const jobOccupationFiltersStore = new JobOccupationFiltersStore()
// const questionnaireStore=new QuestionnaireStore();
// const authStore = new AuthStore()
// const appStore = new AppStore()
// /**
//  * Save the instance in global object
//  */
// const rootStores = {
//   [CITIES_STORE]: citiesStore,
//   [JOB_OCCUPATION_STORE]: jobOccupationsStore,
//   [USER_INFO_STORE]: userInfoStore,
//   [COURSE_STORE]: courseStore,
//   [LANGS_STORE]: languagesStore,
//   [FILTER_COURSE_STORE]: filterCoursesStore,
//   [JOB_OCCUPATION_FILTER_STORE]:jobOccupationFiltersStore,
//   [QUESTIONNAIRE_STORE]:questionnaireStore,
//   [AUTH_STORE]:authStore,
//   [APP_STORE]:appStore
// };

// // Debugging purpose
// if (process.env.NODE_ENV !== "production") {
//   (window as any)["stores"] = rootStores;
// }

// export default rootStores;
