import oneIcon from "../../../assets/images/Smylis/Focused/01.png"
import twoIcon from "../../../assets/images/Smylis/Focused/02.png"
import threeIcon from "../../../assets/images/Smylis/Focused/03.png"
import fourIcon from "../../../assets/images/Smylis/Focused/04.png"
import fivetIcon from "../../../assets/images/Smylis/Focused/05.png"
import oneIconGray from "../../../assets/images/Smylis/01.png"
import twoIconGray from "../../../assets/images/Smylis/02.png"
import threeIconGray from "../../../assets/images/Smylis/03.png"
import fourIconGray from "../../../assets/images/Smylis/04.png"
import fivetIconGray from "../../../assets/images/Smylis/05.png"


import  {ReactComponent as FirstIconWhite} from "../../../assets/images/Smylis/white_smilyes/01.svg" 
import  {ReactComponent as SecondIconWhite} from "../../../assets/images/Smylis/white_smilyes/02.svg" 
import  {ReactComponent as ThirdIconWhite} from "../../../assets/images/Smylis/white_smilyes/03.svg" 
import  {ReactComponent as FourthIconWhite} from "../../../assets/images/Smylis/white_smilyes/04.svg" 
import  {ReactComponent as FifthIconWhite} from "../../../assets/images/Smylis/white_smilyes/05.svg" 

import  {ReactComponent as FirstIconLightBlue} from "../../../assets/images/Smylis/Light_blue/01.svg" 
import  {ReactComponent as SecondIconLightBlue} from "../../../assets/images/Smylis/Light_blue/02.svg" 
import  {ReactComponent as ThirdIconLightBlue} from "../../../assets/images/Smylis/Light_blue/03.svg" 
import  {ReactComponent as FourthIconLightBlue} from "../../../assets/images/Smylis/Light_blue/04.svg" 
import  {ReactComponent as FifthIconLightBlue} from "../../../assets/images/Smylis/Light_blue/05.svg" 
export interface IImge {
  grayIcon: string;
  blueIcon: string;
  value: number;
  whiteIcon:any;
  lightBlueIcon:any;
}

export  const iconsOfSmiles: IImge[] = [
  {
    grayIcon: oneIconGray,
    blueIcon: oneIcon,
    value: 5,
    whiteIcon:FirstIconWhite,
    lightBlueIcon:FirstIconLightBlue
  },
  {
    grayIcon: twoIconGray,
    blueIcon: twoIcon,
    value: 4,
    whiteIcon:SecondIconWhite,
    lightBlueIcon:SecondIconLightBlue

  },
  {
    grayIcon: threeIconGray,
    blueIcon: threeIcon,
    value: 3,
    whiteIcon:ThirdIconWhite,
    lightBlueIcon:ThirdIconLightBlue
  },
  {
    grayIcon: fourIconGray,
    blueIcon: fourIcon,
    value: 2,
    whiteIcon:FourthIconWhite,
    lightBlueIcon:FourthIconLightBlue
  },
  {
    grayIcon: fivetIconGray,
    blueIcon: fivetIcon,
    value: 1,
    whiteIcon:FifthIconWhite,
    lightBlueIcon:FifthIconLightBlue
  },
];
