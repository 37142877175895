import {
  action,
  IObservableArray,
  makeAutoObservable,
  observable,
} from "mobx";
import { RootStore } from ".";
import { ICourse } from "../../common/interfaces/Course";
import SessionUtils from "../../common/utils/SessionUtils";


class CourseStore {
  coursesByJobOccupation: IObservableArray<ICourse> = observable([]);
  private _rootStore: RootStore
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
    this.coursesByJobOccupation.replace([]);
    //////////////////////////////////////////////
    this.selectedCourse = null;

    // id: number;
    //  name: string;
    //   location: string;
    //    duration: { hours: number; days: number; months: number; };
    //     cost: number;
    //      description: string;
    //       training_institution: { id: number; name: string; url: string; };
    //        syllabus: string;
    //         basic_requirements: string[];
    //        }
  }

  selectedCourse: ICourse | null;
  @action
  setSelectedCourse = (selectedCourse: ICourse | null) => {
    this.selectedCourse = selectedCourse;
  };

  @action
  setCoursesByJobOccupation = (
    coursesByJobOccupationIArry: Array<ICourse>
  ) => {
    this.coursesByJobOccupation.replace(coursesByJobOccupationIArry);
  };

  @action
  setIsPopupShown = () => {
    this._rootStore.UserInfoStore.addOneClickToCoursePage();
    if (SessionUtils.getItem("popupHasShown")) {
      return;
    }
    if (this._rootStore.UserInfoStore.getCoursePageClicks == 2) {
      this._rootStore.UserInfoStore.setIsFeedbackPopupOpen(true)
      SessionUtils.setItem("popupHasShown", true)
    }
    else {
      return;
    }
  }
}

export default CourseStore;
