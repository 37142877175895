import { observer } from "mobx-react";
import "./ChangeAnswerPopup.scss"
import AnswersClose from "../../../assets/images/Answer/Answers_Close.svg";
interface Props {
    setChangeAnswerPopup: any;
}
const ExitButtonAnswerPopup: React.FC<Props> = ({ setChangeAnswerPopup }) => {

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <button
                tabIndex={4}
                style={{ border: "none", backgroundColor: "transparent", cursor: "pointer" }}
                onClick={() => { setChangeAnswerPopup(false) }}
            >
                <img className="answer-close-img" src={AnswersClose} alt="AnswersClose" />
            </button>
        </div>
    );
};

const ExitButtonAnswerPopupObserver = observer(ExitButtonAnswerPopup);
export default ExitButtonAnswerPopupObserver;
