import React, { useEffect, useState } from "react";
import rootStores from "../../../../BL/stores/index";
import "./jobOpucationCard.scss"
import { IJobOccupation } from "../../../../common/interfaces/Job-Occupation";
import { ReactComponent as CloseIconDark } from "../../../../assets/icons/job-occupation-card/close_dark.svg"
import { ReactComponent as CloseIconLight } from "../../../../assets/icons/job-occupation-card/close_light.svg"
import { ReactComponent as InfoIconPurple } from "../../../../assets/icons/job-occupation-card/info_purple.svg"
import { ReactComponent as InfoIconWhite } from "../../../../assets/icons/job-occupation-card/info_white.svg"
import { observer } from "mobx-react";
import PopUpCardJobComponentObserver from "./PopUpCardJobComponent";
import Tooltip from "../../../../common/components/Tooltip/tooltip.cmp";
interface IProps {
  name?: string;
  description?: string;
  demand?: string | null;
  average_working_hours?: number;
  distance_from_home?: number;
  match_percentage?: any;
  course_count?: number | null;
  salary?: number;
  setIsQuestionMarkClicked: (value: boolean, ref: any) => void;
  isQuestionMarkClicked: boolean;
  monthlyHours?: number;
  selectedQuestionMark: any;
  navigationIndexCard: number;
  moveToPage?: (
    value: string,
    jobOccupation: IJobOccupation,
    index: number,
    userPath: boolean
  ) => void;
  job_occupation?: IJobOccupation;
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
  id?: string;
}

const userInfoStore = rootStores.UserInfoStore;
const JobOccupationFilterStore = rootStores.JobOccupationFiltersStore;

const JObOccupationCard: React.FC<IProps> = ({
  name,
  salary,
  course_count,
  demand,
  match_percentage,
  moveToPage,
  job_occupation,
  ref,
  id
}) => {
  const { userPath } = userInfoStore;
  const { nameToDrop, setNameToDrop } = JobOccupationFilterStore;

  const [demandTooltip, setDemandTooltip] = useState(false);
  const [percentagePopUp, setPercentagePopUp] = useState(false);

  function numberWithCommas(x: number) {
    const temp = Math.floor(x / 1000) > 0 ? Math.floor(x / 1000) + "," : "";
    const temp1 = x % 1000;
    const temp2 = temp1 < 10 ? "00" + temp1 : temp1 < 100 ? "0" + temp1 : temp1;
    return temp.toString() + temp2.toString();
  }

  const showDemandTooltip = (e: any) => {
    if (course_count != 0) {
      setDemandTooltip(true);
    }
  };

  const hideDemandTooltip = (e: any) => {
    e.preventDefault();
    setDemandTooltip(false);
  };

  const handleClickPercentage = (e: any) => {
    setPercentagePopUp(true);
  };


  useEffect(() => {
    setPercentagePopUp(false);
  }, [name]);

  const handleChosenOccupation = () => {
    if (course_count !== 0) {
      moveToPage!(`${name}`, job_occupation!, job_occupation?.index || 0, userPath)
    }
  }

  return (
    <>
      {percentagePopUp ? (
        <PopUpCardJobComponentObserver
          setPercentagePopUp={setPercentagePopUp}
          job_occupation={job_occupation!}
        />
      ) : (
        <div
          className="occupation-card__container"
          style={{ backgroundColor: match_percentage < 0.9 ? "white" : "#0C7AF6" }}
          ref={ref}
          id={id}
        >
          <div className="occupation-card__percentage-and-remove">
            <div className="occupation-card__percentage-and-details">
              <p
                tabIndex={1}
                role="button"
                onKeyDown={(e) => e.key == "Enter" && handleClickPercentage(e)}
                onClick={handleClickPercentage}
                className={
                  match_percentage < 0.9
                    ? "occupation-card__match-percentages"
                    : "occupation-card__match-percentages high-match"
                }
              >
                {`${Math.trunc(match_percentage * 100)}% התאמה`}
                <br />
                <p
                  tabIndex={1}
                  className={
                    match_percentage < 0.9
                      ? "occupation-card__match-percentages details"
                      : "occupation-card__match-percentages details high-match"
                  }
                >
                  {"+ לפירוט"}
                </p>
              </p>
            </div>
            <div className="occupation-card__remove-icon-container">
              <button
                title="הסר עיסוק"
                tabIndex={1}
                onClick={(e) => {
                  e.stopPropagation();
                  setNameToDrop!([...nameToDrop!, name!]);
                }}
                className={
                  match_percentage < 0.9
                    ? "p-sm-JO half-bolded black btn-JOC pointer"
                    : "p-sm-JO half-bolded white btn-JOC pointer"
                }
              >
                {match_percentage < 0.9 ? (
                  <CloseIconDark className="occupation-card__remove-card" />
                ) : (
                  <CloseIconLight className="occupation-card__remove-card" />
                )}
              </button>
            </div>
          </div>
          <div className="occupation-card__main-details"
            role="button"
            onClick={handleChosenOccupation}
            onKeyPress={handleChosenOccupation}
          >
            <div className="occupation-card__occupation-name">
              <p
                tabIndex={1}
                className={
                  course_count === 0
                    ? "JOC-title-and-description-counter-null occupation-name-text p-30-JO"
                    : match_percentage < 0.9
                      ? "occupation-card__occupation-name-text"
                      : "occupation-card__occupation-name-text high-match"
                }
              >
                {name}
              </p>
            </div>
            <div className="occupation-card__salary-and-demand">
              <div className="occupation-card__high-demand">
                {salary && (
                  <p className={salary && match_percentage < 0.9 ? "occupation-card__salary-demand-text salary-text__mobile-br" : "occupation-card__salary-demand-text high-match salary-text__mobile-br"} tabIndex={1}>
                    {`שכר ממוצע בתחום: `}
                    <span style={{overflowWrap: "break-word"}}>{`${numberWithCommas(salary)}`}&nbsp;₪</span>
                  </p>
                )}
              </div>
              <div className="occupation-card__high-demand">
                {demand === "high" && (
                  <>
                    {/* <Tooltip visibility={demandTooltip} closeFunction={hideDemandTooltip} /> */}
                    <div className="high-demand__container" role="button"

                    // FUTURE TOOLTIP DO NOT REMOVE

                    // onClick={showDemandTooltip}
                    // onTouchStart={showDemandTooltip}
                    // onMouseEnter={(e) => {
                    //   e.preventDefault()
                    //   showDemandTooltip(e)
                    // }}
                    // onMouseLeave={(e) => {
                    //   e.preventDefault()
                    //   hideDemandTooltip(e)
                    // }}
                    // onKeyPress={demandTooltip ? hideDemandTooltip : showDemandTooltip}
                    >
                      {/* {match_percentage < 0.9 ?
                          <InfoIconPurple className="occupation-card__info-icon" />
                          : <InfoIconWhite className="occupation-card__info-icon" />} */}

                      {/* FUTURE TOOLTIP DO NOT REMOVE */}

                      <p className={match_percentage < 0.9 ? "occupation-card__salary-demand-text dark-purple" : "occupation-card__salary-demand-text high-match"} tabIndex={1}>
                        {"בדרישה גבוהה"}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="occupation-card__button-container"
            role="button"
            tabIndex={1}
            onClick={handleChosenOccupation}
            onKeyPress={handleChosenOccupation}
          // onClick={() =>
          //   course_count !== 0
          //     ? moveToPage!(`${name}`, job_occupation!, job_occupation?.index || 0, userPath)
          //     : null
          // }
          >
            <div className={match_percentage < 0.9 ? "occupation-card__occupations-button" : "occupation-card__occupations-button--high-match"}>
              <p className={match_percentage < 0.9 ? "occupation-card__occupations-button-text" : "occupation-card__occupations-button-text--high-match"}>
                <span className="desktop-only">{'נמצאו'}</span>
                {` ${course_count} הכשרות`}
              </p>
            </div>
            <svg className="occupation-card__occupations-round-button" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id={match_percentage < 0.9 ? "occupations-round-button__arrow-circle" : "occupations-round-button__arrow-circle--high-match"}
                d="M37.5 0C58.1782 0 75 16.8218 75 37.5C75 58.1782 58.1782 75 37.5 75C16.8218 75 0 58.1782 0 37.5C0 16.8218 16.8218 0 37.5 0ZM25.9156 39.7094L41.5406 55.3343C42.15 55.9437 42.95 56.25 43.75 56.25C44.55 56.25 45.3501 55.9437 45.9595 55.3343C47.1813 54.1125 47.1813 52.1375 45.9595 50.9156L32.5437 37.5L45.9593 24.0844C47.1812 22.8625 47.1812 20.8875 45.9593 19.6657C44.7375 18.4438 42.7625 18.4438 41.5406 19.6657L25.9156 35.2907C24.6937 36.5126 24.6937 38.4875 25.9156 39.7094Z"
                fill={match_percentage < 0.9 ? "#401B8F" : "white"} />
            </svg>
          </div>
        </div>
      )}
    </>
  );
};

const JObOccupationCardObsever = observer(JObOccupationCard);
export default JObOccupationCardObsever;