import React, { useMemo } from "react";
import "./SmileyPageComponent.scss";
import { observer } from "mobx-react";
import rootStores from "../../../BL/stores";
import BackArrow from "../../../assets/images/BackArrow.svg";
import { iconsOfSmiles, IImge } from "./Smiley";
import { IQuestion } from "../../../common/interfaces/Question";
import SmileysIconsComponent from "./SmileysIconsComponent";
import SaveForAnotherTimeComponent from "../SaveForAnotherTimeComponent";
import { DisplayState } from "../../../BL/stores/questionnaire.store";
import { switchText } from "../../../common/utils/SmileyText.util";
interface IProps {
  displayState: DisplayState;
  moveBetweenQuestionsSmiley?: any;
  saveForAnotherTimeFunc?: any;
  setDisplayState?: any;
  doneOrSkipOrSaveQuestionnaire?: boolean
  setDoneOrSkipOrSaveQuestionnaire?: (value: boolean) => void;
  setLevel?: any;
}

const questionsStore = rootStores.questionnaireStore;

const SmileyPageComponent: React.FC<IProps> = ({
  displayState,
  moveBetweenQuestionsSmiley,
  saveForAnotherTimeFunc,
}) => {

  const { selectedQuestion, questions } = questionsStore;

  const questionIndexInChapter = useMemo(() => {
    const questionIndex: number = questions.findIndex(
      (ques: IQuestion) => ques._id === selectedQuestion?._id
    );
    return questionIndex % 6 + 1;
  }, [selectedQuestion])

  return (
    <div className="smile-container-page">
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between"
      }}>
        {
          displayState === DisplayState.introduction &&
          iconsOfSmiles.map((smiley: IImge, index: number) => (
            <div key={index}>
              <div className="smiley-desktop-mobile">
                <img
                  className={"smiley-image-icon-introduction"}
                  src={smiley.grayIcon}
                  alt={switchText(index)}
                />
                <p className="introduction-smiley-text" tabIndex={0}>{switchText(index)}</p>
              </div>
            </div>
          ))
        }
      </div>
      {displayState === DisplayState.Questionnaire &&
        <div>
          <p className="h2-JO-smileys" tabIndex={0}> {`שאלה ${questionIndexInChapter} / 6`} </p>
          <div className="question-value-container">
            <p className="h1-JO-smileys" tabIndex={0}> {selectedQuestion?.value} </p>
          </div>
          <div>
            <SmileysIconsComponent moveBetweenQuestionsSmiley={moveBetweenQuestionsSmiley} />
          </div>
          <div className="arrows-container-smiley">
            <div
              className="right-previous-container"
              role={"button"}
              tabIndex={0}
              aria-pressed={"false"}
              onClick={() => {
                moveBetweenQuestionsSmiley("backward", selectedQuestion!._id);
              }}
            >
              <img
                className="move-previous-question-back-arrow-smiley-page"
                style={{
                  color: "#162543",
                  cursor: selectedQuestion?._id === questions[0]._id ? "" : "pointer",
                  opacity: selectedQuestion?._id === questions[0]._id ? 0.4 : 1
                }}
                src={BackArrow}
                alt="arrow"
              />
              <p className="back-arrow-text"
                tabIndex={0}
                style={{
                 

                  cursor: selectedQuestion?._id === questions[0]._id ? "" : "pointer",
                  opacity: selectedQuestion?._id === questions[0]._id ? 0.4 : 1
                }}>
                {"לשאלה הקודמת"}
              </p>
            </div>
            <div className="save-for-another-time-component-container-mobile">
              <SaveForAnotherTimeComponent saveForAnotherTimeFunc={saveForAnotherTimeFunc} />
            </div>
          </div>
        </div>
      }
    </div>
  );
};
const SmileyPageComponentObservable = observer(SmileyPageComponent);
export default SmileyPageComponentObservable;