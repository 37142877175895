import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import rootStores from "../../../BL/stores";
import "./ChangeAnswerPopup.scss"
import PopupChaptersComponent from "./PopupChaptersComponent";
import PopupQuestionsComponent from "./PopupQuestionsComponent";
import SaveButtonAnswerPopup from "./SaveButtonAnswerPopup";
import ResetButtonAnswerPopup from "./ResetButtonAnswerPopup";
import ExitButtonAnswerPopup from "./ExitButtonAnswerPopup";
import ResetPopupConfirmation from "./ResetPopupConfirmation";
import QuestionsFetcher from "../../../Infrastructure/fetchers/QuestionsFetcher";
import { DisplayState } from "../../../BL/stores/questionnaire.store";
import SessionUtils from "../../../common/utils/SessionUtils";
interface Props {
    setChangeAnswerPopup: any;
    setQuestionnaireStateToDisplay: any;
    setDoneOrSkipOrSaveQuestionnaire: any;
}

const questionnaireStore = rootStores.questionnaireStore;
const authStore = rootStores.AuthStore;

const ChangeAnswerPopup: React.FC<Props> = ({ setChangeAnswerPopup, setQuestionnaireStateToDisplay, setDoneOrSkipOrSaveQuestionnaire }) => {
    const { isUserLogged } = authStore
    const {
        questions,
        questionsClone,
        resetAnswers,
        setQuestions,
        setSelectedQuestion,
        setCloneQuestions,
        calculateQuestionaire,
        setTopTwo,
    } = questionnaireStore

    const [currentChapter, setCurrentChapter] = useState<number>(1);
    const [isReset, setIsReset] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(() => {
        setCloneQuestions();
    }, []);

    const resetAnswersFunc = () => {
        const emptyTopTwo = {
            realistic: 0,
            investigative: 0,
            artistic: 0,
            social: 0,
            enterprising: 0,
            conventional: 0,
        };
        setTopTwo(emptyTopTwo);
        setDoneOrSkipOrSaveQuestionnaire(false);
        resetAnswers();
        setSelectedQuestion(questions[0]);
        setQuestionnaireStateToDisplay(DisplayState.introduction);
        setChangeAnswerPopup(false);
    }

    const saveAnswers = () => {
        try {
            setQuestions(questionsClone);
            let previousAnswers: {
                answer: number;
                question: string;
            }[] = [];
            questionsClone.forEach((q) => {
                SessionUtils.setItem(q._id, q.answer)
                previousAnswers.push({
                    answer: q.answer!,
                    question: q._id,
                })
            }
            );
            isUserLogged && QuestionsFetcher.updateUserQuestionnaire(previousAnswers);
            calculateQuestionaire();
            setIsSaved(true);

        } catch (error) {
            alert("חלה בעיה בשמירת התשובות, אנא נסו שוב")
        }

    }

    return (
        <div className="change-answer-pop-up-opacity-bg">
            <div className="change-answer-pop-up-container-bg" 
            >
                <div className="change-answer-pop-up-container-header">
                    <div className="top-functional-buttons-container" >
                        <div className="change-answer-title-container" tabIndex={2}>
                            <p className="change-answer-title-top text-white">{"התשובות"}</p>
                            <p className="change-answer-title-bottom text-white bolded">{"שלך"}</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {!isReset &&
                                <div className="reset-save-buttons">
                                    <ResetButtonAnswerPopup setIsReset={setIsReset} />
                                    <SaveButtonAnswerPopup saveAnswersFunc={saveAnswers} isSaved={isSaved} />
                                </div>
                            }
                            <ExitButtonAnswerPopup setChangeAnswerPopup={setChangeAnswerPopup} />
                        </div>
                    </div>
                </div>
                <div className="change-answer-pop-up-container-body">
                    {isReset ?
                        <div>
                            <ResetPopupConfirmation resetAnswers={resetAnswersFunc} setIsReset={setIsReset} />
                        </div>
                        :
                        <div className="change-answer-chapters-questions-container">
                            <div className="text-white div-popupChapters">
                                <div>
                                    <PopupChaptersComponent currentChapter={currentChapter} setCurrentChapter={setCurrentChapter} />
                                </div>
                                <div className="popup-questions-component-container">
                                    <PopupQuestionsComponent currentChapter={currentChapter} setIsSaved={setIsSaved} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const ChangeAnswerPopupObserver = observer(ChangeAnswerPopup);
export default ChangeAnswerPopupObserver;