class SerializationUtils {
  static spreadTypeLocalStorage<T>(value: Array<T>, paramName: keyof T) {
    let str = "";
    value.forEach((res, i) =>
      i === value.length - 1
        ? (str += res[paramName])
        : (str += res[paramName] + ",")
    );
    return str;
  }

  static spreadWithNumberOrStringLocalStorage<T = string | number>(value: Array<T>) {
    let str = "";
    value.forEach((res, i) =>
      i === value.length - 1
        ? (str += res)
        : (str += res + ",")
    );
    return str;
  }
}

export default SerializationUtils;
