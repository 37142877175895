import React from "react";
import CSS from "csstype";
import "./contactUsForm.scss";
import leftArrow from "../../../../../assets/images/Main/Arrow_main.png";
import UserFetcher from "../../../../../Infrastructure/fetchers/UserFetcher";
import { useForm } from "react-hook-form";
import { SideBarModals } from "../../../../../BL/stores/App.store";

interface Props {
  setChosenModal: (value: SideBarModals) => void;
  width?: string | number;
  height?: string | number;
  style?: CSS.Properties;
  className?: string;
  title?: string;
}
export interface IContactDTO {
  fullname: string;
  email: string;
  phone_number: string;
  content: string;
}

const ContactUsForm: React.FC<Props> = ({ style, title, setChosenModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IContactDTO>({
    criteriaMode: "all",
  });

  const submit = async (data: IContactDTO) => {
    await UserFetcher.contactMail(data)
      .then((res) => {
        alert("מייל נשלח בהצלחה!");
        setChosenModal(SideBarModals.none);
      })
      .catch((err) => alert("משהו השתבש אנא נסו שנית"));
  };

  const supportEmail = "MeravAl@jdc.org";

  return (
    <form onSubmit={handleSubmit(submit)} className="contact-us-form">
      {title && (
        <div className="header-cotactUsForm" tabIndex={0}>
          <h1 className="h1-cotactUsForm">{title.split(" ")[0]}</h1>
          <h1 className="h1-cotactUsForm" style={{ color: "white", fontWeight: 1000 }}>
            {title?.split(" ")[1]}
          </h1>
          {/* <p className="p-lg-JO white p-email">{supportEmail}</p> */}
        </div>
      )}
      <div className="input-contact-container ">
        <input
          className="contacu-us-inputForm p-md-JO white-text"
          type="text"
          placeholder="שם מלא"
          // {...register("fullname", { required: true, maxLength: 20,pattern:/^[a-z\u0590-\u05fe(\s)?)+]+$/i })}
          {...register("fullname", {
            required: true,
            maxLength: 20,
            pattern:
              /^[a-zA-Z\u0590-\u05fe]{2,}(?: [a-zA-Z\u0590-\u05fe]+){0,}$/,
          })}
          aria-errormessage="errInput1"
          aria-invalid={errors.fullname ? true : false}

        />
      </div>
      {/* {errors.fullname?.types?. && */}
      {/* <p className="p-md-JO" style={{ position: "fixed" }}>
          שם מלא באותיות
        </p> */}
      {errors.fullname && errors.fullname.type === "required" && (
        <p id="errInput1" className="p-md-JO contact-us-error">שם מלא באותיות</p>
      )}
      {errors.fullname && errors.fullname.type === "pattern" && (
        <p id="errInput1" className="p-md-JO contact-us-error"> שם מלא באותיות בלבד</p>
      )}
      {errors.fullname && errors.fullname.type === "maxLength" && (
        <p id="errInput1" className="p-md-JO contact-us-error">יש למלא עד 20 תווים</p>
      )}
      <div className="input-contact-container">
        <input
          className="contacu-us-inputForm p-md-JO white-text"
          type="email"
          placeholder={'דוא"ל'}
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          aria-errormessage="errInput2"
          aria-invalid={errors.email ? true : false}

        />
      </div>
      {errors.email && errors.email.type === "required" && (
        <p id="errInput2" className="p-md-JO contact-us-error">חובה להזין כתובת מייל</p>
      )}
      {errors.email && errors.email.type === "pattern" && (
        <p id="errInput2" className="p-md-JO contact-us-error">יש להזין כתובת דוא"ל תקינה</p>
      )}
      <div className="input-contact-container">
        <input
          className="contacu-us-inputForm p-md-JO white-text"
          type="tel"
          placeholder="טלפון"
          // {...register("phone_number",{required:true,maxLength:10,minLength:7,pattern:/^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/})}
          {...register("phone_number", {
            required: true,
            maxLength: 10,
            minLength: 7,
            pattern: /^05\d([-]{0,1})\d{7}$/,
          })}
          aria-errormessage="errInput3"
          aria-invalid={errors.phone_number ? true : false}

        />
      </div>
      {errors.phone_number && errors.phone_number?.type === "required" && (
        <p id="errInput3" className="p-md-JO contact-us-error">יש להזין מספר טלפון תקין </p>
      )}
      {errors.phone_number && errors.phone_number?.type === "pattern" && (
        <p id="errInput3" className="p-md-JO contact-us-error">יש להזין מספר טלפון תקין </p>
      )}
      {errors.phone_number && errors.phone_number?.type === "maxLength" && (
        <p id="errInput3" className="p-md-JO contact-us-error">
          {" "}
          יש להזין מספר טלפון עד 10 ספרות{" "}
        </p>
      )}
      {errors.phone_number && errors.phone_number?.type === "minLength" && (
        <p id="errInput3" className="p-md-JO contact-us-error">
          {" "}
          יש להזין מספר טלפון מעל 7 ספרות{" "}
        </p>
      )}

      <div className="input-contact-container">
        <input
          className="contacu-us-inputForm p-md-JO white-text"
          type="text"
          placeholder={"הודעה"}
          {...register("content", { required: true, maxLength: 40 })}
          aria-errormessage="errInput4"
          aria-invalid={errors.content ? true : false}
        />
      </div>
      {errors.content && errors.content?.type === "maxLength" && (
        <p id="errInput4" className="p-md-JO contact-us-error">ניתן להכניס עד 40 תווים</p>
      )}
      {/* {errors.content && errors.content?.type === "minLength" && <p className="p-md-JO contact-us-error" >
        ניתן להכניס מינימום 8 תווים
      </p>} */}
      {errors.content && errors.content?.type === "required" && (
        <p id="errInput4" className="p-md-JO contact-us-error">הכנסת ערכים היא חובה </p>
      )}
      <button
        type="submit"
        className="send-container"
        style={{ opacity: "1", cursor: "pointer" }}
      >
        <p className="p-send">שליחה </p>
        <img
          className="arrow-send-contact"
          src={leftArrow}
          alt="שליחת"
        />
      </button>
      <p className="pop-up-message-sent p-lg-JO white"></p>
      <p className="pop-up-message-sent p-lg-JO white"></p>
    </form>
  );
};
export default ContactUsForm;